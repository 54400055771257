import actions from './actions'

const state = () => ({
    history: [],
    loading: true,
});

// getters
const getters = {};

// mutations
const mutations = {
    set_history(state, payload) {
        state.history = payload
        state.loading = false
    },
    set_loader(state, value) {
        state.loading = value
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};