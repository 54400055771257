<template>
  <div>
    <v-navigation-drawer
      v-cloak
      class="core-drawer green-bg"
      width="256px"
      :permanent="$vuetify.breakpoint.mdAndUp"
      mobileBreakpoint="2400"
      v-model="drawer"
      :mini-variant.sync="mini"
      mini-variant-width="67"
    >
      <div
        class="d-flex align-center mt-8"
        style="margin-bottom: 42px"
        @click="mini = !mini"
      >
        <Logo class="drawer-logo mr-auto" />
        <v-btn icon>
          <img
            src="@/assets/images/chevron-left.svg"
            width="24px"
            height="24px"
            class="mr-4 drawer-arrow"
          />
        </v-btn>
      </div>
      <v-list nav>
        <v-list-item-group v-model="item">
          <v-list-item
            :class="$store.state.promo.view === i.title ? 'v-list-item--active' : ''"
            v-for="i in items.filter((i) => i.show === true)"
            :key="i.title"
            @click="setSelected(i.title)"
            :to="i.link"
          >
            <v-list-item-icon>
              <img width="24px" height="24px" class="v-icon" :src="i.icon" alt="" />
            </v-list-item-icon>
            <v-list-item-content
              :style="selected === i.title ? { color: '#00746b' } : { color: 'white' }"
            >
              <v-list-item-title>{{ $t(`${i.title}`) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-if="$store.getters['app/isItt'] && $store.getters['app/hasPromoAccess']"
            key="mobilepromonew"
            href="https://corporate.parkingbnb.world/new"
          >
            <v-list-item-icon>
              <img
                width="24px"
                height="24px"
                class="v-icon"
                :src="require('@/assets/images/Promo.svg')"
              />
            </v-list-item-icon>
            <v-list-item-content
              :style="{ color: 'white' }"
            >
              <v-list-item-title>{{ $t('mobile_promo') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar absolute top class="drawer-icon" @click="drawer = !drawer">
      <v-app-bar-nav-icon class="drawer-burger ml-0 mr-4"></v-app-bar-nav-icon>
      <h4 class="main-title white--text">{{ shortname }}</h4>
    </v-toolbar>
  </div>
</template>

<script>
import Logo from "@/components/core/Logo";

export default {
  name: "PromoDrawer",
  components: {
    Logo,
  },
  computed: {
    selected: {
      get() {
        return this.$store.state.promo.view;
      },
      set(item) {
        this.$store.commit("promo/set_view", item);
      },
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    places() {
      return this.$store.state.app.places;
    },
    company() {
      return this.$store.state.app.user.company_name;
    },
    shortname() {
      let shortname = "";
      if (this.isCompany) {
        this.company.split(" ").map((word) => {
          shortname = shortname + word.charAt(0);
        });
      } else if (this.places[0]) {
        this.places[0].place_name.split(" ").map((word) => {
          shortname = shortname + word.charAt(0);
        });
      }
      return shortname;
    },
    items() {
      return [
        {
          title: "promo_form",
          icon:
            this.selected === "promo_form"
              ? require("@/assets/images/Promo Green.svg")
              : require("@/assets/images/Promo.svg"),
          link: "/promo/customer-service",
          show: true,
        },
        {
          title: "promo_history",
          icon:
            this.selected === "promo_history"
              ? "https://storage.googleapis.com/parkingbnb-html-images/%20Parking%20history%20green.svg"
              : "https://storage.googleapis.com/parkingbnb-html-images/%20Parking%20history.svg",
          link: "/promo/history",
          show: true,
        },
        {
          title: "parking_payment",
          icon:
            this.selected === "parking_payment"
              ? "https://storage.googleapis.com/parkingbnb-html-images/ Payments green.svg"
              : "https://storage.googleapis.com/parkingbnb-html-images/ Payments.svg",
          link: "/promo/parking-payment",
          show: this.$store.getters["app/isCyber"] || this.$store.getters["app/isItt"] || this.$store.getters["app/isGoldin"],
        },
        {
          title: "subscriptions",
          icon:
            this.selected === "subscriptions"
              ? require("@/assets/images/subscriptionsGreen.webp")
              : require("@/assets/images/subscriptions.webp"),
          link: "/promo/subscriptions",
          show: this.$store.getters["app/isCyber"],
        },
        {
          title: "logbook",
          link: "/promo/logbook",
          icon:
            this.selected === "logbook"
              ? require("@/assets/images/logbook-green.svg")
              : require("@/assets/images/logbook-white.svg"),
          show: this.$store.getters["app/hasLogbook"],
        },
        // {
        //   title: "transactions",
        //   icon:
        //     this.selected === "transactions"
        //       ? "https://storage.googleapis.com/parkingbnb-html-images/ Payments green.svg"
        //       : "https://storage.googleapis.com/parkingbnb-html-images/ Payments.svg",
        //   link: "/promo/transactions",
        //   show: this.$store.getters["app/isCyber"] || this.$store.getters["app/isItt"],
        // },
      ];
    },
  },
  data() {
    return {
      mini: false,
      drawer: null,
      item: "",
    };
  },
  watch: {
    mini(newValue) {
      this.$emit("drawerMini", newValue);
    },
  },
  methods: {
    setSelected(item) {
      this.selected = item;
      // this.$store.commit("promo/set_view", item);
    },
  },
};
</script>
