<template>
  <div class="campaign-form pb-0">
    <div class="mb-4 d-flex justify-space-between align-center">
      <h3 class="main-title" :style="{ maxWidth: '90%' }">
        {{ user.name || $t("add") }}
      </h3>
      <!-- <v-btn text class=" icon-close pa-0" min-width="24px" @click="hide" /> -->
    </div>

    <v-form ref="form" autocomplete="off"
      ><v-text-field
        class="modal-input"
        v-model="user.name"
        :label="$t('fullname')"
        :rules="[() => !!user.name || $t('fullname') + $t('required')]"
      /><v-text-field
        class="modal-input"
        v-model="user.email"
        :label="$t('email')"
        :rules="[() => !!user.email || $t('email') + $t('required')]"
      />

      <div class="d-flex justify-space-between">
        <v-autocomplete
          attach
          style="max-width: 300px"
          v-model="user.country_code"
          item-value="dial_code"
          item-text="name"
          class="modal-input mr-6"
          :items="countryCodes"
          :rules="[() => !!user.country_code || $t('country_code') + $t('required')]"
        >
          <template v-slot:item="data">
            <span :class="`code-select selection-${data.item.i}`"
              ><img src="@/assets/images/countries.webp"
            /></span>
            <span class="pl-2">{{ data.item.name }}</span>
            <span style="opacity: 0.6" class="ml-3">{{ data.item.dial_code }}</span>
          </template>
          <template v-slot:selection="data">
            <span :class="`code-select selection-${data.item.i}`"
              ><img src="@/assets/images/countries.webp"
            /></span>
            <span class="pl-2">{{ data.item.dial_code }}</span>
            <span class="pl-2">{{ data.item.name }}</span>
          </template>
        </v-autocomplete>
        <v-text-field
          class="modal-input"
          :value="user.phone.replace(user.country_code, '')"
          @change="(v) => (user.phone = user.country_code + v)"
          type="tel"
          pattern="\d*"
          :rules="[() => !!user.phone || $t('phone_number') + $t('required')]"
          :label="$t('phone_number')"
        />
      </div>
      <v-select
        attach
        item-value="locale"
        item-text="desc"
        v-model="user.locale"
        class="modal-input"
        :items="locales"
        :label="$t('locale')"
      />
    </v-form>

    <div class="modal-footer d-flex align-center px-0 py-4">
      <v-btn class="main-btn mb-4 mr-5 py-2 px-4" @click="save">{{ $t("save") }}</v-btn>
      <v-btn text @click="back" class="flat-btn modal-footer-btn mb-4">{{
        $t("cancel")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import codes from "@/assets/countryCodes.json";
export default {
  name: "UserForm",
  props: {
    selected: {
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      user: {},
      countryCodes: codes,
    };
  },
  computed: {
    current() {
      return this.$store.state.users.current;
    },
    locales() {
      let res = [];
      for (const [key, value] of Object.entries(this.$t("locale_options"))) {
        res.push({
          locale: key,
          desc: value,
        });
      }
      return res;
    },
  },
  watch: {},
  mounted() {
    this.user = { ...this.current };
  },
  methods: {
    back() {
      this.$emit("hide");
    },
    save() {
      let data = {
        user: { ...this.user },
      };
      this.$store.dispatch("users/updateUser", data);
      this.back();
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_modal.scss";
@import "@/scss/_guestsTable.scss";
</style>
