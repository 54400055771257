var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("promocodes_history")))]),_c('div',{staticClass:"d-flex justify-start align-center my-4"},[_c('v-text-field',{staticClass:"parking-search ma-0 mr-4",attrs:{"autocomplete":"off","solo":"","placeholder":_vm.$t('search_promo')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('CoreFilter',{ref:"coreFilter",attrs:{"hidden":{
        car_type: true,
        pricing: true,
        zone: true,
        status: true,
        wallet: true,
      }},on:{"filterSet":_vm.updFilters,"filterReset":_vm.resetFilters}}),_c('ReportDownloadForm',{ref:"modal",attrs:{"view":"promocodes"}})],1),_c('CodeHistoryTable',{ref:"table",attrs:{"searchValue":_vm.search,"filters":_vm.filters}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }