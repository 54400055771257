<template>
  <div class="tabs-content">
    <v-tabs v-model="tab" class="mt-5 mb-8 place-tabs">
      <v-tab
        @change="handleTabChange(item)"
        v-for="(item, index) in [...list].filter((i) => i.show)"
        :key="index"
        >{{ $t(`${item.title}`) }}</v-tab
      >
    </v-tabs>
    <router-view />
  </div>
</template>
<script>
export default {
  name: "PromoAdmin",
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    list() {
      return [
        {
          title: "promo_history",
          link: "/platform/promo/history",
          show: true,
        },
        {
          title: "your_promo",
          link: "/platform/promo/campaigns",
          show: this.$store.getters["app/isAdmin"],
        },
        {
          title: "discounts",
          link: "/platform/promo/discounts",
          show: this.$store.getters["app/isCyber"],
        },
        {
          title: "promo_form",
          link: "/platform/promo/promo-form",
          show:
            (this.$store.getters["app/hasPromoAccess"] &&
              !this.$store.getters["app/isReadonly"]) ||
            (this.$store.getters["app/isReadonly"] &&
              this.$store.state.app.user.permissions.promo),
        },
        // {
        //   title: "promocodes",
        //   link: "/platform/promo/promocodes",
        //   show: this.$store.getters["app/isCyber"],
        // },
        // {
        //   title: "promocodes_history",
        //   link: "/platform/promo/promocodes-history",
        //   show: this.$store.getters["app/isCyber"],
        // },
      ];
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    tab: {
      get() {
        return this.$store.state.promo.tab;
      },
      set(value) {
        this.$store.commit("promo/set_tab", value);
      },
    },
  },
  watch: {},
  methods: {
    handleTabChange(newValue) {
      this.$router.push(newValue.link).catch({});
    },
  },
};
</script>
