<template>
  <div class="campaign-form pb-0">
    <div class="mb-4 d-flex justify-space-between align-center">
      <h3 class="main-title" :style="{ maxWidth: '90%' }">
        {{ getTitle() }}
      </h3>
      <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
    </div>

    <v-form ref="form" autocomplete="off">
      <div class="mb-4">
        <label class="modal-label">{{ $t("campaign_info") }}</label>

        <v-text-field
          v-model="campaign.campaign_name"
          class="modal-input"
          :label="$t('campaign_name')"
        />
      </div>
      <v-text-field
        v-model="campaign.campaign_description"
        class="modal-textarea modal-input"
        :label="$t('campaign_desc')"
      />
      <div
        class="d-flex justify-space-between align-center mb-4 flex-wrap flex-md-nowrap guest-row-3"
      >
        <template v-if="!isCyber">
          <v-text-field
            v-model.number="campaign.custom_params.fixed_hours"
            class="modal-textarea modal-input"
            :label="$t('free_fixed_hours')"
            :rules="[numberRules]"
          />
          <v-text-field
            v-model.number="campaign.custom_params.fixed_cost"
            class="modal-textarea modal-input"
            :label="$t('cost')"
            :rules="[numberRules]"
          />
        </template>
        <!-- DATES -->
        <div
          class="d-flex date"
          :style="{ 'max-width': isCyber ? '100%' : '32%', 'flex-grow': isCyber ? 1 : 0 }"
        >
          <v-menu
            content-class="datepicker-menu"
            :close-on-content-click="!!campaign.start_date"
            attach
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                readonly
                style="max-width: 100%"
                class="modal-input datepicker-inp"
                :value="
                  campaign.start_date
                    ? $moment(campaign.start_date).format('DD.MM.YYYY')
                    : ''
                "
                :label="$t('from')"
              ></v-text-field>
              <span class="datepicker-divider"></span>
            </template>
            <v-date-picker
              :locale="$i18n.locale"
              first-day-of-week="1"
              :isDark="false"
              v-model="campaign.start_date"
            ></v-date-picker>
          </v-menu>
          <v-menu
            content-class="datepicker-menu"
            :close-on-content-click="!!campaign.start_date"
            attach
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                class="modal-input datepicker-inp-second"
                :value="
                  campaign.end_date ? $moment(campaign.end_date).format('DD.MM.YYYY') : ''
                "
                :label="$t('to')"
              ></v-text-field>
            </template>
            <v-date-picker
              :locale="$i18n.locale"
              first-day-of-week="1"
              :isDark="false"
              v-model="campaign.end_date"
              :min="campaign.start_date"
            ></v-date-picker>
          </v-menu>
        </div>
      </div>

      <div class="border-btm">
        <div class="d-flex justify-space-between align-center">
          <label class="modal-label">{{ $t("stores") }}</label>
          <v-btn @click="addStore" text class="modal-btn"
            >+ {{ $t("add") }} {{ $t("store") }}</v-btn
          >
        </div>
        <div class="pb-4">
          <div v-for="(store, index) in campaign.stores" :key="index">
            <div v-if="store.status !== 3" class="text-right">
              <v-text-field
                @input="store.status !== 1 ? changeStoreStatus(index) : ''"
                v-model="store.store_name"
                class="modal-input"
                :label="$t('store_name')"
              /><v-text-field
                @input="store.status !== 1 ? changeStoreStatus(index) : ''"
                v-model="store.store_address"
                class="modal-input"
                :label="$t('store_address')"
              />
              <v-btn @click="deleteStore(index)" text class="modal-btn mr-2"
                >{{ $t("delete") }}
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="border-btm" v-if="isCyber">
        <div class="d-flex justify-space-between align-center mb-5">
          <label class="modal-label">{{ $t("rules") }}</label>
          <v-btn @click="addRule" text class="modal-btn">+ {{ $t("add") }} </v-btn>
        </div>
        <div v-for="(rule, index) in campaign.constraints" :key="rule.id" class="my-4">
          <div class="d-flex align-center">
            <label class="modal-label mr-6">{{ $t("rule") }} {{ index + 1 }}</label>
            <v-checkbox
              v-model="rule.different_merchants"
              :label="$t('diff_merchants')"
              class="filter-checkbox my-0 pa-0 mr-auto"
              :class="rule.different_merchants ? 'active' : ''"
            />
            <v-btn @click="deleteRule(index)" text class="modal-btn mr-2"
              >{{ $t("delete") }}
            </v-btn>
          </div>
          <div class="d-flex guest-row-3 justify-space-between">
            <v-text-field
              v-model.number="rule.free_hours"
              class="modal-input"
              :label="$t('free_fixed_hours')"
              :rules="[numberRules]"
            />
            <v-text-field
              :value="rule.total_amount / 100 || 0"
              @input="(v) => (rule.total_amount = v * 100)"
              class="modal-input"
              :label="$t('cost')"
              :rules="[numberRules]"
            />
            <v-text-field
              v-model.number="rule.no_of_receipts"
              class="modal-input"
              :label="$t('receipts_allowed')"
              :rules="[numberRules]"
            />
          </div>
        </div>
      </div>
    </v-form>

    <div class="modal-footer d-flex align-center px-0 py-2">
      <v-btn class="main-btn mb-4 mr-5 py-2 px-4" @click="sendPromo">{{
        $t("save")
      }}</v-btn>
      <v-btn
        text
        v-if="openPromo"
        @click="deletePromo"
        class="flat-btn modal-footer-btn mb-4"
        >{{ $t("delete") }} {{ $t("promo") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
const DEFAULT_CAMPAIGN = {
  campaign_name: "",
  campaign_description: "",
  custom_params: {},
  place_id: undefined,
  constraints: [{}],
  stores: [
    {
      status: 1,
      store_id: null,
    },
  ],
};
export default {
  name: "CampaignForm",
  data() {
    return {
      campaign: {},
    };
  },
  computed: {
    allPlaces() {
      return this.$store.state.app.places;
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    isCyber() {
      return this.$store.getters["app/isCyber"];
    },
    place() {
      return this.$store.state.promo.place;
    },
    openPromo() {
      return this.$store.state.promo.cur_campaign;
    },
  },
  watch: {
    openPromo(value) {
      this.reformatCampaign(value);
    },
  },
  mounted() {
    let campaign = this.reformatCampaign(this.openPromo);
    this.campaign = {
      ...this.campaign,
      ...campaign,
    };
  },
  methods: {
    hide() {
      this.$store.commit(
        "promo/set_current_campaign",
        JSON.parse(JSON.stringify(DEFAULT_CAMPAIGN))
      );
      this.$router.push("/platform/promo/campaigns");
    },
    reformatCampaign(value) {
      return {
        ...value,
        end_date: value.end_date ? this.$moment(value.end_date).format("YYYY-MM-DD") : "",
        start_date: value.start_date
          ? this.$moment(value.start_date).format("YYYY-MM-DD")
          : "",
      };
    },
    numberRules(value) {
      if (!value || !isNaN(value)) {
        return true;
      }
      return this.$t("phone_err");
    },
    getTitle() {
      if (this.openPromo.campaign_id) {
        return `${this.$t("change")} "${this.openPromo.campaign_name}"`;
      } else {
        return `${this.$t("add")} ${this.$t("promo")}`;
      }
    },
    addStore() {
      this.campaign.stores.push({
        status: 1,
        store_id: null,
      });
      this.$forceUpdate();
    },
    deleteStore(index) {
      if (this.campaign.stores[index].status === 1) {
        this.campaign.stores.splice(index, 1);
      } else {
        this.campaign.stores[index].status = 3;
      }
      this.$forceUpdate();
    },
    changeStoreStatus(index) {
      this.campaign.stores[index].status = 2;
    },
    reformatStores() {
      if (this.campaign.stores) {
        this.campaign.stores.forEach((store) => {
          if (!store.status) {
            store.status = 0;
          }
        });
      }
    },
    sendPromo(action) {
      this.$refs.form.validate();
      if (this.$refs.form.validate() === true) {
        this.reformatStores();

        let body = { ...this.campaign };
        delete body.use_count;
        body.start_date = new Date(body.start_date).toISOString();
        body.end_date = new Date(body.end_date).toISOString();
        body.place_id = body.place_id || this.place;
        if (!this.isCompany) body.place_id = this.allPlaces[0].place_id;

        // console.log(body);
        if (this.campaign.campaign_id) {
          this.$store.dispatch("promo/changePromoCampaign", body);
          if (this.isCyber) {
            this.$store.dispatch("promo/setCampaignRules", body);
          }
        } else {
          this.$store.dispatch("promo/addPromoCampaign", body);
        }
        this.hide();
      }
      return action;
    },
    deletePromo() {
      this.$store
        .dispatch("promo/deletePromo", this.openPromo.campaign_id)
        .catch((err) => {
          console.log(err);
        });
      this.hide();
    },

    //CYBERPORT SHIT

    addRule() {
      this.campaign.constraints.push({});
    },
    deleteRule(index) {
      this.campaign.constraints.splice(index, 1);
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_modal.scss";
@import "@/scss/_guestsTable.scss";
</style>
