<template>
  <div class="user-content user-chatbox">
    <h3 class="main-title">{{ $t("user_notify", { user: current.name }) }}</h3>
    <v-textarea
      rows="6"
      v-model="message"
      class="modal-input mb-6"
      :label="$t(`chat_msg`)"
    />
    <div class="d-flex align-center justify-end">
      <v-btn text @click="notify(1)" class="main-btn px-6 py-3 mr-4">{{
        $t("chat_send_sms")
      }}</v-btn>
      <v-btn text @click="notify(2)" class="main-btn px-6 py-3">{{
        $t("chat_send_email")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import "@/scss/_modal.scss";
export default {
  name: "UserGeneral",
  data: () => {
    return {
      message: "",
    };
  },
  computed: {
    current() {
      return this.$store.state.users.current;
    },
  },
  methods: {
    notify(via) {
      let data = {
        title: "",
        via,
        message: this.message,
        id: this.current.id,
      };
      this.$store.dispatch("users/notify", data).then(() => {
        this.message = "";
      });
    },
  },
};
</script>

<style></style>
