import { base, monthly } from '@/axios-api'
import store from '@/store/index';
import qs from 'qs';
import EventBus from '../../../event-bus';
import i18n from '@/plugins/i18n';

export default {
    getUsers({
        commit,
        dispatch
    }) {
        base({
                url: "/user/list",
                method: 'GET',
                params: {
                    place_id: store.state.app.places.map(p => p.place_id)
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            }).then((response) => {
                if (response.status === 200) {
                    let users = response.data.body || []
                    users.map(u => {
                        return {
                            ...u,
                            places: u.places || []
                        }
                    })
                    users = users.sort((a, b) => new Date(b.last_login).getTime() - new Date(a.last_login).getTime())
                    commit("set_users", users)
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    addUser({
        commit,
        dispatch
    }, data) {
        base({
                url: "/user/create",
                method: 'POST',
                data
            }).then((response) => {
                if (response.status === 200) {
                    dispatch("getUsers")
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('user_added'),
                        duration: 3000
                    })
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    changeUser({
        commit,
        dispatch
    }, data) {
        base({
                url: "/user/update",
                method: 'PUT',
                data
            }).then((response) => {
                if (response.status === 200) {
                    dispatch("getUsers")
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('user_changed'),
                        duration: 3000
                    })
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    deleteUser({
        commit,
        dispatch
    }, user_id) {
        base({
                url: "/user/delete/" + user_id,
                method: 'DELETE',
            }).then((response) => {
                if (response.status === 200) {
                    dispatch("getUsers")
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('user_deleted'),
                        duration: 3000
                    })
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },


    getBlacklist({
        commit,
        dispatch
    }) {
        base({
                url: "/blacklist",
                method: 'GET',
                params: {
                    place_id: store.state.app.places.map(p => p.place_id)
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            }).then((response) => {
                if (response.status === 200) {
                    let blacklist = response.data.body ? response.data.body : []
                    commit("set_blacklist", blacklist)
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    deleteBlacklist({
        commit,
        dispatch
    }, data) {
        base({
                url: "/blacklist",
                method: 'DELETE',
                data
            }).then((response) => {
                if (response.status === 200) {
                    dispatch("getBlacklist")
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('blacklist_updated'),
                        duration: 3000
                    })
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    addToBlacklist({
        commit,
        dispatch
    }, data) {
        base({
                url: "/blacklist",
                method: 'POST',
                data
            }).then((response) => {
                if (response.status === 200) {
                    dispatch("getBlacklist")
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('blacklist_updated'),
                        duration: 3000
                    })
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },

    getPricings({
        commit,
        dispatch
    }) {
        monthly({
                url: "/pricings",
                method: 'GET',
                params: {
                    place_id: store.state.app.places.map(p => p.place_id),
                    lang: i18n.locale
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            }).then((response) => {
                if (response.status === 200) {
                    let payload = response.data.body ? response.data.body : []
                    commit("set_pricings", payload)
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    deletePricings({
        commit,
        dispatch
    }, data) {
        monthly({
                url: "/pricings",
                method: 'DELETE',
                data
            }).then((response) => {
                if (response.status === 200) {
                    dispatch("getPricings")
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('pricings_updated'),
                        duration: 3000
                    })
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    addPricings({
        commit,
        dispatch
    }, data) {
        monthly({
                url: "/pricings",
                method: 'POST',
                data
            }).then((response) => {
                if (response.status === 200) {
                    dispatch("getPricings")
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('pricings_updated'),
                        duration: 3000
                    })
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    updatePricings({
        commit,
        dispatch
    }, data) {
        monthly({
                url: "/pricings",
                method: 'PUT',
                data
            }).then((response) => {
                if (response.status === 200) {
                    dispatch("getPricings")
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('pricings_updated'),
                        duration: 3000
                    })
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
}