<script>
export default {
  name: "ZoneHistoryTable",
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    searchValue: {
      type: String,
      default: "",
    },
    filters: {
      default: {
        dates: [],
        zone_id: [],
      },
    },
    openReportForm: {
      default: false,
      type: Boolean,
    },
    isOpenForm: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      data: [],
      loading: true,
      dataLoading: false,
      disableLoader: false,
      counter: {
        0: 1,
        1: 1,
        2: 1,
      },
      mycss: {},
      itemsPerPage: 25,
      params: {
        from: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString(),
        to: new Date().toISOString(),
        limit: 25,
        sort_by: 3,
        sort_direction: 1,
        status: null,
        zone_id: [],
        guest_name: "",
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t("guest"),
          value: "username",
          class: "table-guest table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("car_type"),
          value: "car_type",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: this.$t("zone"),
          value: "zone",
          sortable: false,
          class: "table-price table-header",
        },
        {
          text: this.$t("date_in"),
          value: "enter_time",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("date_out"),
          value: "exit_time",
          class: "table-date table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("duration"),
          value: "duration",
          class: "table-date table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("penalty"),
          value: "penalty",
          class: "table-price table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "table-price table-header",
          isDesc: undefined,
        },
      ];
    },
    records() {
      switch (this.tab) {
        case 0:
          return this.$store.state.history.zone_history.complied;
        case 1:
          return this.$store.state.history.zone_history.violated;
        case 2:
          return this.$store.state.history.zone_history.all;
        default:
          return [];
      }
    },
    initialLoading() {
      return this.$store.state.history.zone_history.loading;
    },
  },
  watch: {
    records() {
      this.loadItems();
    },
    data(newValue, oldValue) {
      this.$emit("upd_data");
      if (newValue.length === oldValue.length) {
        this.disableLoader = true;
      }

      this.loading = false;
      this.dataLoading = false;
    },
  },
  mounted() {
    this.loadItems();
    this.scroll();
  },
  methods: {
    newFrom(newFilters) {
      let filters = newFilters ? newFilters : this.filters;
      let newDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - 1)
      ).toISOString();
      if (filters) {
        return filters.dates[0] ? this.$moment(filters.dates[0]).toISOString() : newDate;
      }
      return newDate;
    },
    newTo(newFilters) {
      let filters = newFilters ? newFilters : this.filters;
      let newDate = this.$moment().toISOString();
      if (filters) {
        return filters.dates[1] ? this.$moment(filters.dates[1]).toISOString() : newDate;
      }
      return newDate;
    },
    updateParams(newValue) {
      this.disableLoader = false;
      let params = {};
      for (const [key, value] of Object.entries({ ...newValue })) {
        if (value) {
          params[key] = value;
        }
      }
      this.updateData(params);
    },
    updateData(params) {
      this.$store.dispatch("history/getZoneHistory", { params, status: null });
      this.$store.dispatch("history/getZoneHistory", { params, status: 1 });
      this.$store.dispatch("history/getZoneHistory", { params, status: 2 });
    },
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.ceil(document.documentElement.scrollTop) + window.innerHeight >=
          document.documentElement.offsetHeight;
        if (
          bottomOfWindow &&
          !this.loading &&
          !this.dataLoading &&
          !this.isOpenForm &&
          this.$store.state.app.tab === "zone_history"
        ) {
          this.loadMore();
        }
      };
    },

    filter(filters) {
      this.loading = true;

      this.params = {
        ...this.params,
        from: this.newFrom(filters),
        to: this.newTo(filters),
        zone_id: filters.zone_id || [],
      };

      this.updateParams({ ...this.params });
    },

    search(value) {
      this.loading = true;
      this.params = {
        ...this.params,
        guest_name: value,
        from: this.newFrom(),
        to: this.newTo(),
      };

      this.updateParams({ ...this.params });
    },

    loadItems() {
      if (this.records.length <= this.itemsPerPage) {
        this.data = [...this.records];
        return;
      }
      if (this.counter[this.tab] < Math.round(this.records.length / this.itemsPerPage)) {
        this.data = this.records.slice(0, this.counter[this.tab] * this.itemsPerPage);
      } else {
        this.data = [...this.records];
      }
      this.loading = false;
    },

    loadMore() {
      this.counter[this.tab]++;
      if (this.tab === 0) {
        if (this.data.length >= this.records.length) {
          return;
        }
        if (this.records.length <= this.itemsPerPage) {
          this.data = [...this.records];
          return;
        }
        if (
          this.counter[this.tab] >= Math.round(this.records.length / this.itemsPerPage)
        ) {
          this.data = [...this.records];
          return;
        }
        this.data = this.records.slice(0, this.counter[this.tab] * this.itemsPerPage);
        return;
      }
      if (!this.disableLoader) {
        this.dataLoading = true;
      }
      this.params = {
        ...this.params,
        from: this.newFrom(),
        to: this.newTo(),
        limit: this.counter[this.tab] * this.itemsPerPage,
      };
      // console.log("load more " + JSON.stringify(this.params));

      this.updateParams({ ...this.params });
    },

    getFormattedDate(date) {
      return date ? this.$moment(date).format("DD.MM.YYYY HH:mm") : "";
    },

    getDuration(parking) {
      let duration = this.$moment.duration(parking.duration, "milliseconds");
      let months = duration._data.months ? duration._data.months + "m" : "";
      let days = duration._data.days ? duration._data.days + "d" : "";
      let hours = duration._data.hours ? duration._data.hours + "h" : "";
      let minutes = duration._data.minutes;

      if (duration._data.seconds && duration._data.minutes !== 59) {
        minutes = minutes + 1;
      }

      minutes = minutes + "m";

      return `${months} ${days} ${hours} ${minutes}`;
    },

    customSort(index, sort_direction) {
      this.loading = true;
      this.headers.map((h) => {
        if (this.headers.indexOf(h) !== index) {
          h.isDesc = undefined;
        }
      });
      this.headers[index].isDesc = sort_direction === 1;
      switch (this.headers[index].value) {
        case "username":
          this.params = { ...this.params, sort_by: 1, sort_direction };
          break;
        case "enter_time":
          this.params = { ...this.params, sort_by: 3, sort_direction };
          break;
        case "exit_time":
          this.params = { ...this.params, sort_by: 4, sort_direction };
          break;
        case "duration":
          this.params = { ...this.params, sort_by: 5, sort_direction };
          break;
        case "penalty":
          this.params = { ...this.params, sort_by: 6, sort_direction };
          break;
        default:
          return;
      }
      this.params = { ...this.params, from: this.newFrom(), to: this.newTo() };
      this.updateParams({ ...this.params });
    },
  },
};
</script>

<template>
  <div>
    <v-skeleton-loader
      v-if="initialLoading"
      :loading="true"
      type="table"
    ></v-skeleton-loader>
    <v-skeleton-loader
      v-if="loading && !initialLoading"
      :loading="true"
      type="table"
    ></v-skeleton-loader>
    <v-data-table
      v-if="!loading && !initialLoading"
      :headers="headers"
      :items-per-page="-1"
      :items="data"
      class="table not-expandable-table"
      hide-default-footer
      disable-pagination
      disable-sort
      mobile-breakpoint="0"
      :no-data-text="$t('no_data')"
    >
      <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div :key="i.text" class="d-flex align-center">
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(headers.indexOf(header), 0)"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(headers.indexOf(header), 1)"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>
      <template v-slot:[`item.username`]="{ item }">
        <div class="d-flex justify-start table-guest align-center">
          <div class="table-user" v-if="item.user">
            <img
              width="36px"
              height="36px"
              class="table-ava-icon table-img"
              :class="item.user.avatar_url ? '' : ' green-bg '"
              :src="
                item.user.avatar_url
                  ? item.user.avatar_url
                  : 'https://storage.googleapis.com/parkingbnb-html-images/avatar_user.svg'
              "
            />
          </div>
          <div class="table-user-name">
            <h4>{{ item.user.first_name }} {{ item.user.last_name }}</h4>
            <span>{{ item.vehicle.plate_number }}</span>
          </div>
        </div>
      </template>
      <template v-slot:[`item.car_type`]="{ item }">
        <div class="table-car-type">
          {{ $t(`car_types[${item.vehicle.car_type}]`) }}
        </div>
      </template>
      <template v-slot:[`item.zone`]="{ item }">
        <div class="table-price ml-2">
          {{ item.zone_record.name }}
        </div>
      </template>

      <template v-slot:[`item.enter_time`]="{ item }">
        <div class="table-date">
          {{ getFormattedDate(item.zone_record.start_time) }}
        </div>
      </template>

      <template v-slot:[`item.exit_time`]="{ item }">
        <div class="table-date">
          {{ getFormattedDate(item.zone_record.end_time) }}
        </div>
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        <div class="table-date">
          {{ getDuration(item.zone_record) }}
        </div>
      </template>

      <template v-slot:[`item.penalty`]="{ item }">
        <div class="table-price">{{ item.zone_record.penalty }} HKD</div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="table-price text-start pl-3">
          <span
            class="zone-status-icon mx-0"
            :class="item.zone_record.status === 2 ? 'violated' : 'complied'"
          />
        </div>
      </template>
    </v-data-table>
    <div v-if="dataLoading">
      <v-skeleton-loader
        v-for="i in 3"
        :key="i"
        :loading="loading"
        type="table-row"
      ></v-skeleton-loader>
    </div>
  </div>
</template>
