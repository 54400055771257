<template>
  <div class="green-bg login-home">
    <LocaleSwitcher />
    <div class="d-md-flex justify-space-between login-page">
      <v-container class="login-content">
        <Logo green="green" />
        <LoginForm />
      </v-container>
      <div class="login-bg">
        <object
          style="height: 100%"
          type="image/svg+xml"
          data="https://storage.googleapis.com/parkingbnb-html-images/Login_BG.svg"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  components: {
    LoginForm: () => import("../components/Login/LoginForm"),
    Logo: () => import("../components/core/Logo"),
    LocaleSwitcher: () => import("../components/core/LocaleSwitcher"),
  },
};
</script>

<style lang="scss" scoped>
body {
  background-color: $green;
}
</style>
