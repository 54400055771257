<template>
  <div class="popup" v-if="open">
    <div class="modal-overlay" @click="hide" v-if="open" />
    <v-menu
      absolute
      center
      :value="true"
      :close-on-content-click="false"
      :close-on-click="false"
      content-class="modal-content core-filter "
    >
      <div class="d-flex justify-space-between align-center pb-2">
        <h3 class="parking-subtitle mr-auto">
          {{ $t("pass_monthly") }} -
          {{ $t(`pass_status_cp[${hrec.status - 1}]`) }}
        </h3>
        <v-btn text class=" icon-close pa-0" min-width="24px" @click="hide" />
      </div>

      <!-- PARKING INFO -->
      <template>
        <h3 class="d-inline-flex desc-text my-2" style="font-size: 18px !important">
          {{ $t("pass_info") }}
        </h3>

        <!-- DATES -->
        <!-- <div class="d-flex align-center justify-space-between"> -->
        <div class="desc-text py-1">
          <span>{{ $t("start_date") }}: </span>
          <b>{{ $moment(hrec.active_from).format("DD MMM YYYY") }}</b>
        </div>
        <div class="desc-text py-1" v-if="hrec.active_until">
          <span>{{ $t("end_date") }}: </span>
          <b>{{ $moment(hrec.active_until).format("DD MMM YYYY") }}</b>
        </div>

        <div class="desc-text py-1">
          <span>{{ $t("price_type") }}: </span>
          <b>
            {{
              $t(`monthly_price_options`).filter((i) => i.id === hrec.price_option_id)[0]
                .text
            }}</b
          >
        </div>

        <div class="desc-text py-1">
          <span>{{ $t("total") }}: </span>
          <b> {{ hrec.amount / 100 }} {{ currency }}</b>
        </div>
        <div class="border-btm mt-2 mx-n1" />
      </template>

      <!-- USER INFO BLOCK -->
      <template>
        <h3 class="d-inline-flex desc-text" style="font-size: 18px !important">
          {{ $t("user_info") }}
        </h3>

        <div class="desc-text py-1">
          <span>{{ $t("guest_fullname") }}: </span>
          <b> {{ hrec.user_name || $t("unnamed") }}</b>
        </div>

        <div class="border-btm mt-2 mx-n1" />
      </template>
      <!-- PAYMENT BLOCK -->
      <v-autocomplete
        v-if="hrec.status === 2"
        v-model="payment_method_id"
        item-value="id"
        item-text="text"
        :items="paymentMethods"
        attach
        class="modal-input my-6"
        :label="$t('payment_method')"
      />
      <div class="d-flex py-2 align-center" v-if="hrec.status < 2">
        <v-btn @click="processPass(2)" class="main-btn px-4 py-2" text>{{
          $t("approve")
        }}</v-btn>
        <v-btn @click="processPass(3)" class="flat-btn error-message" text>{{
          $t("decline")
        }}</v-btn>
      </div>
      <div class="d-flex py-2 align-center" v-if="hrec.status === 2">
        <v-btn @click="paySubscription" class="main-btn px-6 py-2" text>{{
          $t("pay")
        }}</v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "PassRecord",
  props: {
    record: {
      default() {
        return {};
      },
    },
  },
  components: {
  },
  data() {
    return {
      open: false,
      editing: false,
      hrec: {},
      payment_method_id: "",
    };
  },
  computed: {
    places() {
      return [...this.$store.state.app.places].map((p) => {
        return { ...p, name: p.place_name };
      });
    },
    currency() {
      return this.$store.getters["app/currency"];
    },
    paymentMethods() {
      return [
        { id: 4, text: "WeChat Pay" },
        { id: 6, text: "AliPay" },
        { id: 7, text: "Octopus" },
        { id: 8, text: "VISA" },
        { id: 9, text: "Mastercard" },
      ];
    },
  },
  watch: {
    record(newValue) {
      this.hrec = JSON.parse(JSON.stringify(newValue));
    },
  },
  methods: {
    show() {
      this.open = true;
    },
    hide() {
      this.open = false;
      this.$emit("resetSelected");
    },
    processPass(status) {
      this.$store.dispatch("subscriptions/processPass", {
        subscription: { ...this.hrec, status },
      });
      this.hide();
    },
    paySubscription() {
      this.$store.dispatch("subscriptions/paySubscription", {
        subscription_request_id: this.hrec.id,
        payment_method_id: this.payment_method_id,
      });
      this.hide();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
</style>

<style lang="scss" scoped>
@media print, screen and (min-width: 576px) {
  .modal,
  .modal-content {
    max-width: 400px !important;
  }
}
</style>
