<template>
  <div class="balance">
    <h3 v-if="isSuperAdmin && !isUtaggoPOC" class="balance-subtitle">
      {{ type === "revenue" ? $t("total_earned") : $t("balance") }}
    </h3>
    <h2 v-if="isSuperAdmin && !isUtaggoPOC" :class="type === 'bold' ? 'font-weight-bold' : ''" class="balance-title">
      {{ currency }}{{ balance }}
    </h2>
  </div>
</template>

<script>
export default {
  name: "Balance",
  props: {
    type: {
      default: "current",
    },
  },
  computed: {
    isUtaggoPOC() {
      return this.$store.getters['app/isUtaggoPOC1'] ||this.$store.getters['app/isUtaggoPOC2'];
    },
    isSuperAdmin() {
      return this.$store.getters['app/isSuperAdmin'];
    },
    balance() {
      return this.$store.state.app.balance;
    },
    currency() {
      return this.$store.getters["app/currency"];
    },
  },
  beforeCreate() {
    if (this.$store.getters["app/authorized"]) {
      this.$store.dispatch("app/updateBalanceRequest").catch((err) => {
        console.log(err);
      });
    }
  },
};
</script>
