<template>
  <div class="video-wrapper">
    <video
      ref="video"
      :controls="isMobile"
      autoplay
      muted
      webkit-playsinline
      playsinline
      class="video-wrapper__video"
    >
      <source
        :src="`${url}?auth_token=${token}`" type="application/x-mpegURL" />
    </video>
  </div>
</template>

<script>
import Hls from "hls.js";
export default {
  name: "StreamingNewHLSPlayer",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isMobile: false,
      hls: null,
      isLoading: true,
      errorMessage: "",
      token: localStorage.getItem("credentials"),
    };
  },
  methods: {
    prepareVideoPlayer() {
      if (Hls.isSupported()) {
        console.log("hello hls.js!");

        //Adds to query params auth_token
        // let token = localStorage.getItem("credentials")
        // class loader extends Hls.DefaultConfig.loader {
        //   constructor(config) {
        //     super(config);
        //     const load = this.load.bind(this);
        //
        //     this.load = (context, ...rest) => {
        //       if (context.url.includes("?")) {
        //         load({ ...context, url: context.url + `&auth_token=${token}` }, ...rest);
        //       } else {
        //         load({ ...context, url: context.url + `?auth_token=${token}` }, ...rest);
        //       }
        //     };
        //   }
        // }

        // const handleError = (error) => {
        //   console.error(error);
        //   if (error.status === 401) {
        //     console.error("Not authorized");
        //   }
        // }

        this.hls = new Hls();
        this.hls.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
          console.log(
            "manifest loaded, found " + data.levels.length + " quality level"
          );
        });

        let stream = `${this.url}?auth_token=${this.token}`;
        this.hls.loadSource(stream);
        const video = this.$refs.video;
        if (video) {
          this.hls.attachMedia(video);
          console.log("video and hls.js are now bound together !");
          video.muted = true;
        }
      }
      console.log("video and hls.js are now prepared");
    },
    destroyHls() {
      this.hls.destroy();
      this.hls = null;
      console.log("Destroyed");
    },
  },
  mounted() {
    this.prepareVideoPlayer();
  },
  updated() {
    this.prepareVideoPlayer();
  },
  beforeDestroy() {
    this.destroyHls();
  },
}
</script>

<style scoped lang="scss">
.video-wrapper {
  position: relative;
  width: 100%;
  &__video {
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: #eeeeee;
    border: none;
  }

  .error,
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
  .error {
    color: red;
  }
}
</style>
