<template>
  <div class="record-popup popup" v-if="open">
    <div class="modal-overlay" @click="hide" v-if="open" />
    <v-menu
      attach
      :value="true"
      :close-on-content-click="false"
      :close-on-click="false"
      content-class="core-filter modal-content"
    >
      <div class="d-flex justify-space-between align-center mb-6">
        <h3 class="parking-subtitle mr-auto">
          {{ $t("pass_monthly") }} -
          {{ $t(`pass_status[${hrec.status - 1}]`) }}
        </h3>
        <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
      </div>

      <v-select
        item-text="desc"
        item-value="id"
        attach
        :items="statusOptions"
        :label="$t('status')"
        v-model="hrec.status"
        class="modal-input filter-dropdown"
      />

      <div class="d-flex py-2 align-center">
        <v-btn @click="save" class="main-btn px-4 py-2" text>{{ $t("save") }}</v-btn>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "PassRecord",
  props: {
    record: {
      default() {
        return {};
      },
    },
  },
  components: {},
  data() {
    return {
      open: false,
      hrec: {},
    };
  },
  computed: {
    statusOptions() {
      return this.$t("pass_status").map((i, index) => {
        return {
          desc: i,
          id: index + 1,
        };
      });
    },
  },
  watch: {
    record(newValue) {
      this.hrec = JSON.parse(JSON.stringify(newValue));
    },
  },
  methods: {
    show() {
      this.open = true;
    },
    hide() {
      this.open = false;
      this.$emit("resetSelected");
    },
    save() {
      this.$store.dispatch("subscriptions/updateSubscription", { ...this.hrec });
      this.hide();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
</style>

<style lang="scss" scoped>
@media print, screen and (min-width: 576px) {
  .modal,
  .modal-content {
    max-width: 400px !important;
  }
}
</style>
