import { base, counter } from '@/axios-api'
import store from '@/store/index';
import qs from 'qs'
import i18n from '@/plugins/i18n';
import EventBus from '../../../event-bus';

export default {
  getDashboardConfig({ commit, dispatch }, parking_id) {
    let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id);
    base({
      url: `/dashboard/config`,
      method: 'GET',
      params: {
        place_id
      },
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    })
      .then((response) => {
        if (response.status === 200) {
          commit('set_config', response.data.configuration);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('logout', null, {
            root: true
          })
        }
      });
  },
  //    DASHBOARD ACTIONS
  getPlaceOccupancy({ commit, dispatch }, parking_id) {
    let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id)
    base({
      url: `/places/occupancy`,
      method: 'GET',
      params: {
        place_id
      },
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    })
      .then((response) => {
        if (response.status === 200) {
          commit('upd_occupancy', response.data.body);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('logout', null, {
            root: true
          })
        }
      });
  },
  getOverviewStats({
                     commit,
                     dispatch
                   }, {
                     params,
                     month
                   }) {
    const BASE_URL = store.getters['app/isCyber'] ? 'dashboard_cyber' : 'dashboard'
    base({
      url: `/${BASE_URL}/stats`,
      params,
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
      method: 'GET'
    }).then(res => {
      if (res.status === 200) {
        commit('upd_dash_overview_stats', {
          stats: res.data.body,
          month
        })
      }
    }).catch(err => {
      console.log(err)
      console.log(err.response)
      if (err.response && err.response.status === 401) {
        dispatch('app/logout', null, {
          root: true
        })
      }
    })
  },
  getPriceUsage({
                  commit,
                  dispatch
                }, params) {
    const BASE_URL = store.getters['app/isCyber'] ? 'dashboard_cyber' : 'dashboard'
    base({
      url: `/${BASE_URL}/price_usage`,
      params,
      method: 'GET',
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    }).then(res => {
      if (res.status === 200) {
        let priceUsage = res.data.body ? res.data.body : []
        commit('upd_dash_prices', priceUsage)
      }
    }).catch(err => {
      console.log(err.response)
      if (err.response && err.response.status === 401) {
        dispatch('app/logout', null, {
          root: true
        })
      }
    })
  },
  getOccupancyDynamics({
                         commit,
                         dispatch
                       }, {
                         params,
                         type
                       }) {
    const BASE_URL = store.getters['app/isCyber'] ? 'dashboard_cyber' : 'dashboard'
    base({
      url: `/${BASE_URL}/occupancy`,
      params,
      method: 'GET',
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    }).then(res => {
      if (res.status === 200) {
        let data = res.data.body ? res.data.body : []
        commit('upd_occ_dynamics', {
          data,
          type
        })
      }
    }).catch(err => {
      console.log(err.response)
      if (err.response && err.response.status === 401) {
        dispatch('app/logout', null, {
          root: true
        })
      }
    })
  },
  getEnterCount({
                  commit,
                  dispatch
                }, {
                  params,
                  day
                }) {
    const BASE_URL = store.getters['app/isCyber'] ? 'dashboard_cyber' : 'dashboard'
    base({
      url: `/${BASE_URL}/enter_count`,
      params,
      method: 'GET',
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    }).then(res => {
      if (res.status === 200) {
        if (day === 0) {
          commit('upd_enter_count', {
            data: res.data.body,
            month: 0
          })
        } else {
          commit('upd_enter_count', {
            data: res.data.body,
            month: 1
          })
        }
      }
    }).catch(err => {
      console.log(err.response)
      if (err.response && err.response.status === 401) {
        dispatch('app/logout', null, {
          root: true
        })
      }
    })
  },
  setPlaceOccupancy({
                      commit,
                      dispatch
                    }, data) {
    base({
      url: `/places/occupancy`,
      data,
      method: 'post',

    }).then(res => {
      if (res.status === 200) {
        EventBus.$emit("notify", {
          group: 'general',
          title: 'Success!',
          text: i18n.t(`occupancy_set`),
          duration: 3000
        })
        dispatch("getPlaceOccupancy")
        return commit
      }
    }).catch(err => {
      console.log(err.response)
      if (err.response && err.response.status === 401) {
        dispatch('app/logout', null, {
          root: true
        })
      }
    })
  },
  //COUNTER TV

  getVacancies({
                 commit,
                 dispatch
               }, params) {
    counter({
      url: `/counters`,
      method: 'GET',
      params,
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    })
      .then((response) => {
        if (response.status === 200) {
          commit('set_counter_data', response.data.content);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('logout', null, {
            root: true
          })
        }
      });
  },
}
