<template>
  <div class="d-flex flex-column align-center justify-space-between">
    <v-form class="promo-form" ref="form" autocomplete="off">
      <div class="total parking-subtitle">
        {{ $t("total") }}: {{ currency }}{{ total }}
      </div>

      <v-combobox
        :disabled="isEditing"
        v-model="body.plate_number"
        @click="loadCars"
        @change="getParking"
        :items="carsList"
        attach
        class="modal-input mt-6"
        :label="plateNumberLabel"
        :rules="[
          (value) => {
            return genericRequiredRule(value, $t('car_plate'));
          },
        ]"
      />
      <div class="error-message" v-if="parking_not_found && body.plate_number">
        {{ $t(`parking_not_found`) }}
      </div>
      <div class="promo-details" v-for="detail in promoDetails" :key="detail.id">
        {{ $t('promo_detail',
              {
                hours: detail.minutes / 60,
                source: $t(detail.source),
                time: $moment(detail.appliedAt).format("YYYY-MM-DD HH:mm"),
              }
            )
        }}
      </div>
      <template v-if="hasDiscount">
        <div class="d-flex align-center">
          <v-checkbox
            :label="$t('recongize_send')"
            v-model="is_recognizing"
            class="filter-checkbox"
            :class="is_recognizing ? 'active' : ''"
          />
          <v-file-input
            style="max-width: 70%"
            class="modal-input ml-auto"
            :label="$t('import')"
            @change="addDiscount"
            :value="discount.file"
          />
        </div>
      </template>
      <div class="d-flex align-center justify-space-between my-6" v-if="cyberFlow">
        <v-text-field v-model="code" :label="$t('promocode')" />
        <v-btn @click="applyCode" text class="flat-btn" :style="{ width: '100%' }">
          {{ $t("apply") }}</v-btn
        >
      </div>
      <v-autocomplete
        :disabled="isEditing || is_recognizing"
        :items="promoList"
        attach
        v-model="body.campaign_id"
        @change="onCampaignSelect"
        item-text="campaign_name"
        item-value="campaign_id"
        return-object
        class="modal-input my-6"
        :label="$t('campaign_name')"
        :rules="[
          (value) => {
            return genericRequiredRule(value, $t('campaign_name'));
          },
        ]"
      />
      <div v-for="(spending, index) in body.spendings" :key="index">
        <v-autocomplete
          :disabled="isEditing || is_recognizing"
          v-model="spending.store_id"
          :items="body.stores"
          item-value="store_id"
          item-text="store_name"
          attach
          class="modal-input my-6"
          :label="$t('store_name')"
          :rules="[
            (value) => {
              return genericRequiredRule(value, $t('store_name'));
            },
          ]"
        />
        <v-text-field
          :disabled="isEditing || is_recognizing"
          v-model.number="spending.amount"
          min="0"
          type="number"
          class="modal-input my-6"
          :label="$t('spent_amount')"
          :rules="[
            (value) => {
              return genericRequiredRule(value, $t('spent_amount'));
            },
          ]"
        />
        <template v-if="cyberFlow">
          <v-autocomplete
            v-model="spending.payment_method"
            :items="paymentMethods"
            attach
            class="modal-input my-6"
            :label="$t('payment_method')"
            :rules="[
              (value) => {
                return genericRequiredRule(value, $t('payment_method'));
              },
            ]"
          />
          <input
            type="file"
            accept="image/*"
            capture="camera"
            style="width: 0"
            :ref="`cam-${spending.store_id}`"
            @change="attachReceipt($event, spending)"
          />
          <img v-if="spending.src" :src="spending.src" style="width: 100%" />
          <div class="d-flex align-center">
            <v-btn
              @click="toggleCamera(spending.store_id)"
              text
              class="modal-btn delete mr-6"
              :disabled="!spending.store_id"
              >{{ $t("take_photo") }}</v-btn
            >
            <v-btn
              @click="deleteShop(index)"
              text
              class="modal-btn delete"
              :disabled="body.spendings.length === 1"
              >{{ $t("delete") }} {{ $t("store") }}</v-btn
            >
          </div></template
        >
      </div>
      <v-btn
        @click="addShop"
        :disabled="isEditing"
        text
        class="flat-btn mt-n4 d-flex ml-auto"
        :style="{ width: '100%' }"
        >+ {{ $t("add") }} {{ $t("store") }}</v-btn
      >

      <v-checkbox
        :label="$t('parking_free')"
        :value="body.discount_option === 'all_free'"
        @change="body.discount_option = $event ? 'all_free' : ''"
        class="filter-checkbox"
      />
      <v-select
        :disabled="
          !!body.custom_params.fixed_hours ||
          !!body.custom_params.fixed_cost ||
          !body.campaign_id ||
          is_recognizing ||
          body.discount_option === 'all_free'
        "
        v-if="!cyberFlow"
        attach
        :items="[1, 2, 3, 4, 5]"
        class="modal-input my-6"
        :value="body.minutes / 60 || undefined"
        @change="($event) => (body.minutes = Number($event) * 60)"
        :label="$t('free_fixed_hours')"
        :rules="[
          (value) => {
            return genericRequiredRule(value, $t('free_fixed_hours'));
          },
        ]"
      />
      <v-text-field
        :disabled="isEditing"
        v-model="custom_params.remark"
        class="modal-input my-6"
        :label="$t('remark')"
      />
      <v-btn @click="viewSummary" class="main-btn mt-4 px-8 py-2">{{ $t("next") }}</v-btn>
    </v-form>

    <div v-if="popup" class="popup record-popup">
      <div class="modal-overlay" @click="hidePopup" />
      <div class="modal-content text-center">
        <div class="promo-summary">
          <h4 class="main-title">{{ $t("promo_summary") }}</h4>
          <div class="total parking-subtitle">
            {{ $t("car") }}: <b>{{ body.plate_number }}</b>
          </div>

          <!-- MANUAL INPUT PROMO INFO -->
          <template v-if="!is_recognizing">
            <div class="total parking-subtitle">
              {{ $t("campaign_name") }}: {{ body.campaign_name }}
            </div>
            <div v-for="(spending, index) in body.spendings" :key="index">
              <h5 class="parking-text pa-2">
                {{ index + 1 }}.
                {{
                  body.stores.filter((s) => s.store_id === spending.store_id)[0]
                    .store_name
                }}
                <span>: {{ currency }}{{ spending.amount }}</span>
              </h5>
            </div>
            <div class="total parking-subtitle">
              {{ $t("total") }}: {{ currency }}{{ total }}
            </div>
            <div class="total parking-subtitle" v-if="body.custom_params.fixed_hours">
              {{ $t("free_hours") }}: {{ body.custom_params.fixed_hours }}
            </div>
            <div class="total parking-subtitle" v-if="cyberFlow">
              {{ $t("free_hours") }}: {{ calculation.free_hours }}
            </div>
            <div
              class="total parking-subtitle"
              v-if="body.discount_option === 'all_free'"
            >
              {{ $t("free_hours") }}: {{ $t("parking_free") }}
            </div>
            <div class="total parking-subtitle" v-else>
              {{ $t("free_hours") }}: {{ body.minutes / 60 }}
            </div>
          </template>
          <template v-else>
            <img :src="discount.src" style="max-height: 384px" class="mt-4" />
          </template>
          <div class="d-flex justify-space-around mt-6">
            <v-btn
              v-if="cyberFlow"
              @click="applyDiscounts"
              text
              class="main-btn px-8 py-2"
              >{{ $t("apply") }}</v-btn
            ><v-btn
              v-else-if="is_recognizing"
              @click="sendReceipt"
              text
              class="main-btn px-8 py-2"
              :loading="busy"
              >{{ $t("apply") }}</v-btn
            ><v-btn v-else @click="applyPromo" text class="main-btn px-8 py-2">{{
              $t("apply")
            }}</v-btn>
            <v-btn text @click="hidePopup" class="flat-btn modal-footer-btn">{{
              $t("edit")
            }}</v-btn>
          </div>
        </div>
      </div>
    </div>

    <div v-if="qr_popup" class="popup">
      <div class="modal-overlay" @click="qr_popup = false" />
      <div class="modal">
        <div class="modal-content text-right qr-popup">
          <v-btn
            text
            class="icon-close pa-0 ml-auto"
            min-width="24px"
            @click="qr_popup = false"
          />
          <img class="d-block" width="100%" :src="qr" alt="QR code" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { base, cyberport } from "@/axios-api";
import qs from "qs";
import "@/scss/_modal.scss";
import { formatPlate } from "../../common/helpers/helpers";
import { uniqueId } from 'lodash';

export default {
  name: "CustomerSvcForm",
  components: {},
  data() {
    return {
      body: {
        stores: [],
        spendings: [{}],
        custom_params: {},
      },
      custom_params: { remark: "" },
      popup: false,
      qr: "",
      qr_popup: false,
      receipts: [],
      parking_id: undefined,
      guest_type: undefined,
      calculation: 0,
      parking_not_found: false,
      code: "",
      //JP demo below
      is_recognizing: false,
      discount: {},
      busy: false,
      promoDetails: [],
    };
  },
  computed: {
    currency() {
      return this.$store.getters["app/currency"];
    },
    promoList() {
      return this.$store.state.promo.campaigns;
    },
    carsList() {
      return this.$store.state.promo.cars;
    },
    total() {
      let total = 0;
      if (this.body.spendings) {
        this.body.spendings.map((purchase) => {
          total = total + purchase.amount;
        });
      }
      if (isNaN(total)) {
        return 0;
      }
      return total;
    },
    cyberFlow() {
      return this.$store.getters["app/isCyber"];
    },
    place() {
      return this.$store.state.app.places[0] || {};
    },
    paymentMethods() {
      return this.$t("receipt_payment_methods");
    },
    plateNumberLabel() {
      let res = this.$t("car_plate");
      if (this.cyberFlow) res = res + ` / ${this.$t("octopus_no")}`;
      return res;
    },
    currentRecord() {
      return this.$store.state.promo.cur_record;
    },
    isEditing() {
      return this.currentRecord.campaign && !!this.currentRecord.campaign.campaign_id;
    },
    isAdmin() {
      return this.$store.getters["app/isAdmin"];
    },

    hasDiscount() {
      return this.$store.getters["app/hasDiscount"];
      // return true;
    },
  },
  mounted() {
    if (this.isEditing) {
      this.body = {
        ...this.body,
        ...this.currentRecord.campaign,
        ...this.currentRecord.vehicle,
        ...this.currentRecord,
        minutes: this.currentRecord.vehicle.promo_minutes,
        stores: this.currentRecord.spendings,
      };
    }
  },
  methods: {
    addShop() {
      this.body.spendings.push({
        campaign_id: this.body.campaign_id,
        store_id: "",
        amount: null,
      });
      this.$forceUpdate();
    },
    deleteShop(index) {
      this.body.spendings.splice(index, 1);
    },
    viewSummary() {
      this.popup = true;
      // console.log(this.custom_params);
      if (this.$store.getters["app/isCyber"]) {
        this.body.spendings.map((i) => {
          if (!i.file) {
            this.addReceipt(null, i);
          }
        });
        let watcher = setInterval(() => {
          if (this.busy === false) {
            cyberport({
              url: "/platform/discount/calculate_discount",
              method: "GET",
              params: {
                receipt_id: this.receipts,
              },
              paramsSerializer: (params) => {
                return qs.stringify(params, {
                  arrayFormat: "repeat",
                });
              },
            }).then((res) => {
              this.calculation = res.data.body;
              clearInterval(watcher);
            });
          }
        }, 500);
      }
    },
    hidePopup() {
      this.popup = false;
    },
    genericRequiredRule(value, msg) {
      if (this.qr_popup === false) {
        return !!value || msg + this.$t("required");
      }
    },
    plateFormatter(value) {
      if (!value) {
        return "";
      }

      return formatPlate(value);
    },
    onCampaignSelect(campaign) {
      this.body = { ...this.body, ...campaign };
    },
    loadCars() {
      this.$store.dispatch("promo/getCars");
    },
    applyPromo() {
      let data = {
        ...this.body,
        custom_params: { ...this.body.custom_params, ...this.custom_params },
        place_id: this.place.place_id,
        guest_type: this.guest_type,
        spendings: this.body.spendings.map((i) => {
          return {
            ...i,
            campaign_id: this.body.campaign_id,
          };
        }),
      };
      if (this.isEditing) {
        this.$store.dispatch("promo/updatePromoHours", data);
        if (this.isAdmin) this.$router.push("/platform/promo/history");
      } else {
        this.$store.dispatch("promo/applyPromo", data);
      }
      this.hidePopup();

      this.resetState();
    },
    resetState() {
      this.$refs.form.reset();
      this.body = {
        stores: [],
        spendings: [{}],
        custom_params: {},
      };
    },

    //cyberport shit
    toggleCamera(store) {
      let ref = `cam-${store}`;
      this.$refs[ref][0].click();
    },
    getParking() {
      this.parking_not_found = false;
      this.promoDetails = [];
      this.body.plate_number = this.plateFormatter(this.body.plate_number);
      base({
        url: "/promo/parking",
        method: "GET",
        params: {
          place_id: this.place.place_id,
          plate_number: this.body.plate_number,
        },
      })
        .then((res) => {
          if (res.data.code === 404) {
            this.parking_not_found = true;
          } else {
            let parking = res.data.body.parking || {};
            this.parking_id = parking.parking.parking_id;
            this.guest_type = parking.parking.guest_type;
            if (res.data.body.hasPromo) {
              this.promoDetails = res.data.body.promoDetails.map((detail) => {
                return {
                  ...detail,
                  id: uniqueId(),
                }
              });
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    },
    attachReceipt(e, data) {
      let files = e.target.files;
      let f = files[0];

      if (files && f) {
        let reader = new FileReader();
        this.addReceipt(f, data);

        reader.onload = (e) => {
          let src = e.target.result;
          data.src = src;
          this.$forceUpdate();
        };

        reader.readAsDataURL(f);
      }
    },
    addReceipt(file, spending) {
      this.busy = true;
      let data = new FormData();
      if (file) data.append("receipt_photos", file, file.name);
      data.append("parking_id", this.parking_id);
      data.append("store_id", spending.store_id);
      data.append("amount", spending.amount * 100);
      data.append("payment_method", spending.payment_method);
      cyberport({
        url: "/platform/discount/add_receipt",
        method: "POST",
        data,
      }).then((res) => {
        console.log("got receipt");
        let rec = res.data.body.receipt_id;
        this.receipts.push(rec);
        this.busy = false;
      });
    },
    applyDiscounts() {
      let data = {
        parking_id: this.parking_id,
        receipt_id: [...this.receipts],
      };

      this.$store.dispatch("promo/applyDiscounts", data);
      this.hidePopup();

      this.resetState();
    },
    applyCode() {
      cyberport({
        url: "/promocodes",
        method: "POST",
        data: {
          parking_id: this.parking_id,
          code: this.code,
          origin: 3,
        },
      }).then((res) => {
        let freeHours = res.data.body.free_minutes / 60;
        this.$notify({
          group: "general",
          title: "Success!",
          text: this.$t("promocode_applied", { n: freeHours }),
          duration: 3000,
        });
      });
    },
    //RECEIPTS DEMO

    addDiscount(f) {
      if (f) {
        let reader = new FileReader();
        this.discount.file = f;

        reader.onload = (e) => {
          let src = e.target.result;
          this.discount = {
            ...this.discount,
            src,
            created_at: new Date(),
          };
        };

        reader.readAsDataURL(f);
      }
    },
    sendReceipt() {
      this.busy = true;
      let data = new FormData();
      data.append("receipt_photos", this.discount.file);
      data.append("parking_id", this.parking_id);
      base({
        url: "/discounts/send_to_recognize_portal",
        method: "POST",
        data,
      }).then(() => {
        this.busy = false;
        this.$notify({
          group: "general",
          title: "Success!",
          text: this.$t("discount_success"),
          duration: 3000,
        });
        this.hidePopup();

        this.resetState();
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$store.commit("promo/set_current_record", {});
    next();
  },
};
</script>

<style lang="scss" scoped>
.promo-details {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
  color: red;
}
</style>
