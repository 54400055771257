<template>
  <div class="tabs-content">
    <h3 class="main-title">{{ $t("notifications") }}</h3>
    <div class="d-flex align-center mt-5 mb-8">
      <h4 class="green-text mr-6">{{ $t("notifications_new", { n: unread }) }}</h4>
      <v-text-field
        v-model="search"
        autocomplete="off"
        class="parking-search py-0 my-0 mr-4"
        :placeholder="$t('search_notification')"
      />
      <CoreFilter
        ref="filter"
        class="mr-4"
        @filterSet="updFilters"
        :hidden="{
          campaign: true,
          created_by: true,
          price_range: true,
          zone: true,
          status: true,
          wallet: true,
          car_type: true,
          pricing: true,
        }"
      />
      <v-btn text class="mr-auto" @click="refresh">
        <img src="@/assets/images/refresh.svg" alt="refresh" />
      </v-btn>
      <v-btn text class="main-btn py-2 px-6 mr-4" @click="openSettings">{{
        $t("settings")
      }}</v-btn>
      <v-btn text class="flat-btn" @click="back">{{ $t("back") }}</v-btn>
    </div>
    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
    <v-data-table
      v-else
      :headers="headers"
      :items-per-page="-1"
      class="table not-expandable"
      :items="list"
      disable-pagination
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.date`]="{ item }">
        <div>
          {{ $moment(item.date).format("DD.MM.YYYY [at] HH:mm") }}
        </div>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <div>
          {{ $t(`notification_types.${[item.type]}`) }}
        </div>
      </template>

      <template v-slot:[`item.description`]="{ item }">
        <div>
          {{ $t(`notification_texts.${[item.type]}`) }}
        </div>
      </template>
    </v-data-table>
    <Settings ref="settings" />
  </div>
</template>
<script>
export default {
  name: "Notifications",
  components: {
    CoreFilter: () => import("@/components/core/Filter"),
    Settings: () => import("@/components/Notifications/Settings"),
  },
  data() {
    return {
      searchValue: "",
      timeout: null,
    };
  },
  computed: {
    unread() {
      return this.list.length;
    },
    list() {
      return [
        {
          type: 1,
          date: "2022-06-23T10:13:33.544Z",
        },
        {
          type: 2,
          date: "2022-06-23T10:13:33.544Z",
        },
        {
          type: 3,
          date: "2022-06-23T10:13:33.544Z",
        },
      ];
    },
    loading() {
      // return this.$store.state.notifications.loading
      return false;
    },
    headers() {
      return [
        {
          text: this.$t(`date`),
          value: "date",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t(`notifications`),
          value: "type",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t(`description`),
          value: "description",
          class: "table-header",
          sortable: false,
        },
      ];
    },
    search: {
      get() {
        return this.searchValue;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchValue = val;
          this.$refs.table.search(this.searchValue);
        }, 1000);
      },
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch("devices/getList");
      this.$store.commit("devices/set_loader", true);
    },
    updFilters(filters) {
      console.log(filters);
    },
    back() {},
    openSettings() {
      this.$refs.settings.show();
    },
  },
};
</script>
