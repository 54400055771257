<template>
  <div class="user-content no-frame">
    <div class="d-flex align-center mb-6">
      <h3 class="main-title my-0 mr-6">{{ $t("logbook") }}</h3>
      <v-text-field
        class="parking-search py-0 my-0"
        :placeholder="$t(`logbook_seacrh`)"
      />
    </div>
    <v-data-table
      :headers="headers"
      :items-per-page="-1"
      class="table"
      :items="records"
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
      :no-data-text="$t('no_data')"
    ></v-data-table>
  </div>
</template>

<script>
export default {
  name: "UserGeneral",
  data: () => {
    return {};
  },
  computed: {
    current() {
      return this.$store.state.users.current;
    },
    records() {
      return this.$store.state.users.logs;
    },
    headers() {
      return [
        {
          text: this.$t("date"),
          value: "date",
          sortable: false,
          class: "table-date table-header",
        },
        {
          text: this.$t("action"),
          value: "actions",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("description"),
          value: "date",
          sortable: false,
          class: "table-header",
        },
      ];
    },
  },
};
</script>

<style></style>
