import { base } from '@/axios-api';
import store from '@/store/index';
import qs from 'qs';
// import Vue from 'vue';
// import i18n from '@/plugins/i18n';

export default {
    getHistory({
        commit,
        dispatch
    }, params) {
        commit('set_loader', true)
        let place_id = store.state.app.places.map(p => p.place_id)
        base({
                url: '/history/overtime',
                method: 'GET',
                params: {
                    ...params,
                    place_id
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body || []
                    commit("set_history", data)
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });

        return dispatch
    },
}