<template>
  <div class="tabs-content">
    <div class="d-flex align-center mt-5 mb-8">
      <v-tabs v-model="tab" class="mr-6 ml-0 mb-0">
        <v-tab v-for="(item, index) in tabs" :key="index">{{ $t(`${item}`) }}</v-tab>
      </v-tabs>
      <v-btn text class="mr-auto dwl-btn" @click.stop="refresh">
        <img
          :style="{ height: '24px', width: '24px' }"
          src="@/assets/images/refresh.svg"
          alt="refresh"
        />
      </v-btn>
    </div>
    <v-skeleton-loader v-if="loading" type="table"></v-skeleton-loader>
    <v-data-table
      v-else
      :headers="headers"
      :items-per-page="-1"
      class="table not-expandable"
      :items="list"
      disable-pagination
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.type`]="{ item }">
        <div>
          {{ $t(`device_types[${[item.device_type - 1]}]`) }}
        </div>
      </template>

      <template v-slot:[`item.location`]="{ item }">
        <div>
          {{ $t(`location_types[${[item.device_position - 1]}]`) }}
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div>
          <span
            class="status-icon pa-4 active"
            :class="{ requested: item.is_online === false }"
          />
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: "Devices",
  data() {
    return {
      drawer: false,
      tab: 2,
    };
  },
  computed: {
    tabs() {
      return this.$t(`device_status_options`);
    },
    devices() {
      return [...this.$store.state.devices.list].sort(
        (a, b) => a.device_position - b.device_position
      );
    },
    list() {
      switch (this.tab) {
        case 0:
          return [...this.devices].filter((i) => i.is_online === true);
        case 1:
          return [...this.devices].filter((i) => i.is_online === false);
        case 2:
          return [...this.devices];
        default:
          return [];
      }
    },
    loading() {
      return this.$store.state.devices.loading;
    },
    headers() {
      return [
        {
          text: this.$t(`device`),
          value: "type",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t(`location`),
          value: "location",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t(`status`),
          value: "status",
          class: "table-header",
          sortable: false,
        },
      ];
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch("devices/getList");
      this.$store.commit("devices/set_loader", true);
    },
  },
};
</script>
