<template>
  <div
    class="record-popup popup parking-record is-long"
    v-show="open"
  >
    <div class="modal-overlay" @click="hide" />
    <v-menu
      absolute
      attach
      :value="!popup"
      :close-on-content-click="false"
      :close-on-click="false"
      content-class="modal-content no-scroll"
    >
      <div class="d-flex justify-space-between align-center pb-2">
        <h3 class="main-title mr-auto">
          {{ $t("parking_record") }}
          <span v-if="formType === 1"
            >-
            {{
              hrec.parking.completed
                ? $t("parking_history_tabs[1]")
                : $t("parking_history_tabs[0]")
            }}</span
          >
        </h3>
        <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
      </div>
      <div class="d-flex flex-wrap record-tabs">
        <div v-if="isSpot" class="photo mr-md-6">
          <ZoomOnHover v-if="hrec.photo" scale="2.5" :img-normal="hrec.photo" />
          <!-- <img v-if="hrec.photo" :src="`${hrec.photo}`" style="width: 100%" /> -->
          <v-skeleton-loader type="image" v-else />
        </div>

        <v-tabs v-model="tab" class="mx-0 record-tabs justify-center">
          <v-tab
            class="mx-0"
            v-for="(item, index) in tabs"
            :key="index"
            >{{ item }}
          </v-tab>
          <v-tab-item>
            <div class="parking-record-block">
              <v-text-field
                :readonly="editable === false"
                class="modal-input mb-3 mt-0"
                @input="plateFormatter"
                :value="hrec.car.plate_number"
                :label="$t('car_plate')"
              />
              <!-- DATES -->
              <div class="desc-text py-1">
                <span>{{ $t("date_in") }}: </span>
                <b v-if="parkingDates.enter_time">{{
                  $moment(parkingDates.enter_time).format("DD.MM.YYYY [at] HH:mm")
                }}</b>
                <div class="form-period date single mt-2">
                  <v-menu
                    content-class="datepicker-menu"
                    attach
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="editable === false"
                        v-on="on"
                        class="modal-input input-half datepicker-inp mt-0"
                        :value="
                          parkingDates.enter_time
                            ? $moment(parkingDates.enter_time).format(
                                'DD.MM.YYYY [at] HH:mm'
                              )
                            : ''
                        "
                        @change="timeChangeHandler(`enter`, $event)"
                        :label="$t('from')"
                        placeholder="DD.MM.YYYY HH:mm"
                      ></v-text-field>
                    </template>

                    <v-tabs fixed-tabs v-model="dates.enter.tab" class="picker-tabs">
                      <v-tab key="calendar">
                        <img src="@/assets/images/calendar.svg" />
                      </v-tab>
                      <v-tab key="timer">
                        <img width="24px" height="24px" src="@/assets/images/clock.svg" />
                      </v-tab>
                      <v-tab-item key="calendar">
                        <v-date-picker
                          :locale="$i18n.locale"
                          first-day-of-week="1"
                          @change="dates.enter.tab = 1"
                          v-model="dates.enter.date"
                          :max="$moment().format('YYYY-MM-DD')"
                        ></v-date-picker>
                      </v-tab-item>
                      <v-tab-item key="timer">
                        <vue-timepicker
                          format="HH:mm:ss"
                          manual-input
                          v-model="dates.enter.time"
                          blur-delay="5000000"
                        />
                      </v-tab-item>
                    </v-tabs>
                  </v-menu>
                </div>
              </div>
              <div class="desc-text py-1">
                <span>{{ $t("date_out") }}: </span>
                <b v-if="parkingDates.exit_time">{{
                  $moment(parkingDates.exit_time).format("DD.MM.YYYY [at] HH:mm")
                }}</b>
                <div class="form-period date single mt-2">
                  <v-menu
                    content-class="datepicker-menu"
                    attach
                    :close-on-content-click="false"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        :disabled="editable === false"
                        v-on="on"
                        class="modal-input input-half datepicker-inp mt-0"
                        @change="timeChangeHandler(`exit`, $event)"
                        :value="
                          parkingDates.exit_time
                            ? $moment(parkingDates.exit_time).format(
                                'DD.MM.YYYY [at] HH:mm'
                              )
                            : ''
                        "
                        :label="$t('to')"
                        placeholder="DD.MM.YYYY HH:mm"
                      ></v-text-field>
                    </template>

                    <v-tabs fixed-tabs v-model="dates.exit.tab" class="picker-tabs">
                      <v-tab key="calendar">
                        <img src="@/assets/images/calendar.svg" />
                      </v-tab>
                      <v-tab key="timer">
                        <img width="24px" height="24px" src="@/assets/images/clock.svg" />
                      </v-tab>
                      <v-tab-item key="calendar">
                        <v-date-picker
                          :locale="$i18n.locale"
                          first-day-of-week="1"
                          @change="dates.exit.tab = 1"
                          v-model="dates.exit.date"
                          :max="$moment().format('YYYY-MM-DD')"
                        ></v-date-picker>
                      </v-tab-item>
                      <v-tab-item key="timer">
                        <vue-timepicker
                          format="HH:mm:ss"
                          manual-input
                          v-model="dates.exit.time"
                          blur-delay="5000000"
                        />
                      </v-tab-item>
                    </v-tabs>
                  </v-menu>
                </div>
              </div>
              <!-- </div> -->

              <template>
                <!-- PLACE -->
                <v-select
                  :no-data-text="$t('no_data')"
                  v-if="$store.getters['app/isCompany']"
                  attach
                  v-model="hrec.place.place_id"
                  item-value="place_id"
                  item-text="name"
                  class="modal-input my-0"
                  :items="places"
                  :label="$t('place')"
                />

                <!-- STATUS AND DURATION AND TOTAL PRICE -->
                <div
                  class="d-flex justify-space-between align-center desc-text flex-wrap"
                  v-if="formType === 1"
                >
                  <div v-if="hrec.parking.guest_type === 4">
                    <span>{{ $t("total") }}: </span>
                    <b>
                      {{
                        hrec.parking.total_price
                          ? hrec.parking.currency + hrec.parking.total_price / 100
                          : "0"
                      }}</b
                    >
                  </div>
                  <div>
                    <span>{{ $t("duration") }}: </span>
                    <b>{{ getDuration(hrec.parking) }}</b>
                  </div>
                </div>
                <v-checkbox
                  v-if="isGoldin"
                  :label="$t('unloading')"
                  :input-value="hrec.parking.is_unloading"
                  @change="($e) => hrec.parking.is_unloading = $e"
                  class="filter-checkbox"
                />
              </template>
              <template v-if="hrec.parking.parking_id && hasPaidCash">
                <div class="d-flex justify-space-between align-center desc-text">
                  <v-checkbox
                    :label="$t('parking_cash')"
                    :disabled="isFree"
                    :input-value="isCash"
                    @change="($e) => paymentMethodHandler($e, 'cash')"
                    class="filter-checkbox"
                    :class="hrec.parking.payment_method === 2 ? 'active' : ''"
                  />
                  <v-text-field
                    :value="hrec.parking.total_price / 100 || null"
                    @change="priceChangeHandler"
                    :label="$t(`total`)"
                    class="modal-input input-half"
                    :disabled="hrec.parking.payment_method !== 2"
                  />
                </div>
                <div class="d-flex justify-space-between align-center desc-text mb-4">
                  <v-checkbox
                    :label="$t('parking_free')"
                    :disabled="isCash"
                    :input-value="isFree"
                    @change="($e) => paymentMethodHandler($e, 'free')"
                    class="filter-checkbox"
                    :class="hrec.parking.payment_method === 2 ? 'active' : ''"
                  /></div
              ></template>
              <!-- <div class="border-btm mt-2 mx-n1" /> -->
            </div>

            <div class="d-flex align-center my-3" v-if="hasEditRight">
              <v-btn v-if="editable" @click="saveRecordInfo" class="main-btn px-6 py-2" text>{{
                  $t("save")
                }}</v-btn>
              <v-btn @click="deleteRecord" class="flat-btn" text>{{ $t("delete") }}</v-btn>
            </div>
            <v-btn
              :loading="loading"
              v-if="record.parking.receipt_printable"
              style="min-width: 100%"
              class="main-btn py-2 ml-auto my-3"
              text
              @click="confirmReprintReceipt"
            >
              {{ $t(`reprint_receipt`) }}
            </v-btn>
            <v-btn
              :loading="loading"
              v-else-if="hasCSPayment"
              style="min-width: 100%"
              class="main-btn py-2 ml-auto my-3"
              text
              @click="openCheckout"
            >
              <template v-if="isItt || isGoldin">{{ $t(`checkout`) }}</template>
              <template v-else>{{ $t(`price_check`) }}</template>
            </v-btn>
            <v-btn
              :loading="loading"
              v-if="record?.payment?.receipt_id"
              class="main-btn py-2 my-3 record-button"
              text
              @click="copyReceiptLink"
            >
              {{
                isCopied
                  ? $t(`copied`)
                  : isCopyError
                    ? $t(`error_short`)
                    : $t(`copy_receipt_link`)
              }}
            </v-btn>
          </v-tab-item>

          <v-tab-item>
            <div class="parking-record-block">
              <v-text-field
                :readonly="editable === false"
                class="modal-input mb-3 mt-0"
                @input="plateFormatter"
                :value="hrec.car.plate_number"
                :label="$t('car_plate')"
              />
              <v-select
                :no-data-text="$t('no_data')"
                :readonly="editable === false"
                class="modal-input my-3"
                attach
                v-model="hrec.car.car_type"
                item-value="id"
                item-text="desc"
                :items="carTypes"
                :label="$t('car_type')"
              />

              <v-text-field
                :readonly="editable === false"
                class="modal-input my-3"
                v-model="hrec.car.color"
                :label="$t('car_color')"
              />
              <v-combobox
                :readonly="editable === false"
                attach
                v-model="hrec.car.brand"
                class="modal-input my-3"
                :items="getCarBrands(hrec.car.brand)"
                :label="$t('car_brand')"
              />
            </div>
            <div class="d-flex align-center my-3" v-if="hasEditRight">
              <v-btn v-if="editable" @click="saveRecordInfo" class="main-btn px-6 py-2" text>{{
                  $t("save")
                }}</v-btn>
              <v-btn @click="deleteRecord" class="flat-btn" text>{{ $t("delete") }}</v-btn>
            </div>
            <v-btn
              :loading="loading"
              v-if="record.parking.receipt_printable"
              style="min-width: 100%"
              class="main-btn py-2 ml-auto my-3"
              text
              @click="confirmReprintReceipt"
            >
              {{ $t(`reprint_receipt`) }}
            </v-btn>
            <v-btn
              :loading="loading"
              v-else-if="hasCSPayment"
              style="min-width: 100%"
              class="main-btn py-2 ml-auto my-3"
              text
              @click="openCheckout"
            >
              <template v-if="isItt || isGoldin">{{ $t(`checkout`) }}</template>
              <template v-else>{{ $t(`price_check`) }}</template>
            </v-btn>
            <v-btn
              :loading="loading"
              v-if="record?.payment?.receipt_id"
              class="main-btn py-2 my-3 record-button"
              text
              @click="copyReceiptLink"
            >
              {{
                isCopied
                  ? $t(`copied`)
                  : isCopyError
                    ? $t(`error_short`)
                    : $t(`copy_receipt_link`)
              }}
            </v-btn>
          </v-tab-item>

          <v-tab-item v-if="isKioskCheckoutAvailable" class="send-to-kiosk-wrapper">
            {{ $t("calculation_total") }}
            <v-text-field
              v-model="kioskCheckoutUserAmount"
              @blur="handleBlurKioskCheckoutAmount"
              :disabled="!isKioskCheckoutAmountLoaded"
              class="modal-input mb-3 mt-0"
            />
            <v-btn
              @click="confirmSendToKiosk"
              :disabled="!isKioskCheckoutAmountLoaded || !kioskCheckoutUserAmount"
              text
              class="main-btn px-6 py-2"
            >
              {{ $t("send_to_kiosk") }}
            </v-btn>
          </v-tab-item>
        </v-tabs>
      </div>
    </v-menu>

    <div class="checkout-wrapper">
      <Checkout
        :record="hrec"
        v-show="popup === 'checkout'"
        @hide="exitPopup"
        @openCSChannel="openCSChannel"
      />
      <OctopusTransactions v-show="popup === 'transactions'" @hide="exitPopup" />
      <OctopusFlow @hide="exitPopup" v-show="popup === 'octopus'" ref="octopusFlow" />
    </div>
  </div>
</template>

<script>
import { base } from "@/axios-api";
import { Centrifuge } from "centrifuge";
const CENTR_HOST = "wss://api.parkingbnb.world/tv/centrifugo/connection/websocket";

import carBrandsDb from "@/assets/cars/carModels.json";
import EventBus from '@/event-bus';
import { formatPlate } from "@/common/helpers/helpers";

const defaultDates = {
  enter: {
    tab: 0,
    date: "",
    time: {
      HH: undefined,
      mm: undefined,
      ss: undefined,
    },
  },
  exit: {
    tab: 0,
    date: "",
    time: {
      HH: undefined,
      mm: undefined,
      ss: undefined,
    },
  },
};

export default {
  name: "ParkingRecordPopup",
  props: {
    record: {
      default() {
        return {
          parking: { guest_type: undefined, is_unloading: false },
          place: {},
          user: {},
          car: {},
        };
      },
    },
  },
  components: {
    VueTimepicker: () => import("vue2-timepicker/src/vue-timepicker.vue"),
    Checkout: () => import("./Checkout.vue"),
    OctopusTransactions: () => import("@/components/Wallet/OctopusTransactions.vue"),
    OctopusFlow: () => import("@/components/Wallet/OctopusFlow.vue"),
  },
  data() {
    return {
      loading: false,
      tab: 0,
      open: false,
      editing: false,
      dates: JSON.parse(JSON.stringify(defaultDates)),
      isCash: false,
      isFree: false,
      hrec: {
        car: {},
        parking: { guest_type: undefined },
        user: {},
        place: {},
      },
      //CHECKOUT
      centrifuge: null,
      popup: "",
      kioskCheckoutUserAmount: "",
      isCopied: false,
      isCopyError: false,
    };
  },
  computed: {
    kioskCheckoutAmount() {
      return this.$store.state.history.kioskCheckoutAmount;
    },
    isKioskCheckoutAmountLoaded() {
      return this.$store.state.history.isKioskCheckoutAmountLoaded;
    },
    isKioskCheckoutAvailable() {
      return this.hrec.parking.kiosk_checkout_available;
    },
    tabs() {
      const tabs = [this.$t(`parking_info`), this.$t(`car_info`)];
      if (this.isKioskCheckoutAvailable) {
        tabs.push(this.$t(`kiosk`));
      }
      return tabs;
    },
    isCustomerService() {
      return this.$store.getters["app/isCustomerService"];
    },
    isSpot() {
      return this.$store.getters["app/isSpot"];
    },
    isGoldin() {
      return this.$store.getters["app/isGoldin"];
    },
    isItt() {
      return this.$store.getters["app/isItt"];
    },
    hasEditRight() {
      return this.$store.getters["app/hasHistoryEditRight"];
    },
    parkingDates() {
      let enterString = `${this.dates.enter.date} ${this.dates.enter.time.HH}:${this.dates.enter.time.mm}:${this.dates.enter.time.ss}`.replaceAll(
        "undefined",
        ""
      );
      let exitString = `${this.dates.exit.date} ${this.dates.exit.time.HH}:${this.dates.exit.time.mm}:${this.dates.exit.time.ss}`.replaceAll(
        "undefined",
        ""
      );
      if (enterString.length < 19) enterString = "";
      else enterString = this.$moment(enterString).toISOString();

      if (exitString.length < 19) exitString = "";
      else exitString = this.$moment(exitString).toISOString();

      return {
        enter_time: enterString,
        exit_time: exitString,
      };
    },
    formType() {
      return Number(!!this.hrec.parking.parking_id);
    },
    places() {
      return [...this.$store.state.app.places].map((p) => {
        return { ...p, name: p.place_name };
      });
    },
    locale() {
      return this.$store.getters["app/locale"];
    },
    hasSimpleHistory() {
      return this.$store.getters["app/hasSimpleHistory"];
    },
    carTypes() {
      let types = [];
      let ids = [...this.$store.state.app.car_types];
      ids
        .filter((i) => i.id !== 0)
        .map((id) => {
          types.push({ id, desc: this.$t(`car_types[${id}]`) });
        });
      return types;
    },
    hasPaidCash() {
      return (
        this.record.parking.guest_type === 10 ||
        (this.record.parking.guest_type === 5 &&
          this.$store.getters["app/hasOfflinePayment"]) ||
        this.isBV
      );
    },
    hasCSPayment() {
      return (
        this.$store.getters["app/isItt"] || this.$store.getters["app/isGoldin"] || this.$store.getters["app/hasCalculation"]
      );
      // return true;
    },
    editable() {
      return true;
    },
    isBV() {
      return this.$store.getters["app/isBV"];
    },
    token() {
      return this.$store.state.app.cs_token;
    },
    refreshToken() {
      return this.$store.state.app.cs_refresh_token;
    },
  },
  watch: {
    open(newValue) {
      this.hrec = JSON.parse(JSON.stringify(this.record));

      if (newValue) {
        if (this.isKioskCheckoutAvailable) {
          this.getKioskCheckoutCalculation({
            parking_id: this.hrec.parking.parking_id,
            guest_type: this.hrec.parking.guest_type,
          });
        }
      } else {
        this.$store.commit('history/set_kiosk_checkout_amount_loaded_status', false);
        this.$store.commit('history/set_kiosk_checkout_amount', 0);
        this.kioskCheckoutUserAmount = "";
      }

      if (this.record && this.record.parking && this.record.parking.enter_time) {
        if (this.isSpot) {
          this.getParkingPic();
        }

        if (this.record.parking.payment_method === 2) {
          this.isFree = this.record.parking.total_price === 0;
          this.isCash = this.record.parking.total_price > 0;
        }

        let enterString = this.record.parking.enter_time;
        let exitString = this.record.parking.exit_time || "";
        this.dates = {
          enter: {
            tab: 0,
            date: this.$moment(enterString).format("YYYY-MM-DD"),
            time: {
              HH: this.$moment(enterString).format("HH"),
              mm: this.$moment(enterString).format("mm"),
              ss: this.$moment(enterString).format("ss"),
            },
          },
          exit: {
            tab: 0,
            date: this.$moment(exitString).format("YYYY-MM-DD"),
            time: {
              HH: this.$moment(exitString).format("HH"),
              mm: this.$moment(exitString).format("mm"),
              ss: this.$moment(exitString).format("ss"),
            },
          },
        };
      }
    },
  },
  methods: {
    show() {
      this.open = true;
    },
    hide() {
      this.isCopied = false;
      this.isCopyError = false;
      this.open = false;
      this.dates = JSON.parse(JSON.stringify(defaultDates));
      this.isCash = false;
      this.isFree = false;
      this.loading = false;
      this.hrec = {
        car: {},
        parking: { guest_type: undefined, is_unloading: false },
        user: {},
        place: {},
      };
      if (this.centrifuge) this.centrifuge.disconnect();
      this.exitPopup();
      this.$emit("hide");
      this.$store.commit("wallet/reset_card");
    },
    copyReceiptLink() {
      try {
        navigator.clipboard.writeText(
          `https://pay.urbanpass.world/receipt/${this.record.payment.receipt_id}`
        )
          .then(() => {
            this.isCopied = true;
          })
          .catch(() => {
            this.isCopyError = true;
          })
      } catch {
        this.isCopyError = true;
      }
    },
    handleBlurKioskCheckoutAmount() {
      const amount = this.kioskCheckoutUserAmount;
        const regexp = new RegExp('^[0-9\\s]*$');
        const regexpSpaces = new RegExp(/\s/);
        if (
          amount.length &&
          (!regexp.test(amount) || regexpSpaces.test(amount))
        ) {
          this.kioskCheckoutUserAmount = '';
        }
    },
    getKioskCheckoutCalculation(params) {
      this.$store.dispatch('history/getKioskCheckoutCalculation', params)
        .then(() => {
          this.kioskCheckoutUserAmount = this.kioskCheckoutAmount;
        });
    },
    confirmReprintReceipt() {
      this.$confirm({
        message: this.$t("confirm_reprint_receipt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("reprint"),
        },
        callback: (confirm) => {
          if (confirm) {
            const data = {
              parking_id: this.hrec.parking.parking_id,
              guest_type: this.hrec.parking.guest_type,
            };
            base({
              url: '/payments/receipts/print',
              data,
              method: 'POST',
            })
              .then((response) => {
                if (response.data.code === 100) {
                  EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Success!',
                    duration: 3000
                  })
                } else {
                  EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Error!',
                    duration: 5000
                  })
                }
              })
              .catch(() => {
                EventBus.$emit("notify", {
                  group: 'general',
                  title: 'Error!',
                  duration: 5000
                })
              });
          }
        },
      });
    },
    confirmSendToKiosk() {
      this.$confirm({
        message: this.$t("confirm_send_to_kiosk"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("yes"),
        },
        callback: (confirm) => {
          if (confirm) {
            const data = {
              parking_id: this.hrec.parking.parking_id,
              guest_type: this.hrec.parking.guest_type,
              forced_price: this.kioskCheckoutUserAmount !== this.kioskCheckoutAmount ? Number(this.kioskCheckoutUserAmount) * 100 : null,
            };
            base({
              url: '/payments/checkout/exit-kiosk',
              data,
              method: 'POST',
            })
              .then((response) => {
                if (response.data.code === 100) {
                  EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Success!',
                    duration: 3000
                  })
                } else {
                  EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Error!',
                    duration: 5000
                  })
                }
              })
              .catch(() => {
                EventBus.$emit("notify", {
                  group: 'general',
                  title: 'Error!',
                  duration: 5000
                })
              });
          }
        },
      });
    },
    plateFormatter(val) {
      this.hrec.car.plate_number = formatPlate(val);
      this.$forceUpdate();
    },

    getDuration(parking) {
      let duration = this.$moment.duration(parking.duration, "milliseconds");
      let months = duration._data.months ? duration._data.months + "m" : "";
      let days = duration._data.days ? duration._data.days + "d" : "";
      let hours = duration._data.hours ? duration._data.hours + "h" : "";
      let minutes = duration._data.minutes;

      if (duration._data.seconds && duration._data.minutes !== 59) {
        minutes = minutes + 1;
      }

      minutes = minutes + "m";

      return `${months} ${days} ${hours} ${minutes}`;
    },
    getCarBrands(value) {
      if (value !== "" && carBrandsDb[value] && value !== "N/A") {
        return carBrandsDb[value].map((brand) => value + " " + brand);
      } else {
        return Object.keys(carBrandsDb);
      }
    },

    priceChangeHandler(val) {
      this.hrec.parking.total_price = val * 100;
      this.hrec.price_rewrite = true;
    },

    paymentMethodHandler(value, type) {
      if (value) {
        this.hrec.parking.payment_method = 2;
        if (type === "cash") this.isCash = true;
        else this.isFree = true;
      } else {
        this.hrec.parking.payment_method = 0;
        this.isFree = false;
        this.isCash = false;
      }
    },
    timeChangeHandler(type, value) {
      if (!value) {
        this.dates[type].date = null;
        this.dates[type].time = {
          HH: undefined,
          mm: undefined,
          ss: undefined,
        };
        this.$forceUpdate();
        return;
      }
      let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      let dateString = value.replace(pattern, "$3-$2-$1");
      let date = this.$moment(new Date(dateString)).format("YYYY-MM-DD");
      this.dates[type].date = date;
      this.dates[type].time = {
        HH: value.slice(-5).slice(0, 2),
        mm: value.slice(-2),
        ss: "00",
      };
      this.$forceUpdate();
    },

    deleteRecord() {
      this.$confirm({
        message: this.$t("parking_delete_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("confirm"),
        },
        callback: (confirm) => {
          if (confirm) {
            let params = {
              parking_id: this.hrec.parking.parking_id,
              guest_type: this.hrec.parking.guest_type,
            };
            this.$store.dispatch("history/deleteRecord", {
              params,
              notify: true,
            });
            this.hide();
          }
        },
      });
    },

    saveRecordInfo() {
      this.$confirm({
        message: this.$t("save_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("save"),
        },
        callback: (confirm) => {
          if (confirm) {
            let data = {
              enter_time: this.$moment(this.parkingDates.enter_time).toISOString(),
              exit_time: this.parkingDates.exit_time
                ? this.$moment(this.parkingDates.exit_time).toISOString()
                : null,
              place_id: this.hrec.place.place_id || this.places[0].place_id,
              guest_type: this.hrec.parking.guest_type,
              plate: this.hrec.car.plate_number,
              car_type: this.hrec.car.car_type,
              car_brand: this.hrec.car.brand,
              color: this.hrec.car.color,
              parking_id: this.hrec.parking.parking_id,
              is_unloading: this.hrec.parking.is_unloading,
            };

            if (this.formType === 0) {
              this.$store.dispatch("history/addRecord", { data, type: 1 });
            } else {
              this.$store.dispatch("history/updateRecord", data);
            }

            if (this.hrec.parking.payment_method === 2) {
              let paymentData = {
                parking_id: this.hrec.parking.parking_id,
                guest_type: this.hrec.parking.guest_type,
                price: this.hrec.parking.total_price,
                price_rewrite: this.hrec.price_rewrite || false,
              };
              // console.log(paymentData);
              if (this.isBV) {
                paymentData = {
                  ...paymentData,
                  price: 0,
                  price_rewrite: true,
                };
              }
              this.$store.dispatch("history/setPayment", { data: paymentData });
            }

            this.hide();
          }
        },
      });
    },

    getParkingPic() {
      base({
        url: `/history/photo`,
        method: "GET",
        responseType: "blob",
        params: {
          parking_id: this.hrec.parking.parking_id,
        },
      }).then((response) => {
        var fileURL = URL.createObjectURL(new Blob([response.data]));
        this.hrec.photo = fileURL;
        this.$forceUpdate();
      });
    },
    openCheckout() {
      this.$store.dispatch("history/getRecordCalculation", {
        parking_id: this.hrec.parking.parking_id,
        guest_type: this.hrec.parking.guest_type,
      });
      this.popup = "checkout";
    },
    exitPopup(success) {
      this.popup = "";
      if (success) this.hide();
      return success;
    },
    getToken() {
      return new Promise((resolve, reject) => {
        base({
          url: "/auth/token/refresh-centrifugo",
          params: { token: this.refreshToken },
        })
          .then((res) => {
            if (!res.status === 200) {
              throw new Error(`Unexpected status code ${res.status}`);
            }
            let data = res.data.body;
            this.$store.commit("app/cs_auth_success", data);
            // console.log(data.access_token);
            resolve(data.access_token);
          })
          .catch((err) => {
            reject(err);
            this.$store.commit("app/logout");
            // location.reload();
          });
      });
    },
    openCSChannel() {
      this.popup = "octopus";
      console.log("connect centrifuge");
      if (this.centrifuge && this.centrifuge.state === "connecting") {
        console.log("aborted counter connection");
        return;
      }
      this.centrifuge = new Centrifuge(CENTR_HOST, {
        token: this.token,
        getToken: () => this.getToken(),
      });

      this.centrifuge.connect();
      this.centrifuge.on("connected", (data) => {
        console.log(`connected ${JSON.stringify(data)}`);
        this.connected = false;
      });
      this.centrifuge.on("connecting", (data) => {
        console.log(`connecting ${JSON.stringify(data)}`);
      });
      this.centrifuge.on("error", (err) => {
        console.log(`error ${JSON.stringify(err)}`);
        if (err.error.code === 3500 || err.error.code === 109) {
          // CODE 2 = TRANSPORT CLOSED
          // CODE 3500 = INVALID TOKEN
          // CODE 109 = EXPIRED TOKEN
          if (!this.connecting && this.$store.getters["app/authorized"]) {
            console.log("auto relogin");
            this.$store.dispatch("app/counterLogin").then(() => {
              this.openCSChannel();
            });
          }
        }
      });
      this.centrifuge.on("disconnected", (data) => {
        console.log(`disconnected ${JSON.stringify(data)}`);
      });
      this.centrifuge.on("publication", (data) => {
        let push = data.data;
        // console.log(push);
        this.handlePush(push);
      });
    },
    handlePush(push) {
      console.log(push);
      if (
        push.event === "parking_payment" &&
        push.content.parking_id === this.hrec.parking.parking_id
      ) {
        this.$store.commit("wallet/set_current_push", push.content);
        this.popup = "octopus";
      } else if (push.event === "last_transactions") {
        this.$store.commit("wallet/set_current_card", push.content);
        this.popup = "transactions";
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
</style>

<style lang="scss" scoped>
.v-text-field__details {
  display: none;
}
@media print, screen and (min-width: 576px) {
  .datepicker-menu {
    top: 3.2em !important;
  }
}

.datepicker-menu {
  background-color: #ffffff;
}

.record-tabs {
  width: 100% !important;
}

.send-to-kiosk-wrapper {
  margin: 30px 0;
}

.record-button {
  width: 100%;
  max-width: none !important;
}
</style>
