import { pwa, cyberport, base } from '@/axios-api'
import store from '@/store/index';
import EventBus from '../../../event-bus';
import i18n from '@/plugins/i18n';
import qs from 'qs'

export default {
    getTransactions({
        commit,
        dispatch
    }, params) {
        if (store.getters['app/isCyber']) dispatch("getTransactionsCyber", params)
        else if (store.getters['app/hasTenantsFlow']) dispatch("getTransactionsUS", params)
        else dispatch("getTransactionsDefault", params)
        return commit
    },
    getTransactionsUS({
        commit,
        dispatch
    }, params) {
        commit("set_loader", true)
        pwa({
                url: '/platform/payments',
                params,
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let transactions = response.data.body || [];
                    transactions = transactions.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                    transactions = transactions.map(i => {
                        i.transactions = i.transactions || []
                        return i
                    })
                    commit("get_transactions", transactions)
                }
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getTransactionsCyber({
        commit,
        dispatch
    }, params) {
        commit("set_loader", true)
        cyberport({
                url: '/platform/transactions',
                params,
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let transactions = response.data.body.transactions || [];
                    transactions = transactions.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                    transactions = transactions.map(i => {
                        i.transactions = i.transactions || []
                        return i
                    })
                    commit("get_transactions", transactions)
                }
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    refund({
        commit,
        dispatch
    }, data) {
        pwa({
                url: `/platform/payments/refund`,
                method: "POST",
                data
            })
            .then(response => {
                if (response.status == 200) {
                    dispatch('getTransactions')
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('transaction_refunded'),
                        duration: 3000
                    })
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
                EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Oops!',
                    text: "",
                    duration: 3000
                })
            });
    },


    getReport({
        commit,
    }, {
        params,
        route
    }) {
        commit("app/set_downloader", true, {
            root: true
        })
        let name = "_transactions"
        cyberport({
                url: route,
                params,
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                if (response.status === 200) {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'report' + name + '.xlsx');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    commit("app/set_downloader", false, {
                        root: true
                    })
                }
            })
            .catch((err) => {
                console.log(err.response);
            });
    },

    getTransactionsDefault({
        commit,
    }, params) {
        commit("set_loader", true)
        base({
                url: '/transactions',
                params,
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let transactions = response.data.body.transactions || [];
                    transactions = transactions.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
                    transactions = transactions.map(i => {
                        i.transactions = i.transactions || []
                        return i
                    })
                    commit("get_transactions", transactions)
                }
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
            });
    },

    cancelPayment({
        commit,
    }, data) {
        base({
                url: '/customer-service/payment/cancel',
                method: 'POST',
                data
            })
            .then((response) => {
                if (response.status === 200) {
                    return { response, commit }
                }
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
            });
    },
    getTransactionLog({ commit }) {
        base({
            url: '/customer-service/transaction-log',
            method: 'POST'
        }).then((res) => {
            return { res, commit }
        })
    }
}