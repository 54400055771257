<template>
  <div class="campaign-form pb-0">
    <div class="mb-4 d-flex justify-space-between align-center">
      <h3 class="main-title" :style="{ maxWidth: '90%' }">
        {{ car.plate_number || $t("add") }}
      </h3>
      <!-- <v-btn text class=" icon-close pa-0" min-width="24px" @click="hide" /> -->
    </div>

    <v-form ref="form" autocomplete="off"
      ><v-text-field
        class="modal-input"
        :value="car.plate_number"
        :label="$t('car_plate')"
        @input="plateFormatter"
        :rules="[() => !!car.plate_number || $t('vehicle_number') + $t('required')]"
      />
      <v-select
        attach
        item-value="id"
        item-text="desc"
        v-model="car.type"
        class="modal-input"
        :items="carTypes"
        :label="$t('car_type')"
      />
      <v-autocomplete
        :items="brands"
        attach
        class="modal-input"
        v-model="car.manufacturer"
        :label="$t('car_brand')"
      />
      <v-select
        attach
        item-value="id"
        item-text="desc"
        v-model="car.engine_type"
        class="modal-input"
        :items="engineTypes"
        :label="$t('engine')"
      />
    </v-form>

    <div class="modal-footer d-flex align-center px-0 py-4">
      <v-btn class="main-btn mb-4 mr-5 py-2 px-4" @click="save">{{ $t("save") }}</v-btn>
      <v-btn text @click="back" class="flat-btn modal-footer-btn mb-4">{{
        $t("cancel")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import carBrandsDb from "@/assets/cars/carModels.json";
import { formatPlate } from "../../common/helpers/helpers";
export default {
  name: "CarForm",
  props: {
    selected: {
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      car: {},
    };
  },
  computed: {
    current() {
      return this.$store.state.users.current;
    },
    engineTypes() {
      return [...this.$t("engine_types")].map((i, index) => {
        return {
          id: index + 1,
          desc: i,
        };
      });
    },
    carTypes() {
      return [...this.$t("car_types")].map((i, index) => {
        return {
          id: index,
          desc: i,
        };
      });
    },
    brands() {
      let result = [];

      for (const [key, value] of Object.entries(carBrandsDb)) {
        value.map((i) => {
          result.push(`${key} ${i}`);
        });
      }
      return result;
    },
  },
  watch: {},
  mounted() {
    this.car = { ...this.selected };
  },
  methods: {    
    plateFormatter(val) {      
      this.car.plate_number = formatPlate(val);
      this.$forceUpdate();
    },
    back() {
      this.$emit("hide");
    },
    save() {
      let submission = {
        user_id: this.current.id,
        data: { vehicle: { ...this.car } },
      };
      if (this.car.id) {
        this.$store.dispatch("users/updateCar", submission);
      } else {
        this.$store.dispatch("users/addCar", submission);
      }
      this.back();
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_modal.scss";
@import "@/scss/_guestsTable.scss";
</style>
