<template>
  <div>
    <v-skeleton-loader v-if="loading" type="table" />
    <v-data-table
      v-else
      :headers="headers"
      :items-per-page="-1"
      class="table"
      :items="data"
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
      :no-data-text="$t('no_data')"
      :expanded="expanded"
      @click:row="expand"
      :singleExpand="true"
    >
      <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div
          :key="i.text"
          class="d-flex align-center"
          :class="
            header.value === 'photos' ||
            header.value === 'zone' ||
            header.value === 'color' ||
            header.value === 'brand' ||
            header.value === 'duration' ||
            header.value === 'price' ||
            header.value === 'is_unloading'
              ? 'justify-center'
              : ''
          "
        >
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(headers.indexOf(header), 0)"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(headers.indexOf(header), 1)"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.username`]="{ item }">
        <div class="d-flex justify-start table-guest align-center">
          <div class="table-user">
            <img
              width="36px"
              height="36px"
              class="table-ava-icon table-img"
              alt="ava"
              :class="item.user.avatar_url ? '' : ' green-bg '"
              :src="
                item.user.avatar_url
                  ? item.user.avatar_url
                  : 'https://storage.googleapis.com/parkingbnb-html-images/avatar_user.svg'
              "
            />
          </div>
          <div class="table-user-name">
            <h4>{{ item.user.first_name }} {{ item.user.last_name }}</h4>
            <span>{{ item.car.plate_number }}</span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.spot_id`]="{ item }">
        <div class="table-user-name">
          <h4>{{ item.parking.spot_id }}</h4>
        </div>
      </template>

      <template v-slot:[`item.car_plate`]="{ item }">
        <div class="table-user-name">
          <h4>{{ item.car.plate_number }}</h4>
        </div>
      </template>

      <template v-slot:[`item.car_type`]="{ item }">
        <div class="table-car-type">
          {{ $t(`car_types[${item.car.car_type}]`) }}
        </div>
      </template>

      <template v-slot:[`item.color`]="{ item }">
        <div class="table-car-type text-center">
          {{ item.car.color }}
        </div>
      </template>

      <template v-slot:[`item.brand`]="{ item }">
        <div class="table-date text-center">
          {{ item.car.brand }}
        </div>
      </template>

<!--      <template v-slot:[`item.photos`]="{ item }">-->
<!--        <div  class="text-center">-->
<!--          <v-btn @click.stop="photosData = item" text>-->
<!--            <div class="camera-icon" />-->
<!--          </v-btn>-->
<!--        </div>-->
<!--      </template>-->

      <template v-slot:[`item.parking`]="{ item }">
        <div class="table-parking d-flex justify-start align-center">
          <div class="table-parking-img">
            <img
              class="parking-img table-img"
              :src="
                places.filter((p) => p.place_id === item.place.place_id)[0]
                  ? places.filter((p) => p.place_id === item.place.place_id)[0].avatar ||
                    'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg'
                  : 'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg'
              "
              :alt="item.place.name"
            />
          </div>
          <span
            style="
              max-width: 115px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            "
          >{{ item.place.name }}</span
          >
        </div>
      </template>

      <template v-slot:[`item.zone_status`]="{ item }">
        <div class="table-zone">
          <span
            v-if="item.zonal"
            alt="Status"
            :style="{ width: '24px', height: '24px' }"
            class="zone-status-icon"
            :class="item.zonal.some((r) => r.status === 2) ? 'violated' : 'complied'"
          />
        </div>
      </template>
      <template v-slot:[`item.zone_name`]="{ item }">
        <div class="d-flex justify-center align-center" :style="{ maxWidth: '120px' }">
          {{ item.zonal[item.zonal.length - 1].name }}
        </div>
      </template>

      <template v-slot:[`item.enter_time`]="{ item }">
        <div class="table-date">
          {{ getFormattedDate(item.parking.enter_time) }}
        </div>
      </template>

      <template v-slot:[`item.exit_time`]="{ item }">
        <div class="table-date">
          {{ getFormattedDate(item.parking.exit_time) }}
        </div>
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        <div class="table-duration text-center">
          {{ getDuration(item.parking.duration) }}
        </div>
      </template>

      <template v-slot:[`item.overparking`]="{ item }">
        <div class="table-duration text-center">
          {{ getDuration(item.parking.overtime) }}
        </div>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <div class="table-price text-center">
          <span
            :class="{
              paid: item.parking.guest_type === 9 && item.parking.total_price === 0,
              unpaid: item.parking.guest_type === 9 && item.parking.total_price > 0,
            }"
          >
            {{ priceNames.filter((i) => i.id === item.parking.guest_type)[0].desc }}
          </span>
        </div>
      </template>

      <template v-slot:[`item.total_price`]="{ item }">
        <div class="table-total">
          {{
            item.parking.total_price
              ? item.parking.currency + item.parking.total_price / 100
              : ""
          }}
        </div>
      </template>

      <template v-slot:[`item.is_unloading`]="{ item }">
        <div class="table-price text-center">
          {{ item.parking.is_unloading ? $t("yes") : '' }}
        </div>
      </template>

      <template v-slot:[`item.payment_method`]="{ item }">
        <div class="table-price text-center">
          {{ getPaymentMethodName(item.payment) }}
        </div>
      </template>

      <template v-slot:[`item.payment_status`]="{ item }">
        <div class="table-status" v-if="item.parking.payment_status">
          <template
            v-if="hasKiosk && csPaymentMethods.includes(item.parking.payment_method)"
          >
            <template v-if="item.parking.total_price"
            >{{ $t("parking_cash") }} |
            </template>
            <template v-else>{{ $t("parking_free") }} | </template>
          </template>
          <template>
            {{ $t(`payment_options[${item.parking.payment_status - 1}]`) }}</template
          >
        </div>
      </template>

      <template v-slot:[`item.spot_status`]="{ item }">
        <div class="table-status">
          <span
            :style="{ width: '24px', height: '24px' }"
            class="zone-status-icon"
            :class="item.violation ? 'violated' : 'complied'"
          />
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="table-status">
          <template v-if="item.parking.completed">
            <template
              v-if="
                item.parking.total_price &&
                (item.parking.guest_type === 5 || item.parking.guest_type === 10)
              "
            >
              <img
                v-if="item.parking.paid"
                src="@/assets/images/completed-green.webp"
                :style="{ width: '24px', height: '24px' }" />
              <img
                v-else
                src="@/assets/images/completed-red.webp"
                :style="{ width: '24px', height: '24px' }"
              /></template>
            <template v-else>
              <img
                src="@/assets/images/completed-grey.webp"
                :style="{ width: '24px', height: '24px' }"
              /></template>
          </template>
          <template v-else>
            <img
              src="@/assets/images/current.webp"
              :style="{ width: '24px', height: '24px' }"
            />
          </template>
        </div>
      </template>

      <template v-slot:[`item.floor.date_start`]="{ item }">
        <div class="table-date">
          {{ getFormattedDate(item?.floor?.date_start) }}
        </div>
      </template>
      <template v-slot:[`item.floor.date_end`]="{ item }">
        <div class="table-date">
          {{ getFormattedDate(item?.floor?.date_end) }}
        </div>
      </template>
      <template v-slot:[`item.parking_space.date_start`]="{ item }">
        <div class="table-date">
          {{ getFormattedDate(item?.parking_space?.date_start) }}
        </div>
      </template>
      <template v-slot:[`item.parking_space.date_end`]="{ item }">
        <div class="table-date">
          {{ getFormattedDate(item?.parking_space?.date_end) }}
        </div>
      </template>

      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0" v-if="item.expanded === true">
          <v-data-table
            :colspan="headers.length"
            :headers="expandedHeaders"
            :items-per-page="-1"
            :items="item.zonal"
            class="table expanded-table"
            hide-default-footer
            mobile-breakpoint="0"
            disable-sort
            disable-pagination
          >
            <template v-slot:[`item.username`]="{ item }">
              <div
                class="d-flex justify-start table-guest align-center px-0 mx-0"
                :style="{ width: 'username' ? '140px' : '' }"
              >
                {{ item.noText }}
              </div>
            </template>

            <template v-slot:[`item.car_type`]="{ item }">
              <div>
                {{ item.noText }}
              </div>
            </template>

            <template v-slot:[`item.color`]="{ item }">
              <div>
                {{ item.noText }}
              </div>
            </template>

            <template v-slot:[`item.brand`]="{ item }">
              <div
                class="table-parking d-flex justify-start align-center"
                :style="{ width: '160px' }"
              >
                {{ item.noText }}
              </div>
            </template>

            <template v-slot:[`item.parking`]="{ item }">
              <div
                class="table-parking d-flex justify-start align-center"
                :style="{ width: '160px' }"
              >
                {{ item.noText }}
              </div>
            </template>

            <template v-slot:[`item.zone`]="{ item }">
              <div
                class="d-flex justify-center align-center"
                :style="{ maxWidth: '120px' }"
                :class="item.status === 2 ? 'violated-row' : 'complied-row'"
              >
                {{ item.name }}
              </div>
            </template>
            <template v-slot:[`item.enter_time`]="{ item }">
              <div class="table-date">
                {{ getFormattedDate(item.start_time) }}
              </div>
            </template>

            <template v-slot:[`item.exit_time`]="{ item }">
              <div class="table-date">
                {{ getFormattedDate(item.end_time) }}
              </div>
            </template>

            <template v-slot:[`item.duration`]="{ item }">
              <div class="table-duration">
                {{ getDuration(item.duration) }}
              </div>
            </template>

            <template v-slot:[`item.penalty`]="{ item }">
              <div class="table-price" v-if="$store.getters['app/hasZonePenalty']">
                {{ item.penalty }}
              </div>
            </template>

            <template v-slot:[`item.total_price`]="{ item }">
              <div class="table-total">
                {{ item.noText }}
              </div>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <div class="table-status">
                {{ item.noText }}
              </div>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <div v-if="busy && !loading">
      <v-skeleton-loader v-for="i in 3" :key="i" type="table-row"></v-skeleton-loader>
    </div>
    <component
      :is="isSelectedWithPhoto ? ParkingRecordPopupWithPhoto : ParkingRecordPopup"
      :record="selected"
      ref="popup"
      @resetSelected="resetRecordForm"
      view="parkingHistory"
    />
  </div>
</template>

<script>
export default {
  name: "TableRecord",
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    searchValue: {
      type: String,
      default: "",
    },
    isShort: {
      type: Boolean,
    },
    openForm: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      ParkingRecordPopupWithPhoto: () => import("@/components/ParkingHistory/ParkingRecordPopupWithPhoto.vue"),
      ParkingRecordPopup: () => import("@/components/ParkingHistory/ParkingRecordPopup.vue"),
      data: [],
      busy: false,
      disableLoader: false,
      counter: {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
      },
      monthCounter: {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
      },
      itemsPerPage: 25,
      params: {
        from: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(),
        to: new Date().toISOString(),
        limit: 25,
        sort_by: 3,
        sort_direction: 0,
      },
      expanded: [],
      selected: { parking: {}, car: {}, user: {}, place: {} },
      filters: { dates: [] },
    };
  },
  components: {
    ParkingHistoryPhotosPopup: () => import("@/components/ParkingHistory/ParkingHistoryPhotosPopup"),
  },
  computed: {
    isSelectedWithPhoto() {
      if (Object.keys(this.selected.parking).length) {
        const entrancePhotoUrl = this.selected.parking.entrance.photo_url;
        const exitPhotoUrl = this.selected.parking.exit.photo_url;
        return entrancePhotoUrl || exitPhotoUrl;
      } else {
        return false;
      }
    },
    locale() {
      return this.$store.getters["app/locale"];
    },
    hasSimpleHistory() {
      return this.$store.getters["app/hasSimpleHistory"];
    },
    hasKiosk() {
      return this.$store.getters["app/hasKiosk"];
    },
    priceNames() {
      return this.$t("price_types");
    },
    headers() {
      if (this.$store.getters["app/isSpot"]) {
        return this.spotHeaders;
      }
      return this.defaultHeaders;
    },
    csPaymentMethods() {
      return this.$t("payment_methods_cs").map((i) => i.id);
    },
    spotHeaders() {
      let initHeaders = [
        {
          text: this.$t("spot"),
          value: "spot_id",
          class: "table-guest table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("car"),
          value: "car_plate",
          class: "table-guest table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("car_type"),
          value: "car_type",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: this.$t("car_color"),
          value: "color",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: this.$t("car_brand"),
          value: "brand",
          sortable: false,
          class: "table-date table-header",
        },
        {
          text: this.$t("date_in"),
          value: "enter_time",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("date_out"),
          value: "exit_time",
          class: "table-date table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("duration"),
          value: "duration",
          class: "table-duration table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("status"),
          value: "spot_status",
          sortable: false,
          class: "table-status table-header",
        },
      ];
      return initHeaders;
    },
    defaultHeaders() {
      let initHeaders = [
        {
          text: this.$t("guest"),
          value: "username",
          class: "table-guest table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("car_type"),
          value: "car_type",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: this.$t("car_color"),
          value: "color",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: this.$t("car_brand"),
          value: "brand",
          sortable: false,
          class: "table-date table-header",
        },
        // {
        //   text: 'Photos',
        //   value: "photos",
        //   class: "table-guest table-header",
        //   sortable: false,
        // },
        {
          text: this.$t("date_in"),
          value: "enter_time",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("date_out"),
          value: "exit_time",
          class: "table-date table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("duration"),
          value: "duration",
          class: "table-duration table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("price_type"),
          value: "price",
          sortable: false,
          class: "table-price table-header",
        },
        {
          text: this.$t("payment_method"),
          value: "payment_method",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "table-status table-header",
        },
      ];
      if (this.$store.getters["app/isCompany"]) {
        initHeaders.splice(4, 0, {
          text: this.$t("parking"),
          value: "parking",
          sortable: false,
          class: "table-parking table-header",
        });
      }
      if (this.$store.getters["app/hasZones"]) {
        initHeaders.splice(4, 0, {
          text: this.$t("zone_status"),
          value: this.$store.getters["app/hasZonePenalty"] ? "zone_status" : "zone_name",
          sortable: false,
          class: "table-header table-zone px-0",
        });
      }
      if (this.$store.getters["app/hasOverParking"]) {
        initHeaders.splice(8, 0, {
          text: this.$t("over_parking"),
          value: "over_parking",
          isDesc: undefined,
          class: "table-duration table-header",
        });
      }
      if (!this.$store.getters["app/hasSimpleHistory"]) {
        initHeaders.splice(9, 0, {
          text: this.$t("total"),
          value: "total_price",
          class: "table-total table-header",
          isDesc: undefined,
        });
      }
      if (this.$store.getters["app/isCyber"] || this.$store.getters["app/isItt"]) {
        initHeaders.splice(10, 0, {
          text: this.$t("payment_status"),
          value: "payment_status",
          sortable: false,
          class: "table-header table-zone px-0",
        });
      }
      if (this.$store.getters["app/isGoldin"]) {
        initHeaders.splice(8, 0, {
          text: this.$t("unloading"),
          value: "is_unloading",
          sortable: false,
          class: "table-header text-center table-price",
        });
      }
      if (this.$store.getters["app/isUtaggoPOC1"]) {
        initHeaders.push(
          {
            text: this.$t("floor"),
            value: "floor.name",
            sortable: false,
            class: "table-header text-center table-price",
          },
          {
            text: this.$t("floor_in_time"),
            value: "floor.date_start",
            sortable: false,
            class: "table-header text-center table-price",
          },
          {
            text: this.$t("floor_exit_time"),
            value: "floor.date_end",
            sortable: false,
            class: "table-header text-center table-price",
          },
          {
            text: this.$t("space"),
            value: "parking_space.space_name",
            sortable: false,
            class: "table-header text-center table-price",
          },
          {
            text: this.$t("space_in_time"),
            value: "parking_space.date_start",
            sortable: false,
            class: "table-header text-center table-price",
          },
          {
            text: this.$t("space_exit_time"),
            value: "parking_space.date_end",
            sortable: false,
            class: "table-header text-center table-price",
          }
        );
      }
      if (this.$store.getters["app/isUtaggoPOC2"]) {
        initHeaders.push(
          {
            text: this.$t("space"),
            value: "parking_space.space_name",
            sortable: false,
            class: "table-header text-center table-price",
          }
        );
      }
      return initHeaders;
    },
    expandedHeaders() {
      return [
        {
          text: "",
          value: "username",
          class: "table-guest table-header mx-0 px-0 maxw-guest",
          isDesc: undefined,
        },
        {
          text: "",
          value: "car_type",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: "",
          value: "parking",
          sortable: false,
          class: "maxw-guest table-header",
        },

        {
          text: this.$t("zone_actual"),
          value: "zone",
          sortable: false,
          class: "table-header table-zone",
        },
        {
          text: this.$t("date_in"),
          value: "enter_time",
          class: "table-date table-header",
          isDesc: false,
        },
        {
          text: this.$t("date_out"),
          value: "exit_time",
          class: "table-date table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("duration"),
          value: "duration",
          class: "table-duration table-header",
          isDesc: undefined,
        },
        {
          text: this.$store.getters["app/hasZonePenalty"] ? this.$t("penalty") : "",
          value: "penalty",
          sortable: false,
          class: "table-date table-header text-center",
        },
        {
          text: "",
          value: "status",
          sortable: false,
          class: "table-status table-header",
        },
      ];
    },
    history() {
      return this.$store.state.history.parking_history;
    },
    loading() {
      return this.history.loading;
    },
    records() {
      let history = this.history;
      switch (this.tab) {
        case 0:
          return history.current;
        case 1:
          return history.completed;
        case 2:
          return history.unpaid;
        case 3:
          return this.isShort ? [...history.all].slice(0, 6) : history.all;
        default:
          return [];
      }
    },
    status() {
      return this.$store.state.history.status;
    },
    places() {
      return this.$store.state.app.places;
    },
    hasHistoryEditRight() {
      return this.$store.getters["app/hasHistoryEditRight"];
    },
  },
  watch: {
    records() {
      this.loadItems();
    },
    data(newValue) {
      this.busy = false;
      if (!this.isShort && newValue.length < this.itemsPerPage) {
        this.monthCounter[this.tab] = this.monthCounter[this.tab] + 3;
        if (this.monthCounter[this.tab] >= 18) {
          this.disableLoader = true;
          return;
        } else {
          // this.monthCounter[this.tab] = this.monthCounter[this.tab] + 3;
          // this.params.limit = this.params.limit - this.itemsPerPage;
          this.loadMore();
          return;
        }
      }
      if (this.$store.getters["app/hasZones"]) {
        newValue.map((i) => {
          let isExpanded = this.expanded.some(
            (e) => e.parking.parking_id === i.parking.parking_id
          );
          i.expanded = isExpanded;
        });
      }
    },
    status(newValue) {
      if (newValue) {
        this.updateParams({
          ...this.params,
          from: this.newFrom(),
          to: this.newTo(),
        });
      }
    },
    tab() {
      if (this.params.search) {
        this.updateParams({
          ...this.params,
          from: this.newFrom(),
          to: this.newTo(),
        });
      }
    },
  },
  mounted() {
    this.loadItems();
    this.scroll();
  },
  methods: {
    getPaymentMethodName(payment) {
      if (payment.is_paid && payment.amount) {
        if (payment.location === 'online') {
          return this.$t('payment_location.online');
        }
        const location = this.$t(`payment_location.${payment.location}`);
        const method = this.$t('payment_methods_cs').find((item) => item.key === payment.method)?.desc;
        return `${method} - ${location}`
      }
      return '';
    },
    newFrom() {
      let filters = this.filters;
      let newDate = new Date(
        new Date().setMonth(new Date().getMonth() - this.monthCounter[this.tab])
      ).toISOString();
      // let newDate = null;
      return filters.dates[0]
        ? this.$moment.utc(filters.dates[0]).toISOString()
        : newDate;
    },
    newTo() {
      let filters = this.filters;
      let newDate = this.$moment().toISOString();
      // let newDate = null;
      return filters.dates[1]
        ? this.$moment.utc(filters.dates[1]).toISOString()
        : newDate;
    },

    resetRecordForm() {
      this.selected = { parking: {}, car: {}, user: {}, place: {} };
    },

    loadItems() {
      if (!this.records) {
        return;
      }
      if (this.records.length <= this.itemsPerPage) {
        this.data = [...this.records];
        return;
      }
      if (this.counter[this.tab] < Math.round(this.records.length / this.itemsPerPage)) {
        this.data = this.records.slice(0, this.counter[this.tab] * this.itemsPerPage);
      } else {
        this.data = [...this.records];
      }
    },
    updateParams(newValue) {
      this.disableLoader = false;
      let tab = this.tab < 3 ? this.tab + 1 : null;
      let params = { tab };
      for (const [key, value] of Object.entries({ ...newValue })) {
        if (value || value === 0) {
          params[key] = value;
        }
      }
      this.updateData(params);
    },
    updateData(params) {
      // let copyForChecking = JSON.parse(JSON.stringify(params));
      // copyForChecking = {
      //   ...copyForChecking,
      //   from: this.$moment(copyForChecking.from).format("DD.MM.YYYY HH:mm:ss"),
      //   to: this.$moment(copyForChecking.to).format("DD.MM.YYYY HH:mm:ss"),
      // };
      // console.log("update data " + JSON.stringify(copyForChecking));

      this.$store.dispatch("history/getHistory", { ...params });
    },
    expand(value) {
      if (this.hasHistoryEditRight) {
        this.selected = value;
        this.$refs.popup.show();
      }
      if (value.zonal && this.$store.getters["app/hasZones"]) {
        let index = -1;

        for (let i = 0; i < this.expanded.length; i++) {
          if (this.expanded[i].parking.parking_id === value.parking.parking_id) {
            index = i;
          }
        }

        if (index === -1) {
          this.data[this.data.indexOf(value)].expanded = true;
          this.expanded.push({ ...value });
        } else {
          this.data[this.data.indexOf(value)].expanded = false;
          this.expanded.splice(index, 1);
        }
      }
    },
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.ceil(document.documentElement.scrollTop) + window.innerHeight >=
          document.documentElement.offsetHeight;
        if (
          bottomOfWindow &&
          !this.loading &&
          !this.busy &&
          !this.openForm &&
          !this.isShort &&
          this.$route.path === "/platform/parking-history"
        ) {
          this.loadMore();
        }
      };
    },

    resetCounter() {
      let freshCounter;
      freshCounter = {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
      };
      this.counter = { ...freshCounter };
      this.monthCounter = { ...freshCounter };
    },

    filter(filters) {
      if (filters.reset) this.resetCounter();
      this.filters = filters;
      this.params = {
        ...this.params,
        from: this.newFrom(),
        to: this.newTo(),
        guest_type: filters.pricing,
        car_type: filters.car_type,
        place_id: filters.place_id,
      };

      this.updateParams({ ...this.params });
    },

    search(value) {
      if (!value) this.resetCounter();

      this.params = {
        ...this.params,
        guest_name: value,
        from: this.newFrom(),
        to: this.newTo(),
        search: true,
      };

      this.updateParams({ ...this.params });
    },

    loadMore() {
      this.counter[this.tab]++;
      if (this.tab === 0) {
        if (
          this.counter[this.tab] >= Math.round(this.records.length / this.itemsPerPage)
        ) {
          this.data = [...this.records];
          return;
        }
        this.data = this.records.slice(0, this.counter[this.tab] * this.itemsPerPage);
        return;
      }
      if (!this.disableLoader) {
        this.busy = true;
      }
      this.params = {
        ...this.params,
        from: this.newFrom(),
        to: this.newTo(),
        limit: this.counter[this.tab] * this.itemsPerPage,
      };
      // console.log(
      //   `new limit ${this.params.limit} & new from ${this.$moment(this.newFrom()).format(
      //     "DD.MM.YY"
      //   )}`
      // );
      this.updateParams({ ...this.params, incrementing: true });
    },

    getFormattedDate(date) {
      return date ? this.$moment(date).format("D.MM.YYYY HH:mm") : "";
    },

    getDuration(milliseconds) {
      let duration = this.$moment.duration(milliseconds, "milliseconds");
      let months = duration._data.months
        ? duration._data.months + this.$t("date_shorts.months")
        : "";
      let days = duration._data.days
        ? duration._data.days + this.$t("date_shorts.days")
        : "";
      let hours = duration._data.hours
        ? duration._data.hours + this.$t("date_shorts.hours")
        : "";
      let minutes = duration._data.minutes;

      if (duration._data.seconds && duration._data.minutes !== 59) {
        minutes = minutes + 1;
      }

      minutes = minutes + this.$t("date_shorts.minutes");

      return `${months} ${days} ${hours} ${minutes}`;
    },

    customSort(index, sort_direction) {
      this.headers.map((h) => {
        if (this.headers.indexOf(h) !== index) {
          h.isDesc = undefined;
        }
      });
      this.headers[index].isDesc = !!sort_direction;
      switch (this.headers[index].value) {
        case "username":
          this.params = { ...this.params, sort_by: 1, sort_direction };
          break;
        case "enter_time":
          this.params = {
            ...this.params,
            sort_by: 3,
            sort_direction: Number(!sort_direction),
          };
          this.headers[index].isDesc = !Number(!sort_direction);
          break;
        case "exit_time":
          this.params = {
            ...this.params,
            sort_by: 4,
            sort_direction: Number(!sort_direction),
          };
          this.headers[index].isDesc = !Number(!sort_direction);
          break;
        case "duration":
          this.params = { ...this.params, sort_by: 5, sort_direction };
          break;
        case "total_price":
          this.params = { ...this.params, sort_by: 6, sort_direction };
          break;
        default:
          return;
      }
      this.params = { ...this.params, from: this.newFrom(), to: this.newTo() };
      this.updateParams({ ...this.params });
    },
  },
};
</script>

<style lang="scss" scoped>
//.camera-button {
//  background-color: transparent;
//  border: none;
//}
//.camera-icon {
//  width: 30px;
//  height: 30px;
//  mask-image: url("~@/assets/images/camera.svg");
//  mask-position: center;
//  mask-size: cover;
//  mask-repeat: no-repeat;
//  background-color: $green;
//}
</style>
