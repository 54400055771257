<template>
  <v-dialog :value="value" @input="$emit('input', $event)" max-width="800">
    <v-card>
      <v-card-title class="text-h5">
        Photos
      </v-card-title>

      <v-card-text>
        {{ value }}
      </v-card-text>

      <v-card-actions class="d-flex flex-column">
        <v-spacer></v-spacer>

        <v-btn
          text
          class="main-btn px-5 mx-auto my-3 pa-2"
          @click="$emit('input', false)"
        >
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ParkingHistoryPhotosPopup",
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isShow: true,
    };
  },
}
</script>

<style scoped lang="scss"></style>
