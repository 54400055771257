<template>
  <div class="tabs-content parking-history">
    <h3 class="main-title">{{ $t("parking_payment") }}</h3>
    <div class="my-6 d-flex flex-wrap flex-lg-nowrap justify-center align-center">
      <v-tabs v-model="tab" class="mx-0 mr-6">
        <v-tab @click.stop v-for="(item, index) in items" :key="index">{{ item }}</v-tab>
      </v-tabs>
      <div class="d-flex justify-center flex-wrap flex-lg-nowrap" style="flex-grow: 1">
        <v-text-field
          v-model="search"
          autocomplete="off"
          @click.stop
          class="parking-search mb-4 mb-sm-0"
          solo
          :placeholder="$t('search_core')"
        ></v-text-field>
        <CoreFilter
          ref="coreFilter"
          @filterSet="updFilters"
          @hide="isOpenFilterForm = false"
          @show="isOpenFilterForm = true"
          :hidden="{
            campaign: true,
            created_by: true,
            price_range: true,
            zone: true,
            status: true,
            wallet: true,
          }"
        />
        <ReportDownloadForm
          @hideEmit="isOpenReportForm = false"
          @showEmit="isOpenReportForm = true"
          ref="modal"
          view="parking_payment"
        />
        <!-- refresh btn -->
        <v-btn text class="mr-auto dwl-btn" @click.stop="refresh">
          <img
            :style="{ height: '24px', width: '24px' }"
            src="@/assets/images/refresh.svg"
            alt="refresh"
          />
        </v-btn>
        <v-btn class="main-btn px-4 mr-2" @click="openTopupForm"
          >{{ $t("top_up") }}
        </v-btn>
        <v-btn class="main-btn px-4" @click="openOctopusTransactions"
          >{{ $t("enquire") }}
        </v-btn>
      </div>
    </div>
    <PaymentHistoryTable
      :isShort="false"
      class="mb-5"
      :searchValue="search"
      :tab="tab"
      ref="table"
      :openForm="openForm"
    />
    <TopupForm :show="isOpenTopupForm" @hide="isOpenTopupForm = false" />
    <OctopusTransactions
      v-show="popupOctopus === 'transactions'"
      @hide="hideTransactions"
    />
    <OctopusFlow v-show="popupOctopus === 'flow'" @hide="hideTransactions" />
  </div>
</template>

<script>
import { base } from "@/axios-api";
import { Centrifuge } from "centrifuge";
const CENTR_HOST = "wss://api.parkingbnb.world/tv/centrifugo/connection/websocket";
export default {
  name: "ParkingPayment",
  components: {
    PaymentHistoryTable: () => import("@/components/ParkingHistory/PaymentHistoryTable"),
    TopupForm: () => import("@/components/Wallet/TopupForm"),
    OctopusTransactions: () => import("@/components/Wallet/OctopusTransactions"),
    OctopusFlow: () => import("@/components/Wallet/OctopusFlow"),
    ReportDownloadForm: () => import("@/components/core/ReportDownloadForm"),
    CoreFilter: () => import("@/components/core/Filter"),
  },
  data() {
    return {
      tab: 3,
      isOpenReportForm: false,
      isOpenFilterForm: false,
      isOpenRecordForm: false,
      isOpenTopupForm: false,
      popupOctopus: "",
      debouncedInput: "",
      timeout: null,
      updateTimer: null,
      centrifuge: null,
    };
  },
  computed: {
    openForm() {
      return (
        this.isOpenReportForm ||
        this.isOpenFilterForm ||
        this.isOpenRecordForm ||
        this.isOpenTopupForm ||
        this.isOpenTransactions ||
        this.isOPenFlow
      );
    },
    items() {
      return this.$t("payment_options");
    },
    loading() {
      return this.$store.state.history.parking_history.loading;
    },
    records() {
      return this.$store.state.history.parking_history.current;
    },
    token() {
      return this.$store.state.app.cs_token;
    },
    refreshToken() {
      return this.$store.state.app.cs_refresh_token;
    },
    search: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedInput = val;
          this.$refs.table.search(this.debouncedInput);
        }, 1000);
      },
    },
  },
  methods: {
    resetRecordForm() {
      this.isOpenRecordForm = false;
      this.defaultRecord = {
        car: {},
        parking: { guest_type: undefined },
        user: {},
        place: {},
      };
    },
    updFilters(filters) {
      this.$refs.table.filter(filters);
    },
    updateTable() {
      this.$refs.table.updateParams({
        ...this.$refs.table.params,
        from: this.$refs.table.newFrom(),
        to: this.$refs.table.newTo(),
      });
    },
    refresh() {
      this.updateTable();
    },
    openAddForm() {
      this.$refs.recordForm.show();
      this.isOpenRecordForm = true;
    },
    openTopupForm() {
      this.isOpenTopupForm = true;
    },
    openOctopusTransactions() {
      this.popupOctopus = "flow";
      this.$store.dispatch("wallet/getTransactionLog");
      this.openCSChannel();
    },
    hideTransactions() {
      if (this.centrifuge) this.centrifuge.disconnect();
      this.popupOctopus = "";
    },
    getToken() {
      return new Promise((resolve, reject) => {
        base({
          url: "/auth/token/refresh-centrifugo",
          params: { token: this.refreshToken },
        })
          .then((res) => {
            if (!res.status === 200) {
              throw new Error(`Unexpected status code ${res.status}`);
            }
            let data = res.data.body;
            this.$store.commit("app/cs_auth_success", data);
            // console.log(data.access_token);
            resolve(data.access_token);
          })
          .catch((err) => {
            reject(err);
            this.$store.commit("app/logout");
            // location.reload();
          });
      });
    },

    openCSChannel() {
      console.log("connect centrifuge");
      if (this.centrifuge && this.centrifuge.state === "connecting") {
        console.log("aborted counter connection");
        return;
      }
      this.centrifuge = new Centrifuge(CENTR_HOST, {
        token: this.token,
        getToken: () => this.getToken(),
      });

      this.centrifuge.connect();
      this.centrifuge.on("connected", (data) => {
        console.log(`connected ${JSON.stringify(data)}`);
        this.connected = false;
      });
      this.centrifuge.on("connecting", (data) => {
        console.log(`connecting ${JSON.stringify(data)}`);
      });
      this.centrifuge.on("error", (err) => {
        console.log(`error ${JSON.stringify(err)}`);
        if (err.error.code === 3500 || err.error.code === 109) {
          // CODE 2 = TRANSPORT CLOSED
          // CODE 3500 = INVALID TOKEN
          // CODE 109 = EXPIRED TOKEN
          if (!this.connecting && this.$store.getters["app/authorized"]) {
            console.log("auto relogin");
            this.$store.dispatch("app/counterLogin").then(() => {
              this.openCSChannel();
            });
          }
        }
      });
      this.centrifuge.on("disconnected", (data) => {
        console.log(`disconnected ${JSON.stringify(data)}`);
      });
      this.centrifuge.on("publication", (data) => {
        let push = data.data;
        console.log(push);
        this.updatePaymentStatus(push);
      });
    },
    updatePaymentStatus(push) {
      if (push.event === "parking_payment") {
        this.$store.commit("wallet/set_current_push", push.content);
        this.popupOctopus = "flow";
      } else if (push.event === "last_transactions") {
        this.popupOctopus = "transactions";
        this.$store.commit("wallet/set_current_card", push.content);
      } else if (push.event === "stop_enquiry") {
        setTimeout(() => {
          this.$store.commit("wallet/reset_card");
          this.hideTransactions();
        }, 2000);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$store.commit("history/set_loader", true);
      let watcher = setInterval(() => {
        if (vm.$store.state.app.places.length) {
          vm.updateTable();
          clearInterval(watcher);
        }
      }, 500);
      vm.updateTimer = setInterval(() => {
        if (
          vm.$store.getters["app/authorized"] &&
          vm.$refs.table &&
          document.documentElement.scrollTop === 0
        ) {
          vm.updateTable();
        }
      }, 60000);
    });
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.updateTimer);
    this.updateTimer = null;
    this.$store.commit("history/set_loader", true);
    next();
  },
};
</script>
