<template>
  <div>
    <v-skeleton-loader v-if="loading" type="table" />
    <v-data-table
      v-else
      :headers="headers"
      :items-per-page="-1"
      class="table"
      :items="data"
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
      :no-data-text="$t('no_data')"
      :expanded="expanded"
      @click:row="expand"
      :singleExpand="true"
    >
      <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div
          :key="i.text"
          class="d-flex align-center"
          :class="
            header.value === 'zone' ||
            header.value === 'duration' ||
            header.value === 'price'
              ? 'justify-center'
              : ''
          "
        >
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(headers.indexOf(header), 0)"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(headers.indexOf(header), 1)"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.username`]="{ item }">
        <div class="d-flex justify-start table-guest align-center">
          <div class="table-user">
            <img
              width="36px"
              height="36px"
              class="table-ava-icon table-img"
              alt="ava"
              :class="item.user.avatar_url ? '' : ' green-bg '"
              :src="
                item.user.avatar_url
                  ? item.user.avatar_url
                  : 'https://storage.googleapis.com/parkingbnb-html-images/avatar_user.svg'
              "
            />
          </div>
          <div class="table-user-name">
            <h4>{{ item.user.first_name }} {{ item.user.last_name }}</h4>
            <span>{{ item.car.plate_number }}</span>
          </div>
        </div>
      </template>

      <template v-slot:[`item.car_type`]="{ item }">
        <div class="table-car-type">
          {{ $t(`car_types[${item.car.car_type}]`) }}
        </div>
      </template>

      <template v-slot:[`item.zone`]="{ item }">
        <div class="table-zone">
          <span
            v-if="item.zonal"
            alt="Status"
            :style="{ width: '24px', height: '24px' }"
            class="zone-status-icon"
            :class="item.zonal.some((r) => r.status === 2) ? 'violated' : 'complied'"
          />
        </div>
      </template>

      <template v-slot:[`item.enter_time`]="{ item }">
        <div class="table-date">
          {{ getFormattedDate(item.parking.enter_time) }}
        </div>
      </template>

      <template v-slot:[`item.exit_time`]="{ item }">
        <div class="table-date">
          {{ getFormattedDate(item.parking.exit_time) }}
        </div>
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        <div class="table-duration text-center">
          {{ getDuration(item.parking.duration) }}
        </div>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <div class="table-price text-center">
          {{ priceNames.filter((i) => i.id === item.parking.guest_type)[0].desc }}
        </div>
      </template>

      <template v-slot:[`item.total_price`]="{ item }">
        <div class="table-price">
          {{
            item.parking.total_price
              ? item.parking.currency + item.parking.total_price / 100
              : ""
          }}
        </div>
      </template>

      <template v-slot:[`item.payment_status`]="{ item }">
        <div class="table-price text-center" v-if="item.parking.payment_status">
          <!-- <template v-if="item.parking.payment_method === 2">{{
            $t("parking_cash")
          }}</template> -->
          <template>
            {{ $t(`payment_options[${item.parking.payment_status - 1}]`) }}
          </template>
        </div>
      </template>

      <template v-slot:[`item.payment_method`]="{ item }">
        <div class="table-price text-center">
          {{ getPaymentMethodName(item.payment) }}
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="table-status">
          <img
            :src="
              item.parking.completed
                ? 'https://storage.googleapis.com/parkingbnb-html-images/Completed.svg'
                : 'https://storage.googleapis.com/parkingbnb-html-images/_Currently%20stay.svg'
            "
            alt="Status"
            :style="{ width: '24px', height: '24px' }"
          />
        </div>
      </template>
    </v-data-table>

    <div v-if="busy && !loading">
      <v-skeleton-loader v-for="i in 3" :key="i" type="table-row" />
    </div>
    <component
      :is="isSelectedWithPhoto ? ParkingRecordPopupWithPhoto : ParkingRecordPopup"
      :record="selected"
      ref="popup"
      @resetSelected="resetRecordForm"
      view="parkingHistory"
    />
  </div>
</template>

<script>
export default {
  name: "PaymentHistoryTable",
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    searchValue: {
      type: String,
      default: "",
    },
    isShort: {
      type: Boolean,
    },
    openForm: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      ParkingRecordPopupWithPhoto: () => import("@/components/ParkingHistory/ParkingRecordPopupWithPhoto.vue"),
      ParkingRecordPopup: () => import("@/components/ParkingHistory/ParkingRecordPopup.vue"),
      data: [],
      busy: false,
      disableLoader: false,
      counter: {
        0: 1,
        1: 1,
        2: 1,
      },
      monthCounter: {
        0: 1,
        1: 1,
        2: 1,
        3: 1,
      },
      itemsPerPage: 200,
      params: {
        from: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(),
        to: new Date().toISOString(),
        limit: 25,
        sort_by: 3,
        sort_direction: 0,
        guest_type: [4, 5, 7, 9, 11],
      },
      expanded: [],
      selected: { parking: {}, car: {}, user: {}, place: {} },
      filters: { dates: [] },
    };
  },
  computed: {
    isSelectedWithPhoto() {
      if (Object.keys(this.selected.parking).length) {
        const entrancePhotoUrl = this.selected.parking.entrance.photo_url;
        const exitPhotoUrl = this.selected.parking.exit.photo_url;
        return entrancePhotoUrl || exitPhotoUrl;
      } else {
        return false;
      }
    },
    locale() {
      return this.$store.getters["app/locale"];
    },
    hasSimpleHistory() {
      return this.$store.getters["app/hasSimpleHistory"];
    },
    hasKiosk() {
      return this.$store.getters["app/hasKiosk"];
    },
    priceNames() {
      return this.$t("price_types");
    },
    headers() {
      let initHeaders = [
        {
          text: this.$t("guest"),
          value: "username",
          class: "table-guest table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("car_type"),
          value: "car_type",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: this.$t("date_in"),
          value: "enter_time",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("date_out"),
          value: "exit_time",
          class: "table-date table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("duration"),
          value: "duration",
          class: "table-duration table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("price_type"),
          value: "price",
          sortable: false,
          class: "table-price table-header",
        },
        {
          text: this.$t("payment_method"),
          value: "payment_method",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("total"),
          value: "total_price",
          class: "table-total table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "table-status table-header",
        },
      ];
      if (this.$store.getters["app/isCyber"] || this.$store.getters["app/isItt"]) {
        initHeaders.splice(7, 0, {
          text: this.$t("payment_status"),
          value: "payment_status",
          sortable: false,
          class: "table-header",
        });
      }
      return initHeaders;
    },
    expandedHeaders() {
      return [
        {
          text: "",
          value: "username",
          class: "table-guest table-header mx-0 px-0 maxw-guest",
          isDesc: undefined,
        },
        {
          text: "",
          value: "car_type",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: "",
          value: "parking",
          sortable: false,
          class: "maxw-guest table-header",
        },

        {
          text: this.$t("zone_actual"),
          value: "zone",
          sortable: false,
          class: "table-header table-zone",
        },
        {
          text: this.$t("date_in"),
          value: "enter_time",
          class: "table-date table-header",
          isDesc: false,
        },
        {
          text: this.$t("date_out"),
          value: "exit_time",
          class: "table-date table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("duration"),
          value: "duration",
          class: "table-duration table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("penalty"),
          value: "penalty",
          sortable: false,
          class: "table-date table-header text-center",
        },
        {
          text: "",
          value: "status",
          sortable: false,
          class: "table-status table-header",
        },
      ];
    },
    history() {
      return this.$store.state.history.parking_history;
    },
    loading() {
      return this.history.loading;
    },
    records() {
      let history = this.history.current;
      switch (this.tab) {
        case 0:
          return history.filter((i) => i.parking.payment_status === 1);
        case 1:
          return history.filter((i) => i.parking.payment_status === 2);
        case 2:
          return history.filter((i) => i.parking.payment_status === 3);
        case 3:
          return history;
        default:
          return [];
      }
    },
    status() {
      return this.$store.state.history.status;
    },
    places() {
      return this.$store.state.app.places;
    },
    isSuper() {
      return this.$store.getters["app/isSuperAdmin"];
    },
  },
  watch: {
    records() {
      this.loadItems();
    },
    data() {
      this.$store.commit("history/set_loader", false);
      this.busy = false;
    },
    status(newValue) {
      if (newValue) {
        this.updateParams({
          ...this.params,
          from: this.newFrom(),
          to: this.newTo(),
        });
      }
    },
  },
  mounted() {
    window.moment = this.$moment;
    this.loadItems();
    this.scroll();
  },
  methods: {
    getPaymentMethodName(payment) {
      if (payment.is_paid && payment.amount) {
        if (payment.location === 'online') {
          return this.$t('payment_location.online');
        }
        const location = this.$t(`payment_location.${payment.location}`);
        const method = this.$t('payment_methods_cs').find((item) => item.key === payment.method)?.desc;
        return `${method} - ${location}`
      }
      return '';
    },
    newFrom() {
      let filters = this.filters;
      let newDate = new Date(
        new Date().setMonth(new Date().getMonth() - this.monthCounter[this.tab])
      ).toISOString();
      // let newDate = null;
      return filters.dates[0]
        ? this.$moment.utc(filters.dates[0]).toISOString()
        : newDate;
    },
    newTo() {
      let filters = this.filters;
      let newDate = this.$moment().toISOString();
      // let newDate = null;
      return filters.dates[1]
        ? this.$moment.utc(filters.dates[1]).toISOString()
        : newDate;
    },

    resetRecordForm() {
      this.selected = { parking: {}, car: {}, user: {}, place: {} };
    },

    loadItems() {
      if (!this.records) {
        return;
      }
      if (this.records.length <= this.itemsPerPage) {
        this.data = [...this.records];
        return;
      }
      if (this.counter[this.tab] < Math.round(this.records.length / this.itemsPerPage)) {
        this.data = this.records.slice(0, this.counter[this.tab] * this.itemsPerPage);
      } else {
        this.data = [...this.records];
      }
    },
    updateParams(newValue) {
      this.disableLoader = false;
      let params = {};
      for (const [key, value] of Object.entries({ ...newValue })) {
        if (value || value === 0) {
          params[key] = value;
        }
      }
      this.updateData(params);
    },
    updateData(params) {
      this.$store.dispatch("history/getCurrentRecords", {
        ...params,
        status: 0,
        place_id: this.places.map((p) => p.place_id),
        include_finished: true,
      });
    },
    expand(value) {
      // if (this.isSuper) {
      this.selected = value;
      this.$refs.popup.show();
      // }
      if (value.zonal && this.$store.getters["app/hasZones"]) {
        let index = -1;

        for (let i = 0; i < this.expanded.length; i++) {
          if (this.expanded[i].parking.parking_id === value.parking.parking_id) {
            index = i;
          }
        }

        if (index === -1) {
          this.data[this.data.indexOf(value)].expanded = true;
          this.expanded.push({ ...value });
        } else {
          this.data[this.data.indexOf(value)].expanded = false;
          this.expanded.splice(index, 1);
        }
      }
    },
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.ceil(document.documentElement.scrollTop) + window.innerHeight >=
          document.documentElement.scrollHeight - 100;
        if (
          bottomOfWindow &&
          !this.loading &&
          !this.busy &&
          !this.openForm &&
          !this.isShort &&
          this.$route.path.includes("parking-payment")
        ) {
          this.loadMore();
        }
      };
    },

    filter(filters) {
      this.filters = filters;
      this.params = {
        ...this.params,
        from: this.newFrom(),
        to: this.newTo(),
        guest_type: filters.pricing,
        car_type: filters.car_type,
        place_id: filters.place_id,
      };

      this.updateParams({ ...this.params });
    },

    search(value) {
      this.params = {
        ...this.params,
        guest_name: value,
        from: this.newFrom(),
        to: this.newTo(),
      };

      this.updateParams({ ...this.params });
    },

    loadMore() {
      this.counter[this.tab]++;
      if (!this.disableLoader) {
        this.busy = true;
      }
      this.params = {
        ...this.params,
        from: this.newFrom(),
        to: this.newTo(),
        limit: this.counter[this.tab] * this.itemsPerPage,
      };
      // console.log(
      //   `new limit ${this.params.limit} & new from ${this.$moment(this.newFrom()).format(
      //     "DD.MM.YY"
      //   )}`
      // );
      this.updateParams({ ...this.params, incrementing: true });
    },

    getFormattedDate(date) {
      return date ? this.$moment(date).format("D.MM.YYYY HH:mm") : "";
    },

    getDuration(milliseconds) {
      let duration = this.$moment.duration(milliseconds, "milliseconds");
      let months = duration._data.months ? duration._data.months + "m" : "";
      let days = duration._data.days ? duration._data.days + "d" : "";
      let hours = duration._data.hours ? duration._data.hours + "h" : "";
      let minutes = duration._data.minutes;

      if (duration._data.seconds && duration._data.minutes !== 59) {
        minutes = minutes + 1;
      }

      minutes = minutes + "m";

      return `${months} ${days} ${hours} ${minutes}`;
    },

    customSort(index, sort_direction) {
      this.headers.map((h) => {
        if (this.headers.indexOf(h) !== index) {
          h.isDesc = undefined;
        }
      });
      this.headers[index].isDesc = !!sort_direction;
      switch (this.headers[index].value) {
        case "username":
          this.params = { ...this.params, sort_by: 1, sort_direction };
          break;
        case "enter_time":
          this.params = {
            ...this.params,
            sort_by: 3,
            sort_direction: Number(!sort_direction),
          };
          this.headers[index].isDesc = !Number(!sort_direction);
          break;
        case "exit_time":
          this.params = {
            ...this.params,
            sort_by: 4,
            sort_direction: Number(!sort_direction),
          };
          this.headers[index].isDesc = !Number(!sort_direction);
          break;
        case "duration":
          this.params = { ...this.params, sort_by: 5, sort_direction };
          break;
        case "total_price":
          this.params = { ...this.params, sort_by: 6, sort_direction };
          break;
        default:
          return;
      }
      this.params = { ...this.params, from: this.newFrom(), to: this.newTo() };
      this.updateParams({ ...this.params });
    },
  },
};
</script>
