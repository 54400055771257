// import Vue from 'vue'
// import i18n from '@/plugins/i18n';
import actions from './actions'

const getDefaultState = () => {
    return {
        list: [],
        loading: true,
        current: JSON.parse(localStorage.getItem("cur_user")) || {},

        vehicles: [],
        transactions: [],
        parkings: [],
        stats: {},
        subscriptions: [],
        promos: [],
        receipts: [],
        payment_methods: []
    }
}

const state = getDefaultState();

const getters = {}

const mutations = {
    set_loader(state, value) {
        state.loading = value
    },
    set_list(state, payload) {
        state.list = payload
        state.loading = false
    },
    set_current(state, payload) {
        state.current = payload
        localStorage.setItem("cur_user", JSON.stringify(payload))
    },

    set_vehicles(state, payload) {
        state.vehicles = payload || []
    },
    set_transactions(state, payload) {
        state.transactions = payload || []
    },
    set_parkings(state, payload) {
        state.parkings = payload || []
    },
    set_subscriptions(state, payload) {
        state.subscriptions = payload || []
    },
    set_receipts(state, payload) {
        state.receipts = payload || []
    },
    set_stats(state, payload) {
        state.stats = payload || {}
    },
    set_promos(state, payload) {
        state.promos = payload || {}
    },
    set_payment_methods(state, payload) {
        state.payment_methods = payload
    },
    reset_current(state) {
        localStorage.removeItem("cur_user")
        state.current = {}
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};