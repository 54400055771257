<template>
  <div class="tabs-content wallet">
    <h3 class="main-title">{{ $t("visit_history") }}</h3>

    <div class="d-flex align-center my-6">
      <v-text-field
        v-model="search"
        autocomplete="off"
        class="parking-search"
        solo
        :placeholder="$t('search_core')"
      ></v-text-field>
      <CoreFilter
        ref="coreFilter"
        @filterSet="updFilters"
        @filterReset="resetFilters"
        @hideEmit="hideFilter"
        @showEmit="showFilter"
        :hidden="{
          car_type: true,
          price_range: true,
          campaign: true,
          zone: true,
          created_by: true,
          pricing: true,
          wallet: true,
        }"
      />
      <v-btn text class="dwl-btn" @click.stop="refresh">
        <img
          :style="{ height: '24px', width: '24px' }"
          src="@/assets/images/refresh.svg"
          alt="refresh"
        />
      </v-btn>
    </div>
    <VisitsTable
      class="mb-5"
      :searchValue="search"
      :filters="filters"
      :tab="tab"
      ref="table"
      :openReportForm="openReportForm"
      :openFilterForm="openFilterForm"
    />
  </div>
</template>

<script>
export default {
  name: "Wallet",
  components: {
    VisitsTable: () => import("@/components/Tenants/VisitsTable"),
    CoreFilter: () => import("@/components/core/Filter"),
  },
  data() {
    return {
      tab: 3,
      filters: {
        dates: [],
      },
      openReportForm: false,
      openFilterForm: false,
      debouncedInput: "",
      timeout: null,
      updateTimer: null,
    };
  },
  computed: {
    items() {
      return this.$t("wallet_status_options");
    },
    search: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedInput = val;
          this.$refs.table.search(this.debouncedInput);
        }, 1000);
      },
    },
  },
  methods: {
    openDownloadForm() {
      this.$refs.modal.show();
      this.openReportForm = true;
    },
    hideReport() {
      this.openReportForm = false;
    },
    showFilter() {
      this.openFilterForm = true;
    },
    hideFilter() {
      this.openFilterForm = false;
    },
    updFilters(filters) {
      this.filters = filters;
      this.$refs.table.filter(filters);
    },
    resetFilters() {
      this.filters = {};
      this.$refs.table.resetParams();
    },
    refresh() {
      this.$refs.table.update();
    },
  },
};
</script>
