var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-content"},[_c('h3',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("subscriptions")))]),_c('div',{staticClass:"my-6 d-flex flex-wrap flex-md-nowrap justify-start align-center"},[_c('v-tabs',{staticClass:"ma-0 mr-6",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item,index){return _c('v-tab',{key:index},[_vm._v(_vm._s(item)+" "),(index === 1)?[_vm._v("("+_vm._s(_vm.activeCount)+")")]:_vm._e()],2)}),1),_c('div',{staticClass:"d-flex justify-center ml-lg-0 my-lg-0"},[_c('CoreFilter',{ref:"coreFilter",attrs:{"hidden":{
          campaign: true,
          created_by: true,
          price_range: true,
          zone: true,
          status: true,
          pricing: true,
          wallet: true,
          car_type: true,
        }},on:{"filterSet":_vm.updFilters,"hideEmit":function($event){_vm.openFilterForm = false},"showEmit":function($event){_vm.openFilterForm = true}}}),_c('v-btn',{staticClass:"mr-lg-auto dwl-btn",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.refresh.apply(null, arguments)}}},[_c('img',{style:({ height: '24px', width: '24px' }),attrs:{"src":require("@/assets/images/refresh.svg"),"alt":"refresh"}})])],1)],1),(_vm.isCyber)?_c('SubscriptionsTableCp',{ref:"table",staticClass:"mb-5",attrs:{"searchValue":_vm.search,"tab":_vm.tab,"openForm":_vm.openForm}}):_c('SubscriptionsTable',{ref:"table",staticClass:"mb-5",attrs:{"searchValue":_vm.search,"tab":_vm.tab,"openForm":_vm.openForm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }