import { cyberport, base } from '@/axios-api'
import store from '@/store/index';
import i18n from '@/plugins/i18n';
import qs from 'qs'

import EventBus from '@/event-bus.js'

export default {
    getCampaigns({
        commit,
        dispatch
    }, parking_id) {
        let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id)
        base({
                url: '/promo/campaigns',
                params: {
                    place_id
                },
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let campaigns = response.data.body || [];
                    campaigns.forEach(campaign => {
                        campaign.stores = campaign.stores || [];
                        campaign.custom_params = campaign.custom_params || {};
                        campaign.constraints = campaign.constraints || [{}]
                    })
                    commit("get_campaigns_success", campaigns)
                    commit("app/set_loader", false, {
                        root: true
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    addPromoCampaign({
        commit,
        dispatch
    }, data) {
        base({
                url: '/promo/campaign',
                method: "POST",
                data
            })
            .then(response => {
                if (response.status == 200) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('promo_added'),
                        duration: 3000
                    })
                    if (store.getters['app/isCyber']) {
                        dispatch('setCampaignRules', data)
                    }
                    dispatch('getCampaigns')
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    changePromoCampaign({
        commit,
        dispatch
    }, data) {
        base({
                url: '/promo/campaign',
                method: "PUT",
                data
            })
            .then(response => {
                if (response.status == 200) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('promo_changed'),
                        duration: 3000
                    })
                    dispatch('getCampaigns')
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 500) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: i18n.t('error'),
                        text: '',
                        duration: 3000
                    })
                }
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    deletePromo({
        commit,
        dispatch
    }, id) {
        base({
                url: '/promo/campaign',
                data: {
                    campaign_id: id
                },
                method: "DELETE"
            })
            .then(response => {
                if (response.status == 200) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('promo_deleted'),
                        duration: 3000
                    })
                    dispatch('getCampaigns')
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getPromoHistory({
        commit,
        dispatch
    }, parking_id) {
        let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id)
        base({
                url: '/promo/history',
                method: "GET",
                params: {
                    place_id
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            }).then(response => {
                if (response.status === 200) {
                    if (response.data.body) {
                        commit("get_promo_history", response.data.body);
                    } else {
                        commit("get_promo_history", []);
                    }
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getCars({
        commit,
        dispatch
    }, parking_id) {
        let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id)
        base({
                url: "/promo/available_cars",
                params: {
                    place_id
                },
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let cars = response.data.body || [];
                    commit("get_cars_success", cars)
                }
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    applyPromo({
        commit,
        dispatch
    }, data) {
        let url = "/promo/apply_code"
        if (data.guest_type === 5) url += "_octopus"
        else if (data.guest_type === 9 || data.guest_type === 11) url += "_monthly"
        base({
                url,
                data,
                method: "POST",
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch("getPromoHistory")

                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('promo_applied', {
                            car: data.plate_number
                        }),
                        duration: 3000
                    })
                    return commit;
                }

            })
            .catch((err) => {
                console.log(err.response);
                console.log(err);
            })
    },
    updatePromoHours({
        commit,
        dispatch
    }, data) {
        base({
                url: `/promo/${data.code}`,
                data,
                method: "PUT",
            })
            .then((response) => {
                if (response.status === 200) {
                    dispatch("getPromoHistory")
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('promo_updated', {
                            car: data.plate_number
                        }),
                        duration: 3000
                    })
                    return commit;
                }

            })
            .catch((err) => {
                console.log(err.response);
            })
    },

    //CYBERPORT SHIT
    applyDiscounts({
        commit
    }, data) {
        cyberport({
                url: "/platform/discount/apply_discount",
                data,
                method: "POST",
            })
            .then((response) => {
                if (response.status === 200) {

                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('promo_applied', {
                            car: data.plate_number
                        }),
                        duration: 3000
                    })
                    return commit;
                }

            })
            .catch((err) => {
                console.log(err.response);
                EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Oops!',
                    text: i18n.t('promo_failed', {
                        car: data.plate_number
                    }),
                    duration: 3000
                })
            })
    },
    getReceipts({
        commit,
        dispatch
    }, ) {
        cyberport({
                url: "/platform/discount/list_receipts",
                method: 'GET',
            })
            .then((response) => {
                if (response.status === 200) {
                    let receipts = response.data.body.receipts || [];
                    commit("get_receipts", receipts)
                }
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    setCampaignRules({
        commit,
        dispatch
    }, data) {
        cyberport({
                url: `/platform/discount/campaign_config`,
                method: "PUT",
                data
            })
            .then(response => {
                if (response.status == 200) {
                    console.log(response)
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getPromocodes({
        commit,

    }, ) {
        cyberport({
                url: "/platform/promocodes",
                method: 'GET',
            })
            .then((response) => {
                if (response.status === 200) {
                    let promocodes = response.data.body || [];
                    commit("get_promocodes", promocodes)
                }
            })
    },
    addPromocode({
        commit,
        dispatch
    }, data) {
        cyberport({
                url: "/platform/promocodes",
                method: 'POST',
                data
            })
            .then((response) => {
                if (response.status === 200) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t(`promocode_added`),
                        duration: 3000
                    })
                    dispatch("getPromocodes")
                    return commit
                }
            })
    },
    generatePromo({
        commit,
        dispatch
    }, data) {
        cyberport({
                url: "/platform/promocodes/many",
                method: 'POST',
                data
            })
            .then((response) => {
                if (response.status === 200) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t(`promocode_generated`),
                        duration: 3000
                    })
                    dispatch("getPromocodes")
                    return commit
                }
            })
    },
    changePromocode({
        commit,
        dispatch
    }, data) {
        cyberport({
                url: "/platform/promocodes",
                method: 'PUT',
                data
            })
            .then((response) => {
                if (response.status === 200) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t(`promocode_updated`),
                        duration: 3000
                    })
                    dispatch("getPromocodes")
                    return commit
                }
            })
    },
    deletePromocode({
        commit,
        dispatch
    }, id) {
        cyberport({
                url: `/platform/promocodes/${id}`,
                method: 'PUT',
            })
            .then((response) => {
                if (response.status === 200) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t(`promocode_deleted`),
                        duration: 3000
                    })
                    dispatch("getPromocodes")
                    return commit
                }
            })
    },
    getCodesHistory({
        commit,

    }, ) {
        cyberport({
                url: "/platform/promocodes/history",
                method: 'GET',
            })
            .then((response) => {
                if (response.status === 200) {
                    let history = response.data.body || [];
                    commit("get_promocodes_history", history)
                }
            })
    },

    getReportCyber({
        commit,
        dispatch
    }, {
        params,
        route
    }) {
        commit("app/set_downloader", true, {
            root: true
        })
        let name = "_promocodes"
        cyberport({
                url: route,
                params,
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                if (response.status === 200) {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'report' + name + '.xlsx');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    commit("app/set_downloader", false, {
                        root: true
                    })
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
}