<template>
  <div
    class="popup"
    :class="openForm ? 'd-flex' : 'd-none'"
    :hidden="openForm"
    style="position: fixed"
  >
    <div class="modal-overlay" @click="hide"></div>
    <div class="modal">
      <div class="modal-content core-filter">
        <div class="mb-4 d-flex justify-space-between">
          <h3 class="main-title">
            {{ $t("dates") }}
          </h3>
          <v-btn text class=" icon-close pa-0" min-width="24px" @click="hide" />
        </div>

        <v-form ref="form">
          <div class="full-border-btm">
            <label class="modal-label">{{ $t("period") }}</label>
            <div v-if="!solo" class="d-flex justify-center form-period date">
              <v-menu
                attach
                content-class="datepicker-menu"
                :close-on-content-click="false"
                @click.native.stop
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    @click.prevent
                    v-on="on"
                    readonly
                    class="modal-input input-half datepicker-inp"
                    :value="dates[0] ? $moment(dates[0]).format('DD.MM.YYYY') : ''"
                    :label="$t('from')"
                    :rules="[() => !!dates[0] || $t('from_err')]"
                  ></v-text-field>
                  <span class="datepicker-divider"></span>
                  <v-text-field
                    v-on="on"
                    class="modal-input input-half datepicker-inp datepicker-inp-second"
                    readonly
                    :value="dates[1] ? $moment(dates[1]).format('DD.MM.YYYY') : ''"
                    :label="$t('to')"
                    :rules="[
                      () =>
                        $moment(dates[1]).isAfter($moment(dates[0])) || $t('range_err'),
                    ]"
                  ></v-text-field>
                </template>
                <v-date-picker :locale="$i18n.locale"
                  first-day-of-week="1"
                  :isDark="false"
                  v-model="dates"
                  :min="dates[0] || ''"
                  range
                ></v-date-picker>
              </v-menu>
            </div>
            <div v-else class="form-period date single">
              <v-menu
                content-class="datepicker-menu  "
                attach
                :close-on-content-click="!!end_date"
              >
                <template v-slot:activator="{ on }">
                  <v-form ref="form">
                    <v-text-field
                      v-on="on"
                      class="modal-input datepicker-inp"
                      @change="changed = true"
                      :value="end_date ? $moment(end_date).format('DD.MM.YYYY') : ''"
                      :label="$t('dates')"
                      :rules="[() => !!end_date || $t('dates') + $t('required')]"
                    ></v-text-field>
                  </v-form>
                </template>
                <v-date-picker :locale="$i18n.locale"
                  first-day-of-week="1"
                  :isDark="false"
                  v-model="end_date"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>

          <div class="modal-footer d-flex align-center px-0 mt-4">
            <button class="main-btn v-btn py-6" @click.prevent="save">
              {{ $t("save") }}
            </button>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import "@/scss/_modal.scss";

export default {
  name: "Datepicker",
  props: {
    solo: {},
  },
  data() {
    return {
      openForm: null,
      dates: [],
      end_date: "",
    };
  },
  methods: {
    show() {
      this.openForm = true;
    },
    hide() {
      this.openForm = false;
      this.$emit("hideEmit");
    },
    save() {
      if (this.solo) {
        this.$emit("save", this.end_date);
      } else {
        this.$emit("save", [...this.dates]);
      }
      this.dates = [];
      this.end_date = "";
      this.$refs.form.reset();
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  position: absolute;
  bottom: 105px;
}
.full-border-btm::after {
  margin-top: 30px;
}

.datepicker-divider {
  top: 34.5%;
}
</style>
