<template>
  <div>
    <v-toolbar absolute top class="drawer-icon promo-toolbar" @click="drawer = !drawer">
      <v-app-bar-nav-icon
        class="hidden-md-and-up drawer-burger ml-0 mr-4"
      ></v-app-bar-nav-icon>
      <h4 class="main-title white--text">{{ $t("companies") }}</h4>
    </v-toolbar>
    <div class="d-flex sidebar-tab-content">
      <v-navigation-drawer
        v-model="drawer"
        class="core-sidebar core-drawer places-drawer"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :mini-variant.sync="mini"
        mini-variant-width="75"
      >
        <div
          class="d-flex align-center mb-3 pt-8"
          @click="mini = !mini"
          style="cursor: pointer"
        >
          <h4
            class="main-title main-title white--text mr-auto py-0"
            :style="{ opacity: mini ? 0 : 1 }"
          >
            {{ $t("companies") }}
          </h4>
          <v-btn icon>
            <img
              src="@/assets/images/chevron-left.svg"
              width="24px"
              height="24px"
              class="mr-4 drawer-arrow"
            />
          </v-btn>
        </div>
        <div class="d-flex flex-column px-2">
          <v-text-field
            v-model="search"
            autocomplete="off"
            class="parking-search sidebar-search"
            solo
            :placeholder="$t('search_company')"
          />
          <v-btn
            class="main-btn pa-2 ml-auto my-4"
            text
            style="max-width: 100%; width: 100%"
            ><template v-if="mini">+</template
            ><template v-else>{{ $t(`add`) }} {{ $t(`company`) }}</template></v-btn
          >
        </div>
        <div
          class="sidebar-list-item"
          v-for="item in list"
          :key="item.id"
          @click="chooseCompany(item)"
          :class="item.id === selected ? 'active' : ''"
        >
          <v-btn text>
            <span
              class="sidebar-list-item_title overimg-text"
              v-if="mini && item.id !== 0"
              >{{ item.name.slice(0, 1) }}</span
            >
            <h4 class="sidebar-list-item_title pl-0">{{ item.name }}</h4>
          </v-btn>
        </div>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "CompanyList",
  data() {
    return {
      drawer: false,
      mini: false,
    };
  },
  watch: {
    mini(newValue) {
      this.$emit("miniChange", newValue);
    },
  },
  mounted() {
    this.$emit("miniChange", this.mini);
  },
  computed: {
    companies() {
      return [...this.$store.state.companies.list];
    },
    list() {
      let result = [...this.companies];
      result.splice(0, 0, {
        id: 0,
        name: this.$t("parking_history_tabs[2]"),
      });
      return result;
    },
    view() {
      return this.$route.path.slice(10, this.$route.path.length);
    },
    selected() {
      return this.$store.state.companies.current.id;
    },
  },
  methods: {
    chooseCompany(item) {
      this.$store.commit("companies/set_current", item);
    },
  },
};
</script>
