<template>
  <div class="parking-record has-pic">
    <div class="no-scroll">
      <div class="d-flex align-center pb-2">
        <h3 class="main-title mr-6 my-0">
          {{ $t("subscription") }}
        </h3>
        <v-btn text class="icon-close ml-auto" min-width="24px" @click="hide" />
      </div>

      <div class="d-flex align-center">
        <v-btn @click="saveRecordInfo" class="main-btn px-6 py-2 mr-4" text>{{
          $t("save")
        }}</v-btn>
        <v-btn @click="deleteRecord" class="flat-btn" text>{{ $t("delete") }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscriptionRecord",
  props: {
    record: {
      default() {
        return {};
      },
    },
    open: {
      default: false,
    },
  },
  components: {},
  data() {
    return {
      loading: false,
      rec: {},
    };
  },
  computed: {},
  watch: {
    record(newValue) {
      this.rec = JSON.parse(JSON.stringify(newValue));
    },
  },
  methods: {
    hide() {
      this.open = false;
      this.$emit("hide");
    },

    deleteRecord() {
      this.$confirm({
        message: this.$t("subscription_delete_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("confirm"),
        },
        callback: (confirm) => {
          if (confirm) {
            let id = this.rec.id;
            this.$store.dispatch("subscriptions/deleteSubscription", id);
            this.hide();
          }
        },
      });
    },

    saveRecordInfo() {
      this.$confirm({
        message: this.$t("save_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("save"),
        },
        callback: (confirm) => {
          if (confirm) {
            let data = {
              ...this.rec,
            };
            if (this.formType === 0) {
              this.$store.dispatch("subscriptions/addUserSubscription", data);
            } else {
              this.$store.dispatch("subscriptions/updateUserSubscription", data);
            }
            console.log(data);

            this.hide();
          }
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
</style>

<style lang="scss" scoped>
.v-text-field__details {
  display: none;
}
@media print, screen and (min-width: 576px) {
  .datepicker-menu {
    top: 3.2em !important;
  }
}
</style>
