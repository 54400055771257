var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-content wallet"},[_c('h3',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("visit_history")))]),_c('div',{staticClass:"d-flex align-center my-6"},[_c('v-text-field',{staticClass:"parking-search",attrs:{"autocomplete":"off","solo":"","placeholder":_vm.$t('search_core')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('CoreFilter',{ref:"coreFilter",attrs:{"hidden":{
        car_type: true,
        price_range: true,
        campaign: true,
        zone: true,
        created_by: true,
        pricing: true,
        wallet: true,
      }},on:{"filterSet":_vm.updFilters,"filterReset":_vm.resetFilters,"hideEmit":_vm.hideFilter,"showEmit":_vm.showFilter}}),_c('v-btn',{staticClass:"dwl-btn",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.refresh.apply(null, arguments)}}},[_c('img',{style:({ height: '24px', width: '24px' }),attrs:{"src":require("@/assets/images/refresh.svg"),"alt":"refresh"}})])],1),_c('VisitsTable',{ref:"table",staticClass:"mb-5",attrs:{"searchValue":_vm.search,"filters":_vm.filters,"tab":_vm.tab,"openReportForm":_vm.openReportForm,"openFilterForm":_vm.openFilterForm}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }