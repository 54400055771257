import EventBus from '../../../event-bus';
import i18n from '@/plugins/i18n';
import actions from './actions'

const getDefaultState = () => {
    return {
        current: JSON.parse(localStorage.getItem("current_group")),
        guest_list: [],
        groups: [],
        subgroups: [],
        status: undefined,
        loading: true,
        guest_tab: 0,
        guest_tree: false,
        place: 0,
        message: null,
        currently_parked: []
    }
}

const state = getDefaultState();

const getters = {}

const mutations = {
    logout(state) {
        localStorage.removeItem("current_group")
        Object.assign(state, getDefaultState())
    },
    set_loader(state, value) {
        state.loading = value
    },
    set_current_place(state, place) {
        state.place = place
    },
    get_guests_success(state, guests) {
        state.guest_list = guests
        setTimeout(() => {
            state.status = undefined
            state.message = null
        }, 5000)
    },
    get_groups_success(state, content) {
        let groups = content.filter(i => !i.parent_id)
        let subgroups = content.filter(i => i.parent_id)
        state.groups = groups
        state.subgroups = subgroups
        state.loading = false
        return state
    },
    add_guest(state, {
        value,
        code,
        guests,
        cars
    }) {
        state.status = value;
        if (code === 109) {
            state.message = i18n.t('guest_partially_added', {
                guests
            })
        } else if (code === 121) {
            state.message = i18n.t('car_dates_clash', {
                cars
            })
        }
        setTimeout(() => {
            state.status = undefined;
            state.message = null
        }, 30000)
        return state
    },
    del_group_success(state, notify) {
        if (notify) {
            EventBus.$emit("notify", {
                group: 'general',
                title: 'Success!',
                text: i18n.t('group_deleted'),
                duration: 3000
            })
        }
        return state
    },
    del_department_success(state, notify) {
        if (notify) {
            EventBus.$emit("notify", {
                group: 'general',
                title: 'Success!',
                text: i18n.t('subgroup_deleted'),
                duration: 3000
            })
        }
        return state
    },
    delete_selected_success(state) {
        EventBus.$emit("notify", {
            group: 'general',
            title: 'Success!',
            text: i18n.t('form_deleted'),
            duration: 3000
        })
        return state
    },
    change_guest_success(state) {
        state.status = true;
        setTimeout(() => {
            state.status = undefined;
        }, 10000);
        return state
    },
    change_guest_error(state, {
        message,
        type
    }) {
        state.status = false;
        setTimeout(() => {
            state.status = false
        }, 10000);
        let error;
        switch (type) {
            case "existingPlate":
                error = message.split(" ").slice(0, -1);
                error = i18n.t('ntf_errors.car_plate_exist', {
                    car: error[4]
                });
                break
            case "currentParked":
                error = i18n.t('ntf_errors.curr_parked');
                break
            default:
                break
        }
        console.log(message)
        console.log(error)
            // EventBus.$emit("notify", {
            //     group: 'general',
            //     title: i18n.t('error'),
            //     text: error,
            //     duration: 3000
            // })
        return state
    },
    set_current_group(state, group) {
        state.current = group;
        localStorage.setItem('current_group', JSON.stringify(group))
    },
    set_guest_tab(state, tab) {
        state.guest_tab = tab
    },
    reset_status(state, status) {
        state.status = status
    },
    set_guest_tree(state, tree) {
        state.guest_tree = tree
    },
    set_currently_parked(state, data) {
        state.currently_parked = data
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};