import actions from './actions'

const getters = {}

const state = () => ({
    history: [],
    loading: true,
    current_payment_card: {},
    current_push: {}
})


const mutations = {
    get_transactions(state, payload) {
        state.history = payload
        state.loading = false
    },
    set_loader(state, value) {
        state.loading = value
    },
    set_current_card(state, payload) {
        state.current_payment_card = payload
    },
    set_current_push(state, payload) {
        state.current_push = payload
    },
    reset_card(state) {
        state.current_payment_card = {}
        state.current_push = {}
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};