<template>
  <div class="guests-table">
    <div v-if="loading">
      <div class="d-flex v-expansion-panel-header" v-for="i in 5" :key="i">
        <v-skeleton-loader type="heading" :loading="true" max-width="40%" />
        <v-skeleton-loader type="chip" :loading="true" class="mr-auto" />
        <v-skeleton-loader type="button" :loading="true" class="flex-grow-0" />
      </div>
    </div>
    <v-expansion-panels flat multiple accordion v-else>
      <v-expansion-panel v-for="guest in history" :key="guest.guest_id">
        <v-expansion-panel-header>
          <div class="d-flex align-center guest-record">
            <h3 class="parking-subtitle">
              {{ guest.user.first_name }} {{ guest.user.last_name }}
              <h3 class="parking-subtitle mt-2">
                {{ guest.vehicle.plate_number }}
              </h3>
            </h3>
            <h1 class="parking-subtitle mx-6">—</h1>
            <div class="parking-subtitle">
              {{ formatDuration(getTotal(guest)) }}
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <thead>
              <tr class="guests-table-headers">
                <th>{{ $t("dates") }}</th>
                <th>{{ $t("over_parking") }}</th>
              </tr>
            </thead>
            <tbody class="guests-table-content">
              <tr v-for="record in guest.list" :key="record.record_id">
                <td>{{ $moment(record.date).format("DD MMM YYYY") }}</td>
                <td>{{ formatDuration(record.minutes) }}</td>
              </tr>
            </tbody>
          </v-simple-table>
          <!-- <div v-for="record in guest.history" :key="record.record_id">
            <span class="streaming-subtitle">{{
              $moment(record.date).format("DD MMM")
            }}</span>
            —
            <span class="streaming-subtitle">{{ formatDuration(record.total) }}</span>
          </div> -->
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "OverparkingTable",
  components: {},
  data() {
    return {
      openForm: null,
    };
  },
  props: {
    searchValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    loading() {
      return this.$store.state.overparking.loading;
    },
    isReadonly() {
      return this.$store.getters["app/isReadonly"];
    },
    places() {
      return [...this.$store.state.app.places];
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    history() {
      return this.search(this.searchValue);
    },
  },
  methods: {
    search(value) {
      let history = [...this.$store.state.overparking.history];
      if (value) {
        history = history.filter((i) =>
          (i.user.first_name + i.user.last_name + i.vehicle.plate_number)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      }
      return history;
    },
    getTotal(guest) {
      let total = 0;
      guest.list = guest.list || [];
      guest.list.map((r) => {
        total = total + r.minutes;
      });
      return total;
    },
    formatDuration(dur) {
      let duration = this.$moment.duration(dur, "minutes");

      let hours = duration._data.hours  ? duration._data.hours : "";
      let minutes = duration._data.minutes;

      if (duration._data.seconds  && duration._data.minutes !== 59) {
        minutes = minutes + 1;
      }

      if (duration._data.days ) {
        hours = hours + 24 * duration._data.days;
      }

      hours += hours  ? " hour" : "";
      hours += duration._data.hours > 1 ? "s" : "";
      minutes = minutes > 1 ? minutes + " minutes" : "";

      return `${hours} ${minutes}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.streaming-subtitle {
  color: $grey;
}
@media print, screen and (max-width: 576px) {
  .guest-record .parking-subtitle {
    font-size: 14px !important;
    width: 86px;
  }
  .guest-record .streaming-subtitle {
    width: 80px;
  }
  .v-expansion-panel-header .flat-btn {
    margin: 0 8px;
  }
}
</style>

<style lang="scss">
@import "@/scss/_modal.scss";
.guests-table .v-skeleton-loader__chip {
  width: 36px;
}
</style>
