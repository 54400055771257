// import Vue from 'vue'
// import i18n from '@/plugins/i18n';
import actions from './actions'

const state = () => ({
    loading: true,
    history: [],
});

const getters = {}

const mutations = {
    logout(state) {
        state.place = 0
    },
    set_loader(state, value) {
        state.loading = value
    },
    set_history(state, payload) {
        state.history = payload
        setTimeout(() => {
            state.loading = false
        }, 500)
    },
    set_limit(state, value) {
        state.current_is_overtime = value
    }
    
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};