<template>
  <div class="tabs-content your-parking guest-flow">
    <h3 class="main-title">{{ title }}</h3>

    <div class="d-flex align-center justify-space-between mb-4">
      <div class="d-flex align-center flex-wrap flex-md-nowrap">
        <v-btn text class="modal-btn mb-0" @click="backToParking">{{
          $t("form_back_parking")
        }}</v-btn>
        <h3 class="modal-label reg-font mb-0">• {{ name }}</h3>
      </div>
      <v-btn v-show="step !== 5" text class="flat-btn" @click="treeHandler">
        {{ tree ? $t("back") : $t("form_show_tree") }}</v-btn
      >
    </div>
    <v-tabs v-show="!tree" :fixed-tabs="true" @change="toStep($event)" class="mb-sm-0">
      <v-tab
        :disabled="(step < index && formType === 0) || (index === step) === 5"
        :class="{
          'v-tab--active': step > index || formType === 1,
          'v-tab--active-first': index === 0,
          'v-tab--active-last': index === step,
        }"
        v-for="(item, index) in steps"
        :key="item"
      >
        {{ item }}</v-tab
      >
    </v-tabs>

    <template v-if="!tree">
      <!-- GROUP FORM -->
      <Group
        @groupChangedHandler="groupChangedHandler"
        @deleteGroup="deleteGroup"
        :group="group"
        v-show="step === 0"
      />

      <!-- GUEST FORM -->
      <v-form ref="guest" autocomplete="off" v-show="form === 'guest'">
        <h3 class="main-title" style="font-size: 20px !important">
          {{ $t("guests") }}
        </h3>
        <div v-for="(subgroup, sIndex) in group.subgroups" :key="sIndex">
          <div
            v-for="(person, persIndex) in subgroup.guests"
            :key="persIndex"
            class="mt-6"
          >
            <template v-if="!hasChoice || (hasChoice && person.chosen)">
              <div class="d-flex align-center">
                <label class="modal-label">{{ $t("guest") }} {{ persIndex + 1 }}</label>
                <v-btn
                  @click="deletePerson(subgroup.guests, person)"
                  text
                  class="modal-btn ml-auto"
                  >{{ $t("delete") }}</v-btn
                >
              </div>
              <div class="d-flex guest-row-3 justify-space-between flex-wrap">
                <v-text-field
                  v-model="person.guest_name"
                  @change="selectSendingGuest(person)"
                  class="modal-input"
                  :label="$t('fullname')"
                  :rules="[() => !!person.guest_name || $t('fullname') + $t('required')]"
                />
                <v-autocomplete :no-data-text="$t('no_data')"     attach
                  style="max-width: 300px"
                  v-model="person.country_code"
                  item-value="dial_code"
                  item-text="name"
                  class="modal-input"
                  :items="countryCodes"
                  :rules="[
                    () => !!person.country_code || $t('country_code') + $t('required'),
                  ]"
                >
                  <template v-slot:item="data">
          <span :class="`code-select selection-${data.item.i}`"
            ><img src="@/assets/images/countries.webp"
          /></span>
                    <span class="pl-2">{{ data.item.name }}</span>
                    <span style="opacity: 0.6" class="ml-3">{{
                      data.item.dial_code
                    }}</span>
                  </template>
                  <template v-slot:selection="data">
          <span :class="`code-select selection-${data.item.i}`"
            ><img src="@/assets/images/countries.webp"
          /></span>
                    <span class="pl-2">{{ data.item.dial_code }}</span>
                    <span class="pl-2">{{ data.item.name }}</span>
                  </template>
                </v-autocomplete>
                <v-text-field
                  class="modal-input"
                  :value="person.phone.replace(person.country_code, '')"
                  @input="(v) => (person.phone = person.country_code + v)"
                  type="tel"
                  pattern="\d*"
                  :label="$t('phone_number')"
                />
              </div>
              <div class="d-flex guest-row">
                <v-text-field
                  v-model="person.octopus_no"
                  @change="selectSendingGuest(person)"
                  class="modal-input"
                  :label="$t('octopus_no')"
                />
                <v-text-field
                  v-model="person.email"
                  @change="selectSendingGuest(person)"
                  class="modal-input"
                  :label="$t('email')"
                />
              </div>

              <div class="d-flex guest-row">
                <v-select :no-data-text="$t('no_data')"    attach
                  @change="selectSendingGuest(person)"
                  v-model="person.subscription.price_option_id"
                  class="modal-input"
                  :items="$t(`monthly_price_options`)"
                  :item-text="(v) => `${v.text} ($${v.price})`"
                  item-value="id"
                  :label="$t('pricing_type')"
                  :rules="[
                    () =>
                      !!person.subscription.price_option_id ||
                      $t('pricing_type') + $t('required'),
                  ]"
                />
                <!-- DATEPICKER FOR PERSON -->
                <div class="d-flex date">
                  <v-menu
                    content-class="datepicker-menu"
                    attach
                    :close-on-content-click="!!person.subscription.active_from"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        class="modal-input input-half datepicker-inp"
                        @change="timeChangeHandler(person, $event, 'active_from')"
                        :value="
                          $moment(person.subscription.active_from).format('DD.MM.YYYY')
                        "
                        :label="$t('from')"
                      ></v-text-field>
                      <span class="datepicker-divider"></span>
                    </template>

                    <v-date-picker :locale="$i18n.locale"
                      first-day-of-week="1"
                      :isDark="false"
                      v-model="person.subscription.active_from"
                      @change="timeChangeHandler(person, $event, 'active_from')"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    content-class="datepicker-menu"
                    attach
                    :close-on-content-click="!!person.subscription.active_until"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        class="modal-input input-half datepicker-inp datepicker-inp-second"
                        @change="timeChangeHandler(person, $event, 'active_until')"
                        :value="
                          $moment(person.subscription.active_until).format('DD.MM.YYYY')
                        "
                        :label="$t('to')"
                      ></v-text-field>
                    </template>
                    <v-date-picker :locale="$i18n.locale"
                      first-day-of-week="1"
                      :isDark="false"
                      v-model="person.subscription.active_until"
                      @change="timeChangeHandler(person, $event, 'active_until')"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>

              <div class="d-flex align-center justify-space-between my-4">
                <label class="modal-label">{{ $t("email_sec") }}</label>
                <v-btn @click="addEmail(person)" text class="flat-btn"
                  >+ {{ $t("add") }}</v-btn
                >
              </div>
              <div class="d-flex guest-row">
                <template v-for="(email, index) in person.secondary_emails">
                  <v-text-field
                    :key="index"
                    v-model="person.secondary_emails[index]"
                    @change="selectSendingGuest(person)"
                    class="modal-input"
                    :label="$t('email')"
                /></template>
              </div>
              <div class="d-flex align-center justify-space-between my-4">
                <label class="modal-label">{{ $t("phone_sec") }}</label>
                <v-btn @click="addPhone(person)" text class="flat-btn"
                  >+ {{ $t("add") }}</v-btn
                >
              </div>
              <div class="d-flex guest-row">
                <template v-for="(phone, index) in person.secondary_phones">
                  <v-text-field
                    :key="index"
                    v-model="person.secondary_phones[index]"
                    @change="selectSendingGuest(person)"
                    class="modal-input"
                    :label="$t('phone_number')"
                /></template>
              </div>
            </template>
          </div>
          <div
            class="d-flex justify-space-between mt-4"
            v-if="!hasChoice || (hasChoice && subgroup.chosen)"
          >
            <v-btn
              @click="addPerson(subgroup.guests)"
              text
              class="flat-btn modal-footer-btn mb-4"
              >+ {{ $t("add") }} {{ $t("guest") }}</v-btn
            >
          </div>
        </div>
      </v-form>

      <!-- CAR FORM -->
      <v-form ref="car" autocomplete="off" v-show="form === 'car'">
        <div class="d-flex align-center justify-space-between">
          <h3 class="main-title" style="font-size: 20px !important">
            {{ $t("cars") }}
          </h3>
        </div>
        <div v-for="(subgroup, sIndex) in group.subgroups" :key="sIndex">
          <div
            class="mb-6"
            v-for="(person, persIndex) in subgroup.guests"
            :key="persIndex"
          >
            <h3
              v-if="!hasChoice || (hasChoice && person.chosen)"
              class="modal-label reg-font"
              style="font-size: 16px !important"
              v-html="
                $t('car_add', {
                  guest: person.guest_name,
                })
              "
            />
            <div
              v-for="(car, carIndex) in [...person.cars].filter((i) => i.status !== 3)"
              :key="carIndex"
              class="mt-6"
            >
              <template v-if="!hasChoice || (hasChoice && car.chosen)">
                <v-expansion-panels flat multiple accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div class="d-flex justify-space-between align-center">
                        <label class="modal-label"
                          >{{ $t("car") }} {{ carIndex + 1 }}</label
                        >
                        <v-btn
                          @click="deleteCar(person, carIndex)"
                          text
                          class="modal-btn mr-8"
                          >{{ $t("delete") }}</v-btn
                        >
                      </div>
                      <div class="d-flex justify-space-between guest-row">
                        <v-text-field
                          @click.stop
                          @change="selectSendingGuest(person)"
                          :value="car.plate_number"
                          :label="$t('car_plate')"
                          @input="(e) => plateChangeHanlder(car, e)"
                          @blur="$forceUpdate()"
                          class="modal-input"
                          :rules="[
                            () =>
                              (car.plate_number && car.plate_number.length < 11) ||
                              $t('car_plate') + ' ' + $t('required'),
                          ]"
                        />
                        <v-select :no-data-text="$t('no_data')"            attach
                          @click.stop
                          :value="$t(`car_types[${car.type}]`)"
                          @change="(value) => (car.type = $t('car_types').indexOf(value))"
                          class="modal-input car-type-select"
                          :items="carTypes"
                          :label="$t('car_type')"
                        />
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div
                        class="d-flex flex-wrap flex-wrap justify-space-between"
                        :class="
                          $store.getters['app/hasZones'] ? 'guest-row-3' : 'guest-row'
                        "
                      >
                        <v-combobox
                          attach
                          v-model="car.manufacturer"
                          @change="selectSendingGuest(person)"
                          class="modal-input"
                          :items="getCarBrands(car.manufacturer)"
                          :label="$t('car_brand')"
                          :rules="[
                            () => !!car.manufacturer || $t('car_brand') + $t('required'),
                          ]"
                        />
                        <v-select :no-data-text="$t('no_data')"            attach
                          :value="$t(`engine_types[${car.engine_type - 1}]`)"
                          @change="
                            (value) => (car.type = $t('engine_types').indexOf(value) + 1)
                          "
                          @blur="$forceUpdate()"
                          class="modal-input car-type-select"
                          :items="$t(`engine_types`)"
                          :label="$t('engine')"
                        />
                        <v-select :no-data-text="$t('no_data')"            v-if="$store.getters['app/hasZones']"
                          attach
                          @change="selectSendingGuest(person)"
                          multiple
                          v-model="car.zones"
                          class="modal-input car-type-select"
                          :items="zones"
                          item-text="zone_name"
                          item-value="zone_id"
                          :label="$t('zone')"
                        /></div
                    ></v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </div>
            <div
              class="d-flex justify-space-between mt-4"
              v-if="!hasChoice || (hasChoice && person.chosen)"
            >
              <v-btn @click="addCar(person)" text class="flat-btn modal-footer-btn mb-4"
                >+ {{ $t("add") }} {{ $t("car") }}</v-btn
              >
            </div>
          </div>
        </div>
      </v-form>

      <template v-if="step < 5 && !tree">
        <div class="d-flex align-center justify-end mt-6">
          <v-btn
            @click="back"
            v-if="step !== 0"
            text
            class="flat-btn modal-footer-btn mr-6"
            >{{ $t("back") }}</v-btn
          >
          <v-btn @click="next" text class="main-btn px-6 py-2">{{ $t("next") }}</v-btn>
        </div></template
      >

      <!-- DONE -->
      <Done v-if="step === 5" />
    </template>

    <template v-else>
      <GuestTree
        :show="tree"
        :checkTab="isCheckTab"
        :group="group"
        :step="step"
        :sending="sending"
        @clickedSend="sendGuest"
        @changeSelected="changeSelected"
      />
    </template>
  </div>
</template>

<script>
import carBrandsDb from "@/assets/cars/carModels.json";
import { base } from "@/axios-api";
import codes from "@/assets/countryCodes.json";
import { formatPlate } from "../../../common/helpers/helpers";

const defaultGroup = {
  group_name: "",
  chosen: true,
  is_simple: false,
  subgroups: [
    {
      chosen: true,
      group_name: "",
      guests: [
        {
          chosen: true,
          guest_name: "",
          extra_info: {},
          subscription: {
            active_from: "",
            active_until: "",
          },
          secondary_emails: [],
          secondary_phones: [],
          phone: "",
          country_code: "+852",
          cars: [
            {
              manufacturer: "N/A",
              chosen: true,
            },
          ],
        },
      ],
    },
  ],
};

export default {
  name: "Flow",
  data() {
    return {
      tree: false,
      step: 0,
      sending: false,
      changed: false,
      changedGroup: false,
      changedSubgroup: null,
      hasChoice: false,
      group: JSON.parse(JSON.stringify(defaultGroup)),
      busyGroup: false,
      busySubgroup: false,
      forced: false,
      file: "",
      countryCodes: codes,
    };
  },
  components: {
    GuestTree: () => import("@/components/YourParking/Cyberport/GuestTree"),
    Group: () => import("@/components/YourParking/Cyberport/Group"),
    Done: () => import("@/components/YourParking/Cyberport/Done"),
  },
  computed: {
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    title() {
      return this.formType === 0
        ? this.$t("add") + " " + this.$t("guest")
        : this.$t("change") + " " + this.$t("guest");
    },
    name() {
      let name = "";
      if (this.isCompany && this.$store.state.app.user.company_name) {
        name = this.$store.state.app.user.company_name;
      } else {
        name = this.places[0] ? this.places[0].place_name : "";
      }
      return name;
    },
    stateGuest() {
      return this.$store.state.guests.current;
    },
    groups() {
      return [...this.$store.state.guests.groups];
    },
    subgroups() {
      return [...this.$store.state.guests.subgroups];
    },
    formType() {
      return (
        Number(!!this.stateGuest && !!this.stateGuest.group_id) ||
        Number(this.stateGuest && this.stateGuest.group_name === "N/A")
      );
    },
    status() {
      return this.$store.state.guests.status;
    },
    guestTab() {
      return this.$store.state.guests.guest_tab;
    },
    isCheckTab() {
      return this.step === 4;
    },
    places() {
      return [...this.$store.state.app.places];
    },
    currentPlace() {
      return this.$store.state.guests.place || this.places[0].place_id;
    },
    steps() {
      let init = [...this.$t("form_steps")];

      init.splice(1, 1); //remove subgroups
      return init;
    },
    zones() {
      return [...this.$store.state.app.zones];
    },
    carTypes() {
      return [...this.$store.state.app.car_types].map((id) =>
        this.$t(`car_types[${id}]`)
      );
    },
    carColors() {
      return [
        "ordinary red",
        "white",
        "blue",
        "grey",
        "orange",
        "black",
        "dark red",
        "light blue",
        "yellow",
        "light green",
        "dark blue",
        "light yellow",
        "storm grey",
        "brown",
        "ordinary tan",
      ];
    },
    form() {
      switch (this.step) {
        case 0:
          return "group";
        case 1:
          return "guest";
        case 2:
          return "car";
        case 3:
          return "check";
      }
      return "";
    },
  },
  watch: {
    guestTab: {
      deep: true,
      handler(val) {
        this.toStep(val);
        if (val !== 5) {
          this.$store.commit("guests/reset_status", undefined);
        }
      },
    },
  },
  mounted() {
    this.parseGuest(this.stateGuest);
  },
  methods: {
    // AUXILARY & FORMATTING METHODS
    excelToJsDate(excel) {
      // console.log(excel);
      let date = new Date(Math.round((excel - 25569) * 86400 * 1000));
      return this.$moment(date).format("YYYY-MM-DD");
    },
    replaceFields(array) {
      let result = [];
      array.map((o) => {
        let newOb = {};
        for (const [key, value] of Object.entries(o)) {
          newOb[this.fields[key]] = value;
        }
        result.push(newOb);
      });
      return result;
    },
    getCarBrands(value) {
      if (value && carBrandsDb[value] && value !== "N/A") {
        return carBrandsDb[value].map((brand) => `${value} ${brand}`);
      } else {
        return Object.keys(carBrandsDb);
      }
    },
    phoneRules(value) {
      if (!value || value === "" || isNaN(value)) {
        return true;
      }
      return this.$t("phone_err");
    },
    plateFormatter(value) {
      if (!value) return "";      
      return formatPlate(value);
    },
    timeChangeHandler(person, value, type) {
      let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      let date = this.$moment(new Date(value.replace(pattern, "$3-$2-$1"))).format(
        "YYYY-MM-DD"
      );
      this.changed = true;
      person.selected = true;
      person.subscription[type] = date;
      this.$forceUpdate();
    },

    customSubgFilter(item, queryText) {
      return (
        item.group_name.toLowerCase().includes(queryText.toLowerCase()) &&
        item.parent_id === this.group.group_id
      );
    },

    //CHANGE HANDLER
    toStep(value) {
      this.step = value;
      // console.log("to step " + value);
      if (value === 3) {
        this.tree = true;
      } else if (value === 4) {
        this.tree = false;
      }
    },
    groupChangedHandler(event) {
      this.group.group_name = event;
      this.changed = true;
      this.changedGroup = true;
      this.group.subgroups.map((d) => {
        d.guests.map((g) => {
          g.selected = true;
        });
      });
    },
    subgroupChangeHandler(subgroup, value) {
      this.changed = true;
      subgroup.group_name = value.group_name || value;
      subgroup.guests.map((g) => {
        g.selected = true;
      });
    },
    selectSendingGuest(guest) {
      guest.selected = true;
      this.changed = true;
    },
    plateChangeHanlder(car, newPlate) {
      car.plate_number = this.plateFormatter(newPlate);
    },

    // TREE HANDLER
    changeSelected(chosen) {
      this.hasChoice = true;
      for (let key of Object.keys(chosen)) {
        if (chosen[key].length ) {
          let tab = Object.keys(chosen).indexOf(key);

          this.$store.commit("guests/set_guest_tab", tab);
          this.toStep(tab);
          this.tree = false;
          break;
        }
      }
    },
    treeHandler() {
      if (this.isCheckTab) {
        this.tree = false;
      } else {
        this.tree = !this.tree;
      }
    },
    // ROUTE METHODS
    next() {
      switch (this.step) {
        case 0:
          this.getGroup();
          this.$store.commit("guests/set_guest_tab", 1);
          break;
        case 1:
          if (this.$refs.guest.validate()) {
            this.$store.commit("guests/set_guest_tab", 2);
          } else {
            this.$refs.guest.validate();
          }
          break;
        case 2:
          if (this.$refs.car.validate()) {
            this.$store.commit("guests/set_guest_tab", 3);
            this.tree = true;
          } else {
            this.$refs.car.validate();
          }
          break;
        default:
          this.sendGuest();
          break;
      }
    },
    back() {
      if (this.step ) {
        this.step--;
        this.$store.commit("guests/set_guest_tab", this.step);
      }
    },
    backToParking() {
      this.$router.push("/platform/your-parking");
    },

    // GROUP METHODS
    updateGroup(group) {
      this.busyGroup = true;
      let data = {
        groups: [
          {
            ...group,
            place_id: this.currentPlace,
          },
        ],
      };
      base({
        url: "/groups",
        method: "PUT",
        data,
      }).then((r) => {
        if (r.status === 200) {
          this.busyGroup = false;
        }
      });
    },
    getGroup() {
      let group = { ...this.group };
      let target =
        [...this.groups].filter((i) => i.group_name === group.group_name)[0] || {};
      this.group = {
        ...this.group,
        ...target,
      };
      if (!this.group.group_id) {
        this.postGroup(this.group, "Group");
      } else if (!target.group_id && this.group.group_id) {
        this.updateGroup(group);
      }
    },

    postGroup(current, type) {
      let param = "busy" + type;
      let data = {
        groups: [
          {
            ...current,
            place_id: this.currentPlace,
          },
        ],
      };
      this[param] = true;
      base({
        url: "/groups",
        method: "POST",
        data,
      }).then((r) => {
        if (r.status === 200) {
          current.group_id = r.data.body[0].group_id;
          current.parent_id = r.data.body[0].parent_id;
          this[param] = false;
        }
      });
    },
    updateSubgroup(current) {
      let data = {
        groups: [
          {
            ...current,
            place_id: this.currentPlace,
          },
        ],
      };
      this.busySubgroup = true;
      base({
        url: "/groups",
        method: "PUT",
        data,
      }).then((r) => {
        if (r.status === 200) {
          this.busySubgroup = false;
        }
      });
    },

    // ADD METHODS
    addPerson(guests) {
      this.changed = true;
      guests.push({
        subscription: {},
        secondary_emails: [],
        secondary_phones: [],
        country_code: "+852",
        phone: "",
        cars: [
          {
            chosen: true,
          },
        ],
      });
    },
    addCar(person) {
      this.changed === true;
      person.cars.push({ chosen: true });
      this.$forceUpdate();
    },
    addEmail(person) {
      person.secondary_emails.push("");
    },
    addPhone(person) {
      person.secondary_phones.push("");
    },

    // DELETE METHODS
    deleteGroup() {
      this.$confirm({
        message: this.$t("group_delete_propmpt", {
          group: this.group.group_name,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            if (this.formType === 1) {
              this.$store.dispatch("guests/deleteGroup", {
                group: this.group.group_id,
                notify: true,
              });
              this.group.subgroups.map((subg, index) => {
                this.dispatchDelSubgroup(index);
              });
              this.backToParking();
            }
          }
        },
      });
    },
    dispatchDelSubgroup(i, notify) {
      if (this.group.subgroups[i].group_id) {
        this.$store.dispatch("guests/deleteGroup", {
          group: this.group.subgroups[i].group_id,
          notify,
        });
      }
      if (this.group.subgroups[i].guests) {
        this.group.subgroups[i].guests.map((guest) => {
          this.$store.dispatch("guests/deleteGuest", {
            guest,
            notify: false,
            forced: false,
          });
        });
      }
    },
    deleteSubgroup(subgroup) {
      this.$confirm({
        message: this.$t("subgroup_delete_prompt", {
          subgroup: this.group.subgroups[subgroup].group_name,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            if (this.formType === 1) {
              this.dispatchDelSubgroup(subgroup, true);
            }
            this.group.subgroups.splice(subgroup, 1);
          }
        },
      });
    },
    deleteGuestReq(guests, guest, index) {
      if (guest.guest_id) {
        this.$store.dispatch("guests/deleteGuest", {
          guest,
          notify: true,
          forced: false,
        });
        this.backToParking();
      } else {
        guests.splice(index, 1);
      }
    },
    deletePerson(guests, guest) {
      let target = guests.filter((g) => g.guest_id === guest.guest_id)[0];
      let index = guests.indexOf(target);

      this.$confirm({
        message: this.$t("guest_delete_prompt", {
          guest: target.first_name,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteGuestReq(guests, guest, index);
          }
        },
      });
    },
    deleteCar(person, car) {
      if (!person.cars[car].id) {
        person.cars.splice(car, 1);
        return;
      }
      this.changed = true;
      this.$confirm({
        message: this.$t("car_delete_prompt", {
          car: person.cars[car].plate_number,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("guests/deleteVehicle", person.cars[car].id);
          }
        },
      });
    },

    reformatGuests() {
      let group = JSON.parse(JSON.stringify(this.group));
      let guests = [];
      group.subgroups.map((subgroup) => {
        subgroup.guests.map((guest) => {
          guest.secondary_emails = guest.secondary_emails.filter((i) => i !== "");
          guest.secondary_phones = guest.secondary_phones.filter((i) => i !== "");

          guests.push({
            ...guest,
            cars: guest.cars.map((car) => {
              return {
                ...car,
              };
            }),
            subscription: {
              ...guest.subscription,
              active_until:
                guest.subscription.active_until &&
                guest.subscription.active_until !==
                  this.$moment().startOf("day").format("YYYY-MM-DD")
                  ? this.$moment(guest.subscription.active_until).toISOString()
                  : "2022-06-11T00:00:00.000Z",
              active_from: this.$moment(guest.subscription.active_from).toISOString(),
            },
            group_id: subgroup.group_id || this.group.group_id,
            place_id: this.currentPlace,
          });
        });
      });
      return guests;
    },
    parseGuest(group) {
      if (this.formType === 1) {
        this.group = group;
        this.$store.commit("guests/set_current_place", group.place_id);
      }
      let tab = this.guestTab;
      if (this.formType === 0) {
        this.tree = false;
      } else if (tab !== 0) {
        this.tree = false;
        this.hasChoice = true;
        this.toStep(tab);
      } else {
        this.tree = true;
        this.step = 0;
      }
      return group;
    },
    validateForms() {
      let invalid = [];
      let forms = Object.keys(this.$refs);
      forms.map((ref, index) => {
        if (
          this.$refs[ref] &&
          !(ref === "carsUploader" || ref === "guestsUploader") &&
          typeof this.$refs[ref] !== "object"
        ) {
          this.$refs[ref].validate();
          if (this.$refs[ref].validate() === false && ref !== "subgroup") {
            invalid.push(index);
          }
        }
      });
      return invalid;
    },
    sendGuest() {
      let watchBusy = setInterval(() => {
        if (!this.busyGroup && !this.busySubgroup) {
          clearInterval(watchBusy);

          if (this.validateForms().length === 0) {
            this.sending = true;
            let guests = this.reformatGuests();
            let newGuests = [];
            let changedGuests = [];
            guests.map((guest) => {
              if (guest.guest_id && guest.selected === true) {
                changedGuests.push(guest);
              } else if (!guest.guest_id) {
                newGuests.push(guest);
              }
            });
            if (newGuests.length ) {
              this.$store.dispatch("guests/addGuest", newGuests);
            }
            if (changedGuests.length ) {
              this.$store.dispatch("guests/changeGuest", {
                guests: changedGuests,
                forced: this.forced,
              });
            }
            if (newGuests.length === 0 && changedGuests.length === 0) {
              this.$store.commit("guests/set_guest_tab", 5);
              this.tree = false;
            }
            let watcher = setInterval(() => {
              if (this.status !== undefined) {
                // this.toStep(5);
                this.$store.commit("guests/set_guest_tab", 5);
                this.tree = false;
                clearInterval(watcher);
              }
            }, 500);
          } else {
            this.toStep(this.validateForms()[0]);
          }
        }
      }, 500);
    },
    resetGuest() {
      this.$store.dispatch("guests/getGuests");
      this.$store.commit("guests/set_current_group", null);
      this.$store.commit("guests/set_guest_tab", 0);
      this.step = 0;
      this.changed = false;
      this.changedGroup = false;
      this.changedSubgroup = null;
      this.hasChoice = false;
      this.tree = false;
      this.forced = false;
      this.group = JSON.parse(JSON.stringify(defaultGroup));
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.step < 5 && this.changed) {
      this.$confirm({
        message: this.$t("save_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("save"),
        },
        callback: (confirm) => {
          if (confirm) {
            if (this.step === 0 && this.changedGroup) {
              this.getGroup();
            } else if (this.step === 1 && this.changedSubgroup) {
              this.group.subgroupsforEach((subgroup) => {
                this.getSubgroup(subgroup);
              });
            }

            this.sendGuest();
            let watcher = setInterval(() => {
              if (this.status !== undefined) {
                this.resetGuest();
                next();
                clearInterval(watcher);
              }
            }, 500);
          } else {
            this.resetGuest();
            next();
          }
        },
      });
    } else {
      this.resetGuest();
      next();
    }
  },
};
</script>
