<template>
  <div class="tabs-content">
    <h3 class="main-title">{{ $t("subscriptions") }}</h3>
    <div class="my-6 d-flex flex-wrap flex-md-nowrap justify-start align-center">
      <v-tabs v-model="tab" class="ma-0 mr-6">
        <v-tab v-for="(item, index) in items" :key="index"
          >{{ item }} <template v-if="index === 1">({{ activeCount }})</template></v-tab
        >
      </v-tabs>
      <div class="d-flex justify-center ml-lg-0 my-lg-0">
        <CoreFilter
          ref="coreFilter"
          @filterSet="updFilters"
          @hideEmit="openFilterForm = false"
          @showEmit="openFilterForm = true"
          :hidden="{
            campaign: true,
            created_by: true,
            price_range: true,
            zone: true,
            status: true,
            pricing: true,
            wallet: true,
            car_type: true,
          }"
        />
        <!-- refresh btn -->
        <v-btn text class="mr-lg-auto dwl-btn" @click.stop="refresh">
          <img
            :style="{ height: '24px', width: '24px' }"
            src="@/assets/images/refresh.svg"
            alt="refresh"
          />
        </v-btn>
      </div>
    </div>
    <SubscriptionsTableCp
      v-if="isCyber"
      class="mb-5"
      :searchValue="search"
      :tab="tab"
      ref="table"
      :openForm="openForm"
    />
    <SubscriptionsTable
      v-else
      class="mb-5"
      :searchValue="search"
      :tab="tab"
      ref="table"
      :openForm="openForm"
    />
  </div>
</template>

<script>
export default {
  name: "Subscriptions",
  components: {
    SubscriptionsTable: () => import("@/components/Subscriptions/SubscriptionsTable"),
    SubscriptionsTableCp: () => import("@/components/Subscriptions/SubscriptionsTableCp"),
    // ReportDownloadForm: () => import("@/components/core/ReportDownloadForm"),
    CoreFilter: () => import("@/components/core/Filter"),
  },
  data() {
    return {
      tab: 5,
      openReportForm: false,
      openFilterForm: false,
      debouncedInput: "",
      timeout: null,
      updateTimer: null,
    };
  },
  mounted() {
    if (this.isCyber) this.tab = 6;
  },
  computed: {
    isCyber() {
      return this.$store.getters["app/isCyber"];
    },
    openForm() {
      return this.openReportForm || this.openFilterForm;
    },
    items() {
      if (this.isCyber) return this.$t("pass_status_cp");
      return this.$t("pass_status");
    },
    activeCount() {
      return this.$store.state.subscriptions.active_count;
    },
    search: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedInput = val;
          this.$refs.table.search(this.debouncedInput);
        }, 1000);
      },
    },
  },
  methods: {
    updFilters(filters) {
      this.$refs.table.filter(filters);
    },
    refresh() {
      this.$refs.table.updateData({ ...this.$refs.table.params });
    },
    openAddForm() {
      this.$refs.recordForm.show();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let watcher = setInterval(() => {
        if (vm.$store.state.app.places.length ) {
          //   vm.refresh();
          clearInterval(watcher);
        }
      }, 500);
      //   vm.updateTimer = setInterval(() => {
      //     if (
      //       vm.$store.getters["app/authorized"] &&
      //       vm.$refs.table &&
      //       document.documentElement.scrollTop === 0
      //     ) {
      //       vm.refresh();
      //     }
      //   }, 60000);
    });
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.updateTimer);
    this.updateTimer = null;
    next();
  },
};
</script>
