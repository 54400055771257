<template>
  <div class="platform" v-cloak>
    <LocaleSwitcher />

    <div class="d-flex justify-space-between">
      <Drawer ref="drawer" @drawerMini="drawerMiniHandler" />
      <div class="platform-content" v-cloak :class="{ extended: mini === true }">
        <div class="d-flex justify-space-between platform-header">
          <h1 class="main-title" style="font-size: 1.2em">{{ name }}</h1>
          <User />
        </div>
        <v-divider />
        <router-view class="tabs-content" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Promo",
  components: {
    User: () => import("@/components/core/User"),
    LocaleSwitcher: () => import("@/components/core/LocaleSwitcher"),
    Drawer: () => import(`@/components/Promo/Drawer`),
  },
  data() {
    return { mini: false };
  },

  computed: {
    place() {
      return this.$store.state.app.places[0] || {};
    },
    name() {
      let name = "";
      if (this.isCompany && this.$store.state.app.user.company_name) {
        name = this.$store.state.app.user.company_name;
      } else {
        name = this.place.place_name;
      }
      return name;
    },
  },
  methods: {
    drawerMiniHandler(value) {
      this.mini = value;
    },
  },
};
</script>
