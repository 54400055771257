<template>
  <div class="record-popup popup notif-popup" v-if="shown">
    <div class="modal-overlay" @click="hide" v-if="shown" />
    <v-menu
      attach
      :value="true"
      :close-on-content-click="false"
      :close-on-click="false"
      content-class="core-filter modal-content"
    >
      <div class="mb-4 d-flex justify-space-between">
        <h3 class="main-title">{{ $t("settings") }}</h3>
        <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
      </div>

      <v-form>
        <div class="d-flex align-center justify-space-between">
          <h4 class="modal-label">{{ $t("auto_refresh") }}</h4>
          <v-switch class="parking-switch mt-0 pt-0" v-model="settings.auto_refresh" />
        </div>
        <div class="d-flex flex-wrap align-center">
          <v-chip-group active-class="active" column v-model="settings.interval">
            <v-chip v-for="i in intervals" :key="i.id">
              <template v-if="i.duration > 60"
                >{{ i.duration / 60 }} {{ $t("minutes") }}</template
              >
              <template v-else>{{ i.duration }} {{ $t("seconds") }}</template>
            </v-chip>
          </v-chip-group>
        </div>
        <div class="d-flex align-center justify-space-between">
          <h4 class="modal-label">{{ $t("allow_floating") }}</h4>
          <v-switch class="parking-switch mt-0 pt-0" v-model="settings.allow_floating" />
        </div>
        <div class="d-flex align-center justify-space-between">
          <h4 class="modal-label">{{ $t("allow_sound") }}</h4>
          <v-switch class="parking-switch mt-0 pt-0" v-model="settings.allow_sound" />
        </div>
        <div class="modal-footer d-flex align-center px-0 mt-4">
          <v-btn class="main-btn py-3 px-4" @click="confirm">{{ $t("confirm") }}</v-btn>
          <v-btn text class="flat-btn modal-footer-btn" @click="hide">{{
            $t("cancel")
          }}</v-btn>
        </div>
      </v-form>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "Settings",
  props: {},
  data() {
    return {
      shown: false,
      settings: {
        auto_refresh: false,
        interval: null,
        allow_floating: false,
        allow_sound: false,
      },
    };
  },
  computed: {
    intervals() {
      return [
        {
          id: 1,
          duration: 15,
        },
        {
          id: 2,
          duration: 30,
        },
        {
          id: 3,
          duration: 60,
        },
        {
          id: 4,
          duration: 300,
        },
        {
          id: 5,
          duration: 600,
        },
      ];
    },
  },
  methods: {
    show() {
      this.shown = true;
      document.body.style = "height: 100vh; overflow: hidden";
      this.$emit("show");
    },
    hide() {
      this.shown = false;
      document.body.style = "height: auto; overflow: auto";
      this.$emit("hide");
    },
    confirm() {
      this.hide();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
</style>
