<template>
  <div>
    <h3 class="main-title">{{ $t("promocodes_history") }}</h3>
    <div class="d-flex justify-start align-center my-4">
      <v-text-field
        autocomplete="off"
        v-model="search"
        class="parking-search ma-0 mr-4"
        solo
        :placeholder="$t('search_promo')"
      ></v-text-field>
      <CoreFilter
        ref="coreFilter"
        @filterSet="updFilters"
        @filterReset="resetFilters"
        :hidden="{
          car_type: true,
          pricing: true,
          zone: true,
          status: true,
          wallet: true,
        }"
      />
      <ReportDownloadForm ref="modal" view="promocodes" />
    </div>
    <CodeHistoryTable :searchValue="search" :filters="filters" ref="table" />
  </div>
</template>

<script>
import "@/scss/_modal.scss";

export default {
  name: "PromoHistory",
  components: {
    CoreFilter: () => import("@/components/core/Filter"),
    ReportDownloadForm: () => import("@/components/core/ReportDownloadForm"),
    CodeHistoryTable: () => import("@/components/Promo/CodeHistoryTable"),
  },
  mounted() {},
  data() {
    return {
      search: "",
      filters: {
        dates: [],
        campaigns: [],
        price_range: [],
        created_by: [],
      },
    };
  },
  methods: {
    updFilters(filters) {
      this.filters = filters;
    },
    resetFilters() {
      this.filters = {
        dates: [],
        campaigns: [],
        price_range: [],
        created_by: [],
      };
    },
  },
};
</script>
