// import Vue from 'vue'
import i18n from '@/plugins/i18n';
import actions from './actions'
import EventBus from '@/event-bus'

const state = () => ({
    stream_sources: [],
    place: 0,
    gate_reasons: []
});

// getters
const getters = {};

// mutations
const mutations = {

    upd_stream_sources(state, payload) {
        let stream_sources = payload.sort((a, b) => a.camera_position - b.camera_position || a.camera_id.localeCompare(b.camera_id))
        for (let i = 0; i < stream_sources.length; i++) {
            for (let j = 0; j < state.stream_sources.length; j++) {
                if (stream_sources[i].camera_id === state.stream_sources[j].camera_id) {
                    stream_sources[i].src = state.stream_sources[i].src
                }
            }
        }
        state.stream_sources = stream_sources
    },
    open_gate(state, status) {
        if (status === true) {
            EventBus
                .$emit("notify", {
                    group: 'general',
                    title: 'Success!',
                    text: i18n.t('gate_open_ntf'),
                    duration: 3000
                })
        } else {
            EventBus
                .$emit("notify", {
                    group: 'general',
                    title: i18n.t('error'),
                    text: '',
                    duration: 3000
                })
        }
        return state;
    },
    close_gate(state, status) {
        if (status === true) {
            EventBus
              .$emit("notify", {
                  group: 'general',
                  title: 'Success!',
                  text: i18n.t('gate_close_ntf'),
                  duration: 3000
              })
        } else {
            EventBus
              .$emit("notify", {
                  group: 'general',
                  title: i18n.t('error'),
                  text: '',
                  duration: 3000
              })
        }
        return state;
    },
    set_stream_place(state, place) {
        state.place = place
    },
    get_gate_reasons(state, data) {
        state.gate_reasons = data
    },
    logout(state) {
        state.stream_sources = []
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
