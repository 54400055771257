import { cyberport, base, monthly } from '@/axios-api'
import EventBus from '../../../event-bus';
import i18n from '@/plugins/i18n';
import qs from 'qs'
import store from '@/store/index';

export default {
    getList({ commit, dispatch }) {
        if (store.getters['app/isCyber']) dispatch('getListCyber')
        else dispatch('getListDefault')
        return commit
    },
    getListCyber({
        commit,
        dispatch
    }, ) {
        cyberport({
                url: `/platform/subscriptions`,
                method: 'GET'
            })
            .then((response) => {
                if (response.status === 200) {
                    let history = response.data.body.subscriptions || []
                    history = history.sort((a, b) => new Date(b.active_from).getTime() - new Date(a.active_from).getTime())
                        // console.log(history)
                    commit('set_list', history);
                }
            })
            .catch((err) => {
                console.log(err.response)
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getListDefault({
        commit,
        dispatch
    }, ) {
        commit("set_loader", true)
        dispatch("getActiveCount")
        let place_id = store.state.app.places.map(p => p.place_id)
        base({
                url: `/guests/monthly_subscriptions`,
                method: 'GET',
                params: {
                    place_id
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let users = response.data.body || []
                    commit('set_list', users);
                }
            })
            .catch((err) => {
                console.log(err.response)
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    processPass({
        commit,
        dispatch
    }, data) {

        cyberport({
                url: `platform/subscriptions/${data.subscription.id}`,
                method: 'PUT',
                data
            })
            .then((response) => {
                if (response.status === 200) {

                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('pass_updated'),
                        duration: 3000
                    })
                    dispatch("getList")
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response)
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    listForCustomerSVC({
        commit,
        dispatch
    }, params) {
        cyberport({
                url: `/platform/customersvc_subscriptions`,
                method: 'GET',
                params: {
                    filter: params.filter || ""
                }
            })
            .then((response) => {
                if (response.status === 200) {
                    let history = response.data.body || []
                    history = history.sort((a, b) => new Date(b.active_from).getTime() - new Date(a.active_from).getTime())
                        // console.log(history)
                    commit('set_customer_svc', history);
                }
            })
            .catch((err) => {
                console.log(err.response)
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    paySubscription({
        commit,
        dispatch
    }, data) {
        cyberport({
                url: `/platform/customersvc_subscriptions/pay`,
                method: 'POST',
                data
            })
            .then((response) => {
                if (response.status === 200) {

                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('pass_paid'),
                        duration: 3000
                    })
                    dispatch("getList")
                    dispatch("listForCustomerSVC", {})
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response)
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getActiveCount({ commit }, ) {
        let place_id = store.state.app.places.map(p => p.place_id)
        monthly({
                url: `/active_count`,
                method: 'GET',
                params: {
                    place_id
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let payload = response.data.body.count
                    commit('set_active_count', payload);
                }
            })
    },
    updateSubscription({ commit, dispatch }, data) {
        base({
                url: `/guests/subscription_status`,
                method: 'PUT',
                data
            })
            .then((response) => {
                if (response.status === 200) {

                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('pass_updated'),
                        duration: 3000
                    })
                    dispatch("getList")
                    return commit
                }
            })
    }
}