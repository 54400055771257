<template>
  <div class="popup record-popup">
    <div class="modal-overlay" @click="hide" />
    <div class="modal-content">
      <h4 class="main-title">{{ $t("spaces_reserved") }}</h4>

      <v-text-field
        type="number"
        min="0"
        v-model.number="space_count"
        :label="$t(`spaces`)"
        class="modal-input"
      />
      <div class="d-flex mt-6 justify-space-between">
        <v-btn class="main-btn px-6 py-2" text @click="setSpaces">{{ $t(`save`) }}</v-btn>
        <v-btn class="flat-btn my-0" text @click="hide">{{ $t(`cancel`) }}</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpacesPopup",
  props: {
    place_id: {
      default: 0,
    },
  },
  data() {
    return {
      space_count: null,
    };
  },

  computed: {
    reservedSpaces() {
      return this.$store.state.app.reserved_spaces;
    },
  },
  watch: {
    reservedSpaces(newValue) {
      this.space_count = JSON.parse(JSON.stringify(newValue));
    },
  },
  mounted() {
    this.$store.dispatch("app/getVacancyRestrictions");
    this.space_count = JSON.parse(JSON.stringify(this.reservedSpaces));
  },
  methods: {
    setSpaces() {
      this.$store.dispatch("app/setVacancyRestrictions", {
        space_count: this.space_count,
        place_id: this.place_id,
      });
      this.hide();
    },
    hide() {
      this.$emit("hide");
    },
  },
};
</script>

<style></style>
