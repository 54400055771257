<template>
  <div>
    <v-skeleton-loader v-if="loading" :loading="true" type="table"></v-skeleton-loader>
    <v-data-table
      v-else
      :headers="headers"
      :items-per-page="-1"
      :items="data"
      class="table mb-12 promo-history-table"
      :custom-sort="customSort"
      hide-default-footer
      mobile-breakpoint="0"
      :no-data-text="$t('no_data')"
      :expanded="expanded"
      @click:row="expand"
      :singleExpand="true"
    >
      <template v-slot:[`item.car_plate`]="{ item }">
        <h4>{{ item.vehicle.plate_number }}</h4>
      </template>
      <template v-slot:[`item.promo_name`]="{ item }">
        <h4 class="pr-4">{{ item.campaign.campaign_name }}</h4>
      </template>
      <template v-slot:[`item.used_at`]="{ item }">
        {{ getFormattedDate(item.vehicle.used_at) }}
      </template>
      <template v-slot:[`item.created_by`]="{ item }">
        {{ item.campaign.created_by }}
      </template>
      <template v-slot:[`item.spent`]="{ item }">
        {{ currency }}{{ getSpendings(item) }}
      </template>
      <template v-slot:[`item.free_hours`]="{ item }">
        <div class="d-flex align-center justify-center">
          <span
            class="text-center"
            style="width: 100px"
            v-if="item.vehicle.discount_option === 'all_free'"
            >{{ $t("parking_free") }}
          </span>
          <span class="text-center" style="width: 100px" v-else
            >{{ item.vehicle.promo_minutes / 60 }}
          </span>

          <v-btn v-if="hasPromoAccess" text class="flat-btn" @click="editHours(item)">{{
            $t("edit")
          }}</v-btn>
        </div>
      </template>
      <template v-slot:[`item.remark`]="{ item }">
        <span v-if="item.campaign.custom_params">{{
          item.campaign.custom_params.remark
        }}</span>
      </template>
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0" v-if="item.expanded === true">
          <v-data-table
            :colspan="headers.length"
            :headers="expandedHeaders"
            :items-per-page="-1"
            :items="item.spendings"
            class="table expanded-table"
            hide-default-footer
            mobile-breakpoint="0"
          >
            <template v-slot:[`item.car_plate`]="{ item }">
              <h4>{{ item.noText }}</h4>
            </template>
            <template v-slot:[`item.promo_name`]="{ item }">
              <h4>{{ item.noText }}</h4>
            </template>
            <template v-slot:[`item.used_at`]="{ item }">
              {{ item.noText }}
            </template>
            <template v-slot:[`item.store_name`]="{ item }">
              {{ item.store_name }}
            </template>
            <template v-slot:[`item.spent`]="{ item }">
              {{ currency }}{{ item.amount / item.factor }}
            </template>
            <template v-slot:[`item.free_hours`]="{ item }">
              <template v-if="item.receipt_url">
                <div class="receipt-img hoverable" @click="viewReceipt(item)">
                  <div class="overlay" />
                  <span class="mr-0">{{ $t("click_to_view") }}</span>
                  <img :src="item.receipt_url" style="width: 100px; height: 100px" /></div
              ></template>
              <span v-else>N/A</span>
            </template>
            <template v-slot:[`item.remark`]="{ item }">
              <span>{{ item.noText }}</span>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <div class="popup record-popup" v-if="popup">
      <div class="modal-overlay" @click="popup = false" />
      <div class="core-filter modal-content d-flex justify-center">
        <img style="max-width: 320px; border-radius: 16px" :src="selected.receipt_url" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PromoHistoryTable",
  props: {
    searchValue: {
      type: String,
      default: "",
    },
    filters: {
      dates: [],
    },
  },
  data() {
    return {
      data: [],
      counter: 1,
      itemsPerPage: 25,
      expanded: [],
      //RECEIPTS
      popup: false,
      selected: {},
    };
  },
  computed: {
    currency() {
      return this.$store.getters["app/currency"];
    },
    hasPromoAccess() {
      return (
        (this.$store.getters["app/hasPromoAccess"] &&
          !this.$store.getters["app/isReadonly"]) ||
        (this.$store.getters["app/isReadonly"] &&
          this.$store.state.app.user.permissions.promo)
      );
    },
    isAdmin() {
      return this.$store.getters["app/isAdmin"];
    },
    isGuard() {
      return this.$store.state.app.user.role_id === 3;
    },
    headers() {
      return [
        {
          text: this.$t("car_plate"),
          value: "car_plate",
          class: "table-header",
        },
        {
          text: this.$t("campaign_name"),
          value: "promo_name",
          class: "table-header",
        },
        {
          text: this.$t("used_at"),
          value: "used_at",
          class: "table-header",
        },
        {
          text: this.$t("created_by"),
          value: "created_by",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("spent_amount"),
          value: "spent",
          class: "table-header",
        },
        {
          text: this.$t("free_hours"),
          value: "free_hours",
          class: "table-header",
        },
        {
          text: this.$t("remark"),
          value: "remark",
          class: "table-header",
          sortable: false,
        },
      ];
    },
    expandedHeaders() {
      return [
        {
          text: "",
          value: "car_plate",
          sortable: false,
          class: "table-header",
        },
        {
          text: "",
          value: "promo_name",
          sortable: false,
          class: "table-header",
        },
        {
          text: "",
          value: "used_at",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("store_name"),
          value: "store_name",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("spent_amount"),
          value: "spent",
          class: "table-header",
        },
        {
          text: this.$t("receipt"),
          value: "free_hours",
          sortable: false,
          class: "table-header",
        },
        {
          text: "",
          value: "remark",
          sortable: false,
          class: "table-header",
        },
      ];
    },
    currentPlace() {
      return this.$store.state.promo.place;
    },
    records() {
      let list = [];
      let history = [...this.$store.state.promo.history];
      if (this.currentPlace) {
        list = history.filter((h) => h.place_id === this.currentPlace)[0].records;
      } else {
        history.map((h) => {
          list = list.concat(h.records);
        });
      }
      return list;
    },
    recordsToShow() {
      return this.search(this.searchValue);
    },
    loading() {
      return this.$store.state.promo.loading_history;
    },
  },
  mounted() {
    this.loadItems();
    this.scroll();
  },
  watch: {
    recordsToShow() {
      this.loadItems();
      this.scroll();
    },
  },
  methods: {
    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.ceil(document.documentElement.scrollTop) + window.innerHeight >=
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          this.loadMore();
        }
      };
    },
    search(value) {
      let newRecords = [...this.filter()];
      newRecords.map((i) => {
        i.show = false;

        let storeNames = [];
        if (i.spendings) {
          storeNames = i.spendings.map((s) => s.store_name.toLowerCase());
        }
        if (
          i.vehicle.plate_number.toLowerCase().includes(value.toLowerCase()) ||
          i.campaign.campaign_name.toLowerCase().includes(value.toLowerCase()) ||
          storeNames.includes(value.toLowerCase())
        ) {
          i.show = true;
        }
      });
      return newRecords.filter((i) => i.show);
    },
    loadItems() {
      if (this.recordsToShow.length <= this.itemsPerPage) {
        this.data = this.recordsToShow;
        return;
      }
      if (this.counter < parseInt(this.recordsToShow.length / this.itemsPerPage)) {
        this.data = this.recordsToShow.slice(0, this.counter * this.itemsPerPage);
      } else {
        this.data = this.recordsToShow;
      }
    },
    loadMore() {
      if (this.data.length >= this.recordsToShow.length) {
        return;
      }
      if (this.recordsToShow.length <= this.itemsPerPage) {
        this.data = this.recordsToShow;
        return;
      }
      if (this.counter >= parseInt(this.recordsToShow.length / this.itemsPerPage)) {
        this.data = this.recordsToShow;
        return;
      }
      this.counter++;
      this.data = this.recordsToShow.slice(0, this.counter * this.itemsPerPage);
      return;
    },
    getFormattedDate(date) {
      return date ? this.$moment(date).format("DD.MM.YYYY HH:mm") : "";
    },
    getSpendings(item) {
      let total = 0;
      if (item.spendings) {
        item.spendings.map((s) => {
          total += s.amount / s.factor;
        });
      }
      return total;
    },
    customSort(items, index, isDescending) {
      switch (index[0]) {
        case "promo_name":
          items.sort((a, b) => {
            if (isDescending[0] === true) {
              return b.campaign.campaign_name.localeCompare(a.campaign.campaign_name);
            } else {
              return a.campaign.campaign_name.localeCompare(b.campaign.campaign_name);
            }
          });
          return items;
        case "car_plate":
          items.sort((a, b) => {
            if (isDescending[0] === true) {
              return b.vehicle.plate_number.localeCompare(a.vehicle.plate_number);
            } else {
              return a.vehicle.plate_number.localeCompare(b.vehicle.plate_number);
            }
          });
          return items;
        case "used_at":
          items.sort((a, b) => {
            let date1 = this.$moment(a.vehicle.used_at);
            let date2 = this.$moment(b.vehicle.used_at);
            let duration;
            if (isDescending[0] === true) {
              duration = this.$moment.duration(date2.diff(date1));
              return duration.valueOf();
            } else {
              duration = this.$moment.duration(date1.diff(date2));
              return duration.valueOf();
            }
          });
          return items;
        case "free_hours":
          items.sort((a, b) => {
            if (isDescending[0] === true) {
              return b.vehicle.promo_minutes - a.vehicle.promo_minutes;
            }
            return a.vehicle.promo_minutes - b.vehicle.promo_minutes;
          });
          return items;
        case "spent":
          items.sort((a, b) => {
            if (isDescending[0] === true) {
              return this.getSpendings(b) - this.getSpendings(a);
            }
            return this.getSpendings(a) - this.getSpendings(b);
          });
          return items;
        default:
          return items;
      }
    },
    expand(value) {
      if (value.spendings) {
        this.data[this.data.indexOf(value)].expanded = !this.data[
          this.data.indexOf(value)
        ].expanded;
        const index = this.expanded.indexOf(value);
        if (index === -1) {
          this.expanded.push(value);
        } else {
          this.expanded.splice(index, 1);
        }
      }
    },

    editHours(value) {
      this.$store.commit("promo/set_current_record", value);
      this.$store.commit("promo/set_tab", 2);
      if (this.isAdmin || this.isGuard) this.$router.push("/platform/promo/promo-form");
      else this.$router.push("/promo/customer_service");
    },

    filter() {
      let records = [...this.records];
      let filters = this.filters;
      if (
        filters.dates.length === 0 &&
        filters.price_range.length === 0 &&
        filters.campaigns.length === 0 &&
        filters.created_by.length === 0
      ) {
        return records;
      } else {
        let fromDate = filters.dates[0]
          ? this.$moment(filters.dates[0])
          : this.$moment(new Date(0).toISOString());
        let toDate = filters.dates[1] ? this.$moment(filters.dates[1]) : this.$moment();
        records.forEach((record) => {
          record.hasPrice = false;
          record.hasDate = false;
          record.hasCampaign = false;
          record.hasCreator = false;

          record.hasPrice =
            this.getSpendings(record) >= filters.price_range[0] &&
            this.getSpendings(record) <= filters.price_range[1];

          if (filters.dates.length) {
            record.hasDate = this.$moment(record.vehicle.used_at).isBetween(
              fromDate,
              toDate
            );
          } else {
            record.hasDate = true;
          }

          if (filters.campaigns.length) {
            record.hasCampaign = filters.campaigns.includes(record.campaign.campaign_id);
          } else {
            record.hasCampaign = true;
          }

          if (filters.created_by.length) {
            record.hasCreator = filters.created_by.includes(record.campaign.created_by);
          } else {
            record.hasCreator = true;
          }
        });

        records = records.filter(
          (item) => item.hasPrice && item.hasDate && item.hasCampaign && item.hasCreator
        );
        return records;
      }
    },

    viewReceipt(rec) {
      this.selected = rec;
      this.popup = true;
    },
  },
};
</script>
