<template>
  <div class="guest-summary">
    <div class="summary-header">
      <h3 class="main-title pb-4">
          {{ $t('vip_car') }}
      </h3>
      <v-row class="mt-n6">
        <v-col>
          <div class="summary-subtitle">{{ $t('inside') }}</div>
          <v-skeleton-loader v-if="loading" type="text" max-width="50%" />
          <div class="summary-stat" v-else>{{ visitors.length }}</div>
        </v-col>
      </v-row>
    </div>

    <template v-if="loading">
      <div v-for="i in 5" :key="i" class="guest-record">
        <v-skeleton-loader type="heading" class="mb-2" />
        <v-skeleton-loader type="text" max-width="30%" />
      </div>
    </template>
    <div class="vip" v-if="!loading">
      <div
        v-for="visitor in visitors"
        :key="visitor.parking.parking_id"
        class="guest-record"
      >
        <h3 class="summary-title mr-4">
          {{ visitor.user.first_name }}
        </h3>
        <div class="streaming-subtitle">
          {{ visitor.car.plate_number }} {{ $moment(visitor.parking.enter_time).format("DD.MM.YYYY HH:mm") }}
        </div>
      </div>
    </div>
    <div class="summary-footer">
      <v-btn text class="flat-btn" @click="$router.push('/platform/parking-history')">{{
        $t("view_all")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import { base } from '@/axios-api';
import qs from 'qs';

export default {
  name: "VipGuests",
  data() {
    return {
      visitors: [],
      loading: true,
      interval: null,
    };
  },
  props: {
    place: {
      default: null,
    },
  },
  computed: {
    vipFilter() {
      return this.$store.state.dashboard.configuration[this.place]?.highlight_filter;
    },
  },
  methods: {
    getVisitors() {
      if (this.vipFilter) {
        base({
          url: '/history',
          method: "GET",
          params: {
            ...this.vipFilter,
            from: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(),
            to: new Date().toISOString(),
            sort_by: 3,
            sort_direction: 0,
          },
          paramsSerializer: params => {
            return qs.stringify(params, {
              arrayFormat: 'repeat',
              skipNulls: true,
            })
          },
        })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.body) {
                this.visitors = response.data.body;
              }
            }
          })
          .catch((err) => {
            console.log(err.response);
            if (err.response && err.response.status === 401) {
              this.$store.dispatch('app/logout', null, {
                root: true
              })
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  beforeMount() {
    this.getVisitors();
    this.interval = setInterval(this.getVisitors, 20 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  }
};
</script>
