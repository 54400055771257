import { cyberport, base } from '@/axios-api';
import store from '@/store/index';
import qs from 'qs';
import EventBus from '../../../event-bus';
import i18n from '@/plugins/i18n';

export default {
    getGuests({
        commit,
        dispatch
    }, parking_id) {
        if (store.getters['app/isCyber']) {
            dispatch("getGuestsCyberport", parking_id)
            return
        } else if (store.getters['app/hasTenantsFlow']) {
            console.log("i have tenants flow")
            let authWatcher = setInterval(() => {
                if (store.getters['app/pwaAuthorized'])
                    dispatch("tenants/getTenants", null, { root: true })
                clearInterval(authWatcher)

            }, 500)
        }
        dispatch("getGuestsDefault", parking_id)
        return commit
    },
    getGroups({
        commit,
        dispatch
    }, parking_id) {
        let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id)
        base({
                url: '/groups',
                method: 'GET',
                params: {
                    place_id
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body || [];
                    commit('get_groups_success', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getGuestsDefault({
        commit,
        dispatch
    }, parking_id) {
        commit('set_loader', true)
        let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id)
        base({
                url: '/guests',
                method: 'GET',
                params: {
                    place_id
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let guests = response.data.body || []
                    guests = guests.map(g => {
                        return {
                            ...g,
                            spaces: g.spaces || [],
                            cars: g.cars || [],
                            extra_info: g.extra_info || {}
                        }
                    })
                    commit('get_guests_success', guests);
                    dispatch('getGroups', place_id)
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },


    addGuest({
        commit,
        dispatch
    }, data) {
        if (store.getters['app/isCyber']) {
            dispatch("addGuestCyberport", data)
            return
        }
        dispatch("addGuestDefault", data)
        return commit
    },
    addGuestDefault({
        commit,
        dispatch
    }, newGuests) {
        let data = {
            guests: newGuests
        };
        base({
                url: '/guests',
                method: "POST",
                data
            })
            .then(response => {
                if (response.status == 200) {
                    if (response.data.code === 109 || response.data.code === 110) {
                        let guests = [...response.data.content].map(g => " " + g.first_name + " " + g.last_name)
                        commit('add_guest', {
                            value: false,
                            code: response.data.code,
                            guests
                        })
                    } else if (response.data.code === 121) {
                        let cars = response.data.message.split(": ")
                        cars = cars[1].split(", ").filter(i => i !== "")
                        commit('add_guest', {
                            value: false,
                            code: response.data.code,
                            cars
                        })
                    } else {
                        commit('add_guest', {
                            value: true,
                            code: response.data.code
                        })
                        dispatch('getGuests')
                    }
                    return response.data.content
                }
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
                if (err.response && err.response.status === 400) {
                    commit('change_guest_error', {
                        message: err.response.data.message,
                        type: "dateParsing"
                    })
                }
            });
    },

    changeGuest({
        commit,
        dispatch
    }, data) {
        if (store.getters['app/isCyber']) {
            dispatch("changeGuestCyberport", data)
            return
        }
        dispatch("changeGuestDefault", data)
        return commit
    },
    changeGuestDefault({
        commit,
        dispatch
    }, {
        guests,
        forced
    }) {
        console.log("sent guest")
        let data = {
            guests,
            forced
        };
        base({
                url: '/guests',
                method: 'PUT',
                data
            })
            .then(response => {
                if (response.status == 200) {
                    if (response.data.code === 107) {
                        let cars = [...response.data.body].map(c => " " + c.car_plate)
                        this._vm.$confirm({
                            message: i18n.t("guest_upd_prompt_parked", {
                                cars
                            }),
                            button: {
                                no: i18n.t("cancel"),
                                yes: i18n.t("save"),
                            },
                            callback: (confirm) => {
                                if (confirm) {
                                    dispatch("changeGuest", {
                                        guests,
                                        forced: true
                                    })
                                }
                            },
                        });
                    } else if (response.data.code === 121) {
                        let cars = response.data.body.split(", ").filter(i => i !== "")
                        commit('add_guest', {
                            value: false,
                            code: response.data.code,
                            cars
                        })
                        dispatch('getGuests')
                    } else {
                        commit('change_guest_success')
                        dispatch('getGuests')
                    }
                    return response.data.content
                }
            })
            .catch((err) => {
                console.log(err.response);
                console.log(err);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
                if (err.response && err.response.status === 400) {
                    commit('change_guest_error', {
                        message: err.response.data.message,
                        type: "dateParsing"
                    })
                }
                if (err.response && err.response.status === 500) {
                    commit('change_guest_error', {
                        message: err.response.data.message,
                        type: "existingPlate"
                    })
                }
                if (err.response.data.code === 704) {
                    commit('change_guest_error', {
                        message: err.response.data.message,
                        type: "currentParked"
                    })
                }
            });
    },


    deleteGuest({
        commit,
        dispatch
    }, parking_id) {
        if (store.getters['app/isCyber']) {
            dispatch("deleteGuestCyberport", parking_id)
            return
        }
        dispatch("deleteGuestDefault", parking_id)
        return commit
    },
    deleteGuestDefault({
        commit,
        dispatch
    }, {
        guest,
        notify,
        forced
    }) {
        base({
                url: '/guests/' + guest.guest_id,
                method: "DELETE",
                params: {
                    place_id: store.state.guests.place,
                    forced
                }
            })
            .then(response => {
                if (response.status == 200) {
                    if (response.data.code === 107) {
                        setTimeout(() => {
                            let cars = response.data.content ? [...response.data.content].map(c => " " + c.car_plate) : []

                            this._vm.$confirm({
                                message: i18n.t("guest_del_prompt_parked", {
                                    cars
                                }),
                                button: {
                                    no: i18n.t("cancel"),
                                    yes: i18n.t("delete"),
                                },
                                callback: (confirm) => {
                                    if (confirm) {
                                        dispatch("deleteGuest", {
                                            guest,
                                            notify,
                                            forced: true
                                        })
                                    }
                                },
                            });

                        }, 0)
                    } else {
                        if (notify) {
                            EventBus.$emit("notify", {
                                group: 'general',
                                title: 'Success!',
                                text: i18n.t('guest_deleted'),
                                duration: 3000
                            })
                        }
                    }
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (notify) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Oops!',
                        text: '',
                        duration: 3000
                    })
                }
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    deleteGroup({
        commit,
        dispatch
    }, {
        group,
        notify
    }) {
        base({
                url: `/groups/${group}`,
                method: "DELETE",
            })
            .then(response => {
                if (response.status == 200) {
                    commit('del_group_success', notify)
                    dispatch('getGuests')
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getTemplate({
        commit,
        dispatch
    }, type) {
        commit("app/set_downloader", true, {
            root: true
        })
        base({
                url: '/files/templates/' + type,
                method: 'GET',
                responseType: 'blob'
            })
            .then((response) => {
                if (response.status === 200) {
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', type + '.xlsx');
                    // fileLink.setAttribute('download', 'report' + name + dates + '.json');
                    document.body.appendChild(fileLink);

                    fileLink.click();
                    commit("app/set_downloader", false, {
                        root: true
                    })
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },

    //cyberport

    getGuestsCyberport({
        commit,
        dispatch
    }, parking_id) {
        commit('set_loader', true)
        let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id)
        cyberport({
                url: '/platform/guests',
                method: 'GET',
                params: {
                    place_id
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let guests = response.data.body || []
                    guests = guests.map(g => {
                        return {
                            ...g,
                            // spaces: g.spaces || [],
                            cars: g.cars || [],
                            extra_info: g.extra_info || {}
                        }
                    })
                    commit('get_guests_success', guests);
                    dispatch('getGroups', place_id)
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    addGuestCyberport({
        commit,
        dispatch
    }, newGuests) {
        let data = {
            guests: newGuests
        };
        cyberport({
                url: '/platform/guests',
                method: "POST",
                data
            })
            .then(response => {
                if (response.status == 200) {
                    commit('add_guest', {
                        value: true,
                        code: response.data.code
                    })
                    dispatch('getGuests')
                }
            })
            .catch((err) => {
                console.log(err);
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
                if (err.response && err.response.status === 400) {
                    commit('change_guest_error', {
                        message: err.response.data.message,
                        type: "dateParsing"
                    })
                }
            });
    },
    changeGuestCyberport({
        commit,
        dispatch
    }, {
        guests,
        forced
    }) {
        console.log("sent guest")
        let data = {
            guests,
            forced
        };
        cyberport({
                url: '/platform/guests',
                method: 'PUT',
                data
            })
            .then(response => {
                if (response.status == 200) {
                    if (response.data.code === 107) {
                        let cars = [...response.data.body].map(c => " " + c.car_plate)
                        this._vm.$confirm({
                            message: i18n.t("guest_upd_prompt_parked", {
                                cars
                            }),
                            button: {
                                no: i18n.t("cancel"),
                                yes: i18n.t("save"),
                            },
                            callback: (confirm) => {
                                if (confirm) {
                                    dispatch("changeGuest", {
                                        guests,
                                        forced: true
                                    })
                                }
                            },
                        });
                    } else if (response.data.code === 121) {
                        let cars = response.data.body.split(", ").filter(i => i !== "")
                        commit('add_guest', {
                            value: false,
                            code: response.data.code,
                            cars
                        })
                        dispatch('getGuests')
                    } else {
                        commit('change_guest_success')
                        dispatch('getGuests')
                    }
                    return response.data.content
                }
            })
            .catch((err) => {
                console.log(err.response);
                console.log(err);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
                if (err.response && err.response.status === 400) {
                    commit('change_guest_error', {
                        message: err.response.data.message,
                        type: "dateParsing"
                    })
                }
                if (err.response && err.response.status === 500) {
                    commit('change_guest_error', {
                        message: err.response.data.message,
                        type: "existingPlate"
                    })
                }
                if (err.response.data.code === 704) {
                    commit('change_guest_error', {
                        message: err.response.data.message,
                        type: "currentParked"
                    })
                }
            });
    },
    deleteGuestCyberport({
        commit,
        dispatch
    }, {
        guest,
        notify,
        forced
    }) {
        cyberport({
                url: `/platform/guests`,
                method: "DELETE",
                params: {
                    place_id: store.state.guests.place,
                    forced,
                    guest_id: guest.guest_id
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then(response => {
                if (response.status == 200) {
                    if (notify) {
                        EventBus.$emit("notify", {
                            group: 'general',
                            title: 'Success!',
                            text: i18n.t('guest_deleted'),
                            duration: 3000
                        })
                    }
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (notify) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Oops!',
                        text: '',
                        duration: 3000
                    })
                }
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
}