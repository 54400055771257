import { base, pwa, cyberport } from '@/axios-api'
import store from '@/store/index';
import router from '@/routes/index'
import i18n from '@/plugins/i18n';
import qs from 'qs'
import EventBus from '../../../event-bus';

export default {
  //    USER ACTIONS
  login({
          commit,
          dispatch
        }, {
          username,
          password
        }) {
    let body = ""
    body += 'user_name=' + encodeURIComponent(username);
    body += '&';
    body += 'password=' + encodeURIComponent(password);
    base({
      url: '/auth/login',
      data: body,
      method: 'POST'
    })
      .then((response) => {
        if (response.status == 200) {
          let user = response.data.content.user;
          localStorage.setItem('user', user);
          if (user.user_name.includes("cyberport")) {
            dispatch("cyberLogin", { username, password })
          } else if (user.user_name.includes("mbb")) {
            dispatch("pwaLogin", { username, password })
          }

          commit('auth_success', response.data.content);
          if (response.data.content.centrifugo) {
            commit('cs_auth_success', response.data.content.centrifugo);
          }


          if (user.user_name === "tupo_watch" || user.user_name === "halo_stream_watch" || user.role_id === 4) {
            router.push('/platform/streaming');
          } else if (user.role_id === 2) {
            router.push('/promo');
          } else {
            router.push('/platform/dashboard');
          }
          commit("set_loader", true);

        }
      })
      .catch(err => {
        console.log(err)
        commit('auth_error', err.response);
        localStorage.removeItem('credentials');
      })
  },

  cyberLogin({
               commit
             }, data) {
    cyberport({
      url: '/platform/auth',
      data,
      method: 'POST'
    })
      .then((response) => {
        if (response.status == 200) {
          let token = response.data.body.auth_token;
          commit("cyber_auth_success", token)

        }
      })
      .catch(err => {
        console.log(err)
        commit('auth_error', err.response);
        localStorage.removeItem('credentials');
      })
  },

  pwaLogin({
             commit
           }, {
             username,
             password
           }) {
    let data = {
      username,
      password
    }
    pwa({
      url: '/platform/auth',
      data,
      method: 'POST'
    })
      .then((response) => {
        if (response.status == 200) {
          let token = response.data.body.auth_token;
          commit("pwa_auth_success", token)

        }
      })
      .catch(err => {
        console.log(err)
        commit('auth_error', err.response);
        localStorage.removeItem('credentials');
      })
  },


  chatLogin({
              commit
            }, {
              user_name,
              password
            }) {
    base({
      url: 'https://chat.parkingbnb.world/test//auth/login',
      data: {
        user_name,
        password
      },
      method: 'POST'
    })
      .then((response) => {
        if (response.status == 200) {
          let token = response.data.body.token
          let user = response.data.body.user
          commit("chat_auth_success", { user, token })
        }
      })
      .catch(err => {
        commit('auth_error', err.response);
        localStorage.removeItem('credentials');
      })
  },

  // refreshing cs token (TODO: change fn name)
  counterLogin({ commit, state }, ) {
    return base({
      url: "/auth/token/refresh-centrifugo",
      method: "GET",
      params: { token: state.cs_refresh_token },
    })
      .then((res) => {
        console.log(res)
        if (!res.status === 200) {
          throw new Error(`Unexpected status code ${res.status}`);
        }
        let data = res.data.body;
        commit("cs_auth_success", data);
      })
      .catch(() => {
        // commit("logout");
        // location.reload();
      });
  },
  logout({
           commit
         }) {
    commit('guests/logout', null, {
      root: true
    });
    commit('history/logout', null, {
      root: true
    });
    commit('dashboard/logout', null, {
      root: true
    });
    commit('streaming/logout', null, {
      root: true
    });
    commit('logout');
    // commit('setLoader', true)
    router.push('/login')
    // setTimeout(() => {
    //     commit('setLoader', false)
    // }, 1000)
  },

  //    PLACE ACTIONS
  updateBalanceRequest({
                         commit,
                         dispatch
                       }) {
    let place_id = store.state.app.places.map(p => p.place_id)
    base({
      url: '/history/balance',
      method: 'GET',
      params: {
        place_id
      },
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    })
      .then((response) => {
        if (response.status === 200) {
          commit('upd_balance', response.data.body.balance);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('logout')
        }
      });
  },
  getPlaceAvatar({
                   commit,
                   dispatch
                 }, place_id) {
    base({
      url: '/places/avatar',
      method: 'GET',
      params: {
        place_id
      },
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    })
      .then((response) => {
        let payload = response.data.body || []
        commit('upd_places_ava', payload);
      })
      .catch(err => {
        console.log(err)
        console.log(err.response)
        if (err.response && err.response.status === 401) {
          dispatch("logout")
        }
      })
  },
  getPlacesInfo({
                  commit,
                  dispatch
                }) {
    let places;
    base({
      url: '/places',
      method: 'GET'
    })
      .then((response) => {
        if (response.status === 200) {
          places = response.data.body;
          if (!places) {
            throw Error
          }
          commit('upd_places', places);

          let ids = places.map(p => p.place_id)

          dispatch('getPlaceAvatar', ids);


          dispatch('streaming/getStreamSources', ids, { root: true });
          dispatch('dashboard/getPlaceOccupancy', null, {
            root: true
          });
          // commit("set_loader", false);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && (err.response.status === 400 || err.response.status === 401)) {
          dispatch('logout')
        } else {
          router.push('/not-found')
        }
      });
  },
  getZones({
             commit,
             dispatch
           }, parking_id) {
    let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id)
    base({
      url: '/zonal/zones',
      method: 'GET',
      params: {
        place_id
      },
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    }).then((response) => {
      if (response.status === 200) {
        let zones = response.data.body ? response.data.body : []
        commit('get_zones_success', zones)
      }
    })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('logout')
        }
      });
  },
  getCarTypes({
                commit,
                dispatch
              }, parking_id) {
    let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id)
    base({
      url: '/vehicles/types',
      method: 'GET',
      params: {
        place_id
      },
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    })
      .then((response) => {
        if (response.status === 200) {
          let types = response.data.body ? response.data.body : []
          commit('get_car_types_success', types);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('app/logout', null, {
            root: true
          })
        }
      });
  },
  getGuestTypes({
                  commit,
                  dispatch
                }, parking_id) {
    let place_id = parking_id ? parking_id : store.state.app.places.map(p => p.place_id)
    base({
      url: '/history/types',
      method: 'GET',
      params: {
        place_id
      },
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    })
      .then((response) => {
        if (response.status === 200) {
          let types = response.data.body ? response.data.body : []
          commit('get_guest_types_success', types);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('app/logout', null, {
            root: true
          })
        }
      });
  },
  getUsers({
             commit,
             dispatch
           }) {
    base({
      url: '/pbnb/users',
      method: 'GET',
      params: {
        verification: 3
      }
    })
      .then((response) => {
        if (response.status === 200) {
          let users = response.data.body ? response.data.body : []
          commit('set_users', users);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('app/logout', null, {
            root: true
          })
        }
      });
  },
  setVacancyRestrictions({
                           commit,
                           dispatch
                         }, data) {
    base({
      url: '/places/vacancy_restrictions',
      method: 'POST',
      data
    })
      .then((response) => {
        if (response.status === 200) {
          EventBus.$emit("notify", {
            group: 'general',
            title: 'Success!',
            text: i18n.t(`spaces_reserved_set`),
            duration: 3000
          })
          dispatch("getVacancyRestrictions")
          return commit
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('app/logout', null, {
            root: true
          })
        }
      });
  },

  getVacancyRestrictions({
                           commit,
                           dispatch
                         }) {
    let place_id = store.state.app.places.map(p => p.place_id)

    base({
      url: '/places/vacancy_restrictions',
      method: 'GET',
      params: {
        place_id
      },
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    })
      .then((response) => {
        if (response.status === 200) {
          let payload = response.data.body.space_count || 0
          commit('set_reserved_spaces', payload);
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('app/logout', null, {
            root: true
          })
        }
      });
  },

}
