import Vue from 'vue'
import vuetify from '@/plugins/vuetify'
import router from './routes/index'
import App from './App.vue'
import store from '@/store/index'
import i18n from '@/plugins/i18n'
import VueApexCharts from 'vue-apexcharts'
import VDragged from 'v-dragged'
import Confirm from '@/components/core/Confirm'
import Notifications from '@/components/core/NotificationHOC/Notifications'
import ZoomOnHover from '@/components/Helpers/ZoomOnHover.vue'

Vue.config.productionTip = false
Vue.use(require('vue-moment'));
Vue.use(Notifications);
Vue.use(VDragged)
Vue.use(Confirm)

Vue.component('apexchart', VueApexCharts)
Vue.component('Confirm', Confirm)
Vue.component('NotificationHOC', Notifications)
Vue.component('ZoomOnHover', ZoomOnHover)


Vue.prototype.$eventBus = new Vue();

Array.prototype.groupBy = function(key) {
    return this.reduce(function(r, a, i) {
        if (!i || r[r.length - 1][0][key] !== a[key]) {
            return r.concat([
                [a]
            ]);
        }
        r[r.length - 1].push(a);
        return r;
    }, []);
};
Array.prototype.getSumByKey = function(key) {
    return this.reduce((accumulator, current) => accumulator + Number(current[key]), 0);
};


new Vue({
    router,
    vuetify,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')