import actions from './actions'
import EventBus from '../../../event-bus';
import i18n from '@/plugins/i18n';

const getDefaultState = () => {
    return {
        kioskCheckoutAmount: 0,
        isKioskCheckoutAmountLoaded: false,
        parking_history: {
            all: [],
            completed: [],
            current: [],
            unpaid: [],
            loading: true
        },
        zone_history: {
            complied: [],
            violated: [],
            all: [],
            loading: true
        },
        stream_history: [],
        status: undefined,
        current_calculation: { blocks: [], price: 0 },
        loading_calculation: true
    }
}

const state = getDefaultState();

// getters
const getters = {};

// mutations
const mutations = {
    logout(state) {
        Object.assign(state, getDefaultState())
    },
    set_kiosk_checkout_amount(state, value) {
        state.kioskCheckoutAmount = value;
    },
    set_kiosk_checkout_amount_loaded_status(state, value) {
        state.isKioskCheckoutAmountLoaded = value;
    },
    set_loader(state, value) {
        state.parking_history.loading = value
    },
    get_history_success(state, data) {
        state.parking_history.all = data
        setTimeout(() => {
            state.parking_history.loading = false
        }, 500)
        return state
    },
    get_current_records_success(state, data) {
        state.parking_history.current = data
        return state
    },
    get_unpaid_records_success(state, data) {
        state.parking_history.unpaid = data
        return state
    },
    get_completed_records_success(state, data) {
        state.parking_history.completed = data
        return state
    },
    set_last_numbers(state, payload) {
        state.stream_history = payload
        return state
    },
    get_zone_history_success(state, data) {
        state.zone_history.loading = false
        state.zone_history.all = data
        return state
    },
    get_zone_history_complied(state, data) {
        state.zone_history.complied = data
        return state
    },
    get_zone_history_violated(state, data) {
        state.zone_history.violated = data
        return state
    },
    edit_record(state, { value, type, notify }) {
        let text;
        switch (type) {
            case 1:
                text = i18n.t('parking_record_added')
                break
            case 2:
                text = i18n.t('parking_record_changed')
                break
            case 3:
                text = i18n.t('parking_record_deleted')
                break
            default:
                break
        }

        if (value && notify !== false) {
            state.status = value
            EventBus.$emit("notify", {
                group: 'general',
                title: 'Success!',
                text,
                duration: 3000
            })
            setTimeout(() => {
                state.status = undefined
            }, 1000)
        }

    },
    refresh_status(state, value) {
        state.status = value
        setTimeout(() => {
            state.status = undefined
        }, 1000)
    },
    set_current_calculation(state, payload) {
        state.current_calculation = payload
    },
    set_loader_calculation(state, payload) {
        state.loading_calculation = payload
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
