<template>
  <div>
    <v-toolbar absolute top class="drawer-icon promo-toolbar" @click="drawer = !drawer">
      <v-app-bar-nav-icon
        class="hidden-md-and-up drawer-burger ml-0 mr-4"
      ></v-app-bar-nav-icon>
      <h4 class="main-title white--text">{{ $t("places") }}</h4>
    </v-toolbar>
    <div class="d-flex sidebar-tab-content">
      <v-navigation-drawer
        v-model="drawer"
        class="core-sidebar core-drawer places-drawer"
        :permanent="$vuetify.breakpoint.mdAndUp"
        :mini-variant.sync="mini"
        mini-variant-width="75"
      >
        <div
          class="d-flex align-center mb-3"
          @click="mini = !mini"
          style="cursor: pointer"
        >
          <h4
            class="main-title main-title white--text mr-auto pb-8"
            :style="{ opacity: mini ? 0 : 1 }"
          >
            {{ $t("places") }}
          </h4>
          <v-btn icon>
            <img
              src="@/assets/images/chevron-left.svg"
              width="24px"
              height="24px"
              class="mr-4 drawer-arrow"
            />
          </v-btn>
        </div>
        <div
          class="sidebar-list-item"
          v-for="item in list"
          :key="item.place_id"
          @click="choosePlace(item)"
          :class="item.place_id === selected ? 'active' : ''"
        >
          <v-btn text>
            <img
              v-if="item.place_id !== 0"
              :src="
                item.avatar ||
                'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg'
              "
              class="table-img parking-img"
            />
            <span
              class="sidebar-list-item_title overimg-text"
              v-if="mini && item.place_id !== 0"
              >{{ item.place_name.slice(0, 1) }}</span
            >
            <h4 class="sidebar-list-item_title pl-0">{{ item.place_name }}</h4>
          </v-btn>
        </div>
      </v-navigation-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "PlacesDrawer",
  data() {
    return {
      drawer: false,
      mini: false,
    };
  },
  watch: {
    mini(newValue) {
      this.$emit("miniChange", newValue);
    },
  },
  mounted() {
    this.$emit("miniChange", this.mini);
  },
  computed: {
    list() {
      let initPlaces = [...this.$store.state.app.places];
      initPlaces.splice(0, 0, {
        place_id: 0,
        place_name: this.$t("parking_history_tabs[3]"),
      });
      return initPlaces;
    },
    view() {
      return this.$route.path.slice(10, this.$route.path.length);
    },
    selected() {
      switch (this.view) {
        case "dashboard":
          return this.$store.state.dashboard.place;
        case "your-parking":
          return this.$store.state.guests.place;
        case "streaming":
          return this.$store.state.streaming.place;
        case "promo/campaigns":
        case "promo/history":
          return this.$store.state.promo.place;
        default:
          return null;
      }
    },
  },
  methods: {
    choosePlace(item) {
      switch (this.view) {
        case "dashboard":
          this.$store.commit("dashboard/set_current_place", item.place_id);
          break;
        case "your-parking":
          this.$store.commit("guests/set_current_place", item.place_id);
          break;
        case "streaming":
          this.$store.commit("streaming/set_stream_place", item.place_id);
          break;
        case "promo/campaigns":
        case "promo/history":
          this.$store.commit("promo/set_current_place", item.place_id);
          break;
        default:
          return null;
      }
    },
  },
};
</script>

