<template>
  <v-app
    v-cloak
    :class="{
      'no-scroll':
        $route.path.includes('/your-parking/guest') ||
        $route.path.includes('/your-parking/cyberport-guest'),
    }"
  >
    <Confirm class="confirm-dialog" />
    <Loader />
    <NotificationPopup />
    <Downloading />
    <router-view />
  </v-app>
</template>

<script>
import Loader from "@/components/core/Loader";
// import Vuetify from "vuetify";
import "@/scss/main.scss";

export default {
  name: "App",
  // vuetify: new Vuetify(),
  components: {
    Loader,
    NotificationPopup: () => import("@/components/core/NotificationPopup"),
    Downloading: () => import("@/components/core/Downloading"),
  },
  mounted() {
    this.$moment.locale("en-AU");
    this.$moment.updateLocale(this.$moment.locale(), { invalidDate: "" });
  },
};
</script>
