import i18n from '@/plugins/i18n';
import actions from './actions'

const currencies = {
  tw: "$",
  hk: "$HK",
  us: "$US",
  ja: "¥",
};

const getDefaultState = () => {
  return {
    loading: false,
    downloading: false,
    language: localStorage.getItem("language") || "en",
    tab: "",
    user: JSON.parse(localStorage.getItem("user")) || {},
    chat_data: {},
    errorLogin: "",
    balance: 0,
    places: [],
    zones: [],
    car_types: [],
    guest_types: [],
    users: [],
    reserved_spaces: 0,
    //CREDENTIALS
    token: localStorage.getItem("credentials"),
    pwa_token: localStorage.getItem("pwa_token"),
    cyber_token: localStorage.getItem("cyber_token"),
    tv_token: localStorage.getItem("tv_token"),
    tv_refresh_token: localStorage.getItem("tv_refresh_token"),
    cs_token: localStorage.getItem("cs_token"),
    cs_refresh_token: localStorage.getItem("cs_refresh_token"),
  }
}

const state = getDefaultState();

// getters
const getters = {
  authorized: state => !!state.token,
  pwaAuthorized: state => !!state.pwa_token,
  isAdmin: (state, getters) => getters.authorized ? (state.user.role_id === 1 || state.user.role_id === 100 || state.user.role_id === 101) : false,
  isCustomerService: (state, getters) => getters.authorized ? (state.user.role_id === 2) : false,
  isGuard: (state, getters) => getters.authorized ? (state.user.role_id === 3) : false,
  isGuardLimit: (state, getters) => getters.authorized ? (state.user.role_id === 4) : false,
  isSuperAdmin: (state, getters) => getters.authorized ? state.user.role_id === 101 : false,
  isReadonly: (state, getters) => getters.authorized ? state.user.role_id === 100 : false,
  isCompany: (state) => state.places.length > 1,
  username: (state) => {
    let user = ""
    if (state.user && state.user.user_name) user = state.user.user_name.toString()
    return user
  },
  guestTypes: (state) => {
    let result = []
    state.guest_types.map(i => {
      result = result.concat(i.guest_types)
    })
    result = new Set(result)
    if (result.size === 0) result = [1, 2, 3, 4, 5, 6, 7, 9, 10, 11]
    return [...result]
  },
  locale: (state) => {
    let locale = 'hk'
    let currencies = state.places.map(p => p.currency)
    if (currencies.includes("USD")) locale = 'us'
    if (currencies.includes("JPY")) locale = 'ja'
    if (currencies.includes("TWD")) locale = 'tw'
    return locale
  },
  permissions: (state) => state.user.permissions || {},
  places: (state) => state.places.map(p => p.place_id),
  features: (state) => state.places.map(p => p.features),
  currency: (state, getters) => currencies[getters.locale],
  isSpot: (state, getters) => getters.username.includes("halo"),
  isBV: (state, getters) => getters.username.includes("bfv") || getters.places.includes(3146),
  isCyber: (state, getters) => getters.username.includes("cyberport"),
  isWASP: (state, getters) => getters.places.includes(3840) || getters.places.includes(4735) || getters.places.includes(4738) || getters.places.includes(5569) || getters.places.includes(6145) || getters.places.includes(6134),
  hasSimpleHistory: (state, getters) => getters.places.includes(1795),
  hasPromo: (state, getters) => getters.features.some(i => !!i.Promo),
  hasTenantsFlow: (state, getters) => getters.username.includes("mbb"),
  hasOverParking: (state, getters) => getters.username.includes("baptist") || getters.places.includes(1778),
  hasOfflinePayment: (state, getters) => getters.username.includes("sfkt") || getters.places.includes(1800),
  hasKiosk: (state, getters) => getters.hasOfflinePayment || getters.places.includes(3840) || getters.isBV || getters.isItt,
  hasCalculation: (state, getters) => getters.places.includes(4720) || getters.places.includes(3840),
  isDemo: (state, getters) => getters.username === "tupo_watch" || getters.username === "halo_stream_watch",
  isPhoenix: (state, getters) => getters.username.includes("savannah") || getters.username.includes("monterey") || getters.username.includes("capri") || getters.username.includes("phoenix"),
  hasZones: (state, getters) => getters.username.slice(0, 4) === "pbnb" || getters.places.includes(4707) || getters.places.includes(1688),
  hasZonePenalty: (state, getters) => getters.places.includes(1688),
  isItt: (state, getters) => getters.username.slice(0, 3) === "itt" || getters.places.includes(987),
  isUtaggoPOC1: (state, getters) => getters.username.includes("utaggo_poc1"),
  isUtaggoPOC2: (state, getters) => getters.username.includes("utaggo_poc2"),
  isAkippa: (state, getters) => getters.username.includes("akippa"),
  isJPDemo: (state, getters) => getters.username.includes("jp_demo"),
  isHKG: (state, getters) => getters.username.includes("hkg_"),
  isGoldin: (state, getters) => getters.username.slice(0, 3) === "tbh" || getters.places.includes(5991),
  hasPromoAccess: (state, getters) => !!getters.permissions.promo || (getters.isAdmin && getters.hasPromo),
  hasMonthlyWA: (state, getters) => getters.username.includes("tait") || getters.places.includes(1968),
  hasHistoryEditRight: (state, getters) => !!getters.permissions.history_edit || getters.isSuperAdmin,
  hasDiscount: (state, getters) => getters.places.includes(4713) || getters.places.includes(4724),
  hasLogbook: (state, getters) => getters.places.includes(987) || getters.places.includes(4738) || getters.places.includes(5569) || getters.places.includes(4735) || getters.places.includes(3840) || getters.places.includes(6134) || getters.places.includes(5991),
  isMonthlyLimited: (state, getters) => {
    // let mtr_places = [1216, 1217, 4707, 4710, 4711, 4712]
    return getters.places.includes(1216) || getters.places.includes(1217) || getters.places.includes(4707) || getters.places.includes(4711) || getters.places.includes(4712) || getters.places.includes(4710)
  },
  isReadOnlyBlackList(_, getters) {
    return (getters.isCustomerService || getters.isGuard) && (getters.isItt || getters.isGoldin);
  },
};

// mutations
const mutations = {
  set_tab(state, tab) {
    state.tab = tab

    localStorage.setItem("drawer", tab)
  },
  set_loader(state, val) {
    state.loading = val;
  },
  set_downloader(state, val) {
    state.downloading = val;
  },
  set_lang(state, language) {
    state.language = language
    localStorage.setItem("language", language)
  },
  auth_success(state, payload) {
    localStorage.setItem('credentials', payload.token);
    localStorage.setItem('user', JSON.stringify(payload.user));
    state.errorLogin = ""
    state.user = payload.user
    state.token = payload.token
  },
  pwa_auth_success(state, token) {
    state.pwa_token = token
    localStorage.setItem("pwa_token", token)
  },
  cyber_auth_success(state, token) {
    state.cyber_token = token
    localStorage.setItem("cyber_token", token)
  },
  chat_auth_success(state, { user, token }) {
    state.chat_token = token
    state.chat_data = user
    localStorage.setItem("chat_token", token)
  },
  cs_auth_success(state, data) {
    state.cs_token = data.access_token
    state.cs_refresh_token = data.refresh_token
    localStorage.setItem("cs_token", data.access_token)
    localStorage.setItem("cs_refresh_token", data.refresh_token)
  },
  auth_error(state, err) {
    console.log(err)
    state.errorLogin = i18n.t("auth_error")
    return state
  },
  logout(state) {
    localStorage.removeItem('credentials');
    localStorage.removeItem('pwa_token');
    localStorage.removeItem('admin_creds')
    localStorage.removeItem('app_refresh')
    localStorage.removeItem('cyber_token')
    localStorage.removeItem('user');
    localStorage.removeItem('drawer');
    localStorage.removeItem('language');
    localStorage.removeItem('cur_user')

    Object.assign(state, getDefaultState())
    state.loading = false
  },
  upd_balance(state, balance) {
    state.balance = balance
  },
  upd_places(state, places) {
    state.places = places
    return state
  },
  upd_places_ava(state, avatars) {
    let places = [...state.places]
    avatars.map(a => {
      places.map(p => {
        if (a.place_id === p.place_id) {
          p.avatar = a.url
        }
      })

    })
    state.places = [...places]
  },
  get_zones_success(state, zones) {
    state.zones = zones
  },
  get_car_types_success(state, cars) {
    const car_types = cars.map(car => car.car_type_id)
    state.car_types = car_types
  },
  get_guest_types_success(state, guest_types) {
    state.guest_types = guest_types
  },
  set_users(state, users) {
    state.users = users
  },
  set_reserved_spaces(state, payload) {
    state.reserved_spaces = payload
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
