<template>
  <div :class="isSetFilter ? 'set-filter' : ''" class="ml-3">
    <v-btn text @click.stop="show" class="dwl-btn mx-2">
      <img
        :style="{ height: '24px', width: '24px' }"
        src="https://storage.googleapis.com/parkingbnb-html-images/ Filter.svg"
        alt="Filter"
      />
    </v-btn>
    <div class="record-popup popup" v-if="shown">
      <div class="modal-overlay" @click="hide" v-if="shown" />
      <v-menu
        attach
        :value="true"
        :close-on-content-click="false"
        :close-on-click="false"
        content-class="core-filter modal-content"
      >
        <div class="mb-4 d-flex justify-space-between">
          <h3 class="main-title">{{ $t("filter") }}</h3>
          <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
        </div>

        <v-form>
          <div class="full-border-btm">
            <label class="modal-label mb-2">{{ $t("period") }}</label>

            <div class="d-flex justify-center form-period date">
              <v-menu
                content-class="datepicker-menu"
                attach
                :close-on-content-click="!!filters.dates[0]"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    readonly
                    class="modal-input input-half datepicker-inp mt-0"
                    :value="
                      filters.dates[0]
                        ? $moment(filters.dates[0]).format('DD.MM.YYYY')
                        : ''
                    "
                    :label="$t('from')"
                  ></v-text-field>
                  <span class="datepicker-divider"></span>
                </template>
                <v-date-picker
                  :locale="$i18n.locale"
                  first-day-of-week="1"
                  :isDark="false"
                  v-model="filters.dates[0]"
                  :max="$moment().format('YYYY-MM-DD')"
                ></v-date-picker>
              </v-menu>
              <v-menu
                content-class="datepicker-menu"
                attach
                :close-on-content-click="!!filters.dates[1]"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    class="modal-input input-half datepicker-inp datepicker-inp-second mt-0"
                    readonly
                    :value="
                      filters.dates[1]
                        ? $moment(filters.dates[1]).format('DD.MM.YYYY')
                        : ''
                    "
                    :label="$t('to')"
                  ></v-text-field>
                  <span class="datepicker-divider"></span>
                </template>
                <v-date-picker
                  :locale="$i18n.locale"
                  first-day-of-week="1"
                  :isDark="false"
                  v-model="filters.dates[1]"
                  :min="filters.dates[0] || ''"
                  :max="$moment().format('YYYY-MM-DD')"
                ></v-date-picker>
              </v-menu>
            </div>
            <!-- PLACE SELECTION -->
            <v-select
              :no-data-text="$t('no_data')"
              attach
              multiple
              v-if="isCompany"
              v-model="filters.place_id"
              item-value="place_id"
              item-text="place_name"
              class="modal-input filter-dropdown mt-2"
              :items="allPlaces"
              :label="$t('places')"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="togglePlaces">
                  <v-list-item-content class="px-4 py-0">
                    <v-list-item-title>
                      {{ $t("parking_history_tabs")[2] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template slot="selection" slot-scope="data">
                <span
                  v-text="
                    chooseAllPlaces && data.item === allPlaces[0]
                      ? $t('parking_history_tabs')[2]
                      : ''
                  "
                />
                <span
                  class="mr-2 mt-1"
                  style="white-space: nowrap"
                  v-text="chooseAllPlaces ? '' : data.item.place_name + ', '"
                />
              </template>
            </v-select>
            <!-- CAR TYPE SELECTION -->
            <v-select
              :no-data-text="$t('no_data')"
              attach
              v-if="!hidden.car_type"
              multiple
              v-model="filters.car_type"
              item-value="id"
              item-text="desc"
              class="modal-input filter-dropdown"
              :items="carTypesSelect"
              :label="$t('car_type')"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleCarTypes">
                  <v-list-item-content class="px-10">
                    <v-list-item-title>
                      {{ $t("parking_history_tabs")[2] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template slot="selection" slot-scope="data">
                {{
                  chooseAllCarTypes && data.item.id === 1
                    ? $t("parking_history_tabs")[2]
                    : ""
                }}
                <span
                  class="mr-2"
                  style="white-space: nowrap"
                  v-text="chooseAllCarTypes ? '' : data.item.desc + ', '"
                />
              </template>
            </v-select>
            <!-- GUEST TYPE SELECTION -->
            <v-select
              :no-data-text="$t('no_data')"
              attach
              v-if="!hidden.pricing"
              multiple
              v-model="filters.pricing"
              item-value="id"
              item-text="desc"
              class="modal-input filter-dropdown"
              :items="allSelectTypes"
              :label="$t('price_type')"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="togglePricing">
                  <v-list-item-content class="px-10">
                    <v-list-item-title>
                      {{ $t("parking_history_tabs")[2] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template slot="selection" slot-scope="data">
                {{
                  chooseAllPriceTypes && data.item.id === 1
                    ? $t("parking_history_tabs")[2]
                    : ""
                }}
                <span
                  class="mr-2"
                  style="white-space: nowrap"
                  v-text="chooseAllPriceTypes ? '' : data.item.desc + ', '"
                />
              </template>
            </v-select>
            <!-- ZONE SELECTION -->
            <v-select
              :no-data-text="$t('no_data')"
              attach
              v-if="!hidden.zone"
              multiple
              v-model="filters.zone_id"
              item-text="zone_name"
              item-value="zone_id"
              class="modal-input filter-dropdown"
              :items="zoneSelect"
              :label="$t('zone')"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleTransTypes">
                  <v-list-item-content class="px-10">
                    <v-list-item-title>
                      {{ $t("zone_tabs")[2] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template slot="selection" slot-scope="data">
                <span class="mr-2" style="white-space: nowrap">{{
                  chooseAllZones ? "" : data.item.zone_name
                }}</span>
                {{
                  chooseAllZones && zoneSelect.indexOf(data.item) === 0
                    ? $t("zone_tabs")[2]
                    : ""
                }}
              </template>
            </v-select>
            <!-- CAMPAIGN SELECTION   -->
            <v-select
              :no-data-text="$t('no_data')"
              attach
              v-if="!hidden.campaign"
              multiple
              v-model="filters.campaigns"
              item-text="campaign_name"
              item-value="campaign_id"
              class="modal-input filter-dropdown"
              :items="campaignSelect"
              :label="$t('campaign_name')"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleCampaigns">
                  <v-list-item-content class="px-10">
                    <v-list-item-title>
                      {{ $t("parking_history_tabs")[2] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template slot="selection" slot-scope="data">
                <span class="mr-2" style="white-space: nowrap">{{
                  chooseAllCampaigns ? "" : data.item.campaign_name
                }}</span>
                {{
                  chooseAllCampaigns && campaignSelect.indexOf(data.item) === 0
                    ? $t("parking_history_tabs")[2]
                    : ""
                }}
              </template>
            </v-select>
            <!-- CREATED BY SELECTION -->
            <v-select
              :no-data-text="$t('no_data')"
              attach
              v-if="!hidden.created_by"
              multiple
              v-model="filters.created_by"
              class="modal-input filter-dropdown"
              :items="createdBySelect"
              :label="$t('created_by')"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleCreators">
                  <v-list-item-content class="px-10">
                    <v-list-item-title>
                      {{ $t("parking_history_tabs")[2] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
            <!-- PRICE RANGE SELECTION -->
            <div v-if="!hidden.price_range">
              <h4 class="modal-label mt-3">{{ $t("price_range") }}</h4>
              <h4 class="modal-label mb-3 slider-label" v-if="unchangedRange">
                HK${{ minSpending }} - HK${{ maxSpending }}
              </h4>
              <h4 class="modal-label mb-3 slider-label" v-else>
                HK${{ filters.price_range[0] }} - HK${{ filters.price_range[1] }}
              </h4>

              <v-range-slider
                :max="maxSpending"
                :min="minSpending"
                step="5"
                @change="($event) => priceChangeHandler($event)"
                :value="unchangedRange ? [minSpending, maxSpending] : filters.price_range"
                class="range-slider"
                thumb-size="18px"
                thumb-color="#00746b"
                height="5px"
              />
            </div>
            <!-- TRANSACTION TYPE SELECTION -->
            <v-select
              :no-data-text="$t('no_data')"
              attach
              v-if="!hidden.wallet"
              multiple
              v-model="filters.transaction_type"
              item-value="id"
              item-text="text"
              class="modal-input filter-dropdown"
              :items="transactionTypes"
              :label="$t('transaction')"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleCarTypes">
                  <v-list-item-content class="px-10">
                    <v-list-item-title>
                      {{ $t("parking_history_tabs")[2] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template slot="selection" slot-scope="data">
                {{
                  chooseAllTransTypes && data.item.id === 1
                    ? $t("parking_history_tabs")[2]
                    : ""
                }}
                <span
                  class="mr-2"
                  style="white-space: nowrap"
                  v-text="chooseAllTransTypes ? '' : data.item.text + ', '"
                />
              </template>
            </v-select>
          </div>

          <div class="modal-footer d-flex align-center px-0 mt-4">
            <v-btn class="main-btn py-3 px-4" @click="setFilters">{{
              $t("confirm")
            }}</v-btn>
            <v-btn text class="flat-btn modal-footer-btn" @click="resetFilters">{{
              $t("reset_all")
            }}</v-btn>
          </div>
        </v-form>
      </v-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "CoreFilter",
  props: {
    hidden: {},
  },
  data() {
    return {
      shown: false,
      isSetFilter: false,
      unchangedRange: true,
      filters: {
        dates: [],
        car_type: [],
        pricing: [],
        campaigns: [],
        price_range: [this.minSpending, this.maxSpending],
        created_by: [],
        zone_id: [],
        place_id: [],
        transaction_type: [],
      },
    };
  },
  computed: {
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    chooseAllPriceTypes() {
      return this.filters.pricing.length === this.allSelectTypes.length;
    },
    chooseAllCarTypes() {
      return this.filters.car_type.length === this.carTypesSelect.length;
    },
    chooseAllCampaigns() {
      return this.filters.campaigns.length === this.campaignSelect.length;
    },
    chooseAllCreators() {
      return this.filters.created_by.length === this.createdBySelect.length;
    },
    chooseAllZones() {
      return this.filters.zone_id.length === this.zoneSelect.length;
    },
    locale() {
      return this.$store.getters["app/locale"];
    },
    hasKiosk() {
      return this.$store.getters["app/hasKiosk"];
    },
    priceNames() {
      return this.$t("price_types");
    },
    allSelectTypes() {
      let types = [];
      let finalTypes = [];
      [...this.$store.state.app.guest_types].map((i) => {
        types = types.concat(i.guest_types).filter((v, i, a) => a.indexOf(v) === i);
      });
      types.map((type) => {
        finalTypes.push({
          id: type,
          desc: this.priceNames.filter((i) => i.id === type)[0].desc,
        });
      });
      return finalTypes;
    },
    carTypesSelect() {
      let types = [];
      let ids = this.$store.state.app.car_types;
      ids.map((id) => {
        if (id !== 0) {
          types.push({ id, desc: this.$t(`car_types[${id}]`) });
        }
      });
      return types;
    },
    promoHistory() {
      let list = [];
      let history = [...this.$store.state.promo.history];

      history.map((h) => {
        list = list.concat(h.records);
      });
      return list;
    },
    campaignSelect() {
      return this.promoHistory
        .map((c) => c.campaign)
        .filter(
          (thing, index, self) =>
            index === self.findIndex((t) => t.campaign_id === thing.campaign_id)
        );
    },
    createdBySelect() {
      return this.promoHistory
        .map((record) => record.campaign.created_by)
        .filter((v, i, a) => a.indexOf(v) === i);
    },
    zoneSelect() {
      return this.$store.getters["app/hasZones"] ? this.$store.state.app.zones : [];
    },
    minSpending() {
      let min = 10000000;
      let counter = 0;
      this.promoHistory.map((record) => {
        let total = 0;
        if (record.spendings) {
          record.spendings.map((store) => {
            total += store.amount;
            counter++;
          });
        }
        if (total < min) {
          min = total;
        }
      });
      if (counter < 2) {
        min = 0;
      }
      return min;
    },
    maxSpending() {
      let max = 0;
      let counter = 0;
      this.promoHistory.map((record) => {
        let total = 0;
        if (record.spendings) {
          record.spendings.map((store) => {
            total += store.amount;
            counter++;
          });
        }
        if (total > max) {
          max = total;
        }
      });
      if (counter < 2) {
        max = max * 2;
      }
      return max;
    },
    chooseAllPlaces() {
      return (
        this.filters.place_id.length === this.allPlaces.length ||
        this.filters.place_id.length === 0
      );
    },
    allPlaces() {
      return this.$store.state.app.places;
    },
    transactionTypes() {
      let types = [];
      for (const [key, value] of Object.entries(this.$t(`payment_types`))) {
        types.push({
          id: key,
          text: value,
        });
      }
      return types;
    },
    chooseAllTransTypes() {
      return (
        this.filters.transaction_type.length === this.transactionTypes.length ||
        this.filters.transaction_type.length === 0
      );
    },
  },
  methods: {
    show() {
      this.shown = true;
      document.body.style = "height: 100vh; overflow: hidden";
      this.$emit("show");
    },
    hide() {
      this.shown = false;
      document.body.style = "height: auto; overflow: auto";
      this.$emit("hide");
    },
    setFilters() {
      this.$emit("filterSet", { ...this.filters, reset: false });
      this.isSetFilter = true;
      this.hide();
    },
    resetFilters() {
      this.filters = {
        dates: [],
        car_type: [],
        pricing: [],
        campaigns: [],
        price_range: [this.minSpending, this.maxSpending],
        created_by: [],
        zone_id: [],
      };
      this.$emit("filterSet", { ...this.filters, reset: true });
      this.hide();
      this.isSetFilter = false;
    },
    togglePricing() {
      this.$nextTick(() => {
        if (this.chooseAllPriceTypes) {
          this.filters.pricing = [];
        } else {
          this.filters.pricing = this.allSelectTypes.map((t) => t.id).slice();
        }
      });
    },
    toggleCarTypes() {
      this.$nextTick(() => {
        if (this.chooseAllCarTypes) {
          this.filters.car_type = [];
        } else {
          this.filters.car_type = this.carTypesSelect.map((t) => t.id).slice();
        }
      });
    },
    toggleCampaigns() {
      this.$nextTick(() => {
        if (this.chooseAllCampaigns) {
          this.filters.campaigns = [];
        } else {
          this.filters.campaigns = this.campaignSelect.map((c) => c.campaign_id).slice();
        }
      });
    },
    toggleCreators() {
      this.$nextTick(() => {
        if (this.chooseAllCreators) {
          this.filters.created_by = [];
        } else {
          this.filters.created_by = this.createdBySelect.slice();
        }
      });
    },
    toggleZones() {
      this.$nextTick(() => {
        if (this.chooseAllZones) {
          this.filters.zone_id = [];
        } else {
          this.filters.zone_id = this.zoneSelect.slice();
        }
      });
    },
    priceChangeHandler(value) {
      this.filters.price_range = value;
      this.unchangedRange = false;
    },
    togglePlaces() {
      this.$nextTick(() => {
        if (this.chooseAllPlaces) {
          this.filters.place_id = [];
        } else {
          this.filters.place_id = this.allPlaces.map((p) => p.place_id).slice();
        }
      });
    },
    toggleTransTypes() {
      this.$nextTick(() => {
        if (this.chooseAllPlaces) {
          this.filters.transaction_type = [];
        } else {
          this.filters.transaction_type = this.transactionTypes.map((p) => p.id).slice();
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
</style>

<style lang="scss" scoped>
.input-half {
  max-width: 48% !important;
}
.datepicker-inp {
  max-width: 50% !important;
  margin-bottom: 18px !important;
}
</style>
