import actions from './actions'

const DEFAULT_CAMPAIGN = {
    campaign_name: "",
    campaign_description: "",
    custom_params: {},
    place_id: undefined,
    stores: [{
        status: 1,
        store_id: null,
    }, ],
    constraints: [{}],
}

const state = () => ({
    tab: 0,
    campaigns: [],
    cars: [],
    history: [],
    loading: true,
    loading_history: true,
    place: 0,
    cur_campaign: JSON.parse(JSON.stringify(DEFAULT_CAMPAIGN)),
    cur_record: {},
    receipts: [],
    promocodes: [],
    loading_codes: true,
    loading_codes_history: true,
    view: ""
});

const getters = {}

const mutations = {
    set_current_place(state, place) {
        state.place = place
    },
    get_campaigns_success(state, campaigns) {
        state.loading = false
        state.campaigns = campaigns
    },
    get_cars_success(state, cars) {
        state.cars = cars
        state.loading = false
    },
    getQR_success(state) {
        return state
    },
    set_view(state, title) {
        state.view = title
    },
    set_current_campaign(state, payload) {
        state.cur_campaign = payload
    },
    get_promo_history(state, history) {
        state.history = history
        state.loading_history = false
    },
    get_receipts(state, payload) {
        state.receipts = payload
    },
    get_promocodes(state, payload) {
        state.promocodes = payload
        state.loading_codes = false
    },
    get_promocodes_history(state, payload) {
        state.codes_history = payload
        state.loading_codes_history = false
    },
    set_tab(state, payload) {
        state.tab = payload
    },
    set_current_record(state, payload) {
        state.cur_record = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};