<template>
  <div class="tabs-content">
    <v-toolbar absolute top class="drawer-icon users-toolbar" @click="drawer = !drawer">
      <v-app-bar-nav-icon class="drawer-burger ml-0 mr-4"></v-app-bar-nav-icon>
      <h4 class="main-title white--text">{{ $t("users") }}</h4>
    </v-toolbar>
    <div class="modal-overlay" v-if="drawer" @click="drawer = false" />
    <div class="d-flex align-stretch justify-space-between user-container">
      <div :class="{ shown: drawer, 'user-drawer': true }">
        <div class="header">
          <h3 class="main-title">{{ $t("users_list") }}</h3>
          <div class="d-flex align-stretch justify-space-between">
            <v-text-field
              v-model="search"
              autocomplete="off"
              class="parking-search py-0 my-0"
              :placeholder="$t('search_user')"
            />
            <div class="triangle-parent mt-2" style="height: 22px">
              <div style="width: 8px; height: 8px" class="triangle up" />
              <div style="width: 8px; height: 8px" class="triangle down" />
            </div>
          </div>
        </div>
        <div class="user-list no-scroll">
          <div
            class="user"
            :class="user.id === current.id ? 'selected' : ''"
            v-for="user in list"
            :key="user.id"
            @click="setUser(user)"
          >
            {{ user.name || $t("unnamed") }}
          </div>
        </div>
      </div>
      <router-view v-if="current.id" />
      <div class="users-plug" v-else>
        <img src="@/assets/images/users_plug.webp" />
        <h4>{{ $t("total") }}: {{ list.length }} {{ $t("users") }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Users",
  components: {},
  data: () => {
    return {
      drawer: false,
      timeout: null,
      params: {
        search: "",
      },
    };
  },
  computed: {
    list() {
      return this.$store.state.users.list;
    },
    current() {
      return this.$store.state.users.current;
    },
    search: {
      get() {
        return this.params.search;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.params.search = val;
          this.update();
        }, 1000);
      },
    },
  },
  methods: {
    setUser(user) {
      this.$store.commit("users/set_current", user);
      this.$store.dispatch("users/getFullInfo", user.id);
      this.$router.push(`/platform/users/${user.id}`);
      this.drawer = false;
    },
    update() {
      this.$store.dispatch("users/getList", { ...this.params });
    },
  },
  mounted() {
    if (this.current.id) {
      this.$router.push(`/platform/users/${this.current.id}`);
    }
  },
};
</script>

<style></style>
