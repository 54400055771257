import Vue from 'vue'
import actions from './actions'

const getDefaultState = () => {
  return {
    stats: {
      current_month: [],
      last_month: []
    },
    occupancy: [],
    price_usage: [],
    occ_dynamics: {
      day: [],
      week: [],
      month: [],
      half_year: [],
      year: []
    },
    loading: true,
    enter_count: {
      today: [],
      yesterday: [],
    },
    loading_prices: true,
    place: 0,
    vacancies: [],
    counter_config: [],
    configuration: {},
  }
}

const state = getDefaultState();

// getters
const getters = {};

// mutations
const mutations = {
  logout(state) {
    Object.assign(state, getDefaultState())
  },
  set_config(state, value) {
    state.configuration = value;
  },
  set_loader(state, value) {
    state.loading = value
  },
  upd_occupancy(state, occupancy) {
    state.occupancy = occupancy
  },
  upd_dash_overview_stats(state, {
    stats,
    month
  }) {
    if (month === 0) {
      Vue.set(state.stats, "current_month", stats)
      if (state.stats.last_month[0]) {
        state.loading = false
      }
    }
    if (month === 1) {
      Vue.set(state.stats, "last_month", stats)
      if (state.stats.current_month[0]) {
        state.loading = false
      }
    }
  },
  upd_enter_count(state, {
    data,
    month
  }) {
    if (month === 0) {
      state.enter_count.today = data
    }
    if (month === 1) {
      state.enter_count.yesterday = data
    }
  },
  upd_dash_prices(state, price_usage) {
    state.price_usage = price_usage
    state.loading_prices = false
  },
  upd_occ_dynamics(state, {
    data,
    type
  }) {
    Vue.set(state.occ_dynamics, type, data)
  },
  set_current_place(state, place) {
    state.place = place
  },
  set_vacancies(state, payload) {
    state.vacancies = payload.vacancies
  },
  set_counter_data(state, payload) {
    state.counter_config = payload.vacancies
    state.vacancies = [...payload.vacancies].map(i => {
      return {
        ...i,
        spaces: i.vacancy
      }
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
