<template>
  <div class="user-content no-frame">
    <SubscriptionRecord :record="selected" v-show="openForm" @hide="hideForm" />
    <template v-if="openForm === false">
      <h3 class="main-title">{{ $t("subscriptions") }}</h3>
      <v-data-table
        :headers="headers"
        :items-per-page="-1"
        class="table"
        :items="records"
        disable-sort
        hide-default-footer
        mobile-breakpoint="0"
        :no-data-text="$t('no_data')"
        @click:row="viewDetails"
      >
        <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
          <div
            :key="i.text"
            class="d-flex align-center"
            :class="
              header.value === 'duration' || header.value === 'price'
                ? 'justify-center'
                : ''
            "
          >
            <div
              class="triangle-parent"
              style="height: 12px"
              v-if="header.sortable !== false"
            >
              <div
                class="triangle up"
                @click="customSort(headers.indexOf(header), 0)"
                :class="header.isDesc === false ? 'active' : ''"
              />
              <div
                class="triangle down"
                @click="customSort(headers.indexOf(header), 1)"
                :class="header.isDesc ? 'active' : ''"
              />
            </div>
            <div>{{ header.text }}</div>
          </div>
        </template>

        <template v-slot:[`item.place`]="{ item }">
          <div class="table-parking d-flex justify-start align-center">
            <div class="table-parking-img">
              <img
                class="parking-img table-img"
                :src="
                  places.filter((p) => p.place_id === item.place_id)[0]
                    ? places.filter((p) => p.place_id === item.place_id)[0].avatar ||
                      'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg'
                    : 'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg'
                "
                :alt="item.place_name"
              />
            </div>
            <span
              style="
                max-width: 115px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              "
              >{{ item.place_name }}</span
            >
          </div>
        </template>

        <template v-slot:[`item.price_type`]="{ item }">
          <div class="table-date">
            {{ $t(`monthly_price_options[${item.price_option_id - 1}]`).text }}
          </div>
        </template>

        <template v-slot:[`item.start`]="{ item }">
          <div class="table-date">
            {{ $moment(item.active_from).format("DD MMM YYYY") }}
          </div>
        </template>

        <template v-slot:[`item.invoice`]="{ item }">
          <div class="table-duration text-center">
            {{ $moment(item.next_invoice_at).format("DD MMM YYYY") }}
          </div>
        </template>

        <template v-slot:[`item.amount`]="{ item }">
          <div class="table-total">
            {{ item.amount ? item.currency + item.total_cost / 100 : "" }}
          </div>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div class="table-status">
            {{ $t(`pass_status_cp[${item.status - 1}]`) }}
          </div>
        </template>
      </v-data-table></template
    >
  </div>
</template>

<script>
export default {
  name: "UserSubscriptions",
  components: {
    SubscriptionRecord: () => import("@/components/Users/SubscriptionRecord"),
  },
  data: () => {
    return {
      openForm: false,
      selected: {},
    };
  },
  computed: {
    current() {
      return this.$store.state.users.current;
    },
    records() {
      return this.$store.state.users.subscriptions;
    },
    places() {
      return this.$store.state.app.places;
    },
    headers() {
      return [
        {
          text: this.$t("parking"),
          value: "place",
          sortable: false,
          class: "table-parking table-header",
        },
        {
          text: this.$t("price_type"),
          value: "type",
          sortable: false,
          class: "table-price table-header",
        },
        {
          text: this.$t("start_date"),
          value: "start",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("next_invoice"),
          value: "invoice",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("total"),
          value: "amount",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "table-header table-zone px-0",
        },
      ];
    },
  },
  methods: {
    viewDetails(value) {
      console.log("opening form");
      this.selected = value;
      this.openForm = true;
    },
    hideForm() {
      this.selected = {};
      this.openForm = false;
    },
  },
};
</script>

<style></style>
