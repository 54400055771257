<template>
  <div class="popup checkout" style="z-index: 20">
    <div class="modal-overlay" style="opacity: 0.16" @click="hide" />
    <div class="modal-content">
      <h4 class="main-title">{{ $t("checkout") }}</h4>
      <div v-if="loadingCalculation" class="mt-2">
        <v-skeleton-loader type="text" class="mb-0" height="18" />
      </div>
      <div
        class="d-flex justify-space-between"
        v-for="(p, i) in priceBlocks"
        :key="i"
        v-else
        style="opacity: 0.6"
      >
        <div v-if="p.pricingTypeID !== 100">
          {{ $t(`pricing_types[${p.pricingTypeID}]`) }} x{{ p.multiplier }}
        </div>
        <div v-else>{{ $t(`pricing_types[0]`) }} x{{ p.multiplier }}</div>
        <div>{{ currencies[hrec.parking.currency] }}{{ p.basePrice / 100 }}</div>
      </div>
      <template v-if="isItt || isGoldin">
        <v-form ref="form">
          <v-autocomplete
            :no-data-text="$t('no_data')"
            v-model="hrec.parking.payment_method"
            item-text="desc"
            item-value="key"
            :items="csPaymentMethods"
            attach
            class="modal-input my-6"
            :label="$t('payment_method')" />
          <v-text-field
            :value="hrec.parking.total_price / 100 || null"
            @change="priceChangeHandler"
            :label="$t(`total`)"
            class="modal-input"
            :rules="[
              () => !loading || !!hrec.parking.total_price || `${$t('total')} ${$t('required')}`,
            ]"
        /></v-form>
        <div class="d-flex mt-6">
          <v-btn
            class="main-btn px-6 py-2"
            :loading="loading"
            text
            @click="initiatePayment"
            >{{ $t(`pay`) }}</v-btn
          >
          <v-btn class="flat-btn" text @click="hide">{{ $t(`cancel`) }}</v-btn>
        </div></template
      >
      <div class="d-flex justify-space-between mt-4 mb-6" v-else>
        <div>{{ $t("total") }}</div>
        <div>
          {{ currencies[hrec.parking.currency] }}{{ hrec.parking.total_price / 100 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { base } from "@/axios-api";
export default {
  name: "ParkingCheckout",
  props: {
    record: {
      default() {
        return {
          parking: { guest_type: undefined },
          place: {},
          user: {},
          car: {},
        };
      },
    },
  },
  data() {
    return {
      hrec: {
        car: {},
        parking: { guest_type: undefined },
        user: {},
        place: {},
      },
      price_rewrite: false,
      loading: false,
    };
  },
  watch: {
    record(newValue) {
      this.hrec = JSON.parse(JSON.stringify(newValue));
    },
    total(newValue) {
      this.hrec.parking.total_price = newValue;
      this.loading = false;
    },
  },

  computed: {
    loadingCalculation() {
      return this.$store.state.history.loading_calculation;
    },
    calculation() {
      return this.$store.state.history.current_calculation;
    },
    total() {
      return this.calculation.price;
    },
    currencies() {
      return this.$t("currencies");
    },
    priceBlocks() {
      let init = [...this.calculation.blocks]
        .flat()
        .sort((a, b) => a.pricingTypeID - b.pricingTypeID);
      let result = [];
      init = init.groupBy("pricingTypeID");
      init.map((group) => {
        let multiplier = group.getSumByKey("multiplier");
        let subtotal = group.getSumByKey("subtotal");
        result.push({
          ...group[0],
          multiplier,
          subtotal,
        });
      });
      return result;
    },
    csPaymentMethods() {
      return this.$t("payment_methods_cs");
    },
    isItt() {
      return this.$store.getters["app/isItt"];
    },
    isGoldin() {
      return this.$store.getters["app/isGoldin"];
    },
  },
  mounted() {
    this.hrec = JSON.parse(JSON.stringify(this.record));
    this.hrec.parking.total_price = this.total;
    // this.openCSChannel();
  },
  methods: {
    priceChangeHandler(val) {
      this.hrec.parking.total_price = val * 100;
      this.price_rewrite = true;
    },
    hide() {
      this.loading = true;
      this.$store.commit('history/set_current_calculation', { blocks: [], price: 0 })
      this.$emit("hide");
    },
    setPayment() {
      let paymentData = {
        parking_id: this.hrec.parking.parking_id,
        guest_type: this.hrec.parking.guest_type,
        price: this.hrec.parking.total_price,
        price_rewrite: this.price_rewrite || false,
      };
      this.$store.dispatch("history/setPayment", { data: paymentData, notify: true });
      this.$emit("hide");
    },
    initiatePayment() {
      if (!this.$refs.form.validate()) return;
      let data = {
        parking_id: this.hrec.parking.parking_id,
        guest_type: this.hrec.parking.guest_type,
        amount: this.hrec.parking.total_price,
        plate: this.hrec.car.plate_number,
        place_id: this.hrec.place.place_id,
        time_in: this.hrec.parking.enter_time,
        payment_method: this.hrec.parking.payment_method,
      };
      if (this.hrec.parking.exit_time) data.time_out = this.hrec.parking.exit_time;
      if (data.payment_method === "cash") {
        this.setPayment();
        return;
      }
      base({
        url: "/customer-service/payment",
        method: "post",
        data,
      })
        .then((res) => {
          if (res.status === 200) {
            this.loading = false;
            this.$emit("openCSChannel");
          }
        })
        .catch((err) => {
          this.$notify({
            group: "general",
            title: "Oops!",
            text: "",
            duration: 3000,
          });
          return err;
        });
    },
  },
};
</script>
