var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-navigation-drawer',{staticClass:"core-drawer green-bg",attrs:{"width":"256px","permanent":_vm.$vuetify.breakpoint.mdAndUp,"mobileBreakpoint":"2400","mini-variant":_vm.mini,"mini-variant-width":"67"},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"d-flex align-center mt-8",staticStyle:{"margin-bottom":"42px"},on:{"click":function($event){_vm.mini = !_vm.mini}}},[_c('Logo',{staticClass:"drawer-logo mr-auto"}),_c('v-btn',{attrs:{"icon":""}},[_c('img',{staticClass:"mr-4 drawer-arrow",attrs:{"src":require("@/assets/images/chevron-left.svg"),"width":"24px","height":"24px"}})])],1),_c('v-list',{attrs:{"nav":""}},[_c('v-list-item-group',{class:{ 'small-font': _vm.items.some((i) => _vm.$t(`${i.title}`).length > 20) },model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_vm._l((_vm.items.filter((i) => i.show === true)),function(i){return _c('v-list-item',{key:i.title,class:_vm.selected === i.title ? 'v-list-item--active' : '',attrs:{"to":i.link}},[_c('v-list-item-icon',[_c('img',{staticClass:"v-icon",attrs:{"width":"24px","height":"24px","src":i.title === _vm.selected ? _vm.selectedIcons[i.title] : _vm.defaultIcons[i.title]}})]),_c('v-list-item-content',{style:(_vm.selected === i.title ? { color: '#00746b' } : { color: 'white' })},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t(`${i.title}`)))]),(i.title === 'devices' && !!_vm.offlineDevices.length)?_c('span',{staticClass:"status-icon requested white--text pa-4 ml-3 d-flex align-center justify-center"},[_vm._v(" "+_vm._s(_vm.offlineDevices)+" ")]):_vm._e()],1)],1)}),((
            _vm.$store.getters['app/isItt']
            || _vm.$store.getters['app/isUtaggoPOC2']
            || _vm.$store.getters['app/isHKG']
            || _vm.$store.getters['app/isAkippa']
            || _vm.$store.getters['app/isJPDemo']
            || _vm.$store.getters['app/username'] === 'demo_tw'
          ) && _vm.$store.getters['app/hasPromoAccess'])?_c('v-list-item',{key:"mobilepromonew",attrs:{"href":"https://corporate.parkingbnb.world/new"}},[_c('v-list-item-icon',[_c('img',{staticClass:"v-icon",attrs:{"width":"24px","height":"24px","src":_vm.defaultIcons.promo}})]),_c('v-list-item-content',{style:({ color: 'white' })},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('mobile_promo')))])],1)],1):_vm._e()],2)],1)],1),_c('v-toolbar',{staticClass:"drawer-icon",attrs:{"absolute":"","top":""},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-app-bar-nav-icon',{staticClass:"drawer-burger ml-0 mr-4"}),_c('h4',{staticClass:"main-title white--text"},[_vm._v(_vm._s(_vm.shortname))])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }