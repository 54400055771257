<script>
export default {
  name: "SubscriptionsTable",
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    searchValue: {
      type: String,
      default: "",
    },
    openForm: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      data: [],
      params: {
        sort_by: "active_from",
        sort_direction: "desc",
      },
      filters: { dates: [] },
      selected: {},
    };
  },
  components: {
    PassRecord: () => import("@/components/Subscriptions/PassRecord"),
  },
  computed: {
    headers() {
      let initHeaders = [
        {
          text: this.$t("guest"),
          value: "user",
          class: "table-guest table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("start_date"),
          value: "start_date",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("price_type"),
          value: "price_type",
          sortable: false,
          class: "table-price table-header",
        },
        {
          text: this.$t("total"),
          value: "price",
          sortable: false,
          class: "table-price table-header",
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "table-status table-header",
        },
      ];
      return initHeaders;
    },
    history() {
      return this.$store.state.subscriptions.list;
    },
    customerSvcRecords() {
      return this.$store.state.subscriptions.customer_svc_list;
    },
    loading() {
      return this.$store.state.subscriptions.loading;
    },
    records() {
      let history = [...this.history];
      if (this.tab !== 6) {
        history = history.filter((i) => i.status === this.tab + 1);
      }
      if (this.$route.path === "/promo/subscriptions") {
        history = [...this.customerSvcRecords];
      }
      return history;
    },
    places() {
      return this.$store.state.app.places;
    },
  },
  methods: {
    updateData(params) {
      this.$store.dispatch("subscriptions/getList", params);
      this.$store.dispatch("subscriptions/listForCustomerSVC", params);
    },

    filter(filters) {
      this.filters = filters;
      this.params = {
        ...this.params,
        car_type: filters.car_type,
        place_id: filters.place_id,
      };

      this.updateData({ ...this.params });
    },

    search(value) {
      this.params = {
        ...this.params,
        filter: value,
      };

      this.updateData({ ...this.params });
    },

    customSort() {},
    openApplication(record) {
      this.selected = record;
      this.$refs.popup.show();
    },
  },
};
</script>

<template>
  <div>
    <v-skeleton-loader v-if="loading" type="table" />
    <v-data-table
      v-else
      :headers="headers"
      :items-per-page="-1"
      class="table"
      :items="records"
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
      @click:row="openApplication"
      :no-data-text="$t('no_data')"
    >
      <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div
          :key="i.text"
          class="d-flex align-center"
          :class="
            header.value === 'price' ||
            header.value === 'months' ||
            header.value === 'slots'
              ? 'justify-center'
              : ''
          "
        >
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(headers.indexOf(header), 0)"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(headers.indexOf(header), 1)"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.user`]="{ item }">
        <div class="table-user-name">
          <h4>{{ item.user_name || $t("unnamed") }}</h4>
        </div>
      </template>

      <template v-slot:[`item.parking`]="{ item }">
        <div class="table-parking d-flex justify-start align-center">
          <div class="table-parking-img">
            <img
              class="parking-img table-img"
              :src="
                places.filter((p) => p.place_id === item.place.id)[0].avatar
                  ? places.filter((p) => p.place_id === item.place.id)[0].avatar
                  : 'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg'
              "
              :alt="item.place.name"
            />
          </div>
          <span style="max-width: 100px">{{ item.place.name }}</span>
        </div>
      </template>

      <template v-slot:[`item.start_date`]="{ item }">
        <div class="table-date">
          {{ $moment(item.active_from).format("DD.MM.YYYY") }}
        </div>
      </template>

      <template v-slot:[`item.price_type`]="{ item }">
        <div class="table-price text-center">
          {{
            $t(`monthly_price_options`).filter((i) => i.id === item.price_option_id)[0]
              .text
          }}
        </div>
      </template>

      <template v-slot:[`item.price`]="{ item }">
        <div class="table-price text-center">{{ item.amount / 100 }}HKD</div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="table-status">
          {{ $t(`pass_status_cp[${item.status - 1}]`) }}
        </div>
      </template>
    </v-data-table>
    <PassRecord ref="popup" :record="selected" />
  </div>
</template>
