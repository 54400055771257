<template>
  <div class="tabs-content your-parking">
    <h3 class="main-title">{{ name }}</h3>

    <div class="parking-header d-flex align-center flex-wrap mt-4">
        <h4 class="parking-subtitle mr-md-2" style="white-space: nowrap">
          {{ $t("monthly_guests") }}
        </h4>
        <v-text-field
          v-model="search"
          autocomplete="off"
          class="parking-search"
          style="min-width: 310px"
          :placeholder="$t('search_core')"
        />

        <ReportDownloadForm
          @hideEmit="openForm = false"
          @showEmit="openForm = true"
          ref="report"
          view="your_parking"
        />
      <v-btn
        class="main-btn pa-2 ml-auto"
        @click="openAddForm"
        v-show="!isReadonly && (currentPlace || !isCompany)"
        ><img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAAA30lEQVRYhe2VSw4BQRCG/2biEoJkLmCDWLry7CwsnMU7ruDxWRgxaI02D5L+kt5Uurq+mql0S4F/BegD43T1yi4eARuurIB6mQIdHun4nFXzdLB16/UFfAVyIwgEgcoFovsA0JDUlGQceS1bDHDVQtLCGLN7vgOGwNZyyeTFGui6BCYFFr+QZGtWPgM3cP4FywK7XwKDbM2HQQOMpLbcd3tL0vQuNpI0d+QcJM2MMc5JfQsgtnQX+5xV+QwEgSDwtwJHS2z/jchHAA3OD0v2hnO9noVIdIEkXYPXGYEf5QRsc7l3beljLgAAAABJRU5ErkJggg=="
      /></v-btn>
    </div>
    <TenantsTable :searchValue="debouncedInput" v-if="tenantFlow" />
    <CyberportGuestsTable :searchValue="debouncedInput" v-if="cyberFlow" />
    <GuestsTable :searchValue="debouncedInput" v-if="!tenantFlow && !cyberFlow" />
  </div>
</template>

<script>
export default {
  name: "YourParking",
  data() {
    return {
      openForm: null,
      debouncedInput: "",
      timeout: null,
    };
  },
  components: {
    GuestsTable: () => import("@/components/YourParking/GuestsTable"),
    CyberportGuestsTable: () => import("@/components/YourParking/Cyberport/GuestsTable"),
    TenantsTable: () => import("@/components/Tenants/TenantsTable"),
    ReportDownloadForm: () => import("@/components/core/ReportDownloadForm"),
  },
  computed: {
    search: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedInput = val;
        }, 1000);
      },
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    isReadonly() {
      return this.$store.getters["app/isReadonly"];
    },
    defaultName() {
      return this.$store.state.app.places[0]
        ? this.$store.state.app.places[0].place_name
        : "";
    },
    name() {
      let name = "";
      if (this.isCompany && this.$store.state.app.user.company_name) {
        name = this.$store.state.app.user.company_name;
      } else {
        name = this.defaultName;
      }
      return name;
    },
    statePlaces() {
      return this.$store.state.app.places;
    },
    places() {
      let initPlaces = [...this.statePlaces];
      initPlaces.push({
        place_id: 0,
        place_name: this.$t("parking_history_tabs[2]"),
      });
      return initPlaces;
    },
    currentPlace() {
      return this.$store.state.guests.place;
    },
    tenantFlow() {
      return this.$store.getters["app/hasTenantsFlow"];
    },
    cyberFlow() {
      return this.$store.getters["app/isCyber"];
    },
  },
  watch: {
    currentPlace(newValue) {
      if (this.$store.getters["app/authorized"])
        this.$store.dispatch("guests/getGuests", newValue);
    },
  },
  methods: {
    openAddForm() {
      if (this.tenantFlow) {
        this.$router.push("/platform/your-parking/tenant").catch(() => {});
      } else if (this.cyberFlow) {
        this.$router.push("/platform/your-parking/cyberport-guest").catch(() => {});
      } else {
        this.$router.push("/platform/your-parking/guest").catch(() => {});
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
@import "@/scss/_guestsTable.scss";
</style>
