// import Vue from 'vue'
// import i18n from '@/plugins/i18n';
import actions from './actions'

const state = () => ({
    list: [],
    loading: true
});

// getters
const getters = {};

// mutations
const mutations = {
    set_list(state, payload) {
        state.list = payload
        state.loading = false
        return state
    },
    set_loader(state, payload) {
        state.loading = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};