import { base, cyberport } from '@/axios-api';
import store from '@/store/index';
import qs from 'qs';
import EventBus from '../../../event-bus';
import i18n from '@/plugins/i18n';

export default {
    getList({
        commit,
        dispatch
    }, params) {
        commit("set_loader", true)
        cyberport({
                url: '/platform/user',
                method: 'GET',
                params,
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body.users || [];
                    commit('set_list', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getFullInfo({ commit, dispatch }, id) {
        dispatch("getVehicles", id)
        dispatch("getStatistics", id)
        dispatch("getTransactions", id)
        dispatch("getPaymentMethods", id)
        dispatch("getParkings", {
            cyber_user_id: id,
            params: {
                from: new Date(
                    new Date().setMonth(new Date().getMonth() - 3)
                ).toISOString(),
                to: new Date().toISOString(),
                sort_by: 3,
                sort_direction: 0,
            }
        })
        dispatch("getSubscriptions", id)
        dispatch("getPromotions", id)
        dispatch("getReceipts", id)
        return commit
    },

    getVehicles({
        commit,
        dispatch
    }, id) {
        // commit("set_loader", true)
        cyberport({
                url: `/platform/user/${id}/vehicles`,
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body.vehicles || [];
                    commit('set_vehicles', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getStatistics({
        commit,
        dispatch
    }, id) {
        // commit("set_loader", true)
        cyberport({
                url: `/platform/user/${id}`,
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body.statistics || [];
                    commit('set_stats', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getTransactions({
        commit,
        dispatch
    }, id) {
        // commit("set_loader", true)
        cyberport({
                url: `/platform/user/${id}/transactions`,
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body.transactions || [];
                    commit('set_transactions', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getParkings({
        commit,
        dispatch
    }, { cyber_user_id, params }) {

        let place_id = store.state.app.places.map(p => p.place_id)
        params.guest_type = params.guest_type || store.getters['app/guestTypes']
        base({
                url: '/history',
                params: {
                    ...params,
                    place_id,
                    cyber_user_id,
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body || [];
                    commit('set_parkings', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getSubscriptions({
        commit,
        dispatch
    }, id) {
        // commit("set_loader", true)
        cyberport({
                url: `/platform/user/${id}/subscriptions`,
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body.subscriptions || [];
                    commit('set_subscriptions', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getPromotions({
        commit,
        dispatch
    }, id) {
        // commit("set_loader", true)
        cyberport({
                url: `/platform/user/${id}/promos`,
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body.promo_usages || [];
                    commit('set_promos', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getReceipts({
        commit,
        dispatch
    }, id) {
        // commit("set_loader", true)
        cyberport({
                url: `/platform/user/${id}/receipts`,
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body.receipts || [];
                    commit('set_receipts', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getPaymentMethods({
        commit,
        dispatch
    }, id) {
        // commit("set_loader", true)
        cyberport({
                url: `/platform/user/${id}/payment_methods`,
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body.payment_methods || [];
                    commit('set_payment_methods', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    notify({ commit }, data) {
        cyberport({
            url: `/platform/user/${data.id}/send_notification`,
            data,
            method: 'post',

        }).then(res => {
            if (res.status === 200) {
                EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Success!',
                    text: i18n.t(`user_notified`),
                    duration: 3000
                })
                return commit
            }
        }).catch(err => {
            console.log(err.response)
        })
    },
    deleteCar({ commit, dispatch }, { user_id, vehicle_id }) {
        cyberport({
            url: `/platform/user/${user_id}/vehicles/${vehicle_id}`,
            method: 'delete',

        }).then(res => {
            if (res.status === 200) {
                EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Success!',
                    text: i18n.t(`car_deleted`),
                    duration: 3000
                })
                dispatch("getVehicles")
                return commit
            }
        }).catch(err => {
            console.log(err.response)
            EventBus.$emit("notify", {
                group: 'general',
                title: '',
                text: i18n.t(`user_notified_error`),
                duration: 3000
            })

        })

    },
    updateCar({ commit, dispatch }, { user_id, data }) {
        cyberport({
            url: `/platform/user/${user_id}/vehicles/${data.vehicle.id}`,
            method: 'put',
            data

        }).then(res => {
            if (res.status === 200) {
                EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Success!',
                    text: i18n.t(`car_updated`),
                    duration: 3000
                })
                dispatch("getVehicles")
                return commit
            }
        }).catch(err => {
            console.log(err.response)
        })

    },
    deleteUser({ commit, dispatch }, id) {
        cyberport({
            url: `/platform/user/${id}`,
            method: 'delete',

        }).then(res => {
            if (res.status === 200) {
                EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Success!',
                    text: i18n.t(`user_deleted`),
                    duration: 3000
                })
                commit("reset_current")
                dispatch("getList", { is_deleted: false })
                return commit
            }
        }).catch(err => {
            console.log(err.response)
        })

    },
    updateUser({ commit, dispatch }, data) {
        cyberport({
            url: `/platform/user/${data.user.id}`,
            method: 'put',
            data

        }).then(res => {
            if (res.status === 200) {
                EventBus.$emit("notify", {
                    group: 'general',
                    title: 'Success!',
                    text: i18n.t(`user_updated`),
                    duration: 3000
                })
                dispatch("getList", { is_deleted: false })
                commit("reset_current")
            }
        }).catch(err => {
            console.log(err.response)
            if (err.response.data.code) {
                EventBus.$emit("notify", {
                    group: 'general',
                    title: '',
                    text: i18n.t(`phone_duplicate`),
                    duration: 3000
                })
            }
        })

    },
}