<template>
  <v-form ref="group" class="mt-6" autocomplete="off">
    <div class="d-flex justify-space-between align-center">
      <!-- <label class="modal-label">{{ $t("guest.company") }} </label> -->
      <label class="modal-label">{{ $t("group") }} </label>
      <v-btn @click="$emit('deleteGroup')" text class="modal-btn" v-if="!specialFlow">{{
        $t("delete")
      }}</v-btn>
    </div>
    <v-combobox
      :value="group.group_name"
      @input.native="($event) => $emit('groupChangedHandler', $event.target.value)"
      @change="($event) => $emit('groupChangedHandler', $event)"
      :items="
        groups.filter((c) =>
          group.group_name ? c.toLowerCase().includes(group.group_name.toLowerCase()) : c
        )
      "
      attach
      class="modal-input"
      :label="$t('group_name')"
      :rules="[() => !!group.group_name || $t('group') + $t('required')]"
    >
      <template slot="item" slot-scope="data">
        {{ data.item }} {{ data.item === "Drivers" ? $t("guest_driver") : "" }}
        {{ data.item === "One-time parking" ? $t("guest_onetime") : "" }}
        {{ data.item === "Over-parking guests" ? $t("guest_limited") : "" }}
      </template>
    </v-combobox>
  </v-form>
</template>

<script>
export default {
  name: "Group",
  props: {
    group: {
      default() {
        return {};
      },
    },
  },
  computed: {
    allGroups() {
      return this.$store.state.guests.groups;
    },
    groups() {
      let groups = [...this.allGroups].map((g) => g.group_name);
      let initList = [...groups]
        .slice()
        .sort((a, b) => a.localeCompare(b))
        .filter((i) => !i.includes("Drivers") && !i.includes("One-time parking"));
      let firstList = [...groups]
        .slice()
        .filter((i) => i.includes("Drivers") || i.includes("One-time parking"))
        .sort((a, b) => b.localeCompare(a));
      return firstList.concat(initList);
    },
    place() {
      return this.$store.state.app.places[0].place_id;
    },
    specialFlow() {
      let group = this.group.group_name;
      return (
        group.includes("Drivers") ||
        group.includes("Customers") ||
        group.includes("One-time") ||
        ((group.includes("Discounted") || group.includes("VIP") || group === "Monthly") &&
          this.place === 3146)
      );
    },
  },
  methods: {},
};
</script>
