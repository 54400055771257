<template>
  <div class="guest-tree" v-show="show">
    <div v-show="!reading && step === 5">
      <h3 class="main-title" style="font-size: 20px !important">
        {{ $t("form_check") }}
      </h3>
      <h3 class="modal-label reg-font">
        {{ $t("form_check_info") }}
      </h3>
    </div>
    <div v-show="!reading">
      <div class="d-flex justify-space-between align-center" style="height: 80px">
        <h3>
          <span class="main-title d-block" style="font-size: 20px !important">
            {{ $t("form_tree") }}
          </span>
          <span class="modal-label reg-font">
            {{ $t("form_see_tree") }}
          </span>
        </h3>
        <template>
          <v-select
            attach
            v-if="hasChosen && this.shownCheckbox"
            v-model="current_action"
            @input="chooseAction($event)"
            :style="{ maxWidth: '200px' }"
            class="modal-input"
            :items="actions"
            :label="$t('actions')"
          />
          <v-btn v-else text class="modal-btn" @click="shownCheckbox = !shownCheckbox">{{
            $t("click_to_modify")
          }}</v-btn>
        </template>
      </div>
    </div>

    <div v-if="!reading" class="row tab-0">
      <!-- <div class="d-flex align-center"> -->
      <v-checkbox
        v-if="shownCheckbox"
        :value="chosenAll"
        @change="chooseAll($event, false)"
        class="filter-checkbox ma-0 mr-3 mb-1"
        :class="chosenAll ? 'active' : ''"
      />
      <h3 class="modal-label first d-flex align-center justify-start">
        <span class="mr-3">{{ group.group_name }}</span>
        <svg
          width="18px"
          viewBox="0 0 20 20"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
              id="1_12-Additional-search-Show-more"
              transform="translate(-18.000000, -873.000000)"
              fill-rule="nonzero"
            >
              <g id="Group-2-Copy-3" transform="translate(0.000000, 861.000000)">
                <g id="Combined-Shape" transform="translate(16.000000, 10.000000)">
                  <path
                    d="M15,4 L15,5 C15,6.0543618 15.8158778,6.91816512 16.8507377,6.99451426 L17,7 C18.0543618,7 18.9181651,6.18412221 18.9945143,5.14926234 L19,5 L19,4 C20.6568542,4 22,5.34314575 22,7 L22,18 C22,20.1421954 20.3160315,21.8910789 18.1996403,21.9951047 L18,22 L6,22 C3.85780461,22 2.10892112,20.3160315 2.00489531,18.1996403 L2,18 L2,7 C2,5.34314575 3.34314575,4 5,4 L5,5 C5,6.1045695 5.8954305,7 7,7 C8.0543618,7 8.91816512,6.18412221 8.99451426,5.14926234 L9,5 L9,4 L15,4 Z M20,9 L4,9 L4,18 C4,19.0016437 4.73632971,19.8313084 5.69722565,19.9772306 L5.85073766,19.9945143 L6,20 L18,20 C19.0543618,20 19.9181651,19.1841222 19.9945143,18.1492623 L20,18 L20,9 Z M18.276953,15.2890567 L18.276953,17.7890567 L15.776953,17.7890567 L15.776953,15.2890567 L18.276953,15.2890567 Z M8.26702667,15.2821605 L8.26702667,17.7821605 L5.76702667,17.7821605 L5.76702667,15.2821605 L8.26702667,15.2821605 Z M13.2751767,15.2821605 L13.2751767,17.7821605 L10.7751767,17.7821605 L10.7751767,15.2821605 L13.2751767,15.2821605 Z M18.2645887,11.0068962 L18.2645887,13.5068962 L15.7645887,13.5068962 L15.7645887,11.0068962 L18.2645887,11.0068962 Z M8.25466232,11 L8.25466232,13.5 L5.75466232,13.5 L5.75466232,11 L8.25466232,11 Z M13.2628124,11 L13.2628124,13.5 L10.7628124,13.5 L10.7628124,11 L13.2628124,11 Z M7,2 C7.55228475,2 8,2.44771525 8,3 L8,5 C8,5.55228475 7.55228475,6 7,6 C6.44771525,6 6,5.55228475 6,5 L6,3 C6,2.44771525 6.44771525,2 7,2 Z M17,2 C17.5522847,2 18,2.44771525 18,3 L18,5 C18,5.55228475 17.5522847,6 17,6 C16.4477153,6 16,5.55228475 16,5 L16,3 C16,2.44771525 16.4477153,2 17,2 Z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </h3>
      <v-btn
        v-show="!isReadonly && !shownCheckbox"
        @click="
          reset();
          chooseAll(true, true);
          edit(0);
        "
        text
        class="modal-btn ml-auto"
        >{{ $t("change") }}</v-btn
      >
    </div>
    <div v-for="(subgroup, sIndex) in group.subgroups" :key="sIndex">
      <div class="row tab-1">
        <div class="d-flex align-center">
          <v-checkbox
            v-if="shownCheckbox"
            :value="chosen.subgroups.includes(subgroup)"
            @change="choose($event, subgroup, 'subgroups', sIndex, null, false)"
            class="filter-checkbox ma-0 mr-3 mb-1"
            :class="chosen.subgroups.includes(subgroup) ? 'active' : ''"
          />
          <h3 class="modal-label first">
            {{ subgroup.group_name || "N/A" }}
          </h3>
        </div>
        <v-btn
          v-show="!isReadonly && !shownCheckbox"
          @click="
            reset();
            choose(true, subgroup, 'subgroups', sIndex, null, true);
            edit(1);
          "
          text
          class="modal-btn ml-auto"
          >{{ $t("change") }}</v-btn
        >
      </div>

      <div
        v-for="(person, personIndex) in [...subgroup.guests].sort((a, b) =>
          a.guest_name.localeCompare(b.guest_name)
        )"
        :key="personIndex"
        class="tab-2-container"
      >
        <div class="row tab-2" v-if="person.show || !hasSearch">
          <!-- <div class="d-flex align-center"> -->
          <v-checkbox
            v-if="shownCheckbox"
            :value="chosen.guests.includes(person)"
            @change="
              choose(
                $event,
                person,
                'guests',
                sIndex,
                subgroup.guests.indexOf(person),
                false
              )
            "
            class="filter-checkbox ma-0 mr-3 mb-1"
            :class="chosen.guests.includes(person) ? 'active' : ''"
          />
          <h3 class="modal-label first">
            <span> {{ person.guest_name || $t(`unnamed`) }}</span>
            <svg
              style="margin-left: 12px"
              width="18px"
              viewBox="0 0 20 20"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
            >
              <g
                id="Page-1"
                stroke="none"
                stroke-width="1"
                fill="none"
                fill-rule="evenodd"
              >
                <g
                  id="1_12-Additional-search-Show-more"
                  transform="translate(-18.000000, -873.000000)"
                  fill-rule="nonzero"
                >
                  <g id="Group-2-Copy-3" transform="translate(0.000000, 861.000000)">
                    <g id="Combined-Shape" transform="translate(16.000000, 10.000000)">
                      <path
                        d="M15,4 L15,5 C15,6.0543618 15.8158778,6.91816512 16.8507377,6.99451426 L17,7 C18.0543618,7 18.9181651,6.18412221 18.9945143,5.14926234 L19,5 L19,4 C20.6568542,4 22,5.34314575 22,7 L22,18 C22,20.1421954 20.3160315,21.8910789 18.1996403,21.9951047 L18,22 L6,22 C3.85780461,22 2.10892112,20.3160315 2.00489531,18.1996403 L2,18 L2,7 C2,5.34314575 3.34314575,4 5,4 L5,5 C5,6.1045695 5.8954305,7 7,7 C8.0543618,7 8.91816512,6.18412221 8.99451426,5.14926234 L9,5 L9,4 L15,4 Z M20,9 L4,9 L4,18 C4,19.0016437 4.73632971,19.8313084 5.69722565,19.9772306 L5.85073766,19.9945143 L6,20 L18,20 C19.0543618,20 19.9181651,19.1841222 19.9945143,18.1492623 L20,18 L20,9 Z M18.276953,15.2890567 L18.276953,17.7890567 L15.776953,17.7890567 L15.776953,15.2890567 L18.276953,15.2890567 Z M8.26702667,15.2821605 L8.26702667,17.7821605 L5.76702667,17.7821605 L5.76702667,15.2821605 L8.26702667,15.2821605 Z M13.2751767,15.2821605 L13.2751767,17.7821605 L10.7751767,17.7821605 L10.7751767,15.2821605 L13.2751767,15.2821605 Z M18.2645887,11.0068962 L18.2645887,13.5068962 L15.7645887,13.5068962 L15.7645887,11.0068962 L18.2645887,11.0068962 Z M8.25466232,11 L8.25466232,13.5 L5.75466232,13.5 L5.75466232,11 L8.25466232,11 Z M13.2628124,11 L13.2628124,13.5 L10.7628124,13.5 L10.7628124,11 L13.2628124,11 Z M7,2 C7.55228475,2 8,2.44771525 8,3 L8,5 C8,5.55228475 7.55228475,6 7,6 C6.44771525,6 6,5.55228475 6,5 L6,3 C6,2.44771525 6.44771525,2 7,2 Z M17,2 C17.5522847,2 18,2.44771525 18,3 L18,5 C18,5.55228475 17.5522847,6 17,6 C16.4477153,6 16,5.55228475 16,5 L16,3 C16,2.44771525 16.4477153,2 17,2 Z"
                      ></path>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </h3>
          <!-- </div> -->
          <h3 class="modal-label reg-font tree-col2">
            {{ person.phone }}
          </h3>
          <h3 class="modal-label reg-font tree-date">
            <template v-if="person.subscription.price_option_id">
              {{
                person.subscription.active_from
                  ? $moment(person.subscription.active_from).format("DD.MM.YYYY")
                  : ""
              }}
              {{
                person.subscription.active_until
                  ? " - " + $moment(person.subscription.active_until).format("DD.MM.YYYY")
                  : ""
              }}</template
            >
          </h3>
          <p class="modal-label reg-font mb-1 tree-last-col">
            {{ person.email }}
          </p>
          <v-btn
            v-show="!isReadonly && !shownCheckbox"
            @click="
              reset();
              choose(
                true,
                person,
                'guests',
                sIndex,
                subgroup.guests.indexOf(person),
                true
              );
              edit(1);
            "
            text
            class="modal-btn"
            >{{ $t("change") }}</v-btn
          >
        </div>

        <div
          v-for="(car, carIndex) in [...person.cars].sort((a, b) =>
            a.plate_number.localeCompare(b.plate_number)
          )"
          :key="carIndex"
        >
          <div class="row tab-3" v-if="car.show || !hasSearch">
            <!-- <div class="d-flex align-center"> -->
            <v-checkbox
              v-if="shownCheckbox"
              :value="chosen.cars.includes(car)"
              @change="
                choose(
                  $event,
                  car,
                  'cars',
                  sIndex,
                  subgroup.guests.indexOf(person),
                  false
                )
              "
              class="filter-checkbox ma-0 mr-3 mb-1"
              :class="chosen.cars.includes(car) ? 'active' : ''"
            />
            <h3 class="modal-label first d-flex align-center">
              <span style="width: 100px">{{ car.plate_number }}</span>
              <span class="reg-font mr-3" v-if="$store.getters['app/hasZones']">
                {{ $t("zone") }}
                {{
                  zones
                    ? zones.slice().filter((z) => z.zone_id === car.zone_id)[0]
                      ? zones.slice().filter((z) => z.zone_id === car.zone_id)[0]
                          .zone_name
                      : ""
                    : ""
                }}
              </span>
            </h3>
            <!-- </div> -->
            <h3 class="modal-label reg-font tree-col2">
              <template v-if="car.type">{{ $t(`car_types[${car.type}]`) }}</template>
            </h3>
            <h3 class="modal-label reg-font tree-date">
              <template v-if="car.engine_type">{{
                $t(`engine_types[${car.engine_type - 1}]`)
              }}</template>
            </h3>
            <h3 class="modal-label reg-font tree-last-col">
              {{ car.manufacturer }}
            </h3>
            <v-btn
              v-show="!isReadonly && !shownCheckbox"
              @click="
                reset();
                choose(true, car, 'cars', sIndex, subgroup.guests.indexOf(person), true);
                edit(2);
              "
              text
              class="modal-btn mb-2"
              >{{ $t("change") }}</v-btn
            >
          </div>
        </div>
      </div>
    </div>
    <div v-if="!reading" class="d-flex align-center justify-end">
      <v-btn @click="back" text class="flat-btn modal-footer-btn mr-6">{{
        $t("back")
      }}</v-btn>
      <v-btn :loading="sending" @click="send" text class="main-btn my-6 px-4 py-2">{{
        $t("done")
      }}</v-btn>
    </div>
    <Datepicker ref="datePicker" @save="saveDates" :solo="true" />
  </div>
</template>

<script>
import { base } from "@/axios-api";
export default {
  components: {
    Datepicker: () => import("@/components/core/Datepicker.vue"),
  },
  name: "GuestTree",
  props: {
    group: {},
    show: {
      type: Boolean,
      default: true,
    },
    reading: {
      type: Boolean,
      default: false,
    },
    hasSearch: {
      type: String,
      default: "",
    },
    step: {
      type: Number,
      default: 0,
    },
    sending: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      current_action: undefined,
      chosen: {
        subgroups: [],
        guests: [],
        cars: [],
      },
      shownCheckbox: false,
      chosenAll: false,
      busyDelete: false,
      currentlyParked: [],
    };
  },
  computed: {
    place() {
      return this.$store.state.places[0];
    },
    zones() {
      return this.$store.state.app.zones;
    },
    actions() {
      return [
        this.$t("change_end_date"),
        this.$t("edit_selected"),
        this.$t("non_pbnb"),
        this.$t("delete"),
      ];
    },
    carTypes() {
      return this.$store.state.app.car_types;
    },
    hasChosen() {
      return (
        this.chosenAll ||
        this.chosen.subgroups.length  ||
        this.chosen.guests.length  ||
        this.chosen.cars.length 
      );
    },
    isReadonly() {
      return this.$store.getters["app/isReadonly"];
    },
    mode() {
      return Number(this.group.is_simple);
    },
  },
  watch: {
    show() {
      if (!this.hasChosen) {
        this.reset();
      }
    },
  },
  methods: {
    edit(tab) {
      this.changeContentHandler();
      this.$store.commit("guests/set_guest_tab", tab);
      this.$emit("clickedEdit", tab);
    },
    back() {
      this.$store.commit("guests/set_guest_tab", 2);
    },
    redirectToResult() {
      this.$store.commit("guests/set_guest_tab", 4);
    },
    reset() {
      this.current_action = undefined;
      this.shownCheckbox = false;

      for (let key of Object.keys(this.chosen)) {
        this.chosen[key].map((choice) => {
          choice.chosen = false;
        });
      }
      this.chosen = {
        subgroups: [],
        guests: [],
        cars: [],
      };

      this.busyDelete = false;
    },
    send() {
      this.$emit("clickedSend");
      this.reset();
    },
    chooseAll(check, nest) {
      this.group.subgroups.map((d) => {
        this.choose(check, d, "subgroups", null, nest);
      });
      this.chosenAll = check;
    },
    choose(check, item, depth, subgroup, guest, nest) {
      this.chosen[depth].push(item);
      if (check) {
        switch (depth) {
          case "subgroups":
            if (nest) {
              this.chooseAll();
            }
            item.guests.map((g) => {
              this.chosen.guests.push(g);
              g.cars.map((c) => {
                this.chosen.cars.push(c);
              });
            });
            break;
          case "guests":
            if (nest) {
              this.chosen.subgroups.push(this.group.subgroups[subgroup]);
            }
            item.cars.map((c) => {
              this.chosen.cars.push(c);
            });
            break;
          case "cars":
            if (nest) {
              this.chosen.subgroups.push(this.group.subgroups[subgroup]);
              this.chosen.guests.push(this.group.subgroups[subgroup].guests[guest]);
            }
            break;
          default:
            break;
        }
      } else {
        let index = this.chosen[depth].indexOf(item);
        this.chosen[depth].splice(index, 1);

        if (item.subgroups) {
          this.chosen = {
            subgroups: [],
            guests: [],
            cars: [],
          };
          this.chosenAll = false;
        } else if (item.guests) {
          let guestIDs = [];
          let carIDs = [];
          item.guests.map((g) => {
            guestIDs.push(g.guest_id);
            g.cars.map((c) => {
              carIDs.push(c.id);
            });
          });
          this.chosen.guests = this.chosen.guests.filter(
            (i) => guestIDs.includes(i.guest_id) === false
          );
          this.chosen.cars = this.chosen.cars.filter(
            (i) => carIDs.includes(i.id) === false
          );
        } else if (item.cars) {
          let carIDs = [];
          item.cars.map((c) => {
            this.chosen.cars.push(c);
            carIDs.push(c.id);
          });
          this.chosen.cars = this.chosen.cars.filter(
            (i) => carIDs.includes(i.id) === false
          );
          this.chosen.guests = this.chosen.guests.filter(
            (i) => i.guest_id !== item.guest_id
          );
        }
      }
    },
    openDatepicker() {
      this.$refs.datePicker.show();
    },
    chooseAction(event) {
      let action = this.actions.indexOf(event);

      switch (action) {
        case 0:
          this.openDatepicker();
          break;
        case 1:
          this.changeContentHandler();
          break;
        case 2:
          this.setGuestTypeChosen();
          break;
        case 3:
          this.deleteChosen();
          break;
      }
    },
    setGuestTypeChosen() {
      this.group.subgroups.map((d) => {
        d.guests.map((g) => {
          if (this.chosen.guests.includes(g)) {
            g.guest_type = g.guest_type === 3 ? 1 : 3;
            g.selected = true;
          }
        });
      });
    },
    saveDates(date) {
      this.group.subgroups.map((d) => {
        d.guests.map((g) => {
          if (this.chosen.guests.filter((chosen) => chosen.guest_id === g.guest_id)[0]) {
            g.selected = true;
            g.end_date = date;
          }
          g.cars.map((c) => {
            if (this.chosen.cars.filter((chosen) => chosen.id === c.id)[0]) {
              c.end_date = date;
              c.status = 2;
              if (c.one_time) {
                g.selected = true;
                c.start_date = date;
              }
            }
          });
        });
      });
    },
    changeContentHandler() {
      for (let depth of Object.keys(this.chosen)) {
        for (let item of this.chosen[depth]) {
          item.chosen = true;
        }
      }
      let newChosen = { ...this.chosen };
      this.$emit("changeSelected", newChosen);
    },
    deleteCompany() {
      if (this.deletable)
        this.$store.dispatch("guests/deleteGroup", {
          group: this.group.group_id,
          notify: false,
        });
      this.$store.commit("guests/delete_selected_success");
      this.$router.push("/platform/your-parking");
    },
    deleteSubgroup(subgroup) {
      let busyWatcher = setInterval(() => {
        if (this.busyDelete === false) {
          this.group.subgroups = this.group.subgroups.filter(
            (i) => i.group_id !== subgroup.group_id
          );
          this.$store.dispatch("guests/deleteGroup", {
            group: subgroup.group_id,
            notify: false,
          });
          if (!this.chosenAll) {
            this.$store.commit("guests/delete_selected_success");
          }
          clearInterval(busyWatcher);
        }
      }, 500);
    },
    async checkParkedGuests(reqs) {
      // console.log("checking parked quests " + JSON.stringify(reqs));
      if (reqs.length === 0) {
        return [];
      }
      const responses = [];
      let data;
      for (let i = 0; i < reqs.length; i++) {
        let person = reqs[i];
        data = {
          guests: [
            {
              ...person,
              cars: this.reformatCars(person.cars),
            },
          ],
          forced: false,
        };
      }
      responses.push(
        await base({
          url: "/guests",
          method: "PUT",
          data,
        }).then((response) => {
          if (response.status == 200) {
            if (response.data.code === 107) {
              let newData = [...this.currentlyParked];
              [...response.data.content].map((c) => {
                newData.push(" " + c.plate_number);
              });
              this.currentlyParked = newData;
              return true;
            } else {
              return false;
            }
          }
        })
      );
      return responses;
    },

    deleteGuests(forced, reqs) {
      for (let i = 0; i < reqs.length; i++) {
        let depIndex = 0;
        this.group.subgroups.map((d) => {
          d.guests.map((g) => {
            if (g.guest_id === reqs[i].guest_id) {
              depIndex = this.group.subgroups.indexOf(d);
              this.busyDelete = true;
            }
          });
        });
        this.$store.dispatch("guests/deleteGuest", {
          guest: reqs[i],
          notify: false,
          forced,
        });
        this.group.subgroups[depIndex].guests = this.group.subgroups[depIndex].guests.map(
          (g) => {
            g.show = g.guest_id !== reqs[i].guest_id;
            return g;
          }
        );
      }
      this.busyDelete = false;
      if (reqs.length ) {
        this.$store.commit("guests/reset_status", true);
        this.redirectToResult();
      }
    },

    deleteChosenGuests() {
      this.sending = true;
      const reqs = [...this.chosen.guests];
      this.checkParkedGuests(reqs).then((checks) => {
        let hasParked = checks.some((e) => e === true);
        if (hasParked) {
          this.$confirm({
            message: this.$t("guest_del_prompt_parked", {
              cars: this.currentlyParked,
            }),
            button: {
              no: this.$t("cancel"),
              yes: this.$t("delete"),
            },
            callback: (confirm) => {
              if (confirm) {
                this.deleteGuests(true, reqs);
              }
            },
          });
        } else {
          this.deleteGuests(false, reqs);
        }
      });
    },

    deleteChosenCars() {
      this.sending = true;
      let finishedWatcher = setInterval(() => {
        if (this.busyDelete === false) {
          let reqs = [];
          let guests = [];
          let cars = [...this.chosen.cars].map((car) => car.id);
          this.group.subgroups.map((d) => {
            d.guests.map((g) => {
              if (this.chosen.guests.some((el) => el.guest_id === g.guest_id)) {
                return;
              }
              g.cars.map((gCar) => {
                if (cars.indexOf(gCar.id) !== -1) {
                  gCar.status = 3;
                  reqs.push(g);
                  guests.push({
                    ...g,
                    cars: this.reformatCars(g.cars),
                  });
                }
              });
            });
          });

          this.checkParkedGuests(reqs).then((checks) => {
            if (checks && checks.length ) {
              let hasParked = checks.some((e) => e === true);
              if (hasParked) {
                this.$confirm({
                  message: this.$t("guest_del_prompt_parked", {
                    cars: this.currentlyParked,
                  }),
                  button: {
                    no: this.$t("cancel"),
                    yes: this.$t("delete"),
                  },
                  callback: (confirm) => {
                    if (confirm) {
                      this.$store.dispatch("guests/changeGuest", {
                        guests,
                        forced: true,
                      });
                      this.$store.commit("guests/reset_status", true);
                      this.redirectToResult();
                    }
                  },
                });
              } else {
                this.$store.dispatch("guests/changeGuest", {
                  guests,
                  forced: false,
                });
                this.$store.commit("guests/reset_status", true);
                this.redirectToResult();
              }
            }
          });
          clearInterval(finishedWatcher);
        }
      }, 500);
    },

    deleteChosen() {
      this.$confirm({
        message: this.$t("form_delete_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteChosenGuests();
            this.deleteChosenCars();
            this.chosen.subgroups.map((d) => {
              this.deleteSubgroup(d);
            });
            if (this.chosenAll) {
              this.deleteCompany();
            }
          }
        },
      });
    },
    reformatCars(cars) {
      return cars
        ? cars.map((car) => {
            return {
              ...car,
            };
          })
        : [];
    },
  },
};
</script>
