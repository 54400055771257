<template>
  <div class="user-content">
    <CarForm :selected="selected" v-if="form" ref="form" @hide="hideForm" />
    <template v-else>
      <div class="d-flex align-center justify-space-between mb-6">
        <h3 class="main-title mb-0">{{ $t("car_info") }}</h3>
        <v-btn text class="main-btn px-6 py-2" @click="openForm()"
          >{{ $t("add") }} {{ $t("car") }}</v-btn
        >
      </div>
      <div class="user-cars-container">
        <div class="user-car" v-for="car in cars" :key="car.id">
          <div class="py-1">
            {{ $t("car_plate") }}: <b>{{ car.plate_number }}</b>
          </div>
          <div class="py-1">{{ $t("car_type") }}: {{ $t(`car_types[${car.type}]`) }}</div>
          <div class="py-1" v-if="car.engine_type">
            {{ $t("engine") }}: {{ $t(`engine_types[${car.engine_type - 1}]`) }}
          </div>
          <div class="py-1">{{ $t("car_brand") }}: {{ car.manufacturer || "N/A" }}</div>
          <div class="d-flex justify-space-between align-center py-1">
            <span class="park-status">{{
              $t(`parking_status[${Number(car.parked)}]`)
            }}</span>
            <span class="error-message" v-if="car.deleted">{{ $t("deleted") }}</span>
          </div>
          <v-divider class="my-4" />
          <div class="d-flex justify-center align-center py-1">
            <v-btn text class="ml-0 mr-6 flat-btn" @click="openForm(car)">{{
              $t("edit")
            }}</v-btn>
            <v-btn text class="mx-0 flat-btn error-message" @click="deleteCar(car)">{{
              $t("delete")
            }}</v-btn>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "UserGeneral",
  data: () => {
    return {
      form: false,
      selected: {},
    };
  },
  components: {
    CarForm: () => import("@/components/Users/CarForm.vue"),
  },
  computed: {
    current() {
      return this.$store.state.users.current;
    },
    cars() {
      return this.$store.state.users.vehicles;
    },
  },
  methods: {
    openForm(car) {
      this.selected = car;
      this.form = true;
    },
    hideForm() {
      this.selected = {};
      this.form = false;
    },
    deleteCar(car) {
      this.$confirm({
        message: this.$t("car_delete_prompt", {
          car: car.plate_number,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("users/deleteCar", {
              user_id: this.current.id,
              vehicle_id: car.id,
            });
          }
        },
      });
    },
  },
};
</script>

<style></style>
