import { base, cyberport } from '@/axios-api'
import store from '@/store/index';
import qs from 'qs';

export default {

    getList({ commit, dispatch }) {
        if (store.getters['app/isCyber']) {
            dispatch('getListCyber')
        } else {
            dispatch('getListDefault')
        }
        return commit
    },
    getListCyber({ commit }) {
        cyberport({
            url: "/platform/devices",
            method: 'GET',
            params: {
                place_id: store.state.app.places.map(p => p.place_id)
            },
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat'
                })
            },
        }).then((response) => {
            if (response.status === 200) {
                let devices = response.data.body || []
                commit("set_list", devices)
            }
        })
    },
    getListDefault({ commit }) {
        base({
            url: "/devices",
            method: 'GET',
            params: {
                place_id: store.state.app.places.map(p => p.place_id)
            },
            paramsSerializer: params => {
                return qs.stringify(params, {
                    arrayFormat: 'repeat'
                })
            },
        }).then((response) => {
            if (response.status === 200) {
                let devices = response.data.body || []
                commit("set_list", devices)
            }
        })
    },
}