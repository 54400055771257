import store from '@/store';

const formatPlate = (str) => {
	if (store.state.app.places[0].currency === 'HKD') {
		return str.toUpperCase()
			.replaceAll(/ /g, "")
			.replaceAll("I", "1")
			.replaceAll("O", "0")
	} else {
		return str;
	}
}

export { formatPlate };
