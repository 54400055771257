<template>
  <div class="popup checkout" style="z-index: 20; position: fixed" v-if="show && !inFlow">
    <div class="modal-overlay" style="opacity: 0.16" @click="hide" />
    <div class="modal-content">
      <div class="d-flex align-center justify-space-between mb-3">
        <h4 class="main-title">{{ $t("top_up") }}</h4>

        <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
      </div>
      <v-form class="topup-container" ref="form">
        <v-text-field
          class="modal-input"
          type="number"
          min="0"
          v-model="input.amount"
          :rules="[
            () => (input.amount && input.amount % 50 === 0) || $t('amount_octopus_error'),
          ]"
          :label="$t('amount')"
        />
        <v-btn
          :loading="loading"
          style="min-width: 100%"
          class="main-btn py-2 my-4"
          text
          @click="topupOctopus"
          >{{ $t(`top_up`) }}</v-btn
        >
      </v-form>
    </div>
  </div>
  <OctopusFlow v-else-if="inFlow" @hide="hide" />
</template>

<script>
import { base } from "@/axios-api";
import { Centrifuge } from "centrifuge";
const CENTR_HOST = "wss://api.parkingbnb.world/tv/centrifugo/connection/websocket";
export default {
  name: "TopupOctopus",
  props: {
    show: {
      default: false,
    },
  },
  data() {
    return {
      centrifuge: null,
      loading: false,
      inFlow: false,
      input: {
        amount: null,
        payment_method: "octopus",
      },
    };
  },
  components: {
    OctopusFlow: () => import("@/components/Wallet/OctopusFlow.vue"),
  },
  computed: {
    token() {
      return this.$store.state.app.cs_token;
    },
    refreshToken() {
      return this.$store.state.app.cs_refresh_token;
    },
  },
  methods: {
    hide() {
      if (this.centrifuge) this.centrifuge.disconnect();
      this.inFlow = false;
      this.loading = false;
      this.input = {
        amount: null,
        payment_method: "octopus",
      };
      this.$emit("hide");
    },
    topupOctopus() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;

      base({
        url: "/customer-service/topup",
        method: "POST",
        data: { ...this.input, amount: Number(this.input.amount) * 100 },
      })
        .then((response) => {
          if (response.status === 200) {
            this.connect();
            this.inFlow = true;
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
        });
    },

    connect() {
      console.log("connect centrifuge");
      if (this.centrifuge && this.centrifuge.state === "connecting") {
        console.log("aborted counter connection");
        return;
      }
      this.centrifuge = new Centrifuge(CENTR_HOST, {
        token: this.token,
        getToken: () => this.getToken(),
      });

      this.centrifuge.connect();
      this.centrifuge.on("connected", (data) => {
        console.log(`connected ${JSON.stringify(data)}`);
        this.connected = false;
      });
      this.centrifuge.on("connecting", (data) => {
        console.log(`connecting ${JSON.stringify(data)}`);
      });
      this.centrifuge.on("error", (err) => {
        console.log(`error ${JSON.stringify(err)}`);
        if (err.error.code === 3500 || err.error.code === 109) {
          // CODE 2 = TRANSPORT CLOSED
          // CODE 3500 = INVALID TOKEN
          // CODE 109 = EXPIRED TOKEN
          if (!this.connecting && this.$store.getters["app/authorized"]) {
            console.log("auto relogin");
            this.$store.dispatch("app/counterLogin").then(() => {
              this.connect();
            });
          }
        }
      });
      this.centrifuge.on("disconnected", (data) => {
        console.log(`disconnected ${JSON.stringify(data)}`);
      });
      this.centrifuge.on("publication", (data) => {
        let push = data.data;
        // console.log(push);
        this.handlePush(push);
      });
    },

    handlePush(push) {
      console.log(push);
      if (push.event === "parking_payment") {
        this.$store.commit("wallet/set_current_push", push.content);
        // this.popup = "octopus";
      } else if (push.event === "last_transactions") {
        this.$store.commit("wallet/set_current_card", push.content);
        // this.popup = "transactions";
      }
    },
    getToken() {
      return new Promise((resolve, reject) => {
        base({
          url: "/auth/token/refresh-centrifugo",
          params: { token: this.refreshToken },
        })
          .then((res) => {
            if (!res.status === 200) {
              throw new Error(`Unexpected status code ${res.status}`);
            }
            let data = res.data.body;
            this.$store.commit("app/cs_auth_success", data);
            // console.log(data.access_token);
            resolve(data.access_token);
          })
          .catch((err) => {
            reject(err);
            this.$store.commit("app/logout");
            // location.reload();
          });
      });
    },
  },
};
</script>
