import { pwa } from '@/axios-api';
import EventBus from '../../../event-bus';
import i18n from '@/plugins/i18n';
import qs from 'qs'

export default {
    getTenants({
        commit,
        dispatch
    }) {
        pwa({
                url: '/platform/tenants/details',
                method: 'GET',
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body.tenants || [];
                    data = data.map(i => {
                        return {
                            ...i,
                            visits: i.visits || []
                        }
                    })
                    commit('get_tenants', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    addTenants({
        commit,
        dispatch
    }, { data, visitors }) {
        pwa({
                url: '/platform/tenants',
                method: "POST",
                data
            })
            .then(response => {
                if (response.status == 200) {

                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('tenant_added'),
                        duration: 3000
                    })
                    console.log(response.data.body)
                    let user = response.data.body.id[0]
                    dispatch("addVisits", { data: visitors, user })
                    dispatch("getTenants")
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                console.log(err);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    updateTenant({
        commit,
        dispatch
    }, data) {
        pwa({
                url: '/platform/tenants',
                method: 'PUT',
                data
            })
            .then(response => {
                if (response.status == 200) {
                    commit('change_tenant')
                    dispatch('getTenants')
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('tenant_changed'),
                        duration: 3000
                    })
                }
            })
            .catch((err) => {
                console.log(err.response);
                console.log(err);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    deleteTenant({
        commit,
        dispatch
    }, data) {
        pwa({
                url: '/platform/tenants',
                method: "DELETE",
                data
            })
            .then(response => {
                if (response.status == 200) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('tenant_deleted'),
                        duration: 3000
                    })
                    dispatch("getTenants")
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response.data.code === 2811) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Oops!',
                        text: i18n.t('tenant_have_parked'),
                        duration: 3000
                    })
                } else {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Oops!',
                        text: '',
                        duration: 3000
                    })
                }
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    addVisits({
        commit,
        dispatch
    }, { data, user }) {
        pwa({
                url: `/platform/visits/${user}`,
                method: "POST",
                data
            })
            .then(response => {
                if (response.status == 200) {
                    dispatch('getTenants')
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response.data.code === 2811) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Oops!',
                        text: i18n.t('visitor_exists'),
                        duration: 3000
                    })
                }
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    updateVisits({
        commit,
        dispatch
    }, data) {
        pwa({
                url: `/platform/visits`,
                method: 'PUT',
                data
            })
            .then(response => {
                if (response.status == 200) {
                    commit('change_tenant')
                    dispatch('getTenants')
                }
            })
            .catch((err) => {
                console.log(err.response);
                console.log(err);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    deleteVisitor({
        commit,
        dispatch
    }, data) {
        pwa({
                url: '/platform/visits',
                method: "DELETE",
                data
            })
            .then(response => {
                if (response.status == 200) {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Success!',
                        text: i18n.t('visitor_deleted'),
                        duration: 3000
                    })
                    dispatch("getTenants")
                    return commit
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
    getVisitsHistory({
        commit,
        dispatch
    }, params) {
        commit("set_history_loader", true)
        pwa({
                url: '/platform/history',
                method: "GET",
                params,
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then(response => {
                if (response.status == 200) {
                    let history = response.data.body.visit_history || []
                    commit("set_visits_history", history)
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
}