<template>
  <div class="popup record-popup wide-popup" style="z-index: 20">
    <div class="modal-overlay" style="opacity: 0.16" @click="hide" />
    <div class="modal-content">
      <div class="d-flex align-center justify-space-between mb-3">
        <h4 class="main-title">{{ $t("last_transactions") }}</h4>

        <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
      </div>
      <div class="transactions-container">
        <div class="header">
          <h3 class="parking-subtitle mb-2" v-if="currentCard.octopus_data">
            {{ $t("octopus_no") }}: {{ currentCard.octopus_data.card_no }}
          </h3>
          <h3
            class="parking-subtitle mb-2"
            v-if="currentCard.octopus_data && currentCard.octopus_data.octopus_type === 0"
          >
            {{ $t("remaining_value") }}: ${{
              round(currentCard.octopus_data.avail_fund / 100, 1)
            }}
          </h3>
        </div>
        <div class="table-header d-flex">
          <span class="cell">{{ $t("number_sign") }}</span>
          <span class="cell">{{ $t("date_time") }}</span>
          <span class="cell">{{ $t("amount") }}</span>
          <span class="cell">{{ $t("device_no") }}</span>
        </div>
        <template v-if="!records.length">
          <v-skeleton-loader
            v-for="i in 5"
            :key="i"
            type="text"
            class="mb-0"
            height="24"
          />
        </template>
        <div class="trans-record" v-for="(record, index) in records" :key="record.id">
          <span class="cell"
            >{{ index }}
            <span v-if="record.machine_id === currentCard.device_id">#</span></span
          >
          <span class="cell">{{ $moment(record.time).format("DD.MM.YYYY HH:mm") }}</span>
          <span class="cell"
            >${{ record.amount > 0 ? "+" : "" }}{{ round(record.amount / 100, 1) }}</span
          >
          <span class="cell">{{ record.machine_id }} </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OctopusTransactions",
  data() {
    return {};
  },
  computed: {
    records() {
      return this.currentCard.transaction_log || [];
    },
    currentCard() {
      return this.$store.state.wallet.current_payment_card;
      // return {
      //   octopus_data: {
      //     card_no: 57127526,
      //     octopus_type: 0,
      //     avail_fund: 100,
      //   },
      //   device_id: "FF66",
      //   transaction_log: [
      //     {
      //       sp_type: 180,
      //       amount: 500,
      //       time: "2023-03-09T08:25:07Z",
      //       machine_id: "FF66",
      //       service_info: "00005F0000",
      //     },
      //     {
      //       sp_type: 180,
      //       amount: -1000,
      //       time: "2023-03-09T08:24:05Z",
      //       machine_id: "FF66",
      //       service_info: "005E000000",
      //     },
      //     {
      //       sp_type: 180,
      //       amount: -100,
      //       time: "2023-03-09T07:53:17Z",
      //       machine_id: "FF66",
      //       service_info: "005B000000",
      //     },
      //     {
      //       sp_type: 180,
      //       amount: 0,
      //       time: "2023-03-09T07:52:52Z",
      //       machine_id: "FF66",
      //       service_info: "005A000000",
      //     },
      //     {
      //       sp_type: 180,
      //       amount: 0,
      //       time: "2023-03-09T07:52:52Z",
      //       machine_id: "FF66",
      //       service_info: "005A000000",
      //     },
      //   ],
      // };
    },
  },
  methods: {
    hide() {
      this.$emit("hide");
      this.$store.commit("wallet/reset_card");
      this.$store.dispatch("history/cancelPayment");
    },
    round(value, precision) {
      if (value === 0) return (0.0).toFixed(1);
      var multiplier = Math.pow(10, precision || 0);
      return (Math.round(value * multiplier) / multiplier).toFixed(precision);
    },
  },
};
</script>

<style></style>
