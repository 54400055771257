<template>
  <div class="user-content no-frame">
    <ParkingRecord :record="selected" v-show="openForm" @hide="hideForm" />
    <template v-if="openForm === false">
      <h3 class="main-title">{{ $t("parking_history") }}</h3>
      <v-data-table
        :headers="headers"
        :items-per-page="-1"
        class="table"
        :items="records"
        disable-sort
        hide-default-footer
        mobile-breakpoint="0"
        :no-data-text="$t('no_data')"
        @click:row="viewDetails"
      >
        <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
          <div
            :key="i.text"
            class="d-flex align-center"
            :class="
              header.value === 'zone' ||
              header.value === 'color' ||
              header.value === 'brand' ||
              header.value === 'duration' ||
              header.value === 'price'
                ? 'justify-center'
                : ''
            "
          >
            <div
              class="triangle-parent"
              style="height: 12px"
              v-if="header.sortable !== false"
            >
              <div
                class="triangle up"
                @click="customSort(headers.indexOf(header), 0)"
                :class="header.isDesc === false ? 'active' : ''"
              />
              <div
                class="triangle down"
                @click="customSort(headers.indexOf(header), 1)"
                :class="header.isDesc ? 'active' : ''"
              />
            </div>
            <div>{{ header.text }}</div>
          </div>
        </template>

        <template v-slot:[`item.car_plate`]="{ item }">
          <div class="table-user-name">
            <h4>{{ item.car.plate_number }}</h4>
          </div>
        </template>

        <template v-slot:[`item.car_type`]="{ item }">
          <div class="table-car-type">
            {{ $t(`car_types[${item.car.car_type}]`) }}
          </div>
        </template>

        <template v-slot:[`item.brand`]="{ item }">
          <div class="table-date text-center">
            {{ item.car.brand }}
          </div>
        </template>

        <template v-slot:[`item.parking`]="{ item }">
          <div class="table-parking d-flex justify-start align-center">
            <div class="table-parking-img">
              <img
                class="parking-img table-img"
                :src="
                  places.filter((p) => p.place_id === item.place.place_id)[0]
                    ? places.filter((p) => p.place_id === item.place.place_id)[0]
                        .avatar ||
                      'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg'
                    : 'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg'
                "
                :alt="item.place.name"
              />
            </div>
            <span
              style="
                max-width: 115px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              "
              >{{ item.place.name }}</span
            >
          </div>
        </template>

        <template v-slot:[`item.enter_time`]="{ item }">
          <div class="table-date">
            {{ getFormattedDate(item.parking.enter_time) }}
          </div>
        </template>

        <template v-slot:[`item.exit_time`]="{ item }">
          <div class="table-date">
            {{ getFormattedDate(item.parking.exit_time) }}
          </div>
        </template>

        <template v-slot:[`item.duration`]="{ item }">
          <div class="table-duration text-center">
            {{ getDuration(item.parking.duration) }}
          </div>
        </template>

        <template v-slot:[`item.price_type`]="{ item }">
          <div class="table-price text-center">
            {{ priceNames.filter((i) => i.id === item.parking.guest_type)[0].desc }}
          </div>
        </template>

        <template v-slot:[`item.total_price`]="{ item }">
          <div class="table-total">
            {{
              item.parking.total_price
                ? item.parking.currency + item.parking.total_price / 100
                : ""
            }}
          </div>
        </template>

        <template v-slot:[`item.payment_status`]="{ item }">
          <div class="table-status">
            {{ $t(`payment_options[${Number(item.parking.paid)}]`) }}
          </div>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div class="table-status">
            <template v-if="item.completed">
              <template
                v-if="
                  item.parking.total_price  &&
                  (item.guest_type === 5 || item.guest_type === 10)
                "
              >
                <img
                  v-if="item.paid"
                  src="@/assets/images/completed-green.webp"
                  :style="{ width: '24px', height: '24px' }" />
                <img
                  v-else
                  src="@/assets/images/completed-red.webp"
                  :style="{ width: '24px', height: '24px' }"
              /></template>
              <template v-else>
                <img
                  src="@/assets/images/completed-grey.webp"
                  :style="{ width: '24px', height: '24px' }"
              /></template>
            </template>
            <template v-else>
              <img
                src="@/assets/images/current.webp"
                :style="{ width: '24px', height: '24px' }"
              />
            </template>
          </div>
        </template> </v-data-table
    ></template>
  </div>
</template>

<script>
export default {
  name: "UserParkings",
  data: () => {
    return {
      data: [],
      params: {
        sort_by: 3,
        sort_direction: 0,
        from: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(),
        to: new Date().toISOString(),
      },
      selected: { parking: {} },
      openForm: false,
    };
  },
  components: {
    ParkingRecord: () => import("@/components/Users/ParkingRecord"),
  },
  mounted() {
    if (this.records.length === 0) {
      this.updateParams({ ...this.params });
    }
  },
  computed: {
    newFrom() {
      return new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString();
    },
    newTo() {
      return new Date().toISOString();
    },
    current() {
      return this.$store.state.users.current;
    },
    records() {
      return this.$store.state.users.parkings;
    },
    priceNames() {
      return this.$t("price_types");
    },
    status() {
      return this.$store.state.history.status;
    },
    headers() {
      let initHeaders = [
        {
          text: this.$t("car_plate"),
          value: "car_plate",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: this.$t("car_type"),
          value: "car_type",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: this.$t("car_brand"),
          value: "brand",
          sortable: false,
          class: "table-date table-header",
        },

        {
          text: this.$t("date_in"),
          value: "enter_time",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("date_out"),
          value: "exit_time",
          class: "table-date table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("duration"),
          value: "duration",
          class: "table-duration table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("total"),
          value: "total_price",
          sortable: false,
          class: "table-price table-header",
        },
        {
          text: this.$t("price_type"),
          value: "price_type",
          sortable: false,
          class: "table-price table-header",
        },
        {
          text: this.$t("payment_status"),
          value: "payment_status",
          sortable: false,
          class: "table-header table-zone px-0",
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "table-status table-header",
        },
      ];

      return initHeaders;
    },
  },
  watch: {
    status(newValue) {
      if (newValue) {
        this.updating = true;
        this.updateParams({
          ...this.params,
          from: this.newFrom,
          to: this.newTo,
        });
      }
    },
  },
  methods: {
    getFormattedDate(date) {
      return date ? this.$moment(date).format("D.MM.YYYY HH:mm") : "";
    },
    getDuration(milliseconds) {
      let duration = this.$moment.duration(milliseconds, "milliseconds");
      let months = duration._data.months  ? duration._data.months + "m" : "";
      let days = duration._data.days  ? duration._data.days + "d" : "";
      let hours = duration._data.hours  ? duration._data.hours + "h" : "";
      let minutes = duration._data.minutes;

      if (duration._data.seconds  && duration._data.minutes !== 59) {
        minutes = minutes + 1;
      }

      minutes = minutes + "m";

      return `${months} ${days} ${hours} ${minutes}`;
    },
    viewDetails(value) {
      this.selected = value;
      this.openForm = true;
    },
    hideForm() {
      this.selected = { parking: {} };
      this.openForm = false;
    },
    updateParams(newValue) {
      this.disableLoader = false;
      let params = {};
      for (const [key, value] of Object.entries({ ...newValue })) {
        if (value || value === 0) {
          params[key] = value;
        }
      }
      this.$store.dispatch("users/getParkings", {
        cyber_user_id: this.current.id,
        params,
      });
    },
    customSort(index, sort_direction) {
      this.headers.map((h) => {
        if (this.headers.indexOf(h) !== index) {
          h.isDesc = undefined;
        }
      });
      this.headers[index].isDesc = !!sort_direction;
      switch (this.headers[index].value) {
        case "username":
          this.params = { ...this.params, sort_by: 1, sort_direction };
          break;
        case "enter_time":
          this.params = {
            ...this.params,
            sort_by: 3,
            sort_direction: Number(!sort_direction),
          };
          this.headers[index].isDesc = !Number(!sort_direction);
          break;
        case "exit_time":
          this.params = {
            ...this.params,
            sort_by: 4,
            sort_direction: Number(!sort_direction),
          };
          this.headers[index].isDesc = !Number(!sort_direction);
          break;
        case "duration":
          this.params = { ...this.params, sort_by: 5, sort_direction };
          break;
        case "total_price":
          this.params = { ...this.params, sort_by: 6, sort_direction };
          break;
        default:
          return;
      }
      this.params = { ...this.params, from: this.newFrom, to: this.newTo };
      this.updateParams({ ...this.params });
      this.updating = true;
    },
  },
};
</script>

<style></style>
