<template>
  <div class="user-content no-frame">
    <h3 class="main-title">{{ $t("transaction_history") }}</h3>

    <v-data-table
      :headers="headers"
      :items-per-page="-1"
      class="table not-expandable"
      :items="records"
      disable-pagination
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div
          :key="i.text"
          class="d-flex align-center"
          :class="['status', 'actions'].includes(header.value) ? 'justify-center' : ''"
        >
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(headers.indexOf(header), -1)"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(headers.indexOf(header), 1)"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div style="min-width: 130px">
          {{ $t(`payment_types[${item.type_id}]`) }}
        </div>
      </template>

      <template v-slot:[`item.provider`]="{ item }">
        <div>
          {{ $t(`payment_providers[${item.payment_provider_id}]`) }}
        </div>
      </template>

      <template v-slot:[`item.origin`]="{ item }">
        <div class="table-user-name" v-if="item.origin_id">
          {{ $t(`cyber_origins[${item.origin_id - 1}]`) }}
        </div>
      </template>

      <template v-slot:[`item.date_time`]="{ item }">
        <div style="min-width: 140px">
          {{ $moment(item.created_at).format("DD.MM.YYYY [at] HH:mm") }}
        </div>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        <div style="text-transform: uppercase" class="table-price">
          {{ item.amount / 100 }}{{ item.currency }}
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div :class="`mx-auto px-2 payment-status status-${item.status_id - 1}`">
          {{ $t(`wallet_status_options[${item.status_id - 1}]`) }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "UserTransactions",
  data: () => {
    return {};
  },
  computed: {
    current() {
      return this.$store.state.users.current;
    },
    records() {
      return this.$store.state.users.transactions;
    },
    headers() {
      let initHeaders = [
        {
          text: this.$t("transaction"),
          value: "action",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("payment_provider"),
          value: "provider",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("origin"),
          value: "origin",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("date"),
          value: "date_time",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("amount"),
          value: "amount",
          class: "table-price table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "table-header",
        },
      ];
      return initHeaders;
    },
  },
  methods: {
    customSort(index, sort_direction) {
      this.headers.map((h) => {
        if (this.headers.indexOf(h) !== index) {
          h.isDesc = undefined;
        }
      });
      this.headers[index].isDesc = sort_direction === 1;
      this.params = {
        ...this.params,
        sort_by: this.headers[index].value,
        sort_direction,
      };
      this.update();
    },

    update() {
      let params = Object.fromEntries(
        Object.entries({ ...this.params }).filter(
          ([_, v]) => (_ !== null) & (v !== null) && v !== ""
        )
      );
      this.$store.dispatch("users/getTransactions", { ...params });
    },
  },
};
</script>
