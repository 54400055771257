<template>
  <div class="tabs-content parking-history zone-history">
    <h3 class="main-title">{{ $t("zone_history") }}</h3>
    <div class="my-6 d-flex flex-wrap flex-md-nowrap justify-start align-center">
      <v-tabs v-model="tab" class="mb-sm-0 mx-0">
        <v-tab
          v-for="item in items"
          :key="item"
          :style="
            items.indexOf(item) === 0 ? { width: '120px', paddingRight: '12px' } : ''
          "
          >{{ item }}</v-tab
        >
      </v-tabs>
      <v-text-field
        v-model="search"
        autocomplete="off"
        class="parking-search flex-grow-0 ml-4 mr-0"
        solo
        :placeholder="$t('search_core')"
      ></v-text-field>
      <CoreFilter
        ref="coreFilter"
        @filterSet="updFilters"
        @hideEmit="hideFilter"
        @showEmit="showFilter"
        :hidden="{
          campaign: true,
          created_by: true,
          price_range: true,
          car_type: true,
          pricing: true,
          wallet: true,
        }"
      />
      <ReportDownloadForm
        ref="report"
        view="zone_history"
        @hideEmit="openReportForm = false"
        @showEmit="openReportForm = true"
      />

      <!-- refresh btn -->
      <v-btn text class="avatar mr-lg-auto dwl-btn" @click="refresh">
        <img
          :style="{ height: '24px', width: '24px' }"
          src="@/assets/images/refresh.svg"
          alt="refresh"
        />
      </v-btn>
    </div>
    <ZoneHistoryTable
      :searchValue="search"
      :tab="tab"
      ref="table"
      :isOpenForm="isOpenForm"
      :filters="filters"
    />
  </div>
</template>

<script>
export default {
  name: "ZoneHistory",
  components: {
    ZoneHistoryTable: () => import("@/components/ZoneHistory/ZoneHistoryTable"),
    CoreFilter: () => import("@/components/core/Filter"),
    ReportDownloadForm: () => import("@/components/core/ReportDownloadForm"),
  },
  data() {
    return {
      tab: 2,
      filters: {
        dates: [],
        zone_id: [],
      },
      openReportForm: false,
      openFilterForm: false,
      debouncedInput: "",
      timeout: null,
      updateTimer: null,
    };
  },
  computed: {
    items() {
      return this.$t("zone_tabs");
    },
    search: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedInput = val;
          this.$refs.table.search(this.debouncedInput);
        }, 1000);
      },
    },
    isOpenForm() {
      return this.openReportForm || this.openFilterForm;
    },
  },
  methods: {
    openDownloadForm() {
      this.$refs.modal.show();
      this.openReportForm = true;
    },
    hideReport() {
      this.openReportForm = false;
    },
    showFilter() {
      this.openFilterForm = true;
    },
    hideFilter() {
      this.openFilterForm = false;
    },
    updFilters(filters) {
      this.filters = filters;
      this.$refs.table.filter(filters);
    },
    updateTable() {
      this.$refs.table.$forceUpdate();
      this.$refs.table.updateParams({
        ...this.$refs.table.params,
        from: this.$refs.table.newFrom(),
        to: this.$refs.table.newTo(),
      });
    },
    refresh() {
      this.updateTable();
      this.$refs.table.loading = true;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      setTimeout(() => {
        vm.updateTable();
      }, 1000);
      vm.updateTimer = setInterval(() => {
        if (vm.$store.getters["app/authorized"] && vm.$refs.table) {
          vm.updateTable();
        }
      }, 60000);
    });
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.updateTimer);
    this.updateTimer = null;
    next();
  },
};
</script>
