<template>
  <div class="tabs-content your-parking guest-flow">
    <h3 class="main-title">{{ getTitle() }}</h3>

    <div class="d-flex align-center justify-space-between mb-4">
      <div class="d-flex align-center flex-wrap flex-md-nowrap">
        <v-btn text class="modal-btn mb-0" @click="backToParking">{{
          $t("form_back_parking")
        }}</v-btn>
        <h3 class="modal-label reg-font mb-0">• {{ name }}</h3>
      </div>
      <v-btn v-show="step !== 2" text class="flat-btn" @click="treeHandler">
        {{ showTree ? $t("back") : $t("form_show_tree") }}</v-btn
      >
    </div>

    <template v-if="!showTree">
      <!-- GUEST FORM -->
      <v-form ref="tenant" autocomplete="off" class="mb-8">
        <div class="d-flex align-center">
          <h3 class="main-title" style="font-size: 20px !important">
            {{ $t("tenant") }}
          </h3>
          <v-btn @click="deleteTenant" text class="modal-btn ml-auto">{{
            $t("delete")
          }}</v-btn>
        </div>
        <div class="d-flex guest-row">
          <v-text-field
            v-model="tenant.first_name"
            class="modal-input"
            :label="$t('guest_fname')"
            :rules="[() => !!tenant.first_name || $t('guest_fullname') + $t('required')]"
          />
          <v-text-field
            v-model="tenant.last_name"
            class="modal-input"
            :label="$t('guest_lname')"
          />
        </div>
        <div class="d-flex guest-row-3 justify-space-between">
          <v-autocomplete
            attach
            style="max-width: 300px"
            v-model="tenant.country_code"
            item-value="dial_code"
            item-text="name"
            class="modal-input"
            :items="countryCodes"
            :rules="[() => !!tenant.country_code || $t('country_code') + $t('required')]"
          >
            <template v-slot:item="data">
              <span :class="`code-select selection-${data.item.i}`"
                ><img src="@/assets/images/countries.webp"
              /></span>
              <span class="pl-2">{{ data.item.name }}</span>
              <span style="opacity: 0.6" class="ml-3">{{ data.item.dial_code }}</span>
            </template>
            <template v-slot:selection="data">
              <span :class="`code-select selection-${data.item.i}`"
                ><img src="@/assets/images/countries.webp"
              /></span>
              <span class="pl-2">{{ data.item.dial_code }}</span>
              <span class="pl-2">{{ data.item.name }}</span>
            </template>
          </v-autocomplete>
          <v-text-field
            class="modal-input"
            :value="tenant.phone.replace(tenant.country_code, '')"
            @change="(v) => (tenant.phone = tenant.country_code + v)"
            type="tel"
            pattern="\d*"
            :rules="[() => !!tenant.phone || $t('phone_number') + $t('required')]"
            :label="$t('phone_number')"
          />
          <v-text-field
            v-model="tenant.building_number"
            class="modal-input"
            :label="$t('address')"
          />
        </div>
      </v-form>

      <!-- VIS FORM -->
      <v-form ref="vis" autocomplete="off">
        <div class="d-flex align-center justify-space-between">
          <h3 class="main-title" style="font-size: 20px !important">
            {{ $t("visitors") }}
          </h3>
        </div>

        <div v-for="(vis, visIndex) in [...tenant.visits]" :key="visIndex" class="mt-6">
          <div class="d-flex justify-space-between align-center">
            <label class="modal-label">{{ $t("visitor") }} {{ visIndex + 1 }}</label>
            <v-btn @click="deleteVisitor(vis)" text class="modal-btn mr-8">{{
              $t("delete")
            }}</v-btn>
          </div>
          <div class="d-flex justify-space-between guest-row-4 flex-wrap">
            <v-text-field
              v-model="vis.visitor_name"
              class="modal-input"
              :label="$t('fullname')"
            />
            <v-text-field
              :value="vis.plate_number"
              :label="$t('car_plate')"
              @input="plateFormatter"
              class="modal-input"
              :rules="[
                () =>
                  (vis.plate_number && vis.plate_number.length < 11) ||
                  $t('car_plate') + ' ' + $t('required'),
              ]"
            />
            <v-select
              :no-data-text="$t('no_data')"
              attach
              v-model="vis.stay_type"
              item-value="id"
              item-text="desc"
              class="modal-input"
              :items="stayOptions"
              :label="$t('stay_type')"
              :rules="[() => !!vis.stay_type || $t('car_type') + ' ' + $t('required')]"
            />

            <div class="date single flex-grow">
              <v-menu
                content-class="datepicker-menu"
                attach
                :close-on-content-click="!!vis.stay_until"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    :disabled="vis.stay_type !== 2"
                    class="modal-input datepicker-inp"
                    @change="timeChangeHandler(vis, $event)"
                    :value="$moment(vis.stay_until).format('DD.MM.YYYY')"
                    :label="$t('visitor')"
                    :rules="[
                      () =>
                        !!vis.stay_until ||
                        vis.stay_type !== 2 ||
                        $t('dates') + $t('required'),
                    ]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :locale="$i18n.locale"
                  first-day-of-week="1"
                  :isDark="false"
                  @change="timeChangeHandler(vis, $event)"
                  v-model="tenant.stay_until"
                ></v-date-picker>
              </v-menu>
            </div>
          </div>
          <div class="d-flex guest-row-4 align-center justify-space-between">
            <v-select
              :no-data-text="$t('no_data')"
              attach
              v-model="vis_type"
              class="modal-input car-type-select"
              :items="carTypes"
              item-text="desc"
              item-value="id"
              :label="$t('car_type')"
              :rules="[
                () =>
                  !!vis.vehicle_type ||
                  vis.vehicle_type === 0 ||
                  $t('car_type') + ' ' + $t('required'),
              ]"
            />
            <v-combobox
              attach
              v-model="vis.manufacturer"
              class="modal-input"
              :items="getCarBrands()"
              :label="$t('car_brand')"
              :rules="[() => !!vis.manufacturer || $t('car_brand') + $t('required')]"
            />
            <v-combobox
              attach
              v-model="vis.model"
              class="modal-input"
              :items="getCarBrands(vis.manufacturer)"
              :label="$t('car_model')"
              :rules="[() => !!vis.model || $t('car_model') + $t('required')]"
            />
            <v-combobox
              attach
              v-model="vis.color"
              class="modal-input"
              :items="carColors"
              :label="$t('car_color')"
            />
          </div>
        </div>
        <div class="d-flex justify-space-between mt-4">
          <v-btn @click="addVisitor" text class="flat-btn modal-footer-btn mb-4"
            >+ {{ $t("add") }} {{ $t("visitor") }}</v-btn
          >
        </div>
        <div class="d-flex align-center justify-end">
          <v-btn v-if="step" @click="back" text class="flat-btn modal-footer-btn mr-6">
            {{ $t("back") }}
          </v-btn>
          <v-btn @click="next" text class="main-btn px-6 py-2">{{ $t("next") }}</v-btn>
        </div>
      </v-form>

      <!-- DONE -->
    </template>

    <template v-else>
      <TenantTree
        :show="showTree"
        :checkTab="isCheckTab"
        :tenant="tenant"
        @clickedSend="send"
        view="flow"
      />
    </template>
  </div>
</template>

<script>
import carBrandsDb from "@/assets/cars/carModels.json";
import codes from "@/assets/countryCodes.json";
import { formatPlate } from "../../common/helpers/helpers";
// import {pwa} from "@/axios-api";

const defaultTenant = {
  first_name: "",
  last_name: "",
  phone: "",
  building_number: "",
  country_code: "+1",
  visits: [],
};

export default {
  name: "TenantFlow",
  data() {
    return {
      showTree: false,
      changed: false,
      tenant: JSON.parse(JSON.stringify(defaultTenant)),
      step: 0,
      countryCodes: codes,
    };
  },
  components: {
    TenantTree: () => import("@/components/Tenants/TenantTree"),
  },
  computed: {
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    name() {
      let name = "";
      if (this.isCompany && this.$store.state.app.user.company_name) {
        name = this.$store.state.app.user.company_name;
      } else {
        name = this.places[0] ? this.places[0].place_name : "";
      }
      return name;
    },
    stateTenant() {
      return this.$store.state.tenants.current;
    },
    formType() {
      return Number(!!this.stateTenant && !!this.stateTenant.id);
    },
    status() {
      return this.$store.state.tenants.status;
    },
    isCheckTab() {
      return this.step === 1;
    },
    places() {
      return this.$store.state.app.places;
    },
    carTypes() {
      return [...this.$store.state.app.car_types].map((id) => {
        return {
          desc: this.$t(`car_types[${id}]`),
          id,
        };
      });
    },
    stayOptions() {
      return [0, 1, 2].map((id) => {
        return {
          desc: this.$t(`stay_options[${id}]`),
          id: id + 1,
        };
      });
    },
    carColors() {
      return [
        "ordinary red",
        "white",
        "blue",
        "grey",
        "orange",
        "black",
        "dark red",
        "light blue",
        "yellow",
        "light green",
        "dark blue",
        "light yellow",
        "storm grey",
        "brown",
        "ordinary tan",
      ];
    },
  },
  watch: {
    stateTenant(val) {
      if (val && val.id) {
        this.tenant = val;
      }
    },
  },
  mounted() {
    this.parseTenant(this.stateTenant);
  },
  methods: {
    getTitle() {
      return this.formType === 0
        ? this.$t("add") + " " + this.$t("tenant")
        : this.$t("change") + " " + this.$t("tenant");
    },
    getCarBrands(value) {
      if (value !== "" && carBrandsDb[value] && value !== "N/A") {
        return carBrandsDb[value];
      } else {
        return Object.keys(carBrandsDb);
      }
    },
    plateFormatter(val) {
      this.vis.plate_number = formatPlate(val);
      this.$forceUpdate();
    },
    timeChangeHandler(vis, value) {
      let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      let date = this.$moment(new Date(value.replace(pattern, "$3-$2-$1"))).format(
        "YYYY-MM-DD"
      );

      vis.stay_until = date;
      this.$forceUpdate();
    },

    // TREE HANDLER

    treeHandler() {
      if (this.isCheckTab) {
        this.showTree = false;
      } else {
        this.showTree = !this.showTree;
      }
    },

    // ROUTE METHODS
    next() {
      if (this.validateForms()) {
        this.showTree = true;
      }
    },
    back() {
      if (this.step > 1) this.step--;
    },
    backToParking() {
      this.$router.push("/platform/your-parking");
    },
    // ADD METHODS

    addVisitor() {
      this.tenant.visits.push({});
    },

    deleteTenant() {
      this.$confirm({
        message: this.$t("tenant_delete_prompt", {
          tenant: `${this.tenant.first_name} ${this.tenant.last_name}`,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("tenants/deleteTenant", {
              tenant_id: [this.tenant.id],
            });
            this.$store.commit("tenants/set_loader", true);
            this.backToParking();
          }
        },
      });
    },
    deleteVisitor(visitor) {
      this.$confirm({
        message: this.$t("visitor_delete_propmt", {
          vis: visitor.visitor_name,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            if (visitor.id) {
              this.$store.dispatch("tenants/deleteVisitor", { ids: [visitor.id] });
            }
            this.tenant.visits = this.tenant.visits.filter((i) => i !== visitor);
          }
        },
      });
    },

    parseTenant(tenant) {
      if (this.formType === 1) {
        this.tenant = JSON.parse(JSON.stringify(tenant));
      }
    },
    validateForms() {
      let invalid = [];
      let forms = Object.keys(this.$refs);
      forms.map((ref, index) => {
        if (this.$refs[ref] && !this.$refs[ref].validate()) {
          invalid.push(index);
        }
      });
      return invalid.length === 0;
    },
    send() {
      let tenantData = {
        tenants: [{ ...this.tenant }],
      };
      this.tenant.visits.forEach((i) => {
        i.stay_until = i.stay_until ? new Date(i.stay_until).toISOString() : null;
      });
      let newVisitsData = {
        visits: this.tenant.visits.filter((i) => !i.id),
      };
      let oldVisitsData = {
        visits: this.tenant.visits.filter((i) => i.id),
      };
      if (this.validateForms()) {
        console.log(this.tenant);
        if (this.formType === 0) {
          this.$store.dispatch("tenants/addTenants", {
            data: tenantData,
            visitors: newVisitsData,
          });
        } else {
          this.$store.dispatch("tenants/updateTenant", { ...this.tenant });
          this.$store.dispatch("tenants/addVisits", {
            data: newVisitsData,
            user: this.tenant.id,
          });
          this.$store.dispatch("tenants/updateVisits", oldVisitsData);
        }
        this.$store.commit("tenants/set_loader", true);
        this.resetGuest();
        this.backToParking();
      }
    },
    resetGuest() {
      this.$store.commit("tenants/set_current", null);
      this.showTree = false;
      this.tenant = JSON.parse(JSON.stringify(defaultTenant));
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.step < 2 && this.changed) {
      this.$confirm({
        message: this.$t("save_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("save"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.send();
            next();
          } else {
            this.resetGuest();
            next();
          }
        },
      });
    } else {
      this.resetGuest();
      next();
    }
  },
};
</script>
