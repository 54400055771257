// import Vue from 'vue'
// import i18n from '@/plugins/i18n';
import actions from './actions'

const getDefaultState = () => {
    return {
        list: [],
        status: undefined,
        current: { id: 0 },
        loading: true,
        history: {
            loading: true,
            current: [],
            completed: [],
            all: []
        }
    }
}

const state = getDefaultState();

const getters = {}

const mutations = {
    logout(state) {
        Object.assign(state, getDefaultState())
    },
    set_loader(state, value) {
        state.loading = value
    },
    get_companies(state, payload) {
        state.list = payload
        state.loading = false
    },
    reset_status(state, status) {
        state.status = status
    },
    set_current(state, payload) {
        state.current = payload
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};