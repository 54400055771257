import { cyberport } from '@/axios-api';
// import store from '@/store/index';
import qs from 'qs';
// import Vue from 'vue';
// import i18n from '@/plugins/i18n';

export default {
    getCompanies({
        commit,
        dispatch
    }, ) {
        cyberport({
                url: '/groups',
                method: 'GET',
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            })
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data.body || [];
                    commit('get_companies', data);
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },
}