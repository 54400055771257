<template>
  <a>
    <v-img
      class="logo-img"
      :src="
        green === 'green'
          ? 'https://storage.googleapis.com/parkingbnb-html-images/logo%20green.svg'
          : 'https://storage.googleapis.com/parkingbnb-html-images/logoWhite.svg'
      "
      aspect-ratio
      alt="ParkingBnB"
    ></v-img>
  </a>
</template>

<script>
export default {
  name: "Logo",
  props: ["green"],
};
</script>
