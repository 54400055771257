var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"user-content no-frame"},[_c('ParkingRecord',{directives:[{name:"show",rawName:"v-show",value:(_vm.openForm),expression:"openForm"}],attrs:{"record":_vm.selected},on:{"hide":_vm.hideForm}}),(_vm.openForm === false)?[_c('h3',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("parking_history")))]),_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.records,"disable-sort":"","hide-default-footer":"","mobile-breakpoint":"0","no-data-text":_vm.$t('no_data')},on:{"click:row":_vm.viewDetails},scopedSlots:_vm._u([_vm._l((_vm.headers),function(i){return {key:`header.${i.value}`,fn:function({ header }){return [_c('div',{key:i.text,staticClass:"d-flex align-center",class:header.value === 'zone' ||
            header.value === 'color' ||
            header.value === 'brand' ||
            header.value === 'duration' ||
            header.value === 'price'
              ? 'justify-center'
              : ''},[(header.sortable !== false)?_c('div',{staticClass:"triangle-parent",staticStyle:{"height":"12px"}},[_c('div',{staticClass:"triangle up",class:header.isDesc === false ? 'active' : '',on:{"click":function($event){_vm.customSort(_vm.headers.indexOf(header), 0)}}}),_c('div',{staticClass:"triangle down",class:header.isDesc ? 'active' : '',on:{"click":function($event){_vm.customSort(_vm.headers.indexOf(header), 1)}}})]):_vm._e(),_c('div',[_vm._v(_vm._s(header.text))])])]}}}),{key:`item.car_plate`,fn:function({ item }){return [_c('div',{staticClass:"table-user-name"},[_c('h4',[_vm._v(_vm._s(item.car.plate_number))])])]}},{key:`item.car_type`,fn:function({ item }){return [_c('div',{staticClass:"table-car-type"},[_vm._v(" "+_vm._s(_vm.$t(`car_types[${item.car.car_type}]`))+" ")])]}},{key:`item.brand`,fn:function({ item }){return [_c('div',{staticClass:"table-date text-center"},[_vm._v(" "+_vm._s(item.car.brand)+" ")])]}},{key:`item.parking`,fn:function({ item }){return [_c('div',{staticClass:"table-parking d-flex justify-start align-center"},[_c('div',{staticClass:"table-parking-img"},[_c('img',{staticClass:"parking-img table-img",attrs:{"src":_vm.places.filter((p) => p.place_id === item.place.place_id)[0]
                  ? _vm.places.filter((p) => p.place_id === item.place.place_id)[0]
                      .avatar ||
                    'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg'
                  : 'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg',"alt":item.place.name}})]),_c('span',{staticStyle:{"max-width":"115px","overflow":"hidden","text-overflow":"ellipsis","display":"-webkit-box","-webkit-line-clamp":"2","-webkit-box-orient":"vertical"}},[_vm._v(_vm._s(item.place.name))])])]}},{key:`item.enter_time`,fn:function({ item }){return [_c('div',{staticClass:"table-date"},[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.parking.enter_time))+" ")])]}},{key:`item.exit_time`,fn:function({ item }){return [_c('div',{staticClass:"table-date"},[_vm._v(" "+_vm._s(_vm.getFormattedDate(item.parking.exit_time))+" ")])]}},{key:`item.duration`,fn:function({ item }){return [_c('div',{staticClass:"table-duration text-center"},[_vm._v(" "+_vm._s(_vm.getDuration(item.parking.duration))+" ")])]}},{key:`item.price_type`,fn:function({ item }){return [_c('div',{staticClass:"table-price text-center"},[_vm._v(" "+_vm._s(_vm.priceNames.filter((i) => i.id === item.parking.guest_type)[0].desc)+" ")])]}},{key:`item.total_price`,fn:function({ item }){return [_c('div',{staticClass:"table-total"},[_vm._v(" "+_vm._s(item.parking.total_price ? item.parking.currency + item.parking.total_price / 100 : "")+" ")])]}},{key:`item.payment_status`,fn:function({ item }){return [_c('div',{staticClass:"table-status"},[_vm._v(" "+_vm._s(_vm.$t(`payment_options[${Number(item.parking.paid)}]`))+" ")])]}},{key:`item.status`,fn:function({ item }){return [_c('div',{staticClass:"table-status"},[(item.completed)?[(
                item.parking.total_price  &&
                (item.guest_type === 5 || item.guest_type === 10)
              )?[(item.paid)?_c('img',{style:({ width: '24px', height: '24px' }),attrs:{"src":require("@/assets/images/completed-green.webp")}}):_c('img',{style:({ width: '24px', height: '24px' }),attrs:{"src":require("@/assets/images/completed-red.webp")}})]:[_c('img',{style:({ width: '24px', height: '24px' }),attrs:{"src":require("@/assets/images/completed-grey.webp")}})]]:[_c('img',{style:({ width: '24px', height: '24px' }),attrs:{"src":require("@/assets/images/current.webp")}})]],2)]}}],null,true)})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }