<template>
  <div class="record-popup popup" v-if="open">
    <div class="modal-overlay" v-if="open" @click="hide" />
    <v-menu
      attach
      :value="true"
      :close-on-content-click="false"
      :close-on-click="false"
      content-class="core-filter modal-content"
    >
      <div class="mb-4 d-flex justify-space-between">
        <h3 class="main-title">{{ $t(`promocodes`) }}</h3>
        <v-btn text class=" icon-close pa-0" min-width="24px" @click="hide" />
      </div>
      <v-form>
        <v-select
          attach
          v-model="campaign.campaign_id"
          item-value="campaign_id"
          item-text="campaign_name"
          class="modal-input mb-6"
          :items="campaigns"
          :label="$t(`campaign_name`)"
        />
        <label class="modal-label">{{ $t(`dates`) }}</label>
        <div class="d-flex justify-center form-period date">
          <v-menu
            attach
            content-class="datepicker-menu"
            :close-on-content-click="false"
            @click.native.stop
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                @click.prevent
                v-on="on"
                readonly
                class="modal-input input-half datepicker-inp mt-0"
                :value="dates[0] ? $moment(dates[0]).format('DD.MM.YYYY') : ''"
                :label="$t('from')"
                :rules="[() => !!dates[0] || $t('from_err')]"
              ></v-text-field>
              <span class="datepicker-divider"></span>
              <v-text-field
                v-on="on"
                class="modal-input input-half datepicker-inp datepicker-inp-second mt-0"
                readonly
                :value="dates[1] ? $moment(dates[1]).format('DD.MM.YYYY') : ''"
                :label="$t('to')"
                :rules="[
                  () =>
                    $moment(dates[1]).isAfter($moment(dates[0])) ||
                    $moment(dates[1])._id === $moment(dates[0])._id ||
                    $t('range_err'),
                ]"
              ></v-text-field>
            </template>
            <v-date-picker :locale="$i18n.locale"
              first-day-of-week="1"
              :isDark="false"
              v-model="dates"
              range
            ></v-date-picker>
          </v-menu>
        </div>
        <div class="d-flex align-center justify-space-between">
          <v-text-field
            class="modal-input"
            style="max-width: 49%"
            v-model="campaign.prefix"
            :label="$t(`prefix`)"
          />
          <v-text-field
            class="modal-input"
            style="max-width: 49%"
            v-model="campaign.postfix"
            :label="$t(`postfix`)"
          />
        </div>

        <v-text-field
          class="modal-input"
          type="number"
          step="1"
          :value="campaign.count"
          @change="(value) => (campaign.count = Number(value))"
          :label="$t(`promocodes_no`)"
        />
        <v-text-field
          class="modal-input"
          type="number"
          step="1"
          :value="campaign.code_length"
          @change="(value) => (campaign.code_length = Number(value))"
          :label="$t(`promocodes_length`)"
          :placeholder="$t(`promocodes_placeholder`)"
        />

        <v-text-field
          class="modal-input"
          :value="campaign.free_minutes / 60"
          @change="(value) => (campaign.free_minutes = value * 60)"
          type="number"
          :label="$t(`free_hours`)"
        />
        <v-btn class="main-btn my-4 py-2 px-6" text @click="sendCode">{{
          $t(`save`)
        }}</v-btn>
        <v-btn class="flat-btn cross-icon" text @click="hide">{{ $t(`cancel`) }}</v-btn>
      </v-form>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["selected"],
  data() {
    return {
      open: false,
      campaign: {
        campaign_id: "",
        count: null,
        code_length: null,
        prefix: "",
        postfix: "",
        active_from: "",
        active_until: "",
        free_minutes: 0,
        max_usages: 1,
      },
      dates: [this.selected.active_from, this.selected.active_until],
    };
  },
  mounted() {
    if (this.selected) {
      this.campaign = { ...this.campaign, ...this.selected };
    }
  },
  computed: {
    campaigns() {
      return this.$store.state.promo.campaigns;
    },
  },
  methods: {
    hide() {
      this.open = false;
    },
    show() {
      this.open = true;
    },
    sendCode() {
      this.campaign = {
        ...this.campaign,
        active_from: this.dates[0] ? this.$moment(this.dates[0]).toISOString() : "",
        active_until: this.dates[1] ? this.$moment(this.dates[1]).toISOString() : "",
        max_usages: Number(this.campaign.max_usages),
      };
      console.log(this.campaign);

      if (this.campaign.id) {
        this.$store.dispatch("promo/changePromocode", { ...this.campaign });
      } else {
        this.$store.dispatch("promo/generatePromo", { ...this.campaign });
      }
      this.hide();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
</style>
