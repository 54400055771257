<template>
  <transition name="fade">
    <div
      v-if="isShow"
      @click="handleClickOverlay"
      class="vc-overlay"
      id="vueConfirm"
      :class="dialog.custom"
    >
      <transition name="zoom">
        <div v-if="isShow" ref="vueConfirmDialog" class="vc-container">
          <img
            v-if="dialog.custom !== 'choose'"
            src="@/assets/images/warning.webp"
            style="width: 64px; height: 64px"
            class="mx-auto my-6"
          />
          <span class="vc-text-grid">
            <h4 v-if="dialog.title" class="vc-title">{{ dialog.title }}</h4>
            <p v-if="dialog.message" class="vc-text">{{ dialog.message }}</p>
          </span>
          <div
            class="vc-btn-grid pb-6 pt-2"
            :class="{ isMono: !dialog.button.no || !dialog.button.yes }"
          >
            <v-btn
              text
              v-if="dialog.button.no"
              @click.stop="(e) => handleClickButton(e, false)"
              class="flat-btn vc-btn"
              :class="dialog.custom"
            >
              {{ dialog.button.no }}
            </v-btn>

            <v-btn
              text
              v-if="dialog.button.yes"
              :disabled="dialog.auth ? !password : false"
              @click.stop="(e) => handleClickButton(e, true)"
              class="vc-btn px-3"
              :class="dialog.custom === 'choose' ? 'flat-btn' : 'main-btn'"
            >
              {{ dialog.button.yes }}
            </v-btn>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
import Vue from "vue";

Vue.directive("focus", {
  inserted: function (el) {
    el.focus();
  },
});

const Component = {
  name: "VueConfirmDialog",
  data() {
    return {
      isShow: false,
      password: null,
      dialog: {
        auth: false,
        title: "",
        message: "",
        custom: "",
        button: {},
      },
      params: {},
    };
  },
  methods: {
    resetState() {
      this.password = null;
      this.dialog = {
        auth: false,
        title: "",
        message: "",
        custom: "",
        button: {},
        callback: () => {},
      };
    },
    handleClickButton({ target }, confirm) {
      if (target.id == "vueConfirm") return;
      if (confirm && this.dialog.auth && !this.password) return;
      this.isShow = false;
      // callback
      if (this.params.callback) {
        this.params.callback(confirm, this.password);
      }
    },
    handleClickOverlay({ target }) {
      if (target.id == "vueConfirm") {
        this.isShow = false;
        // callback
        if (this.params.callback) {
          this.params.callback(false, this.password);
        }
      }
    },
    handleKeyUp({ keyCode }) {
      if (keyCode == 27) {
        this.handleClickOverlay({ target: { id: "vueConfirm" } });
      }
      if (keyCode == 13) {
        this.handleClickButton({ target: { id: "" } }, true);
      }
    },
    open(params) {
      this.resetState();
      this.params = params;
      this.isShow = true;
      // set params to dialog state
      Object.entries(params).forEach((param) => {
        if (typeof param[1] == typeof this.dialog[param[0]]) {
          this.dialog[param[0]] = param[1];
        }
      });
    },
  },
  mounted() {
    Vue.prototype.$confirm = this.open;
    // if (!document) return;
    // events.$on("open", this.open);
    // events.$on("close", () => {
    //   this.handleClickOverlay({ target: { id: "vueConfirm" } });
    // });
    // document.addEventListener('keyup', this.handleKeyUp)
  },
  beforeDestroy() {
    // document.removeEventListener('keyup', this.handleKeyUp)
  },
};

export default Component;
</script>
