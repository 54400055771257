<template>
  <div class="user-content">
    <div class="d-flex align-center justify-space-between mb-6">
      <h3 class="main-title my-0">{{ $t("user_info") }}</h3>
      <v-btn @click="openForm" class="main-btn px-8 py-2" text>{{ $t("edit") }}</v-btn>
    </div>
    <div class="user-row">
      <span>{{ $t("balance") }}</span
      ><span>{{ current.balance }}HKD</span>
    </div>
    <div class="user-row">
      <span>{{ $t("phone_number") }}</span
      ><a v-if="current.phone" :href="`tel:${current.phone}`">{{ current.phone }}</a
      ><span v-else>N/A</span>
    </div>
    <div class="user-row">
      <span>{{ $t("email") }}</span
      ><a v-if="current.email" :href="`mailto:${current.email}`">{{ current.email }}</a
      ><span v-else>N/A</span>
    </div>
    <div class="user-row">
      <span>{{ $t("locale") }}</span
      ><span>{{ $t(`locale_options.${current.locale}`) }}</span>
    </div>
    <div class="user-row">
      <span class="mr-auto">{{ $t("subscription") }}</span>
      <template v-if="subscriptions.length ">
        <span v-for="(sub, index) in subscriptions" :key="sub.id"
          >{{ $t(`monthly_price_options[${sub.price_option_id - 1}]`).text
          }}<span class="mr-1" v-if="index !== subscriptions.length - 1">, </span>
        </span></template
      ><template v-else>N/A</template>
    </div>

    <div class="user-row">
      <span>{{ $t("payment_method") }}</span>
      <div class="d-flex align-center">
        <div class="card-icon" v-for="payment in paymentMethods" :key="payment.id">
          <img
            :src="require(`@/assets/images/payments/${payment.card.brand}.webp`)"
            class="icon"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserGeneral",
  data: () => {
    return {};
  },
  computed: {
    current() {
      return { ...this.$store.state.users.current };
    },
    subscriptions() {
      return [...this.$store.state.users.subscriptions].sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      );
    },
    paymentMethods() {
      return this.$store.state.users.payment_methods;
    },
  },
  methods: {
    openForm() {
      this.$emit("openForm");
    },
  },
};
</script>

<style></style>
