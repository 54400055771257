<template>
  <div class="guest-summary dashboard-donut">
    <div class="summary-header">
      <h3 class="main-title mb-4 d-flex align-center">
        {{ $t("pricing_type") }}
      </h3>
    </div>
    <div>
      <template v-if="loadingSeries">
        <div class="position-relative">
          <v-skeleton-loader :loading="true" type="image" />
          <span class="donut-loader-center" />
        </div>
        <div
          class="d-flex justify-space-between pb-1"
          v-for="i in 5"
          :key="i"
          style="position: relative"
        >
          <v-skeleton-loader :loading="true" type="chip" max-width="100%" />
          <v-skeleton-loader :loading="true" type="heading" />
        </div>
      </template>
      <div
        :style="{
          display: loadingSeries ? 'none' : 'block',
        }"
      >
        <apexchart
          type="donut"
          :options="chartOptions"
          :series="series.map((type) => type.perc)"
          :labels="series.map((type) => type.label)"
          :data="series"
          width="100%"
          height="450px"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PricingSummary",
  props: {
    place: {
      default: 0,
    },
  },
  data() {
    return {
      chartOptions: {
        plotOptions: {
          pie: {
            donut: {
              size: "40%",
            },
            height: "300px",
          },
        },
        chart: {
          type: "donut",
          fontFamily: "Quicksand, sans-serif",
          height: "400px",
          events: {},
        },
        colors: ["#00746b", "#ff6e4c", "#464d4d", "#009788", "#b4b8b8"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          fontSize: "16px",
          fontWeight: "bold",
          position: "bottom",
          height: "105px",
          markers: {
            width: "8px",
            height: "8px",
            radius: "8px",
          },
          formatter: (seriesName, opts) => {
            let perc = this.series[opts.seriesIndex].perc;
            let label = this.series[opts.seriesIndex].label;
            return [`<span class="mr-auto">${perc}%</span>`, `<span>${label}</span>`];
          },
        },
        tooltip: {
          custom: ({ series, seriesIndex }) => {
            let label = this._computedWatchers.series.value[seriesIndex].label;
            return (
              '<div class="arrow_box pa-2">' +
              "<span>" +
              series[seriesIndex] +
              "% - " +
              label +
              "</span>" +
              "</div>"
            );
          },
        },
      },
    };
  },
  computed: {
    drawChart() {
      return this.$store.state.app.tab === "dashboard" && this.series;
    },
    currentPlace() {
      return [...this.$store.state.dashboard.price_usage].filter(
        (p) => p.place_id === this.place
      )[0];
    },
    priceUsage() {
      return this.currentPlace ? this.currentPlace.price_usage : [];
    },
    stateGuestTypes() {
      return [...this.$store.state.app.guest_types];
    },
    availableTypes() {
      let types = [];
      if (this.place) {
        let data = this.stateGuestTypes.filter((p) => p.place_id === this.place)[0] || {};
        types = data.guest_types || [];
      } else {
        this.stateGuestTypes.map((i) => {
          types = types.concat(i.guest_types).filter((v, i, a) => a.indexOf(v) === i);
        });
      }
      return types;
    },

    loadingSeries() {
      return this.$store.state.dashboard.loading_prices;
    },

    locale() {
      return this.$store.getters["app/locale"];
    },
    hasSimpleHistory() {
      return this.$store.getters["app/hasSimpleHistory"];
    },
    hasKiosk() {
      return this.$store.getters["app/hasKiosk"];
    },
    series() {
      let names = this.$t("price_types");

      let series = [];
      this.availableTypes.map((type) => {
        series.push({
          perc: 0,
          label: names.filter((i) => i.id === type)[0].desc,
          type,
        });
      });
      this.priceUsage.map((price) => {
        series.map((i) => {
          if (i.type === price.price_type) {
            i.perc = Math.round(price.usage);
          }
        });
      });

      return series.sort((a, b) => (a.perc < b.perc ? 1 : -1));
    },
  },
};
</script>
<style lang="scss">
.dashboard-donut {
  .position-relative {
    max-width: 300px;
    margin: 0 auto;
  }
  .donut-loader-center {
    position: absolute;
    top: 33%;
    right: 35%;
    width: 90px;
    height: 90px;
    border-radius: 55%;
    background: white;
  }
  .v-skeleton-loader__image {
    border-radius: 55%;
    height: 215px;
    width: 215px;
    margin: 12px auto;
  }
  .v-skeleton-loader__heading {
    min-width: 200px;
    height: 26px;
  }
}
</style>
