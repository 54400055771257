<template>
  <div class="record-popup popup" v-if="open">
    <div class="modal-overlay" v-if="open" @click="hide" />
    <v-menu
      attach
      :value="true"
      :close-on-content-click="false"
      :close-on-click="false"
      content-class="core-filter modal-content"
    >
      <div class="mb-4 d-flex justify-space-between">
        <h3 class="main-title">{{ $t(`promocode`) }}</h3>
        <v-btn text class=" icon-close pa-0" min-width="24px" @click="hide" />
      </div>
      <v-form>
        <v-text-field
          class="mb-4 modal-input"
          v-model="promo.code"
          :label="$t(`promocode`)"
        />
        <label class="modal-label">{{ $t(`dates`) }}</label>
        <div class="d-flex justify-center form-period date">
          <v-menu
            attach
            content-class="datepicker-menu"
            :close-on-content-click="false"
            @click.native.stop
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                @click.prevent
                v-on="on"
                readonly
                class="modal-input input-half datepicker-inp mt-0"
                :value="dates[0] ? $moment(dates[0]).format('DD.MM.YYYY') : ''"
                :label="$t('from')"
                :rules="[() => !!dates[0] || $t('from_err')]"
              ></v-text-field>
              <span class="datepicker-divider"></span>
              <v-text-field
                v-on="on"
                class="modal-input input-half datepicker-inp datepicker-inp-second mt-0"
                readonly
                :value="dates[1] ? $moment(dates[1]).format('DD.MM.YYYY') : ''"
                :label="$t('to')"
                :rules="[
                  () =>
                    $moment(dates[1]).isAfter($moment(dates[0])) ||
                    $moment(dates[1])._id === $moment(dates[0])._id ||
                    $t('range_err'),
                ]"
              ></v-text-field>
            </template>
            <v-date-picker :locale="$i18n.locale"
              first-day-of-week="1"
              :isDark="false"
              v-model="dates"
              range
            ></v-date-picker>
          </v-menu>
        </div>
        <v-text-field
          class="modal-input"
          :value="promo.free_minutes / 60"
          @change="(value) => (promo.free_minutes = value * 60)"
          type="number"
          :label="$t(`free_hours`)"
        />
        <v-text-field
          class="modal-input"
          v-model.number="promo.max_usages"
          type="number"
          :label="$t(`usages_max`)"
        />
        <v-btn class="main-btn my-4 py-2 px-6" text @click="sendCode">{{
          $t(`save`)
        }}</v-btn>
        <v-btn
          class="flat-btn cross-icon mr-auto"
          text
          @click="deactivateCode"
          :disabled="!promo.id"
          v-if="promo.is_active === true"
          >{{ $t(`deactivate`) }}</v-btn
        >
        <v-btn
          class="flat-btn mr-auto"
          text
          @click="activateCode"
          :disabled="!promo.id"
          v-if="promo.is_active === false"
          >{{ $t(`activate`) }}</v-btn
        >
        <v-btn
          class="flat-btn error--text"
          text
          @click="deleteCode"
          :disabled="!promo.id"
          >{{ $t(`delete`) }}</v-btn
        >
      </v-form>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: ["selected"],
  data() {
    return {
      open: false,
      promo: {
        code: "",
        max_usages: null,
        free_minutes: null,
        active_from: "",
        active_until: "",
        currency: "HKD",
      },
      dates: [this.selected.active_from, this.selected.active_until],
    };
  },
  watch: {
    selected(newValue) {
      this.promo = { ...this.promo, ...newValue };
      this.dates = [newValue.active_from, newValue.active_until];
    },
  },
  methods: {
    hide() {
      this.open = false;
      this.$emit("reset");
    },
    show() {
      this.open = true;
    },
    sendCode() {
      this.promo.active_from = this.$moment(this.dates[0]).toISOString();
      this.promo.active_until = this.$moment(this.dates[1]).toISOString();
      console.log(this.promo);

      if (this.promo.id) {
        this.$store.dispatch("promo/changePromocode", { ...this.promo });
      } else {
        this.$store.dispatch("promo/addPromocode", { ...this.promo });
      }
      this.hide();
    },
    deactivateCode() {
      this.$store.dispatch("promo/changePromocode", { ...this.promo, is_active: false });
      this.hide();
    },
    activateCode() {
      this.$store.dispatch("promo/changePromocode", { ...this.promo, is_active: true });
      this.hide();
    },
    deleteCode() {
      this.$store.dispatch("promo/deletePromocode", { id: this.promo.id });
      this.hide();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
</style>
