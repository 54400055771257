<template>
  <div>
    <v-skeleton-loader v-if="initialLoading" type="table" />
    <v-data-table
      :headers="headers"
      :items-per-page="-1"
      class="table not-expandable"
      v-else
      :items="pricings"
      disable-pagination
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-for="(i, index) in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div :key="i.text" class="d-flex align-center">
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(index, 'asc')"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(index, 'desc')"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.pricing`]="{ item }">
        <div class="d-flex align-center table-checkbox">
          <v-checkbox
            class="ma-0 pa-0 filter-checkbox"
            :input-value="selected.includes(item.pricing_id)"
            :class="{ active: selected.includes(item.pricing_id) }"
            @change="togglePricing($event, item)"
            :label="item.description"
          />
          <!-- <h4>{{  }}</h4> -->
        </div>
      </template>

      <template v-slot:[`item.months`]="{ item }">
        <div>
          {{ item.months }}
        </div>
      </template>

      <template v-slot:[`item.deposit`]="{ item }">
        <div>${{ item.deposit / 100 }}</div>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        <div>${{ item.price / 100 }}</div>
      </template>

      <template v-slot:[`item.places`]="{ item }" v-if="isCompany">
        <div>
          {{ places.filter((i) => i.place_id === item.place_id)[0].place_name }}
        </div>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center">
          <v-btn text class="ml-0 flat-btn mr-6" @click="editPricing(item)">{{
            $t(`edit`)
          }}</v-btn>
        </div>
      </template>
    </v-data-table>
    <PricingForm
      :type="1"
      ref="pricingForm"
      :selected="pricing"
      @hideEmit="pricing = {}"
    />
  </div>
</template>

<script>
export default {
  name: "Pricings",
  components: {
    PricingForm: () => import("@/components/Settings/PricingForm"),
  },
  data() {
    return {
      selected: [],
      pricing: {},
    };
  },
  computed: {
    headers() {
      let init = [
        {
          text: this.$t("pricing"),
          value: "pricing",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("price"),
          value: "price",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("deposit"),
          value: "deposit",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("months"),
          value: "months",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          class: "table-header",
          sortable: false,
        },
      ];
      if (this.isCompany) {
        init.splice(1, 0, {
          text: this.$t("places"),
          value: "places",
          class: "table-header",
          sortable: false,
        });
      }
      return init;
    },
    pricings() {
      return this.$store.state.settings.pricings;
    },
    places() {
      return this.$store.state.app.places;
    },
    initialLoading() {
      return this.$store.state.settings.loading_pricings;
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
  },
  methods: {
    editPricing(item) {
      this.pricing = item;
      this.$refs.pricingForm.show();
    },
    togglePricing(value, item) {
      if (value) this.selected.push(item.pricing_id);
      else this.selected = this.selected.filter((i) => i !== item.pricing_id);
    },
    deleteSelected() {
      this.$confirm({
        message: this.$t("form_delete_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            let data = {
              pricings: this.selected,
            };
            this.$store.dispatch("settings/deletePricings", data);
          }
        },
      });
    },
  },
};
</script>
<style lang="scss"></style>
