<template>
<h3 v-if="isLoaded" class="hourly-space">
  {{ $t('hourly_space_vacancy') }} -
  <span v-for="hourlyVacancy in hourlyVacancies" :key="hourlyVacancy.place_id" class="hourly-space-item">
    {{ hourlyVacancy.short_name }}{{ hourlyVacancy.short_name ? ":" : "" }}{{'\xa0'}}{{ hourlyVacancy.vacancy ? hourlyVacancy.vacancy : $t('full') }}
  </span>
</h3>
</template>

<script>
import qs from 'qs';
import { base } from '@/axios-api';

export default {
  name: "HourlyVacancy",
  data() {
    return {
      hourlyVacancies: [],
      isLoaded: false,
      interval: null,
    };
  },
  computed: {
    placesIds() {
      return this.$store.getters['app/places'];
    },
  },
  created() {
    this.getHourlyVacancies();
    this.interval = setInterval(() => {
      this.getHourlyVacancies();
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
    this.interval = null;
  },
  methods: {
    getHourlyVacancies() {
      base({
        url: "/places/vacancy",
        method: "GET",
        params: {
          place_id: this.placesIds,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, {
            arrayFormat: "repeat",
          });
        },
      })
        .then((response) => {
          this.hourlyVacancies = response.data.body;
          this.isLoaded = true;
        })
    },
  },
}
</script>

<style scoped lang="scss">
.hourly-space {
  @media screen and (max-width: 1440px){
    font-size: 16px;
  }
  @media screen and (max-width: 960px){
    font-size: 14px;
    max-width: 60%;
    text-align: left;
    justify-self: flex-start;
  }
}
.hourly-space-item {
  text-transform: uppercase;
}
</style>
