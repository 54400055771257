<template>
  <div class="tabs-content your-parking guest-flow">
    <h3 class="main-title">{{ title }}</h3>

    <div class="d-flex align-center justify-space-between mb-4">
      <div class="d-flex align-center flex-wrap flex-md-nowrap">
        <v-btn text class="modal-btn mb-0" @click="backToParking">{{
          $t("form_back_parking")
        }}</v-btn>
        <h3 class="modal-label reg-font mb-0">• {{ name }}</h3>
      </div>
      <v-btn v-show="step !== 5" text class="flat-btn" @click="treeHandler">
        {{ tree ? $t("back") : $t("form_show_tree") }}</v-btn
      >
    </div>
    <v-tabs v-show="!tree" :fixed-tabs="true" @change="toStep($event)" class="mb-sm-0">
      <v-tab
        :disabled="(step < index && formType === 0) || (index === step) === 5"
        :class="{
          'v-tab--active': step > index || formType === 1,
          'v-tab--active-first': index === 0,
          'v-tab--active-last': index === step,
        }"
        v-for="(item, index) in steps"
        :key="item"
      >
        {{ item }}</v-tab
      >
    </v-tabs>

    <template v-if="!tree">
      <!-- GROUP FORM -->
      <Group
        @groupChangedHandler="groupChangedHandler"
        @deleteGroup="deleteGroup"
        :group="group"
        v-show="step === 0"
      />
      <!-- SUBGROUP FORM -->
      <v-form ref="subgroup" autocomplete="off" v-show="form === 'subgroup'">
        <h3 class="main-title" style="font-size: 20px !important">
          {{ $t("subgroups") }}
        </h3>
        <h3
          class="modal-label reg-font"
          v-html="$t('subgroup_add', { group: group.group_name })"
        />
        <div class="mt-6" v-for="(subgroup, sIndex) in group.subgroups" :key="sIndex">
          <template v-if="(hasChoice && subgroup.chosen) || !hasChoice">
            <div class="d-flex justify-space-between align-center">
              <label class="modal-label">{{ $t("subgroup") }} {{ sIndex + 1 }}</label>
              <v-btn @click="deleteSubgroup(sIndex)" text class="modal-btn">{{
                $t("delete")
              }}</v-btn>
            </div>
            <v-combobox
              :items="subgroups"
              :filter="customSubgFilter"
              attach
              item-text="group_name"
              item-value="group_id"
              :value="subgroup.group_name"
              @change="subgroupChangeHandler(subgroup, $event)"
              class="modal-input"
              label="Subgroup name"
              :rules="[() => !!subgroup.group_name || $t('subgroup') + $t('required')]"
          /></template>
        </div>

        <div class="d-flex justify-space-between mt-6">
          <v-btn
            @click="addSubgroup(group.subgroups)"
            text
            class="flat-btn modal-footer-btn mb-4"
            >+ {{ $t("add") }} {{ $t("subgroup") }}</v-btn
          >
        </div>
      </v-form>

      <!-- GUEST FORM -->
      <v-form ref="guest" autocomplete="off" v-show="form === 'guest'">
        <h3 class="main-title" style="font-size: 20px !important">
          {{ $t("guests") }}
        </h3>
        <div v-for="(subgroup, sIndex) in group.subgroups" :key="sIndex">
          <div class="d-flex align-center justify-space-between">
            <h3
              v-if="mode === 0 && flow === 0"
              class="modal-label reg-font"
              v-html="
                $t('guest_add', {
                  subgroup: subgroup.group_name,
                  group: group.group_name,
                })
              "
            />
            <template v-if="mode === 0 && formType === 0">
              <input
                accept=".xls,.xlsx"
                style="width: 0; margin-right: auto"
                type="file"
                :ref="subgroup.group_name"
                @change="uploadFile"
              />
              <v-btn class="flat-btn mr-5" text @click="downloadTemplate('guests')">{{
                $t("download_template")
              }}</v-btn>
              <v-btn
                class="flat-btn d-flex align-center"
                text
                @click="startUpload(subgroup.group_name)"
                ><v-file-input class="pa-0 ma-0 mr-n2" @click.stop.prevent /><span>{{
                  $t("import")
                }}</span></v-btn
              >
            </template>
          </div>
          <div
            v-for="(guest, guestIndex) in subgroup.guests"
            :key="guestIndex"
            class="my-6"
          >
            <template v-if="!hasChoice || (hasChoice && guest.chosen)">
              <div class="d-flex align-center">
                <label class="modal-label">{{ $t("guest") }} {{ guestIndex + 1 }}</label>
                <v-checkbox
                  :label="$t('non_pbnb')"
                  :input-value="guest.guest_type === 3"
                  @change="($event) => guestTypeChangeHandler($event, guest, 3)"
                  class="filter-checkbox my-0 ml-4 mb-1 pa-0"
                  :class="guest.guest_type === 3 ? 'active' : ''"
                />
                <v-checkbox
                  v-if="hasOverparking"
                  :label="$t('overparking')"
                  v-model="guest.is_overtime"
                  class="filter-checkbox my-0 ml-4 mb-1 pa-0"
                  :class="guest.is_overtime ? 'active' : ''"
                />
                <v-btn
                  @click="deleteGuest(subgroup.guests, guestIndex, guest)"
                  text
                  class="modal-btn ml-auto"
                  >{{ $t("delete") }}</v-btn
                >
              </div>
              <div class="d-flex guest-row">
                <v-text-field
                  v-model="guest.first_name"
                  @change="selectSendingGuest(guest)"
                  class="modal-input"
                  :label="$t('guest_fname')"
                  :rules="[
                    () => !!guest.first_name || $t('guest_fullname') + $t('required'),
                  ]"
                />
                <v-text-field
                  v-model="guest.last_name"
                  @change="selectSendingGuest(guest)"
                  class="modal-input"
                  :label="$t('guest_lname')"
                />
              </div>
              <!-- SPACES -->
              <div class="d-flex align-baseline mt-8">
                <div class="modal-label mr-8" style="font-size: 18px !important">
                  {{
                    $t("guest_spaces", { g: `${guest.first_name} ${guest.last_name}` })
                  }}
                </div>
                <v-btn
                  @click="addSpaceRecord(guest)"
                  text
                  class="flat-btn modal-footer-btn"
                  >+ {{ $t("add") }} {{ $t("parking_record") }}</v-btn
                >
              </div>
              <div v-for="(record, recordIndex) in guest.spaces" :key="record.record_id">
                <div class="d-flex justify-space-between align-center mt-4">
                  <label class="modal-label"
                    >{{ $t("parking_record") }} {{ recordIndex + 1 }}</label
                  >
                  <v-checkbox
                    :label="$t('space_fixed')"
                    v-model="record.is_fixed"
                    @change="selectSendingGuest(guest)"
                    class="filter-checkbox my-0 ml-4 mb-1 pa-0 mr-auto"
                    :class="record.is_fixed ? 'active' : ''"
                  />
                  <v-btn
                    @click="deleteSpaceRecord(guest, recordIndex)"
                    text
                    class="modal-btn"
                    >{{ $t("delete") }}</v-btn
                  >
                </div>
                <div class="d-flex guest-row">
                  <v-text-field
                    type="number"
                    min="0"
                    v-model.number="record.space_count"
                    @change="selectSendingGuest(guest)"
                    class="modal-input"
                    :label="$t('spaces_number')"
                    :rules="[
                      () =>
                        !isNaN(record.space_count) ||
                        $t('spaces_number') + ' ' + $t('required'),
                    ]"
                  />

                  <!-- DATEPICKER FOR GUEST -->
                  <div class="d-flex date">
                    <v-menu
                      content-class="datepicker-menu"
                      attach
                      :close-on-content-click="!!record.start_date"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          class="modal-input input-half datepicker-inp"
                          @change="
                            timeChangeHandler(guest, $event, 'start_date', recordIndex)
                          "
                          :value="$moment(record.start_date).format('DD.MM.YYYY')"
                          :label="$t('from')"
                          :disabled="Number(record.space_count) < 1"
                          :rules="[
                            () =>
                              Number(record.space_count) === 0 ||
                              !!record.start_date ||
                              $t('from_err'),
                          ]"
                        ></v-text-field>
                        <span class="datepicker-divider"></span>
                      </template>

                      <v-date-picker
                        :locale="$i18n.locale"
                        first-day-of-week="1"
                        :isDark="false"
                        :value="$moment(record.start_date).format('YYYY-MM-DD')"
                        @change="
                          timeChangeHandler(guest, $event, 'start_date', recordIndex)
                        "
                      ></v-date-picker>
                    </v-menu>
                    <v-menu
                      content-class="datepicker-menu"
                      attach
                      :close-on-content-click="!!record.end_date"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-on="on"
                          class="modal-input input-half datepicker-inp datepicker-inp-second"
                          @change="
                            timeChangeHandler(guest, $event, 'end_date', recordIndex)
                          "
                          :value="$moment(record.end_date).format('DD.MM.YYYY')"
                          :label="$t('to')"
                          :disabled="Number(record.space_count) < 1"
                          :rules="[
                            () =>
                              Number(record.space_count) === 0 ||
                              $moment(record.end_date).isAfter(
                                $moment(record.start_date)
                              ) ||
                              $moment(record.start_date)._i ===
                                $moment(record.end_date)._i ||
                              isFree ||
                              $t('range_err'),
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        :locale="$i18n.locale"
                        first-day-of-week="1"
                        :isDark="false"
                        :value="$moment(record.end_date).format('YYYY-MM-DD')"
                        @change="
                          timeChangeHandler(guest, $event, 'end_date', recordIndex)
                        "
                      ></v-date-picker>
                    </v-menu>
                  </div>
                </div>
              </div>
              <div class="d-flex guest-row" v-if="isBV">
                <v-text-field
                  v-model="guest.extra_info.loyalty_number"
                  @change="selectSendingGuest(guest)"
                  class="modal-input"
                  :label="$t('loyalty_card')"
                />
                <!-- DATEPICKER FOR LOYALTY CARD -->
                <div class="d-flex date single">
                  <v-menu
                    content-class="datepicker-menu"
                    attach
                    :close-on-content-click="!!guest.extra_info.loyalty_exp"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-on="on"
                        class="modal-input input-half datepicker-inp"
                        @change="timeChangeHandler(guest, $event, 'loyalty')"
                        :value="
                          $moment(guest.extra_info.loyalty_exp).format('DD.MM.YYYY')
                        "
                        :label="$t('exp_date')"
                        :disabled="!guest.extra_info.loyalty_number"
                      ></v-text-field>
                    </template>

                    <v-date-picker
                      :locale="$i18n.locale"
                      first-day-of-week="1"
                      :isDark="false"
                      @change="timeChangeHandler(guest, $event, 'loyalty')"
                    ></v-date-picker>
                  </v-menu>
                </div>
              </div>
              <v-divider class="mt-6" />
            </template>
          </div>
          <div
            class="d-flex justify-space-between mt-4"
            v-if="!hasChoice || (hasChoice && subgroup.chosen)"
          >
            <v-btn
              @click="addGuest(subgroup.guests)"
              text
              class="flat-btn modal-footer-btn mb-4"
              >+ {{ $t("add") }} {{ $t("guest") }}</v-btn
            >
          </div>
        </div>
      </v-form>

      <!-- CAR FORM -->
      <v-form ref="car" autocomplete="off" v-show="form === 'car'">
        <div class="d-flex align-center justify-space-between">
          <h3 class="main-title" style="font-size: 20px !important">
            {{ $t("cars") }}
          </h3>
          <template v-if="mode === 1 && formType === 0">
            <input
              accept=".xls,.xlsx"
              style="width: 0; margin-right: auto"
              type="file"
              ref="carsUploader"
              @change="uploadFile"
            />
            <v-btn class="flat-btn mr-5" text @click="downloadTemplate('cars')">{{
              $t("download_template")
            }}</v-btn>
            <v-btn
              class="flat-btn d-flex align-center"
              text
              @click="startUpload('carsUploader')"
              ><v-file-input class="pa-0 ma-0 mr-n2" @click.stop.prevent /><span>{{
                $t("import")
              }}</span></v-btn
            >
          </template>
        </div>
        <div v-for="(subgroup, sIndex) in group.subgroups" :key="sIndex">
          <div
            class="mb-6"
            v-for="(guest, guestIndex) in subgroup.guests"
            :key="guestIndex"
          >
            <h3
              v-if="
                mode !== 1 && flow !== 2 && (!hasChoice || (hasChoice && guest.chosen))
              "
              class="modal-label reg-font"
              style="font-size: 16px !important"
              v-html="
                $t('car_add', {
                  guest: guest.first_name,
                })
              "
            />
            <div
              v-for="(car, carIndex) in [...guest.cars].filter((i) => i.status !== 3)"
              :key="carIndex"
              class="mt-6"
            >
              <template v-if="!hasChoice || (hasChoice && car.chosen)">
                <v-expansion-panels flat multiple accordion>
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      <div class="d-flex justify-space-between align-center">
                        <label v-if="flow === 2 || mode === 1" class="modal-label"
                          >{{ $t("car") }} {{ guestIndex + 1 }}</label
                        >
                        <label v-else class="modal-label"
                          >{{ $t("car") }} {{ carIndex + 1 }}</label
                        >
                        <v-btn
                          @click="deleteCar(guest, carIndex)"
                          text
                          class="modal-btn mr-6"
                          >{{ $t("delete") }}</v-btn
                        >
                      </div>
                      <div class="d-flex justify-space-between guest-row-3 flex-wrap">
                        <v-text-field
                          :key="carPlateKey"
                          @click.stop
                          @change="selectSendingGuest(guest)"
                          :value="car.car_plate"
                          :label="$t('car_plate')"
                          @blur="(e) => plateChangeHanlder(guest, car, e.target.value)"
                          class="modal-input"
                          :rules="[
                            () =>
                              (car.car_plate && car.car_plate.length < 13) ||
                              $t('car_plate') + ' ' + $t('required'),
                          ]"
                        />
                        <!-- DATEPICKER FOR CAR -->
                        <template>
                          <div class="date single flex-grow" v-if="flow === 2">
                            <v-menu
                              content-class="datepicker-menu"
                              attach
                              :close-on-content-click="!!car.start_date"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  class="modal-input datepicker-inp"
                                  @change="carDateHandler(guest, car, $event)"
                                  :value="$moment(car.start_date).format('DD.MM.YYYY')"
                                  :label="$t('dates')"
                                  :rules="[
                                    () =>
                                      !!car.start_date || $t('dates') + $t('required'),
                                  ]"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :locale="$i18n.locale"
                                first-day-of-week="1"
                                :isDark="false"
                                @change="carDateHandler(guest, car, $event)"
                                :value="$moment(car.start_date).format('YYYY-MM-DD')"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                          <div class="d-flex date flex-grow" v-else>
                            <v-menu
                              content-class="datepicker-menu"
                              attach
                              :close-on-content-click="!!car.start_date"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  class="modal-input input-half datepicker-inp"
                                  @change="
                                    carDateHandler(guest, car, $event, 'start_date')
                                  "
                                  :value="$moment(car.start_date).format('DD.MM.YYYY')"
                                  :label="$t('from')"
                                  :rules="[() => !!car.start_date || $t('from_err')]"
                                ></v-text-field>
                                <span class="datepicker-divider"></span>
                              </template>
                              <v-date-picker
                                :locale="$i18n.locale"
                                first-day-of-week="1"
                                :isDark="false"
                                @change="carDateHandler(guest, car, $event, 'start_date')"
                                :value="$moment(car.start_date).format('YYYY-MM-DD')"
                              />
                            </v-menu>
                            <v-menu
                              content-class="datepicker-menu"
                              attach
                              :close-on-content-click="!!car.end_date"
                            >
                              <template v-slot:activator="{ on }">
                                <v-text-field
                                  v-on="on"
                                  class="modal-input input-half datepicker-inp datepicker-inp-second"
                                  @change="carDateHandler(guest, car, $event, 'end_date')"
                                  :value="$moment(car.end_date).format('DD.MM.YYYY')"
                                  :label="$t('to')"
                                  :rules="[
                                    () =>
                                      $moment(car.end_date).isAfter(
                                        $moment(car.start_date)
                                      ) ||
                                      $moment(car.start_date)._i ===
                                        $moment(car.end_date)._i ||
                                      isFree ||
                                      $t('range_err'),
                                  ]"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                :locale="$i18n.locale"
                                first-day-of-week="1"
                                :isDark="false"
                                @change="carDateHandler(guest, car, $event, 'end_date')"
                                :value="$moment(car.end_date).format('YYYY-MM-DD')"
                              ></v-date-picker>
                            </v-menu>
                          </div>
                        </template>
                        <v-text-field
                          @click.stop
                          @change="
                            selectSendingGuest(guest), (car.status = car.car_id ? 2 : 1)
                          "
                          v-model="car.remark"
                          :label="$t('remark')"
                          class="modal-input"
                        />
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div
                        class="d-flex flex-wrap flex-wrap justify-space-between"
                        :class="
                          $store.getters['app/hasZones'] ? 'guest-row-5' : 'guest-row-4'
                        "
                      >
                        <v-text-field
                          v-model="car.driver_phone"
                          @change="
                            selectSendingGuest(guest), (car.status = car.car_id ? 2 : 1)
                          "
                          class="modal-input"
                          :label="$t('driver_phone')"
                          :rules="[phoneRules]"
                        />
                        <v-select
                          :no-data-text="$t('no_data')"
                          attach
                          :value="$t(`car_types[${car.car_type}]`)"
                          @change="carTypeHandler(guest, car, $event)"
                          class="modal-input car-type-select"
                          :items="carTypes"
                          :label="$t('car_type')"
                          :rules="[
                            () =>
                              !!car.car_type ||
                              car.car_type === 0 ||
                              $t('car_type') + ' ' + $t('required'),
                          ]"
                        />
                        <v-combobox
                          attach
                          v-model="car.brand_name"
                          @change="
                            selectSendingGuest(guest), (car.status = car.car_id ? 2 : 1)
                          "
                          class="modal-input"
                          :items="getCarBrands(car.brand_name)"
                          :label="$t('car_brand')"
                          :rules="[
                            () => !!car.brand_name || $t('car_brand') + $t('required'),
                          ]"
                        />
                        <v-combobox
                          attach
                          v-model="car.color"
                          @change="
                            selectSendingGuest(guest), (car.status = car.car_id ? 2 : 1)
                          "
                          class="modal-input"
                          :items="carColors"
                          :label="$t('car_color')"
                        />
                        <v-select
                          :no-data-text="$t('no_data')"
                          v-if="$store.getters['app/hasZones']"
                          attach
                          @change="
                            selectSendingGuest(guest), (car.status = car.car_id ? 2 : 1)
                          "
                          multiple
                          v-model="car.zones"
                          class="modal-input car-type-select"
                          :items="zones"
                          item-text="zone_name"
                          item-value="zone_id"
                          :label="$t('zone')"
                        /></div
                    ></v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </template>
            </div>
            <div
              class="d-flex justify-space-between mt-4"
              v-if="!hasChoice || (hasChoice && guest.chosen)"
            >
              <v-btn
                @click="addCar(subgroup.guests, guest)"
                text
                class="flat-btn modal-footer-btn mb-4"
                >+ {{ $t("add") }} {{ $t("car") }}</v-btn
              >
            </div>
          </div>
        </div>
      </v-form>

      <template v-if="step < 5 && !tree">
        <div class="d-flex align-center justify-end mt-6">
          <v-btn
            @click="back"
            v-if="step !== 0"
            text
            class="flat-btn modal-footer-btn mr-6"
            >{{ $t("back") }}</v-btn
          >
          <v-btn @click="next" text class="main-btn px-6 py-2">{{ $t("next") }}</v-btn>
        </div></template
      >

      <!-- DONE -->
      <Done v-if="step === 5" />
    </template>

    <template v-else>
      <GuestTree
        :show="tree"
        :checkTab="isCheckTab"
        :group="group"
        :step="step"
        :sending="sending"
        @clickedSend="sendGuest"
        @changeSelected="changeSelected"
        @setSending="setSending"
      />
    </template>
  </div>
</template>

<script>
import XLSX from "xlsx";
import carBrandsDb from "@/assets/cars/carModels.json";
import { base } from "@/axios-api";
import { formatPlate } from "../../common/helpers/helpers";

const defaultGuest = {
  chosen: true,
  first_name: "",
  extra_info: {},
  cars: [
    {
      brand_name: "N/A",
      car_type: 1,
      chosen: true,
      start_date: "",
      end_date: "",
    },
  ],
  spaces: [
    {
      chosen: true,
      start_date: "",
      end_date: "",
      is_fixed: false,
    },
  ],
};

const defaultGroup = {
  group_name: "",
  chosen: true,
  is_simple: false,
  subgroups: [
    {
      chosen: true,
      group_name: "",
      guests: [JSON.parse(JSON.stringify(defaultGuest))],
    },
  ],
};

export default {
  name: "Flow",
  data() {
    return {
      tree: false,
      step: 0,
      sending: false,
      changed: false,
      changedGroup: false,
      changedSubgroup: null,
      hasChoice: false,
      group: JSON.parse(JSON.stringify(defaultGroup)),
      busyGroup: false,
      busySubgroup: false,
      forced: false,
      file: "",
      fields: {
        "First name": "first_name",
        "Last name": "last_name",
        Spaces: "space_number",
        "End date": "end_date",
        "Start date": "start_date",
        Overtime: "is_overtime",
        "Non-parkingbnb": "non_pbnb",
        Cars: "cars_count",
        "Car plate": "car_plate",
        Remark: "remark",
        "Phone number": "driver_phone",
        "Vehicle brand": "brand_name",
        Zone: "zone",
        Color: "color",
        "Car start date": "start_date",
        "Car end date": "end_date",
        "Car type": "car_type",
      },
      carPlateKey: 0,
    };
  },
  components: {
    GuestTree: () => import("@/components/YourParking/GuestTree"),
    Group: () => import("@/components/YourParking/Group"),
    Done: () => import("@/components/YourParking/Done"),
  },
  computed: {
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    title() {
      return this.formType === 0
        ? this.$t("add") + " " + this.$t("guest")
        : this.$t("change") + " " + this.$t("guest");
    },
    name() {
      let name = "";
      if (this.isCompany && this.$store.state.app.user.company_name) {
        name = this.$store.state.app.user.company_name;
      } else {
        name = this.places[0] ? this.places[0].place_name : "";
      }
      return name;
    },
    stateGuest() {
      return this.$store.state.guests.current;
    },
    groups() {
      return [...this.$store.state.guests.groups];
    },
    subgroups() {
      return [...this.$store.state.guests.subgroups];
    },
    formType() {
      return (
        Number(!!this.stateGuest && !!this.stateGuest.group_id) ||
        Number(this.stateGuest && this.stateGuest.group_name === "N/A")
      );
    },
    status() {
      return this.$store.state.guests.status;
    },
    guestTab() {
      return this.$store.state.guests.guest_tab;
    },
    isCheckTab() {
      return this.step === 4;
    },
    places() {
      return [...this.$store.state.app.places];
    },
    currentPlace() {
      return this.$store.state.guests.place || this.places[0].place_id;
    },
    steps() {
      let init = [...this.$t("form_steps")];

      if (this.flow === 1) init.splice(1, 1); //remove subgroups for drivers
      if (this.flow === 2 || this.mode === 1) init.splice(1, 2); //remove subgroups and guests
      return init;
    },
    zones() {
      return [...this.$store.state.app.zones];
    },
    carTypes() {
      return [...this.$store.state.app.car_types].map((id) =>
        this.$t(`car_types[${id}]`)
      );
    },
    carColors() {
      return this.$t("car_colors");
    },
    hasOfflinePayment() {
      return this.$store.getters["app/hasOfflinePayment"];
    },
    hasLimitedGuests() {
      return this.$store.getters["app/hasOverParking"];
    },
    hasOverparking() {
      return this.$store.getters["app/hasOverParking"];
    },
    isOffline() {
      return this.group.group_name === "Customers" && this.hasOfflinePayment;
    },
    isLimited() {
      return (
        this.group.group_name.includes("Limited Hour Parking") && this.hasLimitedGuests
      );
    },
    isOvertime() {
      return this.group.group_name.includes("Hourly Parking") && this.hasLimitedGuests;
    },
    isBV() {
      return this.$store.getters["app/isBV"];
    },
    isCyber() {
      return this.$store.getters["app/isCyber"];
    },
    isFree() {
      return this.group.group_name.includes("Free parking") && this.isBV;
    },
    flow() {
      //0 - default (group)
      //1 - driver (no subgroups)
      //2 - one time (no subgroups, no guests)
      let result = 0;
      let group = this.group.group_name;
      if (group.includes("Drivers") || group.includes("Customers")) result = 1;
      if (this.group.group_name.includes("One-time parking")) result = 2;

      return result;
    },
    mode() {
      //0 - default (advanced)
      //1 - simple
      return Number(this.group.is_simple === true);
    },
    discount() {
      switch (this.group.group_name) {
        case "Discounted 10%":
          return 10;
        case "Discounted 50%":
          return 50;
        default:
          return 0;
      }
    },
    form() {
      switch (this.step) {
        case 0:
          return "group";
        case 1:
          if (this.flow === 0 && this.mode === 0) return "subgroup";
          else return "guest";
        case 2:
          if (this.flow !== 2 && this.mode === 0) return "guest";
          else return "car";
        case 3:
          return "car";
      }
      return "";
    },
  },
  watch: {
    guestTab: {
      deep: true,
      handler(val) {
        this.toStep(val);
        if (val !== 5) {
          this.$store.commit("guests/reset_status", undefined);
        }
      },
    },
  },
  mounted() {
    this.parseGuest(this.stateGuest);
  },
  methods: {
    // FILE METHODS
    downloadTemplate(type) {
      this.$store.dispatch("guests/getTemplate", type);
    },
    startUpload(uploader) {
      if (uploader === "carsUploader") {
        this.$refs.carsUploader.click();
      } else {
        this.$refs[uploader][0].click();
      }
      this.file = uploader;
    },
    uploadFile(e) {
      let files = e.target.files,
        f = files[0];
      let reader = new FileReader();
      reader.onload = (e) => {
        let data = new Uint8Array(e.target.result);
        let workbook = XLSX.read(data, { type: "array" });
        let sheetName = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[sheetName];

        let content = XLSX.utils.sheet_to_json(worksheet);
        if (this.mode === 0) {
          this.processFileGuests(content);
        } else {
          this.processFileCars(content);
        }
      };
      reader.readAsArrayBuffer(f);
    },
    processFileGuests(data) {
      let init = this.replaceFields(data);
      let guests = init.map((g, i) => {
        if (g.cars_count) {
          g.cars = init
            .slice()
            .splice(i + 1, g.cars_count)
            .map((c) => this.formatCar(c));
          delete g.cars_count;
          return {
            ...g,
            guest_type: g.non_pbnb ? 3 : 1,
            start_date: g.start_date,
            end_date: g.end_date,
            chosen: true,
            spaces: [],
          };
        }
      });
      guests = guests.filter((g) => !!g);
      this.group.subgroups.map((d) => {
        if (d.group_name === this.file) {
          d.guests = guests;
          this.$forceUpdate();
        }
      });
    },
    processFileCars(data) {
      let init = this.replaceFields(data);
      let guests = init.map((c) => {
        return {
          first_name: "",
          last_name: "",
          space_number: 1,
          is_overtime: c.is_overtime,
          guest_type: c.non_pbnb ? 3 : 1,
          start_date: this.excelToJsDate(c.start_date),
          end_date: this.excelToJsDate(c.end_date),
          cars: [
            {
              ...this.formatCar(c),
            },
          ],
          spaces: [],
        };
      });
      guests = guests.filter((g) => !!g);
      this.group.subgroups.map((d) => {
        if (d.group_name === this.file || this.file === "carsUploader") {
          d.guests = guests;
        }
      });
    },
    formatCar(car) {
      return {
        ...car,
        start_date: this.excelToJsDate(car.start_date),
        end_date: this.excelToJsDate(car.end_date),
        zones: car.zone
          ? this.zones.filter((z) => z.name === car.zone)[0]
            ? this.zones.filter((z) => z.name === car.zone)[0].zone_id
            : null
          : null,
        car_plate: this.plateFormatter(car.car_plate),
        brand_name: car.brand_name || "N/A",
        car_type: car.car_type ? this.$t("car_types").indexOf(car.car_type) : 0,
        driver_phone: JSON.stringify(car.driver_phone),
        remark: "" + (car.remark || ""),
        color: "",
        chosen: true,
      };
    },
    // AUXILARY & FORMATTING METHODS
    excelToJsDate(excel) {
      // console.log(excel);
      let date = new Date(Math.round((excel - 25569) * 86400 * 1000));
      return this.$moment(date).format("YYYY-MM-DD");
    },
    replaceFields(array) {
      let result = [];
      array.map((o) => {
        let newOb = {};
        for (const [key, value] of Object.entries(o)) {
          newOb[this.fields[key]] = value;
        }
        result.push(newOb);
      });
      return result;
    },
    getCarBrands(value) {
      if (value && carBrandsDb[value] && value !== "N/A") {
        return carBrandsDb[value].map((brand) => `${value} ${brand}`);
      } else {
        return Object.keys(carBrandsDb);
      }
    },
    phoneRules(value) {
      if (!value || value === "" || !isNaN(value)) {
        return true;
      }
      return this.$t("phone_err");
    },
    plateFormatter(value) {
      if (!value) return "";

      return formatPlate(value);
    },
    getDatePattern(value) {
      let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      let date = this.$moment(new Date(value.replace(pattern, "$3-$2-$1"))).format(
        "YYYY-MM-DD"
      );
      return date;
    },
    carDateHandler(guest, car, value, type) {
      let date = this.getDatePattern(value);
      this.changed = true;
      guest.selected = true;
      if (this.flow === 2) {
        car.start_date = date;
        car.end_date = date;
      } else {
        car[type] = date;
      }
      if (car.car_id) {
        car.status = 2;
      }
      this.$forceUpdate();
    },
    timeChangeHandler(guest, value, type, i) {
      let date = this.getDatePattern(value);
      this.changed = true;
      guest.selected = true;
      if (type === "loyalty") {
        guest.extra_info.loyalty_exp = date;
      } else {
        guest.spaces[i][type] = date;
        guest.cars.map((car) => {
          car[type] = date;
          if (car.car_id) {
            car.status = 2;
          }
        });
      }
      this.$forceUpdate();
    },
    carTypeHandler(guest, car, value) {
      console.log(value);
      this.selectSendingGuest(guest);
      car.car_type = this.$t("car_types").indexOf(value);
      car.status = car.car_id ? 2 : 1;
    },

    customSubgFilter(item, queryText) {
      return (
        item.group_name.toLowerCase().includes(queryText.toLowerCase()) &&
        item.parent_id === this.group.group_id
      );
    },

    //CHANGE HANDLER
    toStep(value) {
      this.step = value;
      // console.log("to step " + value);
      if (value === 4) {
        this.tree = true;
      } else if (value === 5) {
        this.tree = false;
      }
    },
    groupChangedHandler(event) {
      this.group.group_name = event;
      this.changed = true;
      this.changedGroup = true;
      this.group.subgroups.map((d) => {
        d.guests.map((g) => {
          g.selected = true;
        });
      });
    },
    subgroupChangeHandler(subgroup, value) {
      this.changed = true;
      subgroup.group_name = value.group_name || value;
      subgroup.guests.map((g) => {
        g.selected = true;
      });
    },
    selectSendingGuest(guest) {
      guest.selected = true;
      this.changed = true;
    },
    plateChangeHanlder(guest, car, newPlate) {
      this.carPlateKey++;
      if (car.car_id) {
        car.status = 3;
        guest.cars.push({
          ...car,
          car_id: null,
          status: 1,
          car_plate: this.plateFormatter(newPlate),
        });
      } else {
        car.car_plate = this.plateFormatter(newPlate);
      }
      this.$forceUpdate();
    },
    guestTypeChangeHandler(event, guest, value) {
      this.changed = true;
      guest.selected = true;
      if (event) {
        guest.guest_type = value;
      } else {
        guest.guest_type = 1;
      }
    },

    // TREE HANDLER
    changeSelected(chosen) {
      this.hasChoice = true;
      for (let key of Object.keys(chosen)) {
        if (chosen[key].length) {
          let tab = Object.keys(chosen).indexOf(key) + 1;

          this.$store.commit("guests/set_guest_tab", tab);
          this.toStep(tab);
          this.tree = false;
          break;
        }
      }
    },
    treeHandler() {
      if (this.isCheckTab) {
        this.tree = false;
      } else {
        this.tree = !this.tree;
      }
    },

    groupChoice() {
      this.getGroup(this.group);
      // if (this.flow !== 0 || this.mode === 1) {
      //   this.group.subgroups.forEach((subgroup) => {
      //     this.getSubgroup(subgroup);
      //   });
      // }
      if (this.mode === 1) {
        this.$store.commit("guests/set_guest_tab", 3);
      } else {
        switch (this.flow) {
          case 0:
            this.$store.commit("guests/set_guest_tab", 1);
            break;
          case 1:
            this.$store.commit("guests/set_guest_tab", 2);
            break;
          case 2:
            this.$store.commit("guests/set_guest_tab", 3);
            break;
        }
      }
    },

    // ROUTE METHODS
    next() {
      switch (this.step) {
        case 0:
          var target = [...this.groups].filter(
            (i) => i.group_name === this.group.group_name
          )[0];
          var newGroup = !target;
          if (this.formType === 0 && newGroup && this.flow !== 1) {
            this.$confirm({
              message: this.$t("group_choose"),
              button: {
                yes: this.$t("simple"),
                no: this.$t("advanced"),
              },
              custom: "choose",
              callback: (confirm) => {
                this.group = { ...this.group, is_simple: confirm === true };
                this.groupChoice();
              },
            });
          } else {
            this.groupChoice();
          }
          break;
        case 1:
          this.group.subgroups.forEach((subgroup) => {
            this.getSubgroup(subgroup);
            this.$store.commit("guests/set_guest_tab", 2);
          });
          break;
        case 2:
          if (this.$refs.guest.validate()) {
            this.$store.commit("guests/set_guest_tab", 3);
          } else {
            this.$refs.guest.validate();
          }
          break;
        case 3:
          if (this.$refs.car.validate()) {
            this.$store.commit("guests/set_guest_tab", 4);
            this.tree = true;
          } else {
            this.$refs.car.validate();
          }
          break;
        default:
          this.sendGuest();
          break;
      }
    },
    back() {
      if (this.step) {
        this.step--;
        this.$store.commit("guests/set_guest_tab", this.step);
      }
    },
    backToParking() {
      this.$router.push("/platform/your-parking");
    },

    // GROUP METHODS
    updateGroup(group) {
      this.busyGroup = true;
      let data = {
        groups: [
          {
            ...group,
            place_id: this.currentPlace,
          },
        ],
      };
      base({
        url: "/groups",
        method: "PUT",
        data,
      }).then((r) => {
        if (r.status === 200) {
          this.busyGroup = false;
        }
      });
    },
    getGroup() {
      let group = { ...this.group };
      let target =
        [...this.groups].filter((i) => i.group_name === group.group_name)[0] || {};
      this.group = {
        ...this.group,
        ...target,
      };
      if (!this.group.group_id) {
        this.postGroup(this.group, "Group");
      } else if (!target.group_id && this.group.group_id) {
        this.updateGroup(group);
      }
    },

    // SUBGROUP METHODS
    addSubgroup(subgroups) {
      let s = JSON.parse(JSON.stringify(defaultGroup)).subgroups[0];
      subgroups.push(s);
    },
    postGroup(current, type) {
      let param = "busy" + type;
      let data = {
        groups: [
          {
            ...current,
            place_id: this.currentPlace,
          },
        ],
      };
      this[param] = true;
      base({
        url: "/groups",
        method: "POST",
        data,
      }).then((r) => {
        if (r.status === 200) {
          current.group_id = r.data.body[0].group_id;
          current.parent_id = r.data.body[0].parent_id;
          this[param] = false;
        }
      });
    },
    updateSubgroup(current) {
      let data = {
        groups: [
          {
            ...current,
            place_id: this.currentPlace,
          },
        ],
      };
      this.busySubgroup = true;
      base({
        url: "/groups",
        method: "PUT",
        data,
      }).then((r) => {
        if (r.status === 200) {
          this.busySubgroup = false;
        }
      });
    },
    getSubgroup(subgroup) {
      let watcher = setInterval(() => {
        if (this.busyGroup === false) {
          let target = [...this.subgroups].filter(
            (i) =>
              i.group_name === subgroup.group_name && i.parent_id === this.group.group_id
          )[0];

          subgroup.group_id = target ? target.group_id : subgroup.group_id;
          subgroup.parent_id = this.group.group_id;
          if (!subgroup.group_id) {
            this.postGroup(subgroup, "Subgroup");
          } else if (!target && subgroup.group_id) {
            this.updateSubgroup(subgroup);
          }
          clearInterval(watcher);
        }
      }, 500);
    },

    // ADD METHODS
    addGuest(guests) {
      this.changed = true;
      guests.push(JSON.parse(JSON.stringify(defaultGuest)));
    },
    addCar(guests, guest) {
      this.changed === true;
      if (this.mode === 1 || this.flow === 2) {
        this.addGuest(guests);
        return;
      }
      guest.cars.push({
        chosen: true,
        brand_name: "N/A",
        car_type: 1,
        driver_phone: "",
        zones: ["00000000-0000-0000-0000-000000000000"],
        end_date: "",
        start_date: "",
      });
    },
    addSpaceRecord(guest) {
      this.changed = true;
      guest.spaces.push({ status: 1 });
    },

    // DELETE METHODS
    checkParkStatus(data) {
      return new Promise((resolve) => {
        base({
          url: "/guests",
          method: "PUT",
          params: { token: this.refreshToken },
          data,
        }).then((res) => {
          let cars = [];
          if (res.data.code === 107) {
            cars = [...res.data.body].map((c) => " " + c.car_plate);
          }
          resolve(cars);
        });
      });
    },
    deleteGroup() {
      this.$confirm({
        message: this.$t("group_delete_propmpt", {
          group: this.group.group_name,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            if (this.formType === 1) {
              this.$store.dispatch("guests/deleteGroup", {
                group: this.group.group_id,
                notify: true,
              });
              this.group.subgroups.map((subg, index) => {
                this.dispatchDelSubgroup(index);
              });
              this.backToParking();
            }
          }
        },
      });
    },
    dispatchDelSubgroup(i, notify) {
      if (this.group.subgroups[i].group_id) {
        this.$store.dispatch("guests/deleteGroup", {
          group: this.group.subgroups[i].group_id,
          notify,
        });
      }
      if (this.group.subgroups[i].guests) {
        this.group.subgroups[i].guests.map((guest) => {
          this.$store.dispatch("guests/deleteGuest", {
            guest,
            notify: false,
            forced: false,
          });
        });
      }
    },
    deleteSubgroup(subgroup) {
      this.$confirm({
        message: this.$t("subgroup_delete_prompt", {
          subgroup: this.group.subgroups[subgroup].group_name,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            if (this.formType === 1) {
              this.dispatchDelSubgroup(subgroup, true);
            }
            this.group.subgroups.splice(subgroup, 1);
          }
        },
      });
    },
    deleteGuestReq(guests, guest, index) {
      this.$store.dispatch("guests/deleteGuest", {
        guest,
        notify: true,
        forced: false,
      });
      if (guests.length === 1 || this.flow === 2 || this.mode === 1) {
        this.backToParking();
      } else {
        guests.splice(index, 1);
      }
    },
    deleteGuest(guests, index, guest) {
      let target = guests.filter((g) => g.guest_id === guest.guest_id)[0];

      this.$confirm({
        message: this.$t("guest_delete_prompt", {
          guest: target.first_name,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.deleteGuestReq(guests, guest, index);
          }
        },
      });
    },
    deleteSpaceRecord(guest, record) {
      if (!guest.spaces[record].record_id) {
        guest.spaces.splice(record, 1);
        return;
      }
      this.changed = true;
      let data = this.prepareGuestData(guest);

      //if check park status returns cars
      let cars = this.checkParkStatus(data);
      if (cars.length) {
        this.$confirm({
          message: this.$t("guest_upd_prompt_parked", { cars }),
          button: {
            no: this.$t("cancel"),
            yes: this.$t("change"),
          },
          callback: (confirm) => {
            if (confirm) {
              this.forced = true;
              guest.selected = true;
              guest.spaces[record].status = 3;
              console.log(guest.spaces);
              this.sendGuest();
            }
          },
        });
      } else {
        guest.selected = true;
        guest.spaces[record].status = 3;
        this.sendGuest();
      }
    },
    prepareGuestData(guest) {
      let guests = [
        {
          ...guest,
          group_id: guest.group_id || null,
          spaces: this.reformatSpaces(guest),
          cars: guest.cars.slice().map((car) => {
            return {
              ...car,
              start_date: this.$moment(car.start_date).format(),
              end_date: this.$moment(car.end_date).format(),
            };
          }),
        },
      ];

      let data = {
        guests,
        forced: false,
      };
      return data;
    },
    deleteCar(guest, car) {
      if (!guest.cars[car].car_id) {
        guest.cars.splice(car, 1);
        return;
      }
      if (this.flow === 2 || this.mode === 1) {
        let guests = this.group.subgroups[0].guests.slice();
        let index = guests.findIndex((g) => g.guest_id === guest.guest_id);
        this.deleteGuestReq(guests, guest, index);
        return;
      }
      this.changed = true;
      this.$confirm({
        message: this.$t("car_delete_prompt", {
          car: guest.cars[car].car_plate,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            let data = this.prepareGuestData(guest);

            //if check park status returns cars
            let cars = this.checkParkStatus(data);
            if (cars.length) {
              this.$confirm({
                message: this.$t("guest_upd_prompt_parked", { cars }),
                button: {
                  no: this.$t("cancel"),
                  yes: this.$t("change"),
                },
                callback: (confirm) => {
                  if (confirm) {
                    this.forced = true;
                    guest.selected = true;
                    guest.cars[car].status = 3;
                    this.sendGuest();
                  }
                },
              });
            } else {
              guest.selected = true;
              guest.cars[car].status = 3;
              this.sendGuest();
            }
          }
        },
      });
    },
    reformatSpaces(guest) {
      console.log('lololol', guest)
      let spaces = [];
      spaces = [...guest.spaces]
        .filter((s) => !!s.space_count)
        .map((s) => {
          return {
            ...s,
            start_date: this.$moment(s.start_date).format(),
            end_date: this.$moment(s.end_date).format(),
            record_id: s.record_id,
            status: s.record_id ? s.status || 2 : 1,
          };
        });
      return spaces;
    },

    reformatCars(cars) {
      return cars.map((car) => {
        return {
          ...car,
          start_date: this.$moment(car.start_date).format(),
          end_date: car.end_date ? this.$moment(car.end_date).format() : null,
          one_time: this.flow === 2,
          status: car.car_id ? car.status || 0 : 1,
        };
      });
    },

    reformatGuests() {
      let group = JSON.parse(JSON.stringify(this.group));
      let guests = [];
      group.subgroups.map((subgroup) => {
        subgroup.guests.map((guest) => {
          if (!guest.guest_type) guest.guest_type = 1;

          if (this.flow === 2) {
            guest.guest_type = 6;
          }
          if (this.isOffline || (this.isBV && group.group_name.includes("Discount"))) {
            guest.guest_type = 10;
          }

          guests.push({
            ...guest,
            spaces: this.reformatSpaces(guest),
            cars: this.reformatCars(guest.cars),
            group_id: subgroup.group_id || this.group.group_id,
            place_id: this.currentPlace,
            is_overtime: this.isOvertime,
            is_limited: this.isLimited,
            discount: this.discount,
            first_name: guest.first_name || guest.cars[0].car_plate,
          });
        });
      });
      return guests;
    },
    parseGuest(group) {
      if (this.formType === 1) {
        this.group = group;
        this.$store.commit("guests/set_current_place", group.place_id);
      }
      let tab = this.guestTab;
      if (this.formType === 0) {
        this.tree = false;
      } else if (tab !== 0) {
        this.tree = false;
        this.hasChoice = true;
        this.toStep(tab);
      } else {
        this.tree = true;
        this.step = 0;
      }
      return group;
    },
    validateForms() {
      let invalid = [];
      let forms = Object.keys(this.$refs);
      forms.map((ref, index) => {
        if (
          this.$refs[ref] &&
          !(ref === "carsUploader" || ref === "guestsUploader") &&
          typeof this.$refs[ref] !== "object"
        ) {
          this.$refs[ref].validate();
          if (this.$refs[ref].validate() === false && ref !== "subgroup") {
            invalid.push(index);
          }
        }
      });
      return invalid;
    },
    sendGuest() {
      let watchBusy = setInterval(() => {
        if (!this.busyGroup && !this.busySubgroup) {
          clearInterval(watchBusy);

          if (this.validateForms().length === 0) {
            this.sending = true;
            let guests = this.reformatGuests();

            if (this.mode === 1) {
              guests.forEach((guest) => {
                guest.spaces = guest?.spaces?.length
                  ? [{
                    ...guest.spaces[0],
                    start_date: guest.cars[0].start_date,
                    end_date: guest.cars[0].end_date,
                    status: 2
                  }]
                  : [{
                    chosen: true,
                    start_date: guest.cars[0].start_date,
                    end_date: guest.cars[0].end_date,
                    is_fixed: false,
                    space_count: 1,
                    status: 1
                  }];
              })
            }

            let newGuests = [];
            let changedGuests = [];
            guests.map((guest) => {
              if (guest.guest_id && guest.selected === true) {
                changedGuests.push(guest);
              } else if (!guest.guest_id) {
                newGuests.push(guest);
              }
            });
            if (newGuests.length) {
              this.$store.dispatch("guests/addGuest", newGuests);
            }
            if (changedGuests.length) {
              this.$store.dispatch("guests/changeGuest", {
                guests: changedGuests,
                forced: this.forced,
              });
            }
            if (newGuests.length === 0 && changedGuests.length === 0) {
              this.$store.commit("guests/set_guest_tab", 5);
              this.tree = false;
            }
            let watcher = setInterval(() => {
              if (this.status !== undefined) {
                // this.toStep(5);
                this.$store.commit("guests/set_guest_tab", 5);
                this.tree = false;
                clearInterval(watcher);
              }
            }, 500);
          } else {
            this.toStep(this.validateForms()[0]);
          }
        }
      }, 500);
    },
    setSending() {
      this.sending = true;
    },
    resetGuest() {
      this.$store.dispatch("guests/getGuests");
      this.$store.commit("guests/set_current_group", null);
      this.$store.commit("guests/set_guest_tab", 0);
      this.step = 0;
      this.changed = false;
      this.changedGroup = false;
      this.changedSubgroup = null;
      this.hasChoice = false;
      this.tree = false;
      this.forced = false;
      this.group = JSON.parse(JSON.stringify(defaultGroup));
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.step < 5 && this.changed) {
      this.$confirm({
        message: this.$t("save_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("save"),
        },
        callback: (confirm) => {
          if (confirm) {
            if (this.step === 0 && this.changedGroup) {
              this.getGroup();
            } else if (this.step === 1 && this.changedSubgroup) {
              this.group.subgroupsforEach((subgroup) => {
                this.getSubgroup(subgroup);
              });
            }

            this.sendGuest();
            let watcher = setInterval(() => {
              if (this.status !== undefined) {
                this.resetGuest();
                next();
                clearInterval(watcher);
              }
            }, 500);
          } else {
            this.resetGuest();
            next();
          }
        },
      });
    } else {
      this.resetGuest();
      next();
    }
  },
};
</script>
