<template>
  <div>
    <v-skeleton-loader v-if="initialLoading" type="table" />
    <v-data-table
      :headers="headers"
      :items-per-page="-1"
      class="table not-expandable"
      v-else
      :items="blacklist"
      disable-pagination
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-for="(i, index) in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div :key="i.text" class="d-flex align-center">
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(index, 'asc')"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(index, 'desc')"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.car`]="{ item }">
        <div class="d-flex align-center table-checkbox">
          <v-checkbox
            v-if="!isReadOnly"
            class="ma-0 pa-0 filter-checkbox"
            :input-value="selected.includes(item.blocked_number)"
            :class="{ active: selected.includes(item.blocked_number) }"
            @change="toggleCar($event, item)"
            :label="item.blocked_number"
          />
          <span v-else>{{item.blocked_number }}</span>
          <!-- <h4>{{  }}</h4> -->
        </div>
      </template>

      <template v-slot:[`item.date`]="{ item }">
        <div>
          {{ $moment(item.created_at).format("DD.MM.YYYY [at] HH:mm") }}
        </div>
      </template>

      <template v-slot:[`item.places`]="{ item }" v-if="isCompany">
        <div>
          {{ places.filter((i) => i.place_id === item.place_id)[0].place_name }}
        </div>
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <div>
          {{ $t(`roles_options.${item.role_id}`) }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "Blacklist",
  components: {},
  props: {
    searchValue: {
      default: "",
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  computed: {
    headers() {
      let init = [
        {
          text: `${this.$t("car_plate")} / ${this.$t("octopus_no")}`,
          value: "car",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("created_at"),
          value: "date",
          class: "table-header",
          sortable: false,
        },
      ];
      if (this.isCompany) {
        init.splice(1, 0, {
          text: this.$t("places"),
          value: "places",
          class: "table-header",
          sortable: false,
        });
      }
      return init;
    },
    stateBlacklist() {
      return [...this.$store.state.settings.blacklist];
    },
    blacklist() {
      return this.search(this.stateBlacklist);
    },
    places() {
      return this.$store.state.app.places;
    },
    initialLoading() {
      return this.$store.state.settings.loading_blacklist;
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
  },
  methods: {
    search(list) {
      return list.filter((i) =>
        i.blocked_number.toLowerCase().includes(this.searchValue.toLowerCase())
      );
    },
    toggleCar(value, item) {
      if (value) this.selected.push(item.blocked_number);
      else this.selected = this.selected.filter((i) => i !== item.blocked_number);
    },
    deleteSelected() {
      this.$confirm({
        message: this.$t("form_delete_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            let data = {
              place_id: this.places[0].place_id,
              blocked_numbers: this.selected,
            };
            this.$store.dispatch("settings/deleteBlacklist", data);
          }
        },
      });
    },
  },
};
</script>
<style lang="scss"></style>
