<template>
  <div class="tabs-content">
    <h3 class="main-title d-flex align-center">
      {{ $t("live_streaming") }}
    </h3>

    <div v-cloak v-for="place in places" :key="place.place_id">
      <div class="d-flex justify-start align-center streaming-header">
        <div class="table-parking-img">
          <img
            class="parking-img table-img"
            alt="ava"
            :src="
              place.avatar
                ? place.avatar
                : 'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg'
            "
          />
        </div>
        <div>
          <h5 class="streaming-title">{{ place.place_name }}</h5>
          <div class="streaming-subtitle">
            {{
              $tc(
                "camera_qty",
                streamSources.filter((s) => s.place_id === place.place_id).length,
                {
                  n: streamSources.filter((s) => s.place_id === place.place_id).length,
                }
              )
            }}
          </div>
        </div>
      </div>
      <LiveCamera ref="streaming" :place_id="place.place_id" />
      <!-- <LiveCamera ref="streaming" :streamSources="[]" :place_id="place.place_id" /> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Streaming",
  data() {
    return {
      updateTimer: null,
    };
  },
  components: {
    LiveCamera: () => import("@/components/Streaming/LiveCamera"),
  },
  computed: {
    currentPlace() {
      return this.$store.state.app.stream_place;
    },
    statePlaces() {
      return [...this.$store.state.app.places];
    },
    places() {
      let places = this.statePlaces;
      if (this.currentPlace) {
        places = places.filter((p) => p.place_id === this.currentPlace);
      }
      return places;
    },
    streamSources() {
      return [...this.$store.state.streaming.stream_sources];
    },
  },
  mounted() {
    this.updateTimer = setInterval(() => {
      let ids = this.places.map((p) => p.place_id);
      this.$store.dispatch("streaming/getStreamSources", ids);
    }, 10000);
    // let ids = this.places.map((p) => p.place_id);
    // this.$store.dispatch("streaming/getStreamSources", ids);
  },
  beforeRouteLeave(to, from, next) {
    clearInterval(this.updateTimer);
    this.updateTimer = null;
    next();
  },
};
</script>
