import actions from "./actions";

const state = () => ({
  selected_user: undefined,
  all: {
    chats: [],
    counters: [],
    files: [],
    users: []
  },
  current_chat: {},
  my_data: {
    id: 3000000
  },
  token: localStorage.getItem("chat_token"),
  redirected_to: null
});

// getters
const getters = {
  chatNotifications: state => {
    let counter = 0;
    state.all.counters.map(c => {
      counter = counter + c.unread_count
    })
    return counter
  },
};

// mutations
const mutations = {
  chat_login_success(state, data) {
    let token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2MTgyMjk0NDIsImp0aSI6IjE5MjUiLCJpYXQiOjE2MTgyMjIyNDIsImlzcyI6InBhcmtpbmctcmVnaXN0cmF0aW9uLXNlcnZpY2UifQ.JklDIJeYZKwz9sVE5LmjkyfPAkYKYf3K7ehOi0BWIfc"
    // localStorage.setItem("chat_token", data.authToken)
    localStorage.setItem("chat_token", token)
    let my_data = data.user
    state.my_data = my_data
    localStorage.setItem("chat_user", JSON.stringify(my_data))

  },
  load_messenger_success(state, data) {
    state.all = data
  },
  set_current_chat(state, {
    chat,
    chat_id
  }) {
    let lastDelivered;
    let lastRead;

    if (chat) {
      chat.chat_id = chat_id
      state.all.chats.forEach(item => {
        if (item.chat_id === chat_id) {
          item.messages_info.forEach(info => {
            if (info.user_id !== state.my_data.id) {
              lastDelivered = info.last_delivered_id;
              lastRead = info.last_read_id
            }
          })
        }
      })

      if (chat.messages && chat.messages.length ) {
        chat.messages.forEach(message => {
          if (message.user_id === state.my_data.id) {
            if (message.id <= lastDelivered) {
              message.delivered = true
            }
            if (message.id <= lastRead) {
              message.read = true
            }
          }
        })
      }

    }
    state.current_chat = chat
    state.selected_user = chat ? chat.users.filter(user => user.user_id !== state.my_data.id)[0] : undefined
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};