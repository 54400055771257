<template>
  <div class="guests-table">
    <div v-if="loading">
      <div class="d-flex v-expansion-panel-header" v-for="i in 7" :key="i">
        <v-skeleton-loader style="max-width: 400px" type="heading" :loading="true" />
        <v-skeleton-loader type="button" :loading="true" class="ml-auto flex-grow-0" />
      </div>
    </div>
    <v-expansion-panels flat multiple>
      <v-expansion-panel v-for="campaign in campaignsToShow" :key="campaign.campaign_id">
        <v-expansion-panel-header>
          <div class="d-flex align-center guest-record">
            <h3 class="parking-subtitle mr-4">{{ campaign.campaign_name }}</h3>
          </div>
          <v-btn
            text
            class="flat-btn"
            v-if="!readOnly"
            @click.stop="openPromoForm(campaign)"
            >{{ $t("change") }}</v-btn
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr class="guests-table-headers">
                  <th style="width: 200px">{{ $t("campaign_desc") }}</th>
                  <th style="width: 100px">{{ $t("use_count") }}</th>
                  <th>{{ $t("stores") }}</th>
                  <th style="width: 100px">{{ $t("start_date") }}</th>
                  <th style="width: 100px">{{ $t("end_date") }}</th>
                </tr>
              </thead>
              <tbody class="guests-table-content">
                <tr>
                  <td>{{ campaign.campaign_description }}</td>
                  <td>{{ campaign.use_count }}</td>
                  <td class="pr-0">
                    <v-list class="mx-auto">
                      <v-list-group>
                        <template v-slot:activator>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="
                                campaign.stores.filter((store) => store.status !== 1)
                                  .length
                              "
                            ></v-list-item-title>
                          </v-list-item-content>
                        </template>

                        <v-list-item
                          v-for="store in campaign.stores.filter(
                            (store) => store.status !== 1
                          )"
                          :key="store.store_id"
                        >
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="store.store_name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list-group>
                    </v-list>
                  </td>
                  <td>
                    {{
                      campaign.start_date
                        ? $moment(campaign.start_date).format("DD.MM.YYYY")
                        : ""
                    }}
                  </td>
                  <td>
                    {{
                      campaign.end_date
                        ? $moment(campaign.end_date).format("DD.MM.YYYY")
                        : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "YourPromoTable",
  data() {
    return {};
  },
  props: {
    searchValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    currentPlace() {
      return this.$store.state.promo.place;
    },
    campaigns() {
      let list = [...this.$store.state.promo.campaigns];
      if (this.currentPlace) {
        list = list.filter((c) => c.place_id === this.currentPlace);
      }
      return list;
    },
    campaignsToShow() {
      return this.search(this.searchValue);
    },
    loading() {
      return this.$store.state.promo.loading;
    },
    readOnly() {
      return this.$store.getters["app/isReadonly"];
    },
  },
  methods: {
    search(value) {
      if (value !== "") {
        return [...this.campaigns].filter((item) =>
          item.campaign_name.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      }
      return [...this.campaigns];
    },
    openPromoForm(campaign) {
      console.log(campaign);
      this.$store.commit("promo/set_current_campaign", campaign);
      this.$router.push("/platform/promo/campaigns/form");
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_guestsTable.scss";
</style>
