<template>
  <div>
    <h3 class="main-title">{{ name }}</h3>
    <div class="d-flex align-center flex-wrap my-4">
      <h4 class="parking-subtitle mr-8">{{ $t("discounts") }}</h4>
      <v-tabs v-model="tab" class="mx-0 my-0">
        <v-tab style="width: auto" v-for="item in options" :key="item">{{
          $t(`${item}`)
        }}</v-tab>
      </v-tabs>
    </div>
    <div class="d-flex align-center flex-wrap">
      <div class="rec-card" v-for="rec in records" :key="rec.id">
        <img :src="rec.photo_url" />
        <div class="d-flex align-center justify-space-between py-1">
          <span>{{ $t(`amount`) }}</span>
          <h4>{{ rec.amount / 100 }}HKD</h4>
        </div>
        <div class="d-flex align-center justify-space-between py-1">
          <span>{{ $t(`store_name`) }}</span>
          <h4>{{ rec.store_name }}</h4>
        </div>
        <div class="d-flex align-center justify-space-between py-1">
          <span>{{ $t(`status`) }}</span>
          <h4>{{ $t(`recognition_options[${rec.status - 1}]`) }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Discounts",
  components: {},
  data() {
    return {
      tab: 4,
    };
  },
  computed: {
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    name() {
      let name = "";
      if (this.isCompany && this.$store.state.app.user.company_name) {
        name = this.$store.state.app.user.company_name;
      } else {
        name = this.$store.state.app.places[0]
          ? this.$store.state.app.places[0].place_name
          : "";
      }
      return name;
    },
    place() {
      return this.$store.state.promo.place;
    },
    options() {
      return this.$t(`recognition_options`);
    },
    receipts() {
      return [...this.$store.state.promo.receipts];
    },
    records() {
      switch (this.tab) {
        case 0:
          return [...this.receipts].filter((i) => i.status === this.tab);
        case 4:
          return [...this.receipts];
        default:
          return [];
      }
    },
  },
  mounted() {
    this.$store.commit("promo/set_view", "discounts");
  },
  methods: {},
};
</script>
