<script>
export default {
  name: "VisitsTable",
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    searchValue: {
      type: String,
      default: "",
    },
    filters: {
      default: {
        dates: [],
      },
    },
    openReportForm: {
      default: false,
      type: Boolean,
    },
    openFilterForm: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      data: [],
      disableLoader: false,
      counter: {
        0: 1,
        1: 1,
        2: 1,
      },
      itemsPerPage: 25,
      params: {
        sort_by: 3,
        sort_direction: 0,
      },
    };
  },

  computed: {
    currency() {
      return this.$store.getters["app/currency"];
    },
    openForm() {
      return this.openReportForm || this.openFilterForm;
    },
    headers() {
      let initHeaders = [
        {
          text: this.$t("tenant"),
          value: "tenant",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("visitors"),
          value: "name",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("car_type"),
          value: "car_type",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: this.$t("car_color"),
          value: "color",
          sortable: false,
          class: "table-car-type table-header",
        },
        {
          text: this.$t("car_brand"),
          value: "brand",
          sortable: false,
          class: "table-date table-header",
        },
        {
          text: this.$t("date_in"),
          value: "date_in",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("date_out"),
          value: "date_out",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("duration"),
          value: "duration",
          class: "table-duration table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("total"),
          value: "total",
          class: "table-total table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "table-header",
        },
      ];
      return initHeaders;
    },
    history() {
      return { ...this.$store.state.tenants.history };
    },
    loading() {
      return this.history.loading;
    },
    records() {
      switch (this.tab) {
        case 0:
          return this.history.current;
        case 1:
          return this.history.completed;
        case 2:
        default:
          return this.history.all;
      }
    },
  },
  mounted() {},
  methods: {
    filter(filters) {
      let from = filters.dates[0] ? new Date(filters.dates[0]).toISOString() : "";
      let to = filters.dates[1] ? new Date(filters.dates[1]).toISOString() : "";
      if (from) this.params.from = from;
      if (to) this.params.to = to;
      this.update();
    },
    search(value) {
      this.params = {
        ...this.params,
        search: value,
      };

      this.update();
    },

    update() {
      this.$store.dispatch("tenants/getVisitsHistory", { ...this.params });
    },

    customSort(index, sort_direction) {
      this.headers.map((h) => {
        if (this.headers.indexOf(h) !== index) {
          h.isDesc = undefined;
        }
      });
      this.headers[index].isDesc = sort_direction === 1;
      this.params = {
        ...this.params,
        sort_by: this.headers[index].value,
        sort_direction,
      };
      this.update();
    },
    getDuration(rec) {
      let x = this.$moment(rec.arrived_at);
      let y = rec.left_at ? this.$moment(rec.left_at) : this.$moment();
      let duration = this.$moment.duration(y.diff(x));
      let months = duration._data.months ? duration._data.months + "m" : "";
      let days = duration._data.days ? duration._data.days + "d" : "";
      let hours = duration._data.hours ? duration._data.hours + "h" : "";
      let minutes = duration._data.minutes;

      if (duration._data.seconds && duration._data.minutes !== 59) {
        minutes = minutes + 1;
      }

      minutes = minutes + "m";

      return `${months} ${days} ${hours} ${minutes}`;
    },
  },
};
</script>

<template>
  <div>
    <v-skeleton-loader v-if="loading" type="table" />

    <v-data-table
      v-if="!loading"
      :headers="headers"
      :items-per-page="-1"
      class="table not-expandable"
      :items="records"
      disable-pagination
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div
          :key="i.text"
          class="d-flex align-center"
          :class="
            ['status', 'brand', 'color'].includes(header.value) ? 'justify-center' : ''
          "
        >
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(headers.indexOf(header), -1)"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(headers.indexOf(header), 1)"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.tenant`]="{ item }">
        <div class="table-user-name">
          <h4>{{ item.tenant_name }}</h4>
        </div>
      </template>

      <template v-slot:[`item.name`]="{ item }">
        <div class="table-user-name">
          <h4>{{ item.visitor_name }}</h4>
          <span>{{ item.plate_number }}</span>
        </div>
      </template>

      <template v-slot:[`item.car_type`]="{ item }">
        <div class="table-car-type">
          {{ $t(`car_types[${item.vehicle_type}]`) }}
        </div>
      </template>

      <template v-slot:[`item.color`]="{ item }">
        <div class="table-car-type text-center">
          {{ item.color }}
        </div>
      </template>

      <template v-slot:[`item.brand`]="{ item }">
        <div class="table-date text-center">
          {{ item.manufacturer }}
        </div>
      </template>

      <template v-slot:[`item.date_in`]="{ item }">
        <div class="table-date">
          {{ $moment(item.arrived_at).format("DD.MM.YYYY HH:mm") }}
        </div>
      </template>

      <template v-slot:[`item.date_out`]="{ item }">
        <div class="table-date" v-if="item.left_at">
          {{ $moment(item.left_at).format("DD.MM.YYYY HH:mm") }}
        </div>
      </template>

      <template v-slot:[`item.duration`]="{ item }">
        <div class="table-duration">
          {{ getDuration(item) }}
        </div>
      </template>

      <template v-slot:[`item.total`]="{ item }">
        <div style="text-transform: uppercase" class="table-price">
          {{ item.amount }} {{ item.currency }}
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div class="table-status">
          <img
            :src="
              item.left_at
                ? 'https://storage.googleapis.com/parkingbnb-html-images/Completed.svg'
                : 'https://storage.googleapis.com/parkingbnb-html-images/_Currently%20stay.svg'
            "
            alt="Status"
            :style="{ width: '24px', height: '24px' }"
          />
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss">
.table,
.content {
  .v-skeleton-loader__table-heading,
  .v-skeleton-loader__table-tfoot {
    display: none;
  }
}
</style>
