<template>
  <div class="popup record-popup" style="z-index: 20; max-width: 420px">
    <div class="modal-overlay" style="opacity: 0.16" @click="hide" />
    <div class="modal-content">
      <div class="octopus-message">
        <template v-if="status === 'init'">
          <h2 v-html="$t('octopus_tap')" />
        </template>
        <template v-if="status === 'unpaid'">
          <h3
            v-if="push.error && !push.error.octopus_error_code"
            v-html="$t('octopus_fail')"
          />

          <h3
            v-else-if="unlistedError"
            v-html="
              $t(`octopus_error_codes.other`, {
                n: push.error.octopus_error_code,
              })
            "
          />
          <h3
            v-else
            v-html="$t(`octopus_error_codes.${push.error.octopus_error_code}`)"
          />
          <h3
            class="mt-3"
            v-if="push.error && push.error.octopus_no"
            v-html="$t('octopus_retry', { n: push.error.octopus_no })"
          />
        </template>
        <template v-if="status === 'paid'">
          <img
            src="https://storage.googleapis.com/parkingbnb-html-images/_Good.svg"
            width="60px"
            height="60px"
          />
          <h2>{{ $t("transaction_success") }}</h2>
          <v-btn style="min-width: 100%" class="main-btn py-2 mt-3" @click="hide">{{
            $t("done")
          }}</v-btn>
          <v-btn
            style="min-width: 100%"
            class="main-btn py-2 my-3"
            @click="printReceipt"
            >{{ $t("print_receipt") }}</v-btn
          >
        </template>
        <template v-if="status === 'failed'">
          <h2 v-html="$t('octopus_fail')" />
        </template>
        <template v-if="cancelButton && status !== 'paid'">
          <v-btn class="main-btn my-4 py-2 px-10" text @click="hide">{{
            $t(`cancel`)
          }}</v-btn>
        </template>
        <template v-else-if="status === 'failed'">
          <v-btn class="main-btn my-4 py-2 px-10" text @click="hide">{{
            $t(`done`)
          }}</v-btn>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { base } from "@/axios-api";
export default {
  name: "OctopusFlow",
  data() {
    return {
      cancelButton: true,
      unlistedError: false,
      codes: [
        100016,
        100017,
        100034,
        100020,
        100032,
        100019,
        100024,
        100021,
        100048,
        100049,
        100022,
        100025,
        100051,
        100001,
        100005,
        1234567,
        12345678,
      ],
    };
  },
  computed: {
    push() {
      return this.$store.state.wallet.current_push;
    },
    status() {
      return this.push.status || "init";
    },
  },
  watch: {
    // "push.can_cancel"(newValue) {},
    push: {
      deep: true,
      handler(newValue) {
        this.cancelButton = newValue.can_cancel;
        this.unlistedError = false;
        if (
          newValue.error &&
          newValue.error.octopus_error_code &&
          !this.codes.includes(newValue.error.octopus_error_code)
        ) {
          this.unlistedError = true;
        }
      },
    },
  },
  methods: {
    hide() {
      if (this.status === "unpaid" && !this.cancelButton) return;
      this.$emit("hide");
      if (this.status === "unpaid" || this.status === "init") {
        this.$store.dispatch("history/cancelPayment");
      }
      this.$store.commit("wallet/reset_card");
    },
    printReceipt() {
      base({
        url: "/customer-service/print_receipt",
        method: "post",
      })
        .then((res) => {
          if (res.status === 200) {
            // console.log(res);
            return res;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },
  },
};
</script>

<style>
.octopus-message {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-direction: column;
  margin-bottom: 8px;
}
</style>
