<template>
  <div class="platform" v-cloak>
    <LocaleSwitcher :class="hasPlaceSelection ? 'sidebar-locale' : ''" />
    <div class="d-flex justify-space-between">
      <Drawer ref="drawer" @drawerMini="drawerMiniHandler" />
      <PlacesDrawer
        ref="placesDrawer"
        @miniChange="placesMiniHandler"
        v-if="hasPlaceSelection"
        :class="{ extended: mini === true }"
      />
      <CompaniesList
        ref="companyList"
        @miniChange="companiesMiniHandler"
        v-if="hasCompanies"
        :class="{ extended: mini === true }"
      />
      <div
        class="platform-content"
        v-cloak
        :class="{
          extended: mini === true,
          'has-sidebar': hasPlaceSelection === true || hasCompanies,
          'extended-places': placesMini === true || companiesMini === true,
        }"
      >
        <div class="d-flex justify-space-between platform-header">
          <Balance />
          <!-- <NotificationManager /> -->
          <HourlyVacancy />
          <User />
        </div>
        <v-divider />
        <!-- <keep-alive> -->
        <router-view />
        <!-- </keep-alive> -->
        <!-- <Chatbox /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Platform",
  components: {
    Drawer: () => import("@/components/core/Drawer"),
    PlacesDrawer: () => import("@/components/core/PlacesDrawer"),
    CompaniesList: () => import("@/components/Monthly/CompanyList"),
    Balance: () => import("@/components/core/Balance"),
    User: () => import("@/components/core/User"),
    LocaleSwitcher: () => import("@/components/core/LocaleSwitcher"),
    HourlyVacancy: () => import('@/components/core/HourlyVacancy.vue'),
    // NotificationManager: () => import("@/components/Notifications/NotificationManager"),
  },
  data() {
    return {
      loader: true,
      mini: false,
      placesMini: undefined,
      companiesMini: undefined,
    };
  },
  computed: {
    hasPromo() {
      return this.$route.path.slice(0, 15) === "/platform/promo";
    },
    hasPlaceSelection() {
      let view = this.$route.path.slice(10, this.$route.path.length);
      return (
        (view === "dashboard" ||
          view === "your-parking" ||
          view === "streaming" ||
          view === "promo/campaigns" ||
          view === "promo/history") &&
        this.$store.getters["app/isCompany"]
      );
    },
    hasCompanies() {
      let view = this.$route.path.slice(10, this.$route.path.length);
      return view === "companies";
    },
  },
  methods: {
    drawerMiniHandler(value) {
      this.mini = value;
    },
    placesMiniHandler(value) {
      this.placesMini = value;
    },
  },
};
</script>
