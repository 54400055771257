<template>
  <div class="guests-table">
    <div v-if="loading">
      <div class="d-flex v-expansion-panel-header" v-for="i in 5" :key="i">
        <v-skeleton-loader type="heading" :loading="true" max-width="40%" />
        <v-skeleton-loader type="chip" :loading="true" class="mr-auto" />
        <v-skeleton-loader type="button" :loading="true" class="flex-grow-0" />
      </div>
    </div>
    <v-expansion-panels flat multiple accordion v-else>
      <v-expansion-panel v-for="(group, index) in guestsToShow" :key="index">
        <v-expansion-panel-header>
          <div class="d-flex align-center guest-record">
            <h3 class="parking-subtitle mr-4">
              {{ group.group_name }}
              <span v-if="!$store.state.guests.place && isCompany">
                ({{ group.place_name }})
              </span>
            </h3>
            <div class="streaming-subtitle mr-4">
              {{ $tc("car_qty", getCars(group), { n: getCars(group) }) }},
              {{
                $tc("spaces_qty", getSpaces(group), {
                  n: getSpaces(group),
                })
              }}
            </div>
            <Calendar :calendarClass="getDateWarning('group', group)" />
          </div>
          <v-btn
            v-show="!isReadonly"
            text
            class="flat-btn"
            @click.stop="openGuestForm(group, 0)"
            >{{ $t("change") }}</v-btn
          >
        </v-expansion-panel-header>
        <v-expansion-panel-content class="guest-flow my-0">
          <GuestTree
            @clickedEdit="(tab) => openGuestForm(group, tab)"
            :show="true"
            :checkTab="false"
            :reading="true"
            :group="group"
            :hasSearch="searchValue"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: "GuestsTable",
  mounted() {},
  components: {
    GuestTree: () => import("./GuestTree.vue"),
    Calendar: () => import("../UI/Calendar.vue"),
  },
  data() {
    return {};
  },
  props: {
    searchValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    loading() {
      return this.$store.state.guests.loading;
    },
    isReadonly() {
      return this.$store.getters["app/isReadonly"];
    },
    records() {
      return this.$store.state.guests.guest_list;
    },
    allgroups() {
      return this.groups.concat(this.subgroups);
    },
    groups() {
      return this.$store.state.guests.groups;
    },
    subgroups() {
      return this.$store.state.guests.subgroups;
    },
    places() {
      return [...this.$store.state.app.places];
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    guests() {
      let result = [];
      [...this.groups].map((g) => {
        let hasSubgroup = [...this.subgroups]
          .filter((s) => s.parent_id === g.group_id)
          .map((s) => {
            return {
              ...s,
              guests: this.reformatGuests(
                this.records.slice().filter((guest) => guest.group_id === s.group_id)
              ),
            };
          });
        let noSubgroup = [
          {
            guests: this.reformatGuests(
              this.records
                .slice()
                .filter(
                  (guest) =>
                    guest.group_id === g.group_id &&
                    this.subgroups.filter((i) => i.group_id === g.group_id).length === 0
                )
            ),
          },
        ];
        result.push({
          ...g,
          subgroups: hasSubgroup
            .concat(noSubgroup)
            .filter((s) => s.guests.length || s.group_id),
        });
      });

      let noGroup = [];
      this.records.map((guest) => {
        if (this.allgroups.filter((g) => g.group_id === guest.group_id)[0] == undefined) {
          noGroup.push(guest);
        }
      });

      if (noGroup.length) {
        result.push({
          subgroups: [{ guests: this.reformatGuests(noGroup) }],
        });
      }

      return result;
    },
    guestsToShow() {
      return this.search(this.searchValue);
    },
    baptistFlow() {
      return this.$store.getters["app/hasOverParking"];
    },
  },
  methods: {
    groupItemBy(array, property) {
      let hash = {},
        props = property.split(".");
      for (let i = 0; i < array.length; i++) {
        let key = props.reduce(function (acc, prop) {
          return acc && acc[prop];
        }, array[i]);
        if (!hash[key]) hash[key] = [];
        hash[key].push(array[i]);
      }
      return hash;
    },
    reformatGuests(guests) {
      let result = guests || [];
      return result.map((guest) => {
        if (!guest.spaces[0]) {
          guest.spaces[0] = {};
        }
        return {
          ...guest,
          cars: guest.cars.map((car) => {
            return {
              ...car,
              // start_date: this.$moment(car.start_date).format("D.MM.YYYY"),
              // end_date: this.$moment(car.end_date).format("D.MM.YYYY"),
            };
          }),
        };
      });
    },
    search(value) {
      let newGuests = [...this.guests];
      newGuests.map((g) => {
        g.group_name = g.group_name || "N/A";
      });
      if (value) {
        newGuests.map((group) => {
          group.show = false;
          if (group.group_name.toLowerCase().includes(value.toLowerCase())) {
            group.show = true;
          }
          group.subgroups.map((subgroup) => {
            subgroup.group_name = subgroup.group_name || "N/A";
            subgroup.show = false;
            if (subgroup.group_name.toLowerCase().includes(value.toLowerCase())) {
              group.show = true;
              subgroup.show = true;
            }
            subgroup.guests.map((p) => {
              p.show = false;
              p.cars.map((c) => {
                c.show = false;
              });
              let hasCar =
                p.first_name.toLowerCase().includes(value.toLowerCase()) ||
                p.last_name.toLowerCase().includes(value.toLowerCase());
              p.cars.map((car) => {
                if (car.car_plate.toLowerCase().includes(value.toLowerCase())) {
                  hasCar = true;
                }
              });
              if (hasCar) {
                group.show = true;
                subgroup.show = true;
                p.show = true;
                p.cars.map((c) => {
                  c.show = true;
                });
              }
            });
          });
        });
        newGuests = newGuests.filter((i) => i.show === true);
      }
      let initList = newGuests
        .sort((a, b) => a.group_name.localeCompare(b.group_name))
        .filter(
          (i) =>
            i.group_name !== "Drivers" &&
            i.group_name !== "One-time parking" &&
            i.group_name !== "Over-parking guests"
        );
      let firstList = newGuests
        .filter(
          (i) =>
            i.group_name === "Drivers" ||
            i.group_name === "One-time parking" ||
            i.group_name === "Over-parking guests"
        )
        .sort((a, b) => b.group_name.localeCompare(a.group_name));
      return firstList.concat(initList);
    },
    openGuestForm(guestObj, tab) {
      this.$store.commit("guests/set_current_place", guestObj.place_id);
      this.$store.commit("guests/set_current_group", guestObj);
      this.$store.commit("guests/set_guest_tab", tab);
      if (tab === 0) {
        this.$store.commit("guests/set_guest_tree", true);
      }
      this.$router.push("/platform/your-parking/guest");
    },
    getCars(group) {
      let total = 0;
      group.subgroups.map((dep) => {
        if (dep.guests) {
          dep.guests.map((guest) => {
            total += guest.cars ? guest.cars.length : 0;
          });
        }
      });
      return total;
    },
    getSpaces(group) {
      let total = 0;
      group.subgroups.map((d) => {
        d.guests.map((guest) => {
          let newSum = guest.spaces.getSumByKey("space_count");
          if (!isNaN(newSum)) total += newSum;
        });
      });
      return total;
    },
    getDateWarning(depth, item) {
      let now = this.$moment();
      let weekLeft = false;
      let threeDaysLeft = false;
      let pastDate = false;

      switch (depth) {
        case "group":
          item.subgroups.map((d) => {
            d.guests.map((g) => {
              g.spaces.map((c) => {
                let spaceDate = this.$moment(c.end_date);
                let spaceDiff = spaceDate.diff(now, "days");
                if (now.isAfter(spaceDate)) {
                  pastDate = true;
                } else if (spaceDiff <= 3) {
                  threeDaysLeft = true;
                } else if (spaceDiff <= 7) {
                  weekLeft = true;
                }
              });

              g.cars.map((c) => {
                if (c) {
                  let carDate = this.$moment(c.end_date);
                  let compCarDiff = carDate.diff(now, "days");
                  if (now.isAfter(carDate)) {
                    pastDate = true;
                  } else if (compCarDiff <= 3) {
                    threeDaysLeft = true;
                  } else if (compCarDiff <= 7) {
                    weekLeft = true;
                  }
                }
              });
            });
          });
          break;
        default:
          break;
      }
      if (pastDate) {
        return "red-svg";
      } else if (threeDaysLeft) {
        return "orange-svg";
      } else if (weekLeft) {
        return "yellow-svg";
      } else {
        return "d-none";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.streaming-subtitle {
  color: $grey;
}
@media print, screen and (max-width: 576px) {
  .guest-record .parking-subtitle {
    font-size: 14px !important;
    width: 86px;
  }
  .guest-record .streaming-subtitle {
    width: 80px;
  }
  .v-expansion-panel-header .flat-btn {
    margin: 0 8px;
  }
}
</style>

<style lang="scss">
@import "@/scss/_modal.scss";
.guests-table .v-skeleton-loader__chip {
  width: 36px;
}
</style>
