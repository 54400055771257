<template>
  <div>
    <v-skeleton-loader v-if="initialLoading" type="table" />
    <v-data-table
      :headers="headers"
      :items-per-page="-1"
      class="table not-expandable settings"
      v-else
      :items="users"
      disable-pagination
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-for="(i, index) in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div :key="i.text" class="d-flex align-center">
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(index, 'asc')"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(index, 'desc')"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.username`]="{ item }">
        <div class="d-flex justify-start table-guest align-center">
          <div class="table-user">
            <img
              width="36px"
              height="36px"
              class="table-ava-icon table-img"
              alt="ava"
              :class="item.avatar_url ? '' : ' green-bg '"
              :src="
                item.avatar_url
                  ? item.avatar_url
                  : 'https://storage.googleapis.com/parkingbnb-html-images/avatar_user.svg'
              "
            />
          </div>
          <div class="table-user-name" style="max-width: 200px">
            <h4>{{ item.user_name }}</h4>
          </div>
        </div>
      </template>

      <template v-slot:[`item.last_login`]="{ item }">
        <div class="table-date">
          {{
            item.last_login
              ? $moment(item.last_login).format("DD.MM.YYYY [at] HH:mm")
              : ""
          }}
        </div>
      </template>

      <template v-slot:[`item.places`]="{ item }" v-if="isCompany">
        <div v-for="id in item.places" :key="id">
          {{
            places.filter((i) => i.place_id === id)[0]
              ? places.filter((i) => i.place_id === id)[0].place_name
              : "N/A"
          }}
        </div>
      </template>

      <template v-slot:[`item.role`]="{ item }">
        <div>
          {{ $t(`roles_options.${item.role_id}`) }}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex align-center">
          <v-btn text class="ml-0 flat-btn mr-6" @click="editUser(item)">{{
            $t(`edit`)
          }}</v-btn>
          <v-btn text class="ml-0 flat-btn" @click="deleteUser(item)">{{
            $t(`delete`)
          }}</v-btn>
        </div>
      </template>
    </v-data-table>
    <UserForm :type="1" ref="userForm" :selected="selected" @hideEmit="selected = {}" />
  </div>
</template>

<script>
export default {
  components: {
    UserForm: () => import("@/components/Settings/UserForm"),
  },
  props: {
    searchValue: {
      default: "",
    },
  },
  data() {
    return {
      selected: {},
    };
  },
  computed: {
    headers() {
      let init = [
        {
          text: this.$t("username"),
          value: "username",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("user_last_login"),
          value: "last_login",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("role"),
          value: "role",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("actions"),
          value: "actions",
          class: "table-header",
          sortable: false,
        },
      ];
      if (this.isCompany) {
        init.splice(3, 0, {
          text: this.$t("places"),
          value: "places",
          class: "table-header",
          sortable: false,
        });
      }
      return init;
    },
    initList() {
      return [...this.$store.state.settings.users];
    },
    stateUsers() {
      let places = this.places.map((p) => p.place_id);
      return this.initList.filter(
        (u) => u.places && u.places.filter((i) => places.includes(i).length)
      );
    },
    users() {
      return this.search(this.stateUsers);
    },
    places() {
      return this.$store.state.app.places;
    },
    initialLoading() {
      return this.$store.state.settings.loading;
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
  },
  methods: {
    search(list) {
      return list.filter((i) => i.user_name.includes(this.searchValue.toLowerCase()));
    },
    editUser(user) {
      this.selected = user;
      setTimeout(() => {
        this.$refs.userForm.show();
      }, 0);
    },
    deleteUser(user) {
      this.$confirm({
        message: this.$t("user_delete_prompt", {
          user: user.user_name,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("settings/deleteUser", user.user_id);
          }
        },
      });
    },
  },
};
</script>
