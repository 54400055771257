<template>
  <div class="tabs-content your-parking">
    <h3 class="main-title">{{ name }}</h3>

    <!-- TABS -->
    <v-tabs v-model="year" @change="yearChangeHandler" class="mt-5 mb-8 place-tabs">
      <v-tab v-for="(year, index) in years" :key="index">{{ year }}</v-tab>
    </v-tabs>
    <v-tabs v-model="month" @change="monthChangeHandler" class="mt-5 mb-8 place-tabs">
      <v-tab v-for="(month, index) in months" :key="index">{{
        $t(`months_list[${month}]`)
      }}</v-tab>
    </v-tabs>
    <!-- END OF TABS -->

    <div class="parking-header d-flex align-center flex-wrap mt-4">
      <v-text-field
        v-model="search"
        autocomplete="off"
        class="parking-search mr-5"
        style="min-width: 310px"
        solo
        :placeholder="$t('search_core')"
      ></v-text-field>

      <ReportDownloadForm
        @hideEmit="openForm = false"
        @showEmit="openForm = true"
        ref="report"
        view="over_parking"
      />
    </div>
    <OverparkingTable :searchValue="debouncedInput" />
  </div>
</template>

<script>
export default {
  name: "OverParking",
  data() {
    return {
      openForm: null,
      debouncedInput: "",
      timeout: null,
      year: 1,
    };
  },
  components: {
    OverparkingTable: () => import("@/components/Overparking/OverparkingTable"),
    ReportDownloadForm: () => import("@/components/core/ReportDownloadForm"),
  },
  computed: {
    month() {
      return Number(this.$moment().format("M")) - 1;
    },
    search: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.debouncedInput = val;
        }, 1000);
      },
    },
    years() {
      return [2021, 2022];
    },
    months() {
      let months = [];
      let currentMonth = this.$moment().format("M");

      for (let i = 0; i < currentMonth; i++) {
        months.push(i);
      }
      if (Number(this.years[this.year]) < Number(this.$moment().format("YYYY"))) {
        months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
      }
      return months;
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    isReadonly() {
      return this.$store.getters["app/isReadonly"];
    },
    defaultName() {
      return this.$store.state.app.places[0]
        ? this.$store.state.app.places[0].place_name
        : "";
    },
    name() {
      let name = "";
      if (this.isCompany && this.$store.state.app.user.company_name) {
        name = this.$store.state.app.user.company_name;
      } else {
        name = this.defaultName;
      }
      return name;
    },
  },
  methods: {
    yearChangeHandler(year) {
      this.tab = year;
      this.month = 0;
      this.monthChangeHandler(0);
    },
    monthChangeHandler(month) {
      let from = this.$moment()
        .year(this.years[this.year])
        .month(month)
        .startOf("month")
        .toISOString();
      let to = this.$moment()
        .month(month + 1)
        .startOf("month")
        .toISOString();
      let params = {
        from,
        to,
      };
      this.$store.dispatch("overparking/getHistory", { ...params });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
@import "@/scss/_guestsTable.scss";
</style>
