import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import paths from './paths'

Vue.use(VueRouter)

const router = new VueRouter({
    routes: paths,
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters['app/authorized']) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
})


export default router
