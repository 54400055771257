import store from '@/store/index'
// import Vue from 'vue'


let initParams = {
    from: new Date(
        new Date().setMonth(new Date().getMonth() - 3)
    ).toISOString(),
    to: new Date().toISOString(),
    limit: 25,
    sort_by: 3,
    sort_direction: 0,
    init: true
};


export default [{
        path: '*',
        redirect: () => {
            let user = store.state.app.user
            let role = user ? user.role_id : 0
            if (store.getters['app/isAdmin']) {
                return ('/platform/dashboard')
            } else if (user && role === 2) {
                return ('/promo')
            } else if (user && role === 3) {
                return ('/platform/parking-history')
            } else {
                return ('/login')
            }
        },
    },
    {
        path: '/login',
        meta: {
            name: 'Login',
            requiresAuth: false
        },
        component: () =>
            import (`@/pages/Login.vue`),
        beforeEnter: (to, from, next) => {
            let user = store.state.app.user
            let role = user ? user.role_id : 0
            if (store.getters['app/isAdmin']) {
                next('/platform/dashboard')
            } else if (store.getters.isLoggedIn && role === 2) {
                next('/promo')
            }
            next()
        },
    },
    {
        path: '/promo',
        redirect: '/promo/customer-service',
        meta: {
            name: 'Promo',
            requiresAuth: true
        },
        component: () =>
            import (`@/pages/Promo.vue`),
        beforeEnter: (to, from, next) => {
            if (store.state.app.places.length === 0) {
                store.dispatch("app/getPlacesInfo")
            }

            if (store.getters['app/hasPromo'] || store.state.app.user.role_id === 2) {
                let watcher = setInterval(() => {
                    if (store.state.app.places.length) {
                        store.dispatch("promo/getCampaigns");
                        store.dispatch("promo/getCars");
                        store.dispatch('app/getCarTypes')
                        store.dispatch('promo/getPromoHistory')
                        setTimeout(() => {
                            store.commit("app/set_loader", false)
                        }, 700)
                        clearInterval(watcher)
                        next()
                    }
                }, 500)
            } else {
                next('/platform/dashboard')
            }
        },
        children: [{
                meta: {
                    name: 'CustomerSvc',
                    requiresAuth: true
                },
                path: '/promo/customer-service',
                component: () =>
                    import (`@/components/Promo/CustomerSvcForm.vue`),
                beforeEnter(to, from, next) {
                    store.commit("promo/set_view", "promo_form")
                    next()
                }
            }, {
                path: '/promo/history',
                meta: {
                    name: 'Promo history',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/Promo/PromoHistory.vue`),
                beforeEnter(to, from, next) {
                    store.commit("promo/set_view", "promo_history")
                    next()
                }
            }, {
                meta: {
                    name: 'ParkingPayment',
                    requiresAuth: true
                },
                path: '/promo/parking-payment',
                component: () =>
                    import (`@/views/ParkingPayment.vue`),
                beforeEnter(to, from, next) {
                    store.commit("promo/set_view", "parking_payment")
                    next()
                }
            },
            {
                meta: {
                    name: 'LogBook',
                    requiresAuth: true
                },
                path: '/promo/logbook',
                component: () =>
                    import (`@/views/LogBook.vue`),
                beforeEnter(to, from, next) {
                    store.commit("promo/set_view", "logbook");
                    let initParams = {
                        sort_by: "created_at",
                        sort_order: "desc",
                        // from: Vue.moment().subtract(1, "minutes").toISOString(),
                        limit: 50
                    }
                    store.dispatch("logbook/getLogsHistory", initParams)
                    next();
                },
            }, {
                meta: {
                    name: 'SubscriptionsPayment',
                    requiresAuth: true
                },
                path: '/promo/subscriptions',
                component: () =>
                    import (`@/views/Subscriptions.vue`),
                beforeEnter(to, from, next) {
                    store.commit("promo/set_view", "subscriptions")
                    store.dispatch("subscriptions/listForCustomerSVC", {})
                    next()
                }
            }
        ]
    },
    {
        path: '/platform',
        meta: {
            name: 'Platform',
            requiresAuth: true
        },
        component: () =>
            import (`@/pages/Platform.vue`),
        beforeEnter: (to, from, next) => {
            store.commit("app/set_loader", true)
            if (store.state.app.places.length === 0) {
                store.dispatch("app/getPlacesInfo")
            }

            let historyWatcher = setInterval(() => {
                if (store.state.app.places.length) {
                    store.dispatch('dashboard/getDashboardConfig');
                    store.dispatch('app/getCarTypes')
                    store.dispatch('app/getGuestTypes');
                    if (store.state.guests.guest_list.length === 0) {
                        store.dispatch("guests/getGuests")
                    }

                    if (store.getters['app/hasZones']) {
                        store.dispatch("app/getZones")
                    }

                    clearInterval(historyWatcher)
                    setTimeout(() => {
                        store.commit("app/set_loader", false)
                    }, 700)
                    next()
                }
            }, 500);

        },

        children: [{
                path: '/platform/dashboard',
                meta: {
                    name: 'Dashboard',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/Dashboard.vue`),
                beforeEnter(to, from, next) {

                    if (store.getters['app/isDemo']) {
                        next('/platform/streaming')
                        return
                    }
                    if (store.getters['app/isAdmin']) {
                        store.dispatch("history/getHistory", initParams);
                    }
                    store.commit("app/set_tab", "dashboard");
                    next()
                }
            },
            {
                path: '/platform/your-parking',
                meta: {
                    name: 'Your Parking',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/YourParking.vue`),
                beforeEnter(to, from, next) {
                    if (!store.getters['app/isAdmin']) {
                        next('/platform/parking-history')
                        return
                    }
                    store.dispatch("guests/getGuests")
                    store.commit('app/set_tab', 'your_parking');
                    next();
                },
            },
            {
                path: '/platform/subscriptions',
                meta: {
                    name: 'Subscriptions',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/Subscriptions.vue`),
                beforeEnter(to, from, next) {
                    if (!store.getters['app/isAdmin']) {
                        next('/platform/parking-history')
                        return
                    }
                    if (store.getters['app/isCyber']) store.dispatch("subscriptions/getList")
                    if (store.getters['app/hasMonthlyWA']) {
                        store.dispatch("subscriptions/getList")
                        store.dispatch('settings/getPricings')
                    }
                    store.commit('app/set_tab', 'subscriptions');
                    next();
                },
            }, {
                path: '/platform/your-parking/guest',
                meta: {
                    name: 'GuestFlow',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/YourParking/Flow.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'your_parking');
                    next();
                },
            }, {
                path: '/platform/your-parking/cyberport-guest',
                meta: {
                    name: 'GuestFlow',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/YourParking/Cyberport/CyberFlow.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'your_parking');
                    next();
                },
            }, {
                path: '/platform/your-parking/tenant',
                meta: {
                    name: 'TenantFlow',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/Tenants/TenantFlow.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'your_parking');
                    next();
                },
            },
            {
                path: '/platform/over-parking',
                meta: {
                    name: 'Over Parking',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/OverParking.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'over_parking');
                    let overParkingParams = {
                        from: new Date(
                            new Date().setDate(1)
                        ).toISOString(),
                        to: new Date().toISOString(),
                    };
                    store.dispatch("overparking/getHistory", {
                        ...overParkingParams
                    })
                    next()
                },
            },
            {
                path: '/platform/parking-history',
                meta: {
                    name: 'Parking History',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/ParkingHistory.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'parking_history');
                    if (store.getters['app/isDemo']) {
                        next('/platform/streaming')
                    }
                    next();
                },
            },
            {
                path: '/platform/visits-history',
                meta: {
                    name: 'Visits History',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/VisitHistory.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'visit_history');
                    store.dispatch("tenants/getVisitsHistory")
                    next();
                },
            },
            {
                path: '/platform/zone-history',
                meta: {
                    name: 'Zone History',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/ZoneHistory.vue`),
                beforeEnter(to, from, next) {
                    if (!store.getters['app/hasZones']) {
                        next('/platform/parking-history')
                        return
                    }
                    store.commit('app/set_tab', 'zone_history');
                    next();
                },
            },
            {
                path: '/platform/streaming',
                meta: {
                    name: 'Streaming',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/Streaming.vue`),
                beforeEnter(to, from, next) {
                    store.commit("app/set_tab", "live_streaming");
                    store.dispatch("streaming/getGateReasons")

                    store.dispatch("history/getHistoryNumbers")
                    store.dispatch("history/getCurrentRecords", {
                        from: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(),
                        to: new Date().toISOString(),
                        sort_by: 3,
                        sort_direction: 0,
                    })
                    next();
                },
            },
            {
                path: '/platform/promo',
                meta: {
                    name: 'My Promo',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/PromoAdmin.vue`),
                beforeEnter(to, from, next) {
                    store.commit("app/set_tab", "promo");

                    store.dispatch('promo/getPromoHistory')
                    store.dispatch('promo/getCampaigns')
                    next();
                },
                children: [{
                        path: '/platform/promo/history',
                        meta: {
                            name: 'Promo history',
                            requiresAuth: true
                        },
                        component: () =>
                            import (`@/components/Promo/PromoHistory.vue`),
                    },
                    {
                        path: '/platform/promo/campaigns',
                        meta: {
                            name: 'Your promotion',
                            requiresAuth: true
                        },
                        component: () =>
                            import (`@/components/Promo/YourPromotion.vue`),
                    },
                    {
                        path: '/platform/promo/campaigns/form',
                        meta: {
                            name: 'Campaign form',
                            requiresAuth: true
                        },
                        component: () =>
                            import (`@/components/Promo/CampaignForm.vue`),
                    },
                    {
                        path: '/platform/promo/promo-form',
                        meta: {
                            name: 'Promo form',
                            requiresAuth: true
                        },
                        component: () =>
                            import (`@/components/Promo/CustomerSvcForm.vue`),
                    },
                    {
                        path: '/platform/promo/discounts',
                        meta: {
                            name: 'Discounts',
                            requiresAuth: true
                        },
                        component: () =>
                            import (`@/components/Promo/Discounts.vue`),
                        beforeEnter(to, from, next) {
                            store.dispatch('promo/getReceipts');
                            next()
                        }
                    },
                    {
                        path: '/platform/promo/promocodes',
                        meta: {
                            name: 'Promocodes',
                            requiresAuth: true
                        },
                        component: () =>
                            import (`@/components/Promo/Promocodes.vue`),
                        beforeEnter(to, from, next) {
                            store.dispatch('promo/getPromocodes');
                            next()
                        }
                    },
                    {
                        path: '/platform/promo/promocodes-history',
                        meta: {
                            name: 'PromocodesHistory',
                            requiresAuth: true
                        },
                        component: () =>
                            import (`@/components/Promo/CodeHistory.vue`),
                        beforeEnter(to, from, next) {
                            store.dispatch('promo/getCodesHistory');
                            store.dispatch('promo/getPromocodes');
                            next()
                        }
                    },
                ]
            },
            {
                path: '/platform/settings',
                meta: {
                    name: 'Settings',
                    requiresAuth: true
                },
                component: () =>
                    import ( /* webpackChunkName: 'settings' */ '@/views/Settings.vue'),
                children: [],
                beforeEnter(to, from, next) {
                    if (store.getters['app/isSuperAdmin']) {
                        store.dispatch('settings/getUsers')
                        store.dispatch('settings/getBlacklist')
                        if (store.getters['app/hasMonthlyWA']) store.dispatch('settings/getPricings')
                        store.commit('app/set_tab', 'settings');
                        next()
                    } else {
                        next('/login')
                    }
                },
            },
            {
                path: '/platform/wallet',
                meta: {
                    name: 'Wallet',
                    requiresAuth: true
                },
                component: () =>
                    import ('@/views/Wallet.vue'),
                children: [],
                beforeEnter(to, from, next) {
                    store.dispatch("wallet/getTransactions")
                    store.commit('app/set_tab', 'wallet');
                    next()
                },
            },
            {
                path: '/platform/companies',
                meta: {
                    name: 'Companies',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/Companies.vue`),
                beforeEnter(to, from, next) {
                    if (!store.getters['app/isAdmin']) {
                        next('/platform/parking-history')
                        return
                    }
                    store.commit('app/set_tab', 'companies');
                    next();
                },
            },
            {
                path: '/platform/companies/form',
                meta: {
                    name: 'Companies',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/components/Monthly/Flow.vue`),
                beforeEnter(to, from, next) {
                    if (!store.getters['app/isAdmin']) {
                        next('/platform/parking-history')
                        return
                    }
                    store.commit('app/set_tab', 'companies');
                    next();
                },
            },
            {
                path: '/platform/devices',
                meta: {
                    name: 'Devices',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/Devices.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'devices');
                    store.dispatch("devices/getList")
                    next();
                },
            },
            {
                path: '/platform/occupancy',
                meta: {
                    name: 'Devices',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/Occupancy.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'occupancy');
                    next();
                },
            },
            {
                path: '/platform/users',
                meta: {
                    name: 'Users',
                    requiresAuth: true
                },

                component: () =>
                    import (`@/views/Users.vue`),
                beforeEnter(to, from, next) {
                    store.dispatch("users/getList", { is_deleted: false })
                    store.commit('app/set_tab', 'users');

                    next();
                },
                children: [

                    {
                        path: '/platform/users/:id(\\d+)',
                        meta: {
                            name: 'UserView',
                            requiresAuth: true
                        },
                        component: () =>
                            import (`@/components/Users/SingleView.vue`),
                        beforeEnter(to, from, next) {
                            store.dispatch("users/getFullInfo", to.params.id);
                            store.commit('app/set_tab', 'users');
                            next();
                        },
                    },
                ]
            },
            {
                path: '/platform/notifications',
                meta: {
                    name: 'Notifications',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/Notifications.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'notifications');
                    next();
                },
            },
            {
                path: '/platform/logbook',
                meta: {
                    name: 'LogBook',
                    requiresAuth: true
                },
                component: () =>
                    import (`@/views/LogBook.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'logbook');
                    let initParams = {
                        sort_by: "created_at",
                        sort_order: "desc",
                        // from: Vue.moment().subtract(1, "minutes").toISOString(),
                        limit: 50
                    }
                    store.dispatch("logbook/getLogsHistory", initParams)
                    next();
                },
            },
            {
                meta: {
                    name: 'ParkingPayment',
                    requiresAuth: true
                },
                path: '/platform/parking-payment',
                component: () =>
                    import (`@/views/ParkingPayment.vue`),
                beforeEnter(to, from, next) {
                    store.commit("app/set_tab", "parking_payment")
                    next()
                }
            },
            {
                path: '/platform/black-list',
                meta: {
                    name: 'LogBook',
                    requiresAuth: true
                },
                component: () =>
                  import (`@/views/ViewBlackListReadOnly.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'blacklist');
                    store.dispatch('settings/getBlacklist')
                    next();
                },
            },
            {
                path: '/platform/members',
                meta: {
                    name: 'MembersWASP',
                    requiresAuth: true
                },
                component: () =>
                  import (`@/views/ViewMembers.vue`),
                beforeEnter(to, from, next) {
                    store.commit('app/set_tab', 'members');
                    next();
                },
            },
        ]
    }
]
