import { base } from '@/axios-api'
import store from '@/store/index';
import qs from 'qs';
// import Vue from 'vue'
// import i18n from '@/plugins/i18n';

export default {
    getLogsHistory({
        commit,
        dispatch
    }, params) {
        commit("set_loader", !params.incrementing)
        base({
                url: "/events",
                method: 'GET',
                params: {
                    ...params,
                    place_id: store.state.app.places.map(p => p.place_id)
                },
                paramsSerializer: params => {
                    return qs.stringify(params, {
                        arrayFormat: 'repeat'
                    })
                },
            }).then((response) => {
                if (response.status === 200) {
                    let payload = response.data.content || []
                    commit("set_history", payload)
                }
            })
            .catch((err) => {
                console.log(err.response);
                if (err.response && err.response.status === 401) {
                    dispatch('app/logout', null, {
                        root: true
                    })
                }
            });
    },

}