<template>
  <div class="parking-record has-pic">
    <div class="no-scroll">
      <div class="d-flex align-center pb-2">
        <h3 class="main-title mr-6 my-0">
          {{ $t("parking_record") }}
        </h3>
        <v-tabs v-model="tab" class="mx-0">
          <v-tab
            class="mr-auto"
            v-for="(item, index) in [
              $t(`parking_info`),
              $t(`car_info`),
              $t(`promo_info`),
            ]"
            :key="index"
            >{{ item }}
          </v-tab>
        </v-tabs>
        <v-btn text class="icon-close ml-auto" min-width="24px" @click="hide" />
      </div>

      <div v-if="tab === 0" class="d-flex flex-wrap py-8">
        <ZoomOnHover v-if="hrec.photo" scale="2.5" :img-normal="hrec.photo" />
        <v-skeleton-loader type="image" v-else />
        <div class="parking-record-block">
          <!-- DATES -->
          <div class="desc-text py-1">
            <span>{{ $t("date_in") }}: </span>
            <b v-if="parkingDates.enter_time">{{
              $moment(parkingDates.enter_time).format("DD.MM.YYYY [at] HH:mm")
            }}</b>
            <div class="form-period date single mt-2">
              <v-menu
                content-class="datepicker-menu"
                attach
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    readonly
                    class="modal-input input-half datepicker-inp mt-0"
                    :value="
                      parkingDates.enter_time
                        ? $moment(parkingDates.enter_time).format('DD.MM.YYYY [at] HH:mm')
                        : ''
                    "
                    :label="$t('from')"
                  ></v-text-field>
                </template>

                <v-tabs fixed-tabs v-model="dates.enter.tab" class="picker-tabs">
                  <v-tab key="calendar">
                    <img src="@/assets/images/calendar.svg" />
                  </v-tab>
                  <v-tab key="timer">
                    <img width="24px" height="24px" src="@/assets/images/clock.svg" />
                  </v-tab>
                  <v-tab-item key="calendar">
                    <v-date-picker
                      :locale="$i18n.locale"
                      first-day-of-week="1"
                      @change="dates.enter.tab = 1"
                      v-model="dates.enter.date"
                      :max="$moment().format('YYYY-MM-DD')"
                    ></v-date-picker>
                  </v-tab-item>
                  <v-tab-item key="timer">
                    <vue-timepicker
                      format="HH:mm:ss"
                      manual-input
                      v-model="dates.enter.time"
                      blur-delay="5000000"
                    />
                  </v-tab-item>
                </v-tabs>
              </v-menu>
            </div>
          </div>
          <div class="desc-text py-1">
            <span>{{ $t("date_out") }}: </span>
            <b v-if="parkingDates.exit_time">{{
              $moment(parkingDates.exit_time).format("DD.MM.YYYY [at] HH:mm")
            }}</b>
            <div class="form-period date single mt-2">
              <v-menu
                content-class="datepicker-menu"
                attach
                :close-on-content-click="false"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-on="on"
                    readonly
                    class="modal-input input-half datepicker-inp mt-0"
                    :value="
                      parkingDates.exit_time
                        ? $moment(parkingDates.exit_time).format('DD.MM.YYYY [at] HH:mm')
                        : ''
                    "
                    :label="$t('to')"
                  ></v-text-field>
                </template>

                <v-tabs fixed-tabs v-model="dates.exit.tab" class="picker-tabs">
                  <v-tab key="calendar">
                    <img src="@/assets/images/calendar.svg" />
                  </v-tab>
                  <v-tab key="timer">
                    <img width="24px" height="24px" src="@/assets/images/clock.svg" />
                  </v-tab>
                  <v-tab-item key="calendar">
                    <v-date-picker
                      :locale="$i18n.locale"
                      first-day-of-week="1"
                      @change="dates.exit.tab = 1"
                      v-model="dates.exit.date"
                      :max="$moment().format('YYYY-MM-DD')"
                    ></v-date-picker>
                  </v-tab-item>
                  <v-tab-item key="timer">
                    <vue-timepicker
                      format="HH:mm:ss"
                      manual-input
                      v-model="dates.exit.time"
                      blur-delay="5000000"
                    />
                  </v-tab-item>
                </v-tabs>
              </v-menu>
            </div>
          </div>
          <!-- </div> -->

          <template>
            <!-- PLACE -->
            <v-select
              :no-data-text="$t('no_data')"
              v-if="$store.getters['app/isCompany']"
              attach
              v-model="hrec.place.place_id"
              item-value="place_id"
              item-text="name"
              class="modal-input my-0"
              :items="places"
              :label="$t('place')"
            />

            <!-- STATUS AND DURATION AND TOTAL PRICE -->
            <div
              class="d-flex justify-space-between align-center desc-text flex-wrap"
              v-if="formType === 1"
            >
              <div class="py-2">
                <span>{{ $t("total") }}: </span>
                <b>
                  {{
                    hrec.parking.total_price
                      ? hrec.currency + hrec.parking.total_price / 100
                      : "0"
                  }}</b
                >
              </div>
              <div>
                <span>{{ $t("duration") }}: </span>
                <b>{{ getDuration(hrec.parking) }}</b>
              </div>
            </div>
          </template>
          <v-autocomplete
            v-model="hrec.parking.payment_method_id"
            item-value="id"
            item-text="text"
            :items="paymentMethods"
            attach
            class="modal-input my-6"
            :label="$t('payment_method')"
          />
        </div>
      </div>
      <div v-if="tab === 1" class="d-flex flex-wrap py-8">
        <ZoomOnHover v-if="hrec.photo" scale="2.5" :img-normal="hrec.photo" />
        <v-skeleton-loader type="image" v-else />
        <div class="parking-record-block">
          <v-text-field
            class="modal-input mb-3 mt-0"
            @input="plateFormatter"
            :value="hrec.car.plate_number"
            @change="plateChanged = true"
            :label="$t('car_plate')"
          />
          <v-select
            class="modal-input my-3"
            attach
            v-model="hrec.car.car_type"
            item-value="id"
            item-text="desc"
            :items="carTypes"
            :label="$t('car_type')"
          /><v-text-field
            class="modal-input mb-3 mt-0"
            :value="hrec.car.color"
            :label="$t('car_color')"
          />
          <v-combobox
            attach
            v-model="hrec.car.brand"
            class="modal-input my-3"
            :items="getCarBrands(hrec.car.brand)"
            :label="$t('car_brand')"
          />
        </div>
      </div>
      <div v-if="tab === 2" class="d-flex flex-wrap py-8">
        <div class="parking-record-block"></div>
      </div>
      <div class="d-flex align-center">
        <template v-if="isAdmin">
          <v-btn @click="saveRecordInfo" class="main-btn px-6 py-2 mr-4" text>{{
            $t("save")
          }}</v-btn>
          <v-btn @click="deleteRecord" class="flat-btn" text>{{ $t("delete") }}</v-btn>
        </template>
        <template v-else>
          <v-btn
            :loading="loading"
            class="main-btn px-6"
            style="height: 32px"
            text
            @click="setPaid()"
            >{{ $t(`pay`) }}</v-btn
          >
        </template>
      </div>
    </div>
  </div>
</template>

<script>
const defaultDates = {
  enter: {
    tab: 0,
    date: "",
    time: {
      hh: undefined,
      mm: undefined,
      ss: undefined,
    },
  },
  exit: {
    tab: 0,
    date: "",
    time: {
      hh: undefined,
      mm: undefined,
      ss: undefined,
    },
  },
};
import carBrandsDb from "@/assets/cars/carModels.json";
import { base } from "@/axios-api";
import { formatPlate } from "../../common/helpers/helpers";
export default {
  name: "ParkingRecord",
  props: {
    record: {
      default() {
        return {
          parking: {},
          car: {},
          place: {},
        };
      },
    },
    view: {
      default: "parkingHistory",
    },
    open: {
      default: false,
    },
  },
  components: {
    VueTimepicker: () => import("vue2-timepicker/src/vue-timepicker.vue"),
  },
  data() {
    return {
      loading: false,
      tab: 0,
      plateChanged: false,
      dates: JSON.parse(JSON.stringify(defaultDates)),
      hrec: {
        parking: {},
        car: {},
        place: {},
      },
    };
  },
  computed: {
    isAdmin() {
      return this.$store.getters["app/isAdmin"];
    },
    isCyber() {
      return this.$store.getters["app/isCyber"];
    },
    parkingDates() {
      let enterString = (
        this.dates.enter.date +
        " " +
        this.dates.enter.time.HH +
        ":" +
        this.dates.enter.time.mm +
        ":" +
        this.dates.enter.time.ss
      ).replaceAll("undefined", "");
      let exitString = (
        this.dates.exit.date +
        " " +
        this.dates.exit.time.HH +
        ":" +
        this.dates.exit.time.mm +
        ":" +
        this.dates.exit.time.ss
      ).replaceAll("undefined", "");

      if (enterString.length < 19) {
        enterString = "";
      } else {
        enterString = new Date(enterString).toISOString();
      }
      if (exitString.length < 19) {
        exitString = "";
      } else {
        exitString = new Date(exitString).toISOString();
      }
      return {
        enter_time: enterString,
        exit_time: exitString,
      };
    },
    formType() {
      return Number(!!this.hrec.parking.id);
    },
    places() {
      return [...this.$store.state.app.places].map((p) => {
        return { ...p, name: p.place_name };
      });
    },
    locale() {
      return this.$store.getters["app/locale"];
    },
    hasSimpleHistory() {
      return this.$store.getters["app/hasSimpleHistory"];
    },
    carTypes() {
      let types = [];
      let ids = [...this.$store.state.app.car_types];
      ids.map((id) => {
        if (id !== 0) {
          types.push({ id, desc: this.$t(`car_types[${id}]`) });
        }
      });
      return types;
    },
    engineTypes() {
      return [...this.$t("engine_types")].map((i, index) => {
        return {
          id: index + 1,
          desc: i,
        };
      });
    },
    paymentMethods() {
      return [
        { id: 4, text: "WeChat Pay" },
        { id: 6, text: "AliPay" },
        { id: 7, text: "Octopus" },
        { id: 8, text: "VISA" },
        { id: 9, text: "Mastercard" },
      ];
    },
  },
  watch: {
    record(newValue) {
      this.hrec = JSON.parse(JSON.stringify(newValue));
      if (newValue && newValue.parking && newValue.parking.enter_time) {
        // this.getParkingPic();
        console.log("get parking pic");

        let enterString = newValue.parking.enter_time;
        let exitString = newValue.parking.exit_time ? newValue.parking.exit_time : "";
        this.dates = {
          enter: {
            tab: 0,
            date: this.$moment(enterString).format("YYYY-MM-DD"),
            time: {
              HH: this.$moment(enterString).format("HH"),
              mm: this.$moment(enterString).format("mm"),
              ss: this.$moment(enterString).format("ss"),
            },
          },
          exit: {
            tab: 0,
            date: this.$moment(exitString).format("YYYY-MM-DD"),
            time: {
              HH: this.$moment(exitString).format("HH"),
              mm: this.$moment(exitString).format("mm"),
              ss: this.$moment(exitString).format("ss"),
            },
          },
        };
      }
    },
  },
  methods: {
    hide() {
      this.open = false;
      this.plateChanged = false;
      this.dates = JSON.parse(JSON.stringify(defaultDates));
      this.hrec = {
        parking: {},
        car: {},
        place: {},
      };
      this.$emit("hide");
    },

    plateFormatter(val) {      
      this.hrec.car.plate_number = formatPlate(val);
      this.$forceUpdate();
    },
    getDuration(parking) {
      let duration = this.$moment.duration(parking.duration, "milliseconds");
      let months = duration._data.months ? duration._data.months + "m" : "";
      let days = duration._data.days ? duration._data.days + "d" : "";
      let hours = duration._data.hours ? duration._data.hours + "h" : "";
      let minutes = duration._data.minutes;

      if (duration._data.seconds && duration._data.minutes !== 59) {
        minutes = minutes + 1;
      }

      minutes = minutes + "m";

      return `${months} ${days} ${hours} ${minutes}`;
    },
    getCarBrands(value) {
      if (value !== "" && carBrandsDb[value] && value !== "N/A") {
        return carBrandsDb[value].map((brand) => value + " " + brand);
      } else {
        return Object.keys(carBrandsDb);
      }
    },
    deleteRecord() {
      this.$confirm({
        message: this.$t("parking_delete_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("confirm"),
        },
        callback: (confirm) => {
          if (confirm) {
            let params = {
              parking_id: this.hrec.parking.parking_id,
              guest_type: this.hrec.parking.guest_type,
            };
            this.$store.dispatch("history/deleteRecord", {
              params,
              notify: true,
            });
            this.hide();
          }
        },
      });
    },

    saveRecordInfo() {
      this.$confirm({
        message: this.$t("save_prompt"),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("save"),
        },
        callback: (confirm) => {
          if (confirm) {
            let data = {
              ...this.hrec,
              enter_time: new Date(this.parkingDates.enter_time).toISOString(),
              exit_time: this.parkingDates.exit_time
                ? new Date(this.parkingDates.exit_time).toISOString()
                : null,
              ...this.hrec.place,
              ...this.hrec.car,
            };
            if (this.formType === 0) {
              this.$store.dispatch("history/addRecord", { data, type: 1 });
            } else {
              if (this.plateChanged) {
                this.$store.dispatch("history/deleteRecord", {
                  params: { parking_id: data.parking_id, guest_type: data.guest_type },
                  notify: false,
                });
                this.$store.dispatch("history/addRecord", { data, type: 2 });
              } else {
                this.$store.dispatch("history/updateRecord", data);
              }
            }
            console.log(data);

            this.hide();
          }
        },
      });
    },

    getParkingPic() {
      base({
        url: `/history/photo`,
        method: "GET",
        responseType: "blob",
        params: {
          parking_id: this.hrec.parking.parking_id,
        },
      }).then((response) => {
        var fileURL = URL.createObjectURL(new Blob([response.data]));
        this.hrec.photo = fileURL;
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
</style>

<style lang="scss" scoped>
.v-text-field__details {
  display: none;
}
@media print, screen and (min-width: 576px) {
  .datepicker-menu {
    top: 3.2em !important;
  }
}
</style>
