<template>
  <div class="popup" :class="openForm ? 'd-flex' : 'd-none'">
    <div class="modal-overlay" @click="hide"></div>
    <div class="modal">
      <div class="modal-content px-6" style="width: 384px">
        <div class="mb-4 d-flex justify-space-between">
          <h3 class="main-title">{{ $t("add") }} {{ $t("pricing") }}</h3>
          <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
        </div>

        <v-form ref="form">
          <div class="full-border-btm d-flex flex-column">
            <v-select
              :no-data-text="$t('no_data')"
              v-if="isCompany"
              attach
              v-model="place_id"
              item-value="place_id"
              item-text="place_name"
              class="modal-input filter-dropdown"
              :items="places"
              :label="$t('places')"
            />
            <div class="mb-6">
              <v-text-field
                v-model="pricing.description"
                class="modal-input"
                :label="$t('description')"
              /><v-text-field
                type="number"
                min="1"
                v-model.number="pricing.months"
                class="modal-input"
                :label="$t('months')"
              /><v-text-field
                type="number"
                min="0"
                :value="pricing.price / 100"
                @change="(v) => (pricing.price = v * 100)"
                class="modal-input"
                :label="$t('price')"
              /><v-text-field
                type="number"
                min="0"
                :value="pricing.deposit / 100"
                @change="(v) => (pricing.deposit = v * 100)"
                class="modal-input"
                :label="$t('deposit')"
              />
            </div>
            <!-- <v-btn text class="flat-btn ml-auto mb-6" @click="add"
              >+ {{ $t("add") }}</v-btn
            > -->
          </div>
          <div class="modal-footer d-flex align-center px-0 mt-4">
            <v-btn class="main-btn px-6 py-2" @click="send">
              {{ $t("save") }}
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import "@/scss/_modal.scss";

export default {
  name: "PricingForm",
  components: {},
  props: {
    type: {
      default: 0,
    },
    selected: {
      default: null,
    },
  },
  data() {
    return {
      openForm: null,
      pricing: {},
      place_id: undefined,
    };
  },
  computed: {
    places() {
      return this.$store.state.app.places;
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
  },
  watch: {
    selected(newValue) {
      this.pricing = { ...newValue };
    },
  },
  methods: {
    show() {
      this.openForm = true;
    },
    hide() {
      this.openForm = false;
      this.$emit("hideEmit");
    },
    add() {
      this.data.push({});
    },
    send() {
      let data = {
        place_id: this.place_id || this.places[0].place_id,
        pricings: [{ ...this.pricing }],
        lang: this.$i18n.locale,
      };
      // console.log(data);
      if (this.pricing.pricing_id) this.$store.dispatch("settings/updatePricings", data);
      else this.$store.dispatch("settings/addPricings", data);

      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  vertical-align: middle;
}
</style>
