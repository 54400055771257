var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-content"},[_c('h3',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("notifications")))]),_c('div',{staticClass:"d-flex align-center mt-5 mb-8"},[_c('h4',{staticClass:"green-text mr-6"},[_vm._v(_vm._s(_vm.$t("notifications_new", { n: _vm.unread })))]),_c('v-text-field',{staticClass:"parking-search py-0 my-0 mr-4",attrs:{"autocomplete":"off","placeholder":_vm.$t('search_notification')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('CoreFilter',{ref:"filter",staticClass:"mr-4",attrs:{"hidden":{
        campaign: true,
        created_by: true,
        price_range: true,
        zone: true,
        status: true,
        wallet: true,
        car_type: true,
        pricing: true,
      }},on:{"filterSet":_vm.updFilters}}),_c('v-btn',{staticClass:"mr-auto",attrs:{"text":""},on:{"click":_vm.refresh}},[_c('img',{attrs:{"src":require("@/assets/images/refresh.svg"),"alt":"refresh"}})]),_c('v-btn',{staticClass:"main-btn py-2 px-6 mr-4",attrs:{"text":""},on:{"click":_vm.openSettings}},[_vm._v(_vm._s(_vm.$t("settings")))]),_c('v-btn',{staticClass:"flat-btn",attrs:{"text":""},on:{"click":_vm.back}},[_vm._v(_vm._s(_vm.$t("back")))])],1),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"table not-expandable",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.list,"disable-pagination":"","disable-sort":"","hide-default-footer":"","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:`item.date`,fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.$moment(item.date).format("DD.MM.YYYY [at] HH:mm"))+" ")])]}},{key:`item.type`,fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(`notification_types.${[item.type]}`))+" ")])]}},{key:`item.description`,fn:function({ item }){return [_c('div',[_vm._v(" "+_vm._s(_vm.$t(`notification_texts.${[item.type]}`))+" ")])]}}],null,true)}),_c('Settings',{ref:"settings"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }