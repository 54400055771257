var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-content parking-history zone-history"},[_c('h3',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("zone_history")))]),_c('div',{staticClass:"my-6 d-flex flex-wrap flex-md-nowrap justify-start align-center"},[_c('v-tabs',{staticClass:"mb-sm-0 mx-0",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c('v-tab',{key:item,style:(_vm.items.indexOf(item) === 0 ? { width: '120px', paddingRight: '12px' } : '')},[_vm._v(_vm._s(item))])}),1),_c('v-text-field',{staticClass:"parking-search flex-grow-0 ml-4 mr-0",attrs:{"autocomplete":"off","solo":"","placeholder":_vm.$t('search_core')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('CoreFilter',{ref:"coreFilter",attrs:{"hidden":{
        campaign: true,
        created_by: true,
        price_range: true,
        car_type: true,
        pricing: true,
        wallet: true,
      }},on:{"filterSet":_vm.updFilters,"hideEmit":_vm.hideFilter,"showEmit":_vm.showFilter}}),_c('ReportDownloadForm',{ref:"report",attrs:{"view":"zone_history"},on:{"hideEmit":function($event){_vm.openReportForm = false},"showEmit":function($event){_vm.openReportForm = true}}}),_c('v-btn',{staticClass:"avatar mr-lg-auto dwl-btn",attrs:{"text":""},on:{"click":_vm.refresh}},[_c('img',{style:({ height: '24px', width: '24px' }),attrs:{"src":require("@/assets/images/refresh.svg"),"alt":"refresh"}})])],1),_c('ZoneHistoryTable',{ref:"table",attrs:{"searchValue":_vm.search,"tab":_vm.tab,"isOpenForm":_vm.isOpenForm,"filters":_vm.filters}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }