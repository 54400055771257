<template>
  <div class="tabs-content your-parking"></div>
</template>

<script>
export default {
  name: "Companies",
  data() {
    return {
      openForm: null,
      debouncedInput: "",
      timeout: null,
    };
  },
  components: {},
  computed: {},
  methods: {},
};
</script>

<style lang="scss">
@import "@/scss/_modal.scss";
@import "@/scss/_guestsTable.scss";
</style>
