<template>
  <div class="d-flex flex-column">
    <div
      v-if="status !== undefined"
      class="notification-content d-flex flex-column align-center justify-center pt-6"
    >
      <v-img
        class="not-img my-4"
        alt="Notification"
        :src="
          status === true
            ? 'https://storage.googleapis.com/parkingbnb-html-images/_Good.svg'
            : 'https://storage.googleapis.com/parkingbnb-html-images/_Wrong.svg'
        "
      />
      <h1 class="main-title text-center" v-if="errorMessage">
        {{ errorMessage }}
      </h1>
      <h1 class="main-title" v-else>
        {{ status === true ? $t("success") : $t("error") }}
      </h1>
    </div>
    <v-btn class="main-btn mt-5 mx-auto px-8 py-3" @click="backToParking">{{
      $t("form_back_parking")
    }}</v-btn>
  </div>
</template>

<script>
export default {
  computed: {
    status() {
      return this.$store.state.guests.status;
    },
    errorMessage() {
      return this.$store.state.guests.message;
    },
  },
  methods: {
    backToParking() {
      this.$router.push("/platform/your-parking");
    },
  },
};
</script>
