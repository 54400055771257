import { chat } from '@/axios-api'
import store from '@/store/index';
import EventBus from '../../../event-bus';


export default {
    getMessenger({
        commit,
        dispatch
    }) {
        chat({
                url: '/chats',
                method: "GET"
            })
            .then(response => {
                let chats = response.data.body
                commit("load_messenger_success", chats)
            })
            .catch(err => {
                console.log(err.response)
                if (err.response.status === 401 || err.response.status === 400) {
                    dispatch('logout')
                }
            })
    },
    getChatByID({
        commit,
        dispatch
    }, chat_id) {
        chat({
                url: '/messages',
                method: "GET",
                params: {
                    chat_id
                }
            })
            .then(response => {
                let chat = response.data.body
                chat.messages = chat.messages || []
                commit("set_current_chat", {
                    chat,
                    chat_id
                })
            })
            .catch(err => {
                console.log(err.response)
                if (err.response.status === 401 || err.response.status === 400) {
                    dispatch('logout')
                } else {
                    EventBus.$emit("notify", {
                        group: 'general',
                        title: 'Oops!',
                        text: "",
                        duration: 3000
                    })
                }
            })

    },
    markMessage({
        commit,
        dispatch
    }, {
        body,
        action
    }) {
        chat({
                url: '/message/' + action,
                method: 'PUT',
                data: JSON.stringify(body),
            })
            .catch(err => {
                console.log(err.response)
                if (err.response.status === 400 || err.response.status === 401) {
                    dispatch("chatLogout")
                }
                return commit
            })
    },
    sendMessage({
        commit,
        dispatch
    }, data) {

        chat({
                url: '/message',
                method: "POST",
                data,
            })
            .then(response => {
                if (response.status === 200) {
                    dispatch("getChatByID", data.chat_id)
                        // console.log(response)
                    return commit
                }
            }).catch(err => {
                console.log(err.response)
                if (err.response.status === 401) {
                    dispatch('logout')
                }
            })
    },

    sendUserTyping({
        commit
    }, data) {
        chat({
            url: '/user_typing',
            method: 'POST',
            data: JSON.stringify(data),
        }).catch(err => {
            console.log(err.response)
            return commit
        })
    },

    createChat({
        commit,
        dispatch
    }, {
        user_id,
        redirect
    }) {
        chat({
            url: '/',
            method: 'POST',
            data: {
                participants: [
                    store.state.chat.my_data.id,
                    user_id
                ]
            },
        }).then(response => {
            if (response.status === 200) {
                console.log(response.data)
                let chat_id = response.data.body.chat_id
                if (redirect) {
                    dispatch('getMessenger', chat_id)
                    dispatch('getChatByID', chat_id)
                }
                return commit
            }
        }).catch(err => {
            console.log(err.response)
        })
    }
};