<template>
  <div class="guest-tree" v-show="show">
    <div v-show="step === 5">
      <h3 class="main-title" style="font-size: 20px !important">
        {{ $t("form_check") }}
      </h3>
      <h3 class="modal-label reg-font">
        {{ $t("form_check_info") }}
      </h3>
    </div>
    <div v-show="view === 'flow'">
      <div class="d-flex justify-space-between align-center" style="height: 80px">
        <h3>
          <span class="main-title d-block" style="font-size: 20px !important">
            {{ $t("form_tree") }}
          </span>
          <span class="modal-label reg-font">
            {{ $t("form_see_tree") }}
          </span>
        </h3>
      </div>
      <h3 class="modal-label first d-flex align-center justify-start">
        <span class="mr-3">{{ tenant.first_name }} {{ tenant.last_name }}</span>
      </h3>
    </div>

    <div
      v-for="(vis, visIndex) in [...tenant.visits].sort((a, b) =>
        a.visitor_name.localeCompare(b.visitor_name)
      )"
      :key="visIndex"
    >
      <div class="row tab-2" v-if="vis.show || !hasSearch">
        <h3 class="modal-label first d-flex align-center">
          <span style="width: 100px">{{ vis.visitor_name }}</span>
        </h3>
        <h3 class="modal-label reg-font tree-col2">
          {{ $t("address") }}: {{ tenant.building_number || "N/A" }}
        </h3>
        <h3 class="modal-label reg-font tree-date">
          <template v-if="vis.stay_until">
            {{ $moment(vis.stay_until).format("DD.MM.YYYY") }}</template
          >
          <template v-else>{{ $t(`stay_options[${vis.stay_type - 1}]`) }}</template>
        </h3>
        <h3 class="modal-label reg-font tree-col2">
          {{ vis.plate_number }}
        </h3>
        <h3 class="modal-label reg-font tree-last-col">
          {{ vis.manufacturer }} {{ vis.model }}
        </h3>
      </div>
    </div>

    <div v-if="view === 'flow'" class="d-flex align-center justify-end">
      <v-btn @click="back" text class="flat-btn modal-footer-btn mr-6">{{
        $t("back")
      }}</v-btn>
      <v-btn :loading="isSending" @click="send" text class="main-btn my-6 px-4 py-2">{{
        $t("done")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  name: "TenantTree",
  props: {
    tenant: {},
    show: {
      type: Boolean,
      default: true,
    },
    view: {
      default: "table",
    },
    hasSearch: {
      type: String,
      default: "",
    },
    step: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isSending: false,
      chosen: [],
    };
  },
  computed: {
    carTypes() {
      return this.$store.state.app.car_types;
    },
  },
  methods: {
    back() {
      this.$store.commit("guests/set_guest_tab", 3);
    },
    redirectToResult() {
      this.$store.commit("guests/set_guest_tab", 5);
    },
    send() {
      this.isSending = true;
      this.$emit("clickedSend");
    },
  },
};
</script>
