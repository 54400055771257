<template>
  <div>
    <div class="stream-page">
      <div
        v-for="(camera, i) in streamSources"
        :key="i"
        :class="{ 'stream-container': true, noGate: !camera.gate_id }"
      >

        <div class="player-wrapper plug">
          <component
            :is="camera.stream_protocol === 'parkingbnb' ? StreamingNewWSPlayer : StreamingNewHLSPlayer"
            :url="camera.url"
          />
          <div class="streaming-subtitle mt-2">
            {{ $t("camera") + " " + camera.camera_position }}
            -
            {{ $t(`${camera.description.toLowerCase()}`) }}
          </div>
        </div>

        <div class="streaming-controllers d-flex" v-if="camera.gate_id">
          <div class="col text-center pl-3 py-0">
            <h4 class="parking-subtitle">{{ $t("car_plate") }}</h4>
            <p class="car_plate history-plate-box">
              <b v-if="camera.current_car">{{ camera.current_car }} </b>
              <b v-else>-</b>
            </p>
            <h4 class="parking-subtitle">{{ $t("price_type") }}</h4>
            <p class="car_plate history-plate-box">
              <b v-if="camera.current_type">
                {{ priceNames.filter((i) => i.id === camera.current_type)[0].desc }}</b
              >
              <b v-else>-</b>
            </p>
            <v-btn
              v-if="camera.gate_actions.has_open"
              v-show="!isReadonly"
              @click="showGatePopup(camera, 'open')"
              class="main-btn px-5 py-2 my-2 mr-2"
              >{{ $t("gate_open") }}</v-btn
            >
            <v-btn
              v-if="camera.gate_actions.has_close"
              v-show="!isReadonly"
              @click="showGatePopup(camera, 'close')"
              class="main-btn px-5 py-2 my-2 mr-2"
            >{{ $t("gate_close") }}</v-btn
            >
            <v-btn
              v-if="camera.gate_id && hasFreeParking"
              v-show="!isReadonly"
              @click="openBothGates"
              class="main-btn px-5 py-2 my-2"
              >{{ $t("gate_open_both") }}</v-btn
            >
          </div>
          <div class="col text-center py-0">
            <h3 class="parking-subtitle">{{ $t("history") }}</h3>
            <template v-for="car in history">
              <div
                :key="history.indexOf(car)"
                class="history-plate-box"
                v-if="car.camera_id === camera.camera_id"
              >
                <b>{{ car.car_plate }},</b>
                <b class="mx-2" v-if="car.guest_type">{{
                  priceNames.filter((i) => i.id === car.guest_type)[0].desc
                }}</b>
                <p>
                  {{ $moment(car.recorded_at).format("DD.MM.YYYY, HH:mm:ss") }}
                </p>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div v-if="popup" class="popup" style="position: fixed">
      <div class="modal-overlay" @click="hideGatePopup" />
      <div class="modal flex-column gate-modal">
        <div class="modal-content text-center">
          <div v-if="selectedGateAction === 'open'" class="parking-subtitle">
            {{
              $t("gate_open_prompt", { description: selected.description.toLowerCase() })
            }}
          </div>
          <div v-if="selectedGateAction === 'close'" class="parking-subtitle">
            {{
              $t("gate_close_prompt", { description: selected.description.toLowerCase() })
            }}
          </div>
          <div v-if="selectedGateAction === 'open'">
            <v-autocomplete
              v-if="(hasCashPay || hasFreeParking) && selected.camera_position === 2"
              attach
              :value="selected.plate_number"
              :item-text="(v) => v.car.plate_number"
              @change="selectParking"
              return-object
              class="modal-input"
              :items="activeRecs"
              :label="$t('car_plate')"
              :rules="[
              () => !!selected.parking_id || $t('car_plate') + ' ' + $t('required'),
            ]"
            />
            <h5 class="my-5 streaming-title text-left">
              {{ $t("gate_reason") }}
            </h5>
            <v-chip-group active-class="active" column v-model="selected.reason_id">
              <template v-for="r in gateReasons"
              ><v-chip
                :key="r.reason_id"
                v-if="
                  r.reason_id < 6 || (r.reason_id > 5 && (canPayCash || hasFreeParking))
                "
              >{{ $t(`gate_reasons[${r.reason_id - 1}]`) }}</v-chip
              ></template
              >
            </v-chip-group>
            <v-text-field
              v-if="selected.reason_id === 4"
              class="modal-input"
              v-model="selected.reason_text"
            />
            <template v-if="activeOccupied >= 5 && selected.camera_position === 1">
              <div class="parking-subtitle mt-4">
                {{ $t("gate_open_warn") }}
              </div></template
            >
          </div>
          <div class="modal-footer d-flex justify-space-around pa-0 py-4">
            <v-btn v-if="selectedGateAction === 'open'" text @click="openGate(selected)" class="main-btn px-6 py-2">{{
              $t("gate_open")
            }}</v-btn>
            <v-btn v-if="selectedGateAction === 'close'" text @click="closeGate(selected)" class="main-btn px-6 py-2">{{
                $t("gate_close")
              }}</v-btn>
            <v-btn text @click="hideGatePopup" class="flat-btn mx-0">{{
              $t("cancel")
            }}</v-btn>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="!streamSources || streamSources.length === 0"
      class="plug my-6"
      style="width: 90%; margin: auto"
    >
      <div class="d-flex align-center justify-center flex-column">
        <img
          src="https://storage.googleapis.com/parkingbnb-html-images/nocamera.svg"
          class="stream_icon"
          alt="NoCamera"
        />
        <p class="main-title mt-6">
          {{ $t("camera_no_found") }}
        </p>
        <p class="parking-subtitle">
          {{ $t("camera_no_found_ext") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import StreamingNewHLSPlayer from '@/components/StreamingNew/StreamingNewHLSPlayer.vue';
import StreamingNewWSPlayer from '@/components/StreamingNew/StreamingNewWSPlayer.vue';
import "@/scss/_modal.scss";
export default {
  name: "LiveCamera",
  data() {
    return {
      sockets: [],
      plate_socket: undefined,
      error: false,
      popup: false,
      selected: {},
      selectedGateAction: '',
      loading: true,
      pauseStream: false,
      StreamingNewHLSPlayer,
      StreamingNewWSPlayer,
    };
  },
  props: {
    place_id: {
      default: null,
    },
  },
  computed: {
    stateSources() {
      return [...this.$store.state.streaming.stream_sources];
    },
    streamSources() {
      return this.stateSources.filter((s) => s.place_id === this.place_id);
    },
    locale() {
      return this.$store.getters["app/locale"];
    },
    hasSimpleHistory() {
      return this.$store.getters["app/hasSimpleHistory"];
    },
    hasKiosk() {
      return this.$store.getters["app/hasKiosk"];
    },
    priceNames() {
      return this.$t("price_types");
    },
    history() {
      return [...this.$store.state.history.stream_history].filter(
        (r) => r.place_id === this.place_id
      );
    },
    isReadonly() {
      return this.$store.getters["app/isReadonly"];
    },
    isOnStreamPage() {
      return this.$route.path === "/platform/streaming" && !document.hidden;
    },
    places() {
      return this.$store.state.app.places;
    },
    gateReasons() {
      return [...this.$store.state.streaming.gate_reasons];
    },
    activeRecs() {
      return [...this.$store.state.history.parking_history.current];
    },
    activeOccupied() {
      return this.activeRecs.filter(
        (i) => i.parking.guest_type === 5 || i.parking.guest_type === 10
      ).length;
    },
    hasCashPay() {
      return this.$store.getters["app/hasOfflinePayment"];
    },
    hasFreeParking() {
      return this.$store.getters["app/isBV"];
    },
    canPayCash() {
      return (
        this.hasCashPay &&
        (this.selected.guest_type === 5 || this.selected.guest_type === 10)
      );
    },
  },
  watch: {
    isOnStreamPage(val) {
      if (val === false) {
        this.pauseStream = true;
      }
    },
  },
  mounted() {
    this.getStreamNumbers();
  },
  methods: {
    showGatePopup(camera, action) {
      this.selected = camera;
      this.selectedGateAction = action;
      this.popup = true;
    },
    hideGatePopup() {
      this.selected = {};
      this.selectedGateAction = '';
      this.popup = false;
    },
    openGate(selected) {
      this.selected.reason_id = this.selected.reason_id + 1;
      let target = this.activeRecs.filter(
        (i) => i.parking.parking_id === selected.parking_id
      )[0];
      if (selected.reason_id === 6 && !target.parking.paid) {
        //reason = 'Paid at the front desk'
        this.$store.dispatch("history/setPayment", {
          data: {
            ...selected,
            price_rewrite: false,
            price: 0,
            at_gate: true,
          },
        });
      } else if (selected.reason_id === 7) {
        //reason = 'Free parking'
        this.$store.dispatch("history/setPayment", {
          data: {
            ...selected,
            price_rewrite: true,
            price: 0,
            at_gate: true,
          },
        });
      }
      this.$store.dispatch("streaming/openGate", {
        ...selected,
        notify: true,
      });
      this.hideGatePopup();
    },
    closeGate(selected) {
      this.$store.dispatch("streaming/closeGate", {
        ...selected,
        notify: true,
      });
      this.hideGatePopup();
    },
    openBothGates() {
      this.$confirm({
        message: this.$t("gate_open_both_prompt"),
        button: {
          yes: this.$t("gate_open_both"),
          no: this.$t("cancel"),
        },
        custom: "",
        callback: (confirm) => {
          if (confirm) {
            this.streamSources.map((c, index) => {
              this.$store.dispatch("streaming/openGate", {
                ...c,
                reason_id: 5,
                notify: index === 0,
              });
            });
          }
          this.hideGatePopup();
        },
      });
    },
    selectParking(v) {
      this.selected = {
        ...this.selected,
        plate_number: v.car.plate_number,
        guest_type: v.parking.guest_type,
        parking_id: v.parking.parking_id,
      };
    },

    getStreamNumbers() {
      let ws = this.plate_socket;
      if (!document.hidden && ws === undefined) {
        ws = new WebSocket("wss://api.corporate.parkingbnb.world/gate/socket");
        // ws = new WebSocket("ws://192.168.1.111:8080/gate/socket");
        this.plate_socket = ws;

        ws.onopen = () => {
          ws.send(localStorage.getItem("credentials"));
          console.log("Opened gate socket");
        };
        ws.onclose = () => {
          this.plate_socket = null;
          console.log("Closed gate socket");
        };

        ws.onmessage = (msg) => {
          console.log(msg);

          msg = JSON.parse(msg.data);
          this.streamSources.map((camera) => {
            if (camera.camera_id === msg.camera_id) {
              camera.current_car = msg.car_plate;
              camera.current_type = msg.guest_type;
              this.$forceUpdate();

              setTimeout(() => {
                camera.current_car = "";
                camera.current_type = "";

                this.$store.dispatch("history/getHistoryNumbers");
              }, 10000);
            }
          });
        };

        ws.onerror = (error) => {
          console.log("on error " + JSON.stringify(error));
          console.log(JSON.stringify(error, ["message", "arguments", "type", "name"]));
          ws.close();
          this.plate_socket = undefined;
          setTimeout(() => {
            this.getStreamNumbers();
          }, 1000);
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.player-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 auto;
  width: 60%;

  @media (max-width: 960px) {
    width: 100%;
  }
}
</style>
