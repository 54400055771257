var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tabs-content wallet"},[_c('h3',{staticClass:"main-title"},[_vm._v(_vm._s(_vm.$t("payments")))]),_c('div',{staticClass:"d-flex align-center my-6"},[_c('v-text-field',{staticClass:"parking-search",attrs:{"autocomplete":"off","solo":"","placeholder":_vm.$t('search_wallet')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('CoreFilter',{ref:"coreFilter",attrs:{"hidden":{
        car_type: true,
        price_range: true,
        campaign: true,
        zone: true,
        created_by: true,
        pricing: true,
      }},on:{"filterSet":_vm.updFilters,"filterReset":_vm.resetFilters,"hideEmit":_vm.hideFilter,"showEmit":_vm.showFilter}}),_c('v-btn',{staticClass:"dwl-btn",attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.refresh.apply(null, arguments)}}},[_c('img',{style:({ height: '24px', width: '24px' }),attrs:{"src":require("@/assets/images/refresh.svg"),"alt":"refresh"}})]),(_vm.isCyber)?_c('ReportDownloadForm',{ref:"modal",attrs:{"view":"wallet"},on:{"hideEmit":function($event){_vm.openReportForm = false},"showEmit":function($event){_vm.openReportForm = true}}}):_vm._e()],1),(_vm.$store.getters[`app/hasTenantsFlow`])?_c('HistoryUS',{ref:"table",staticClass:"mb-5",attrs:{"searchValue":_vm.search,"filters":_vm.filters,"tab":_vm.tab,"openReportForm":_vm.openReportForm,"openFilterForm":_vm.openFilterForm}}):_vm._e(),(_vm.$store.getters[`app/isCyber`])?_c('HistoryCyber',{ref:"table",staticClass:"mb-5",attrs:{"searchValue":_vm.search,"filters":_vm.filters,"tab":_vm.tab,"openReportForm":_vm.openReportForm,"openFilterForm":_vm.openFilterForm}}):_vm._e(),(!_vm.$store.getters[`app/isCyber`] && !_vm.$store.getters[`app/hasTenantsFlow`])?_c('HistoryDefault',{ref:"table",staticClass:"mb-5",attrs:{"searchValue":_vm.search,"filters":_vm.filters,"tab":_vm.tab,"openReportForm":_vm.openReportForm,"openFilterForm":_vm.openFilterForm}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }