<template>
  <div class="popup" :class="openForm ? 'd-flex' : 'd-none'">
    <div class="modal-overlay" @click="hide"></div>
    <div class="modal">
      <div class="modal-content px-6" style="width: 384px">
        <div class="mb-4 d-flex justify-space-between">
          <h3 class="main-title">
            {{ type === 0 ? $t("add") : $t("change") }}
            {{ $t("user") }}
          </h3>
          <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
        </div>

        <v-form ref="form">
          <div class="full-border-btm">
            <v-text-field
              v-model="user.user_name"
              class="modal-input mb-6"
              :label="$t('username')"
              :rules="[!!user.user_name || $t('username') + $t('required')]"
            />
            <v-select
              :no-data-text="$t('no_data')"
              attach
              v-model="user.role_id"
              item-value="role_id"
              item-text="desc"
              class="modal-input mb-6"
              :items="roles"
              :label="$t('role')"
            />
            <v-text-field
              v-model="user.password"
              class="modal-input mb-6"
              :label="$t('password')"
              :rules="[!!user.password || $t('password') + $t('required')]"
            />
            <v-select
              :no-data-text="$t('no_data')"
              attach
              multiple
              v-model="user.places"
              item-value="place_id"
              item-text="place_name"
              class="modal-input filter-dropdown"
              :items="allPlaces"
              :label="$t('places')"
              style="max-width: 340px"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="togglePlaces">
                  <v-list-item-content class="px-10">
                    <v-list-item-title>
                      {{ $t("parking_history_tabs")[3] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template slot="selection" slot-scope="data">
                {{
                  chooseAllPlaces && allPlaces[0] === data.item
                    ? $t("parking_history_tabs")[3]
                    : ""
                }}
                <span
                  class="mr-2"
                  style="white-space: nowrap"
                  v-text="chooseAllPlaces ? '' : data.item.place_name + ', '"
                />
              </template>
            </v-select>
            <v-checkbox
              class="filter-checkbox my-5"
              :label="$t('promo_access')"
              v-model="user.permissions.promo"
              :class="{ active: user.permissions.promo }"
            />
            <v-checkbox
              class="filter-checkbox my-5"
              :label="$t('parking_edit')"
              v-model="user.permissions.history_edit"
              :class="{ active: user.permissions.history_edit }"
            />
          </div>

          <div class="modal-footer d-flex align-center px-0 mt-4">
            <v-btn class="main-btn py-2 px-6" @click="sendUser">
              {{ $t("save") }}
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import "@/scss/_modal.scss";

export default {
  name: "UserForm",
  components: {},
  props: {
    type: {
      default: 0,
    },
    selected: {
      default: null,
    },
  },
  data() {
    return {
      openForm: null,
      user: {
        user_name: "",
        password: "",
        role_id: undefined,
        places: [],
        permissions: {},
      },
    };
  },
  computed: {
    roles() {
      let res = [];
      for (const [key, value] of Object.entries(this.$t("roles_options"))) {
        res.push({
          role_id: Number(key),
          desc: value,
        });
      }
      return res;
    },
    chooseAllPlaces() {
      return this.user.places.length === this.allPlaces.length;
    },
    allPlaces() {
      return this.$store.state.app.places;
    },
  },
  methods: {
    show() {
      this.$refs.form.reset();
      if (this.type === 1) {
        this.user = { ...this.selected, password: "" };
      }
      this.openForm = true;
    },
    hide() {
      this.openForm = false;
      this.user = { permissions: {} };
      this.$refs.form.reset();
      this.$emit("hideEmit");
    },
    togglePlaces() {
      this.$nextTick(() => {
        if (this.chooseAllPlaces) {
          this.user.places = [];
        } else {
          this.user.places = this.allPlaces.map((p) => p.place_id).slice();
        }
      });
    },
    sendUser() {
      this.user.role_id = Number(this.user.role_id);
      let body = { ...this.user };
      if (this.type === 0) {
        this.$store.dispatch("settings/addUser", body);
      } else {
        this.$store.dispatch("settings/changeUser", body);
      }

      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  vertical-align: middle;
}
</style>
