import EventBus from '../../../event-bus';
import i18n from '@/plugins/i18n';
import actions from './actions'

const getDefaultState = () => {
    return {
        list: [],
        status: undefined,
        current: {},
        loading: true,
        history: {
            loading: true,
            current: [],
            completed: [],
            all: []
        }
    }
}

const state = getDefaultState();

const getters = {}

const mutations = {
    logout(state) {
        Object.assign(state, getDefaultState())
    },
    set_loader(state, value) {
        state.loading = value
    },
    get_tenants(state, payload) {
        state.list = payload
        state.loading = false
    },
    reset_status(state, status) {
        state.status = status
    },
    set_current(state, payload) {
        state.current = payload
    },
    add_guest(state, value) {
        state.status = value;
        setTimeout(() => {
            state.status = undefined;
            state.message = null
        }, 30000)
    },
    del_tenant(state, notify) {
        if (notify) {
            EventBus.$emit("notify", {
                group: 'general',
                title: 'Success!',
                text: i18n.t('tenant_deleted'),
                duration: 3000
            })
        }
        return state
    },
    del_visitor(state, notify) {
        if (notify) {
            EventBus.$emit("notify", {
                group: 'general',
                title: 'Success!',
                text: i18n.t('visitor_deleted'),
                duration: 3000
            })
        }
        return state
    },
    delete_selected_success(state) {
        EventBus.$emit("notify", {
            group: 'general',
            title: 'Success!',
            text: i18n.t('form_deleted'),
            duration: 3000
        })
        return state
    },
    change_tenant(state) {
        state.status = true;
        setTimeout(() => {
            state.status = undefined;
        }, 10000);
        return state
    },
    set_visits_history(state, payload) {
        state.history = {
            loading: false,
            current: payload.filter(i => !i.left_at),
            completed: payload.filter(i => i.left_at),
            all: payload
        }
    },
    set_history_loader(state, value) {
        state.history.loading = value
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};