<template>
  <div v-if="$store.state.app.isDownloading" class="core-loader small">
    <div class="stream_spiner mb-0" />
    <p class="main-subtitle pl-0 mb-0">{{ $t("loading") }}...</p>
  </div>
</template>

<script>
export default {
  name: "Downloading",
};
</script>
