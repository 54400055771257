import actions from './actions'
// import Vue from 'vue'
// import i18n from '@/plugins/i18n';

const getDefaultState = () => {
    return {
        loading: true,
        list: [],
        customer_svc_list: [],
        active_count: 0
    }
}

const state = getDefaultState();

// getters
const getters = {};

// mutations
const mutations = {
    logout(state) {
        Object.assign(state, getDefaultState())
    },
    set_loader(state, value) {
        state.loading = value
    },
    set_list(state, payload) {
        state.list = payload
        state.loading = false
    },
    set_customer_svc(state, payload) {
        state.customer_svc_list = payload
    },
    set_active_count(state, payload) {
        state.active_count = payload
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};