<template>
  <div v-if="loading" class="core-loader">
    <div class="stream_spiner" />
  </div>
</template>

<script>
export default {
  name: "Loader",
  data() {
    return {};
  },
  computed: {
    loading() {
      return this.$store.state.app.loading;
    },
  },
};
</script>
