<template>
  <div class="popup" v-if="openForm">
    <div class="modal-overlay" @click="hide"></div>
    <div class="modal">
      <div class="modal-content px-6" style="width: 384px">
        <div class="mb-4 d-flex justify-space-between">
          <h3 class="main-title">
            {{ $t("blacklist_add") }}
          </h3>
          <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
        </div>

        <v-form ref="form">
          <div class="full-border-btm d-flex flex-column">
            <v-select
              :no-data-text="$t('no_data')"
              v-if="isCompany"
              attach
              v-model="place_id"
              item-value="place_id"
              item-text="place_name"
              class="modal-input filter-dropdown"
              :items="places"
              :label="$t('places')"
            />
            <v-text-field
              v-for="(car, index) in data"
              :key="index"              
              :value="car"
              class="modal-input"
              @input="plateFormatter($event, index)"
              :label="`${$t('car_plate')} / ${$t('octopus_no')}`"
            />
            <v-btn text class="flat-btn ml-auto mb-6" @click="add"
              >+ {{ $t("add") }}</v-btn
            >
          </div>
          <div class="modal-footer d-flex align-center px-0 mt-4">
            <v-btn class="main-btn px-6 py-2" @click="send">
              {{ $t("save") }}
            </v-btn>
          </div>
        </v-form>
      </div>
    </div>
  </div>
</template>

<script>
import "@/scss/_modal.scss";
import { formatPlate } from "../../common/helpers/helpers";

export default {
  name: "BlacklistForm",
  components: {},
  props: {
    type: {
      default: 0,
    },
    selected: {
      default: null,
    },
  },
  data() {
    return {
      openForm: false,
      data: [''],
      place_id: undefined,
    };
  },
  computed: {
    places() {
      return this.$store.state.app.places;
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
  },
  methods: {
    show() {
      this.openForm = true;
    },
    hide() {
      this.openForm = false;
      this.data = [''];
      this.$emit("hideEmit");
    },
    plateFormatter(val, index) {
      this.data[index] = formatPlate(val);
      this.$forceUpdate();
    },
    add() {
      this.data.push('');
    },
    send() {
      const blocked_numbers = this.data.filter(el => el);
      const data = {
        place_id: this.place_id || this.places[0].place_id,
        blocked_numbers,
      };
      this.$store.dispatch("settings/addToBlacklist", data);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  vertical-align: middle;
}
</style>
