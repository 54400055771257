<template>
  <div class="tabs-content dashboard-page">
    <div class="overview-summary">
      <div class="summary-header overview-header">
        <h3 class="main-title">{{ $t("overview") }}</h3>
      </div>

      <div v-if="loading" class="content d-flex justify-space-between">
        <div v-for="i in 4" :key="i" class="overview-block">
          <v-skeleton-loader :loading="loading" type="sentences"></v-skeleton-loader>
          <v-skeleton-loader :loading="loading" type="chip" />
        </div>
        <div class="overview-block" v-if="hasLED">
          <v-skeleton-loader :loading="loading" type="sentences"></v-skeleton-loader>
          <v-skeleton-loader :loading="loading" type="chip" />
        </div>
      </div>

      <div v-if="!loading" class="content d-flex justify-space-between align-start">
        <div class="overview-block">
          <div class="summary-subtitle">
            {{ $t("avg_duration") }}
          </div>
          <span class="summary-stat d-block d-sm-inline mr-2">{{ avgDuration.avg }}</span>
          <span :class="avgDuration.diff >= 0 ? 'stat-inc' : 'stat-dec'"
            >{{ avgDuration.diff > 0 ? "+" : "" }}{{ avgDuration.diff }}%</span
          >
        </div>
        <div class="overview-block">
          <div class="summary-subtitle">
            {{ $t("avg_ticket") }}
          </div>
          <span class="summary-stat d-block d-sm-inline mr-2">{{ avgTicket.avg }}</span>
          <span :class="avgTicket.diff >= 0 ? 'stat-inc' : 'stat-dec'"
            >{{ avgTicket.diff > 0 ? "+" : "" }}{{ avgTicket.diff }}%</span
          >
        </div>
        <div class="overview-block">
          <div class="summary-subtitle">{{ $t("most_common_car") }}</div>
          <div class="summary-stat">{{ commonCar }}</div>
        </div>

        <div class="overview-block">
          <div class="summary-subtitle">
            {{ $t("today_occ") }}
          </div>
          <span class="summary-stat d-block d-sm-inline mr-2">{{
            current_enter.enter_count
          }}</span>
          <span :class="enter_diff >= 0 ? 'stat-inc' : 'stat-dec'"
            >{{ enter_diff > 0 ? "+" : "" }}{{ enter_diff }}</span
          >
        </div>

        <div class="overview-block" v-if="hasLED">
          <div class="summary-subtitle">
            {{ $t("occupancy_displayed") }}
          </div>
          <div class="d-flex align-baseline">
            <span
              class="summary-stat mr-4"
              :contenteditable="true"
              style="outline: 0"
              @input="onInput"
              >{{ displayedOccupancy }}</span
            >
            <v-btn class="flat-btn" text @click="setOccupancy">{{ $t(`save`) }}</v-btn>
          </div>
        </div>
        <!-- <DashboardFilter /> -->
      </div>
    </div>
    <div class="d-flex flex-wrap align-stretch justify-space-between">
      <component :place="current_place_id" v-if="!isMonthlyLimited" :is="vipFilter ? VipGuests : GuestSummary" />
      <ParkingOccupancy :place="current_place_id" />
      <PricingsSummary :place="current_place_id" v-if="!isMonthlyLimited" />
      <OccupancyDynamics :place="current_place_id" />
    </div>
    <HistorySummary :place="current_place_id" />
  </div>
</template>

<script>
export default {
  name: "Dashboard",
  components: {
    HistorySummary: () => import("@/components/Dashboard/HistorySummary"),
    ParkingOccupancy: () => import("@/components/Dashboard/ParkingOccupancy"),
    PricingsSummary: () => import("@/components/Dashboard/PricingsSummary"),
    OccupancyDynamics: () => import("@/components/Dashboard/OccupancyDynamics"),
  },
  data() {
    return {
      displayedOccupancy: 0,
      GuestSummary: () => import("@/components/Dashboard/GuestSummary"),
      VipGuests: () => import("@/components/Dashboard/VipGuests"),
    };
  },
  mounted() {
    if (this.statePlaces.length) {
      this.fetchAllData();
    }
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  watch: {
    statePlaces(newValue, oldValue) {
      if (oldValue.length !== newValue.length && this.$store.getters["app/authorized"]) {
        this.fetchAllData();
        this.currentPlace = this.statePlaces.length;
      }
    },
    occupancy: {
      deep: true,
      handler(newValue) {
        this.displayedOccupancy = newValue.displayed_occupancy;
      },
    },
  },
  computed: {
    vipFilter() {
      return this.$store.state.dashboard.configuration[this.current_place_id]?.highlight_filter;
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    isSpot() {
      return this.$store.getters["app/isSpot"];
    },
    isMonthlyLimited() {
      return this.isSpot || this.$store.getters["app/isMonthlyLimited"];
    },
    hasLED() {
      // return this.$store.getters["app/username"].includes("monterey");
      return false;
    },
    occupancy() {
      return (
        [...this.$store.state.dashboard.occupancy].filter(
          (stat) => stat.place_id == this.current_place_id
        )[0] || {}
      );
    },
    last_stats() {
      return [...this.$store.state.dashboard.stats.last_month].filter(
        (stat) => stat.place_id == this.current_place_id
      )[0];
    },
    current_stats() {
      return [...this.$store.state.dashboard.stats.current_month].filter(
        (stat) => stat.place_id == this.current_place_id
      )[0];
    },
    current_enter() {
      return [...this.$store.state.dashboard.enter_count.today].filter(
        (stat) => stat.place_id == this.current_place_id
      )[0];
    },
    last_enter() {
      return [...this.$store.state.dashboard.enter_count.yesterday].filter(
        (stat) => stat.place_id == this.current_place_id
      )[0];
    },
    enter_diff() {
      return this.current_enter.enter_count - this.last_enter.enter_count;
    },
    loading() {
      return this.$store.state.dashboard.loading;
    },
    places() {
      let initPlaces = [...this.$store.state.app.places];
      initPlaces.push({
        place_id: 0,
        place_name: this.$t("parking_history_tabs[2]"),
      });
      return initPlaces;
    },
    statePlaces() {
      return [...this.$store.state.app.places];
    },
    current_place_id() {
      return this.$store.state.dashboard.place || this.statePlaces[0]
        ? this.statePlaces[0].place_id
        : 0;
    },
    avgDuration() {
      let avg1 = this.current_stats.average_duration;
      let avg2 = this.last_stats.average_duration;

      let diff;
      if (avg2 === 0) {
        diff = 0;
      } else {
        diff = ((avg1 - avg2) / avg2) * 100;
      }

      return {
        avg: this.getDuration(avg1),
        diff: Math.round(diff),
      };
    },
    avgTicket() {
      let avg1 = this.current_stats.average_ticket;
      let avg2 = this.last_stats.average_ticket;
      let diff;
      if (avg2 === 0) {
        diff = 0;
      } else {
        diff = ((avg1 - avg2) / avg2) * 100;
      }
      return { avg: Math.round(avg1 / 100), diff: Math.round(diff) };
    },
    commonCar() {
      return this.current_stats.most_common_plate;
    },
    m() {
      return this.$moment;
    },
    dates() {
      let today = this.roundToNearestHour(this.m().subtract(1, "minutes")).toISOString();

      let months1ago = this.roundToNearestHour(
        this.m().subtract(1, "months")
      ).toISOString();
      let months2ago = this.roundToNearestHour(
        this.m().subtract(2, "months")
      ).toISOString();
      let months6ago = this.roundToNearestHour(
        this.m().subtract(6, "months")
      ).toISOString();

      let year1ago = this.roundToNearestHour(this.m().subtract(1, "years")).toISOString();
      return { today, months1ago, months2ago, months6ago, year1ago };
    },
  },
  methods: {
    getDuration(mins) {
      let duration = this.m.duration(mins, "minutes");
      let months = duration._data.months ? duration._data.months + "m" : "";
      let days = duration._data.days ? duration._data.days + "d" : "";
      let hours = duration._data.hours ? duration._data.hours + "h" : "";
      let minutes = duration._data.minutes;

      if (duration._data.seconds && duration._data.minutes !== 59) {
        minutes = minutes + 1;
      }

      minutes = minutes + "m";

      return `${months} ${days} ${hours} ${minutes}`;
    },
    onInput(e) {
      this.displayedOccupancy = e.target.innerText;
    },
    setOccupancy() {
      let data = {
        displayed_occupancy: Number(this.displayedOccupancy),
      };
      this.$store.dispatch("dashboard/setPlaceOccupancy", data);
    },
    roundToNearestHour(date) {
      date = new Date(date.toISOString());
      date.setMinutes(date.getMinutes() + 30);
      date.setMinutes(0, 0, 0);

      return date;
    },
    fetchAllData() {
      console.log("fetching all data");
      this.$store.commit("dashboard/set_loader", true);

      let place_id = this.statePlaces.map((p) => p.place_id);

      this.$store.dispatch("dashboard/getPriceUsage", {
        from: this.dates.months1ago,
        to: this.dates.today,
        place_id,
      });
      this.getStats(place_id);
      this.getOccupancyDynamics(place_id);
      this.getEnterCount(place_id);
      this.getVacancies(place_id);
    },
    getStats(place_id) {
      let paramsCurrent = {
        from: this.dates.months1ago,
        to: this.dates.today,
        place_id,
      };
      let paramsPrev = {
        from: this.dates.months2ago,
        to: this.dates.months1ago,
        place_id,
      };

      this.$store.dispatch("dashboard/getOverviewStats", {
        params: paramsCurrent,
        month: 0,
      });
      this.$store.dispatch("dashboard/getOverviewStats", {
        params: paramsPrev,
        month: 1,
      });
    },
    getOccupancyDynamics(place_id) {
      this.$store.dispatch("dashboard/getOccupancyDynamics", {
        params: {
          from: this.m().subtract(4, "days").toISOString(),
          to: this.dates.today,
          group_by: "moment",
          place_id,
        },
        type: "day",
      });
    },
    getEnterCount(place_id) {
      let enterCountYesterday = {
        from: this.roundToNearestHour(
          this.m().subtract(1, "d").startOf("day")
        ).toISOString(),
        to: this.roundToNearestHour(this.m().subtract(1, "d")).toISOString(),
        place_id,
      };
      let enterCountToday = {
        from: this.roundToNearestHour(this.m().startOf("day")).toISOString(),
        to: this.roundToNearestHour(this.m()).toISOString(),
        place_id,
      };

      this.$store.dispatch("dashboard/getEnterCount", {
        params: enterCountYesterday,
        day: 1,
      });
      this.$store.dispatch("dashboard/getEnterCount", {
        params: enterCountToday,
        day: 0,
      });
    },
    getVacancies(place_id) {
      this.$store.dispatch("dashboard/getVacancies", {
        place_id,
      });
    },
  },
};
</script>
