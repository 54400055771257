<script>
export default {
  name: "PaymentsHistory",
  props: {
    tab: {
      type: Number,
      default: 0,
    },
    searchValue: {
      type: String,
      default: "",
    },
    filters: {
      default: {
        dates: [],
      },
    },
    openReportForm: {
      default: false,
      type: Boolean,
    },
    openFilterForm: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      expanded: [],
      disableLoader: false,
      counter: {
        0: 1,
        1: 1,
        2: 1,
      },
      itemsPerPage: 25,
      params: {
        sort_by: 3,
        sort_direction: 0,
      },
    };
  },

  computed: {
    currency() {
      return this.$store.getters["app/currency"];
    },
    openForm() {
      return this.openReportForm || this.openFilterForm;
    },
    headers() {
      let initHeaders = [
        {
          text: this.$t("user"),
          value: "user",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("dates"),
          value: "date_time",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("amount"),
          value: "amount",
          class: "table-price table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
          class: "table-header",
        },
      ];
      return initHeaders;
    },
    expandedHeaders() {
      let initHeaders = [
        {
          text: this.$t("transaction"),
          value: "action",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("dates"),
          value: "date_time",
          class: "table-date table-header",
          isDesc: true,
        },
        {
          text: this.$t("amount"),
          value: "amount",
          class: "table-price table-header",
          isDesc: undefined,
        },
        {
          text: this.$t("status"),
          value: "status",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("actions"),
          value: "actions",
          sortable: false,
          class: "table-header",
        },
      ];
      return initHeaders;
    },
    history() {
      return [...this.$store.state.wallet.history];
    },
    loading() {
      return this.$store.state.wallet.loading;
    },
  },
  mounted() {},
  methods: {
    filter(filters) {
      let from = filters.dates[0] ? new Date(filters.dates[0]).toISOString() : "";
      let to = filters.dates[1] ? new Date(filters.dates[1]).toISOString() : "";
      if (from) this.params.from = from;
      if (to) this.params.to = to;
      this.update();
    },
    search(value) {
      this.params = {
        ...this.params,
        part_name: value,
      };

      this.update();
    },

    update() {
      this.$store.dispatch("wallet/getTransactions", { ...this.params });
    },

    customSort(index, sort_direction) {
      this.headers.map((h) => {
        if (this.headers.indexOf(h) !== index) {
          h.isDesc = undefined;
        }
      });
      this.headers[index].isDesc = sort_direction === 1;
      this.params = {
        ...this.params,
        sort_by: this.headers[index].value,
        sort_direction,
      };
      this.update();
    },

    refund(transaction) {
      this.$store.dispatch("wallet/refund", transaction);
    },

    expand(value) {
      let index = -1;

      for (let i = 0; i < this.expanded.length; i++) {
        if (this.expanded[i].id === value.id) {
          index = i;
        }
      }
      console.log(index);
      if (index === -1) {
        value.expanded = true;
        this.expanded.push({ ...value });
      } else {
        value.expanded = false;
        this.expanded.splice(index, 1);
      }
    },
  },
};
</script>

<template>
  <div>
    <v-skeleton-loader v-if="loading" type="table" />

    <v-data-table
      v-if="!loading"
      :headers="headers"
      :items-per-page="-1"
      class="table not-expandable"
      :items="history"
      disable-pagination
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
      :expanded="expanded"
      @click:row="expand"
      :singleExpand="true"
    >
      <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div
          :key="i.text"
          class="d-flex align-center"
          :class="['status', 'actions'].includes(header.value) ? 'justify-center' : ''"
        >
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(headers.indexOf(header), -1)"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(headers.indexOf(header), 1)"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.user`]="{ item }">
        <div class="table-user-name">
          <h4>{{ item.transactions[0] ? item.transactions[0].user_name : "" }}</h4>
        </div>
      </template>

      <template v-slot:[`item.date_time`]="{ item }">
        <div class="table-date">
          {{ $moment(item.created_at).format("DD.MM.YYYY [at] HH:mm") }}
        </div>
      </template>

      <template v-slot:[`item.amount`]="{ item }">
        <div style="text-transform: uppercase" class="table-price">
          {{ item.amount / 100 }}{{ item.currency }}
        </div>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <div :class="`mx-auto payment-status status-${item.status - 1}`">
          {{ $t(`wallet_status_options[${item.status - 1}]`) }}
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          v-if="item.status === 2"
          text
          class="flat-btn mx-auto"
          @click="refund(item)"
          >{{ $t("refund") }}
        </v-btn>
      </template>

      <!-- <template v-slot:[`item.actions`]>
        <div class="text-center"><img src="@/assets/images/arrow-down.svg" /></div>
      </template> -->
      <template #expanded-item="{ headers, item }">
        <td :colspan="headers.length" class="pa-0" v-if="item.expanded === true">
          <v-data-table
            v-if="!loading"
            :headers="expandedHeaders"
            :items-per-page="-1"
            class="table not-expandable"
            :items="item.transactions"
            disable-pagination
            disable-sort
            hide-default-footer
            mobile-breakpoint="0"
            :expanded="expanded"
            @click:row="expand"
            :singleExpand="true"
            hide-default-header
          >
            <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
              <div
                :key="i.text"
                class="d-flex align-center"
                :class="
                  ['status', 'actions'].includes(header.value) ? 'justify-center' : ''
                "
              >
                <div
                  class="triangle-parent"
                  style="height: 12px"
                  v-if="header.sortable !== false"
                >
                  <div
                    class="triangle up"
                    @click="customSort(headers.indexOf(header), -1)"
                    :class="header.isDesc === false ? 'active' : ''"
                  />
                  <div
                    class="triangle down"
                    @click="customSort(headers.indexOf(header), 1)"
                    :class="header.isDesc ? 'active' : ''"
                  />
                </div>
                <div>{{ header.text }}</div>
              </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <div class="table-user-name">
                <div>
                  {{ $t(`transaction_types[${item.type_id}]`) }}
                </div>
              </div>
            </template>

            <template v-slot:[`item.date_time`]="{ item }">
              <div class="table-date">
                {{ $moment(item.created_at).format("DD.MM.YYYY [at] HH:mm") }}
              </div>
            </template>

            <template v-slot:[`item.amount`]="{ item }">
              <div style="text-transform: uppercase" class="table-price">
                {{ item.amount / 100 }}{{ item.currency }}
              </div>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <div :class="`mx-auto payment-status status-${item.status_id - 1}`">
                {{ $t(`wallet_status_options[${item.status_id - 1}]`) }}
              </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }"
              ><div style="width: 55px">{{ item.notext || "" }}</div></template
            >
          </v-data-table>
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<style lang="scss">
.table,
.content {
  .v-skeleton-loader__table-heading,
  .v-skeleton-loader__table-tfoot {
    display: none;
  }
}
</style>
