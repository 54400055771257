import { base } from '@/axios-api'
import store from '@/store/index';
import qs from 'qs';

export default {
  openGate({
             commit,
             dispatch
           }, data) {
    base({
      url: '/gate/open',
      data: {
        gate_id: data.gate_id,
        reason_id: data.reason_id,
        reason_text: data.reason_text || ""

      },
      method: 'POST'
    })
      .then((response) => {
        if (response.status === 200) {
          if (data.notify) {
            commit("open_gate", true)
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('logout')
        }
        commit("open_gate", false)
      });
    return {
      commit,
      dispatch
    }
  },
  closeGate({ commit, dispatch }, data) {
    base({
      url: '/gate/close',
      data: {
        gate_id: data.gate_id,
      },
      method: 'POST'
    })
      .then((response) => {
        if (response.status === 200) {
          if (data.notify) {
            commit("close_gate", true)
          }
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('logout')
        }
        commit("close_gate", false)
      });
  },
  light({
          commit,
          dispatch
        }, {
          light_id,
          action
        }) {
    base({
      url: '/gate/' + action,
      data: {
        light_id
      },
      method: 'POST'
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(action)
          return commit
        }
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response && err.response.status === 401) {
          dispatch('logout')
        }
      });
    return {
      commit,
      dispatch
    }
  },
  getStreamSources({
                     commit,
                     dispatch
                   }, place_id) {
    place_id = place_id || store.state.app.places.map(p => p.place_id)
    base({
      url: '/places/stream_sources',
      method: 'GET',
      params: {
        place_id
      },
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    })
      .then((response) => {
        let payload = response.data.body || [];
        // let newCameras = payload.map(c => c.url).sort((a, b) => a.localeCompare(b))
        // let prevCameras = [...store.state.streaming.stream_sources].map(c => c.url).sort((a, b) => a.localeCompare(b))
        commit('upd_stream_sources', payload);
        return true;

      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status === 401) {
          dispatch("logout")
        }
      })
  },
  getGateReasons({
                   commit,
                   dispatch
                 }) {
    let lang = "en_us"
    if (store.state.app.language === "zh-hk") {
      lang = "zh_hk"
    }
    base({
      url: '/gate/report_reasons',
      method: 'GET',
      params: {
        lang
      },
      paramsSerializer: params => {
        return qs.stringify(params, {
          arrayFormat: 'repeat'
        })
      },
    })
      .then((response) => {
        let reasons = response.data.body ? response.data.body : [];
        commit('get_gate_reasons', reasons);
      })
      .catch(err => {
        console.log(err.response)
        if (err.response && err.response.status === 401) {
          dispatch("logout")
        }
      })
  },
}
