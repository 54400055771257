<template>
  <v-menu
    v-model="userMenu"
    open-on-hover
    :close-on-content-click="false"
    :content-class="userClass"
  >
    <template v-slot:activator="{ on }">
      <img
        style="width: 50px; height: 50px; margin-top: 7px"
        :src="avatar"
        class="user-icon"
        v-on="on"
        alt="ava"
      />
    </template>
    <div class="user-opened d-flex flex-column">
      <div class="d-flex justify-space-between top-menu">
        <h3 class="user-title">
          {{ name }}
        </h3>
        <img :src="avatar" class="user-icon" alt="ava" />
      </div>
      <div class="header-down">
        <img
          :style="{ width: '24px', height: '24px' }"
          src="https://storage.googleapis.com/parkingbnb-html-images/%20Logout.svg"
          alt="ava"
        />
        <v-btn text class="btn-logout ml-4" @click="logout">{{
          $t("logout")
        }}</v-btn>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: "User",
  data() {
    return {
      userMenu: false,
    };
  },
  computed: {
    userClass() {
      let r = "user-dropdown";
      if (this.hasPlaceSelection) r = r + " has-sidebar";
      if (this.$route.path === "/promo") r = r + " promo-user";
      return r;
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    places() {
      return this.$store.state.app.places.length 
        ? this.$store.state.app.places
        : [{}];
    },
    avatar() {
      let avatar = "https://storage.googleapis.com/parkingbnb-html-images/avatar.svg";
      if (this.isCompany) {
        if (this.$store.state.app.user.avatarURL) {
          avatar = this.$store.state.app.user.avatarURL;
        }
      } else {
        avatar = this.places[0].avatar || avatar;
      }
      return avatar;
    },
    name() {
      let name = "";
      if (this.isCompany && this.$store.state.app.user.company_name) {
        name = this.$store.state.app.user.company_name;
      } else {
        name = this.places[0].place_name;
      }
      return name;
    },
    hasPlaceSelection() {
      let view = this.$route.path.slice(10, this.$route.path.length);
      return (
        (view === "dashboard" ||
          view === "your-parking" ||
          view === "streaming" ||
          view === "promo/campaigns" ||
          view === "promo/history") &&
        this.$store.getters["app/isCompany"]
      );
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("app/logout");
    },
  },
};
</script>

<style lang="scss" scoped>
.user-dropdown {
  display: block;
  position: absolute;
  background: rgb(255, 255, 255);
  border-radius: 16px 40px 16px 16px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.12), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.08);
  height: 141px;
  width: 336px;
  right: -12px !important;
  top: 75px !important;
  left: auto !important;
  z-index: 10 !important;
  &.has-sidebar {
    top: 145px !important;
  }
}

.promo-user {
  top: 3px !important;
  right: -16px !important;
}

.v-menu-transition-leave-acitve .v-menu-transition-leave-to {
  transition-duration: 0s !important;
  transform-origin: top right !important;
}

.top-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 80px;
  padding: 16px 28px;
  border-bottom: 1px solid rgb(228, 235, 235);
}

.header-down {
  padding-left: 24px;
  display: flex;
  align-items: center;
  height: 56px;
}

.user-title {
  color: $grey;
  font-family: $font-qs;
  font-weight: bold;
  font-size: $fs-text;
  letter-spacing: 0px;
  line-height: 28px;
  max-width: 200px;
}
.user-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  cursor: pointer;
}
@media print, screen and (min-width: 600px) {
  .promo-user {
    right: -4px !important;
  }
}
@media print, screen and (min-width: 960px) {
  .user-dropdown,
  .user-dropdown.has-sidebar {
    top: 3px !important;
    right: -8px !important;
  }
  .promo-user {
    right: 36.5px !important;
    top: 3px !important;
  }
}
</style>
