<template>
  <div>
    <h3 class="main-title">{{ name }}</h3>
    <h4 class="parking-subtitle mr-2 mt-5">{{ $t("your_promo") }}</h4>
    <div class="parking-header d-flex justify-start align-center flex-wrap mt-4">
      <v-text-field
        v-model="search"
        autocomplete="off"
        class="parking-search ml-0 mr-auto"
        :placeholder="$t('search_campaign')"
      ></v-text-field>
      <v-btn
        class="main-btn px-4 py-2"
        @click="openAddForm"
        v-if="(place || !isCompany) && !readOnly"
        >{{ $t("add") }} {{ $t("promo") }}</v-btn
      >
    </div>
    <YourPromoTable :searchValue="search" />
  </div>
</template>

<script>
export default {
  name: "YourPromotion",
  components: {
    YourPromoTable: () => import("@/components/Promo/YourPromoTable"),
  },
  data() {
    return {
      openPromo: null,
      search: "",
    };
  },
  computed: {
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    readOnly() {
      return this.$store.getters["app/isReadonly"];
    },
    name() {
      let name = "";
      if (this.isCompany && this.$store.state.app.user.company_name) {
        name = this.$store.state.app.user.company_name;
      } else {
        name = this.$store.state.app.places[0]
          ? this.$store.state.app.places[0].place_name
          : "";
      }
      return name;
    },
    place() {
      return this.$store.state.promo.place;
    },
  },
  mounted() {
    this.$store.commit("promo/set_view", "your_promo");
  },
  methods: {
    openAddForm() {
      this.$router.push("/platform/promo/campaigns/form");
    },
  },
};
</script>

<style lang="scss" scoped>
.parking-search {
  margin-bottom: 24px;
}
</style>
