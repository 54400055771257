<template>
  <div class="wrapper">
    <div v-if="!url"  class="player-info player-info--offline">
      <span>Camera offline</span>
    </div>
    <div v-else-if="isLoading" class="player-info player-info--loading">
      <span class="main-title">Loading...</span>
    </div>
    <div v-else-if="isError" class="player-info player-info--error">
      <span class="main-title">Something went wrong, trying to reconnect</span>
    </div>
    <img v-else :src="frameSrc" width="640" height="370" class="player" />
  </div>
</template>

<script>
//TODO: Если wsUrl это пустая строка то выводить, что камера офлайн
export default {
  name: "StreamingNewWSPlayer",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      ws: null,
      frameSrc: null,
      nextFrameIntervalId: null,
      isLoading: false,
      isError: false,
    };
  },
  methods: {
    openWebSocket() {
      this.isError = false;
      this.isLoading = true;

      this.closeWebSocket();

      this.ws = new WebSocket(this.url);

      this.ws.onopen = () => {
        this.ws.send("START_STREAM");
        console.log("Stream socket opened");
      };

      this.ws.onmessage = (msg) => {
        this.isLoading = false;
        const previousSrc = this.frameSrc;
        this.frameSrc = URL.createObjectURL(msg.data);
        URL.revokeObjectURL(previousSrc);
      };

      this.ws.onerror = (error) => {
        this.isError = true;
        this.isLoading = false;
        console.log("on error " + JSON.stringify(error));
        console.log(
          JSON.stringify(error, ["message", "arguments", "type", "name"])
        );
        setTimeout(this.openWebSocket, 2000);
      };

      this.nextFrameIntervalId = setInterval(() => {
        if (this.ws.readyState === 1) {
          this.ws.send("GET_NEXT_FRAME");
        }
      }, 1000);
    },
    closeWebSocket() {
      if (this.ws) {
        this.ws.close();
        this.ws = null;
        clearInterval(this.nextFrameIntervalId);
      }
    },
  },
  created() {
    this.openWebSocket();
    window.addEventListener("blur", this.closeWebSocket);
    window.addEventListener("focus", this.openWebSocket);
  },
  beforeDestroy() {
    this.closeWebSocket();
    window.removeEventListener("blur", this.closeWebSocket);
    window.removeEventListener("focus", this.openWebSocket);
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/_colors.scss";
.wrapper {
  width: 100%;
  overflow: clip;
}

.player {
  width: 100%;
  height: auto;
}

.player-info {
  width: 100%;
  aspect-ratio: 16 / 9;
  display: flex;
  background-color: #CCCCCC;

  span {
    margin: auto;
  }

  &--error span {
    color: darken(#ff6e4c, 20%) !important;
  }
}
</style>
