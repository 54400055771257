import actions from './actions'

const state = () => ({
    users: [],
    loading: true,
    blacklist: [],
    loading_blacklist: true,
    pricings: [],
    loading_pricings: true
});

// getters
const getters = {};

// mutations
const mutations = {
    set_users(state, payload) {
        state.users = payload
        state.loading = false
        return state
    },
    set_blacklist(state, payload) {
        state.blacklist = payload
        state.loading_blacklist = false
        return state
    },
    set_pricings(state, payload) {
        state.pricings = payload
        state.loading_pricings = false
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};