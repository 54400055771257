<template>
  <svg
    width="18px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="1_12-Additional-search-Show-more"
        transform="translate(-18.000000, -873.000000)"
        fill-rule="nonzero"
      >
        <g id="Group-2-Copy-3" transform="translate(0.000000, 861.000000)">
          <g id="Combined-Shape" transform="translate(16.000000, 10.000000)">
            <path
              :class="calendarClass"
              d="M15,4 L15,5 C15,6.0543618 15.8158778,6.91816512 16.8507377,6.99451426 L17,7 C18.0543618,7 18.9181651,6.18412221 18.9945143,5.14926234 L19,5 L19,4 C20.6568542,4 22,5.34314575 22,7 L22,18 C22,20.1421954 20.3160315,21.8910789 18.1996403,21.9951047 L18,22 L6,22 C3.85780461,22 2.10892112,20.3160315 2.00489531,18.1996403 L2,18 L2,7 C2,5.34314575 3.34314575,4 5,4 L5,5 C5,6.1045695 5.8954305,7 7,7 C8.0543618,7 8.91816512,6.18412221 8.99451426,5.14926234 L9,5 L9,4 L15,4 Z M20,9 L4,9 L4,18 C4,19.0016437 4.73632971,19.8313084 5.69722565,19.9772306 L5.85073766,19.9945143 L6,20 L18,20 C19.0543618,20 19.9181651,19.1841222 19.9945143,18.1492623 L20,18 L20,9 Z M18.276953,15.2890567 L18.276953,17.7890567 L15.776953,17.7890567 L15.776953,15.2890567 L18.276953,15.2890567 Z M8.26702667,15.2821605 L8.26702667,17.7821605 L5.76702667,17.7821605 L5.76702667,15.2821605 L8.26702667,15.2821605 Z M13.2751767,15.2821605 L13.2751767,17.7821605 L10.7751767,17.7821605 L10.7751767,15.2821605 L13.2751767,15.2821605 Z M18.2645887,11.0068962 L18.2645887,13.5068962 L15.7645887,13.5068962 L15.7645887,11.0068962 L18.2645887,11.0068962 Z M8.25466232,11 L8.25466232,13.5 L5.75466232,13.5 L5.75466232,11 L8.25466232,11 Z M13.2628124,11 L13.2628124,13.5 L10.7628124,13.5 L10.7628124,11 L13.2628124,11 Z M7,2 C7.55228475,2 8,2.44771525 8,3 L8,5 C8,5.55228475 7.55228475,6 7,6 C6.44771525,6 6,5.55228475 6,5 L6,3 C6,2.44771525 6.44771525,2 7,2 Z M17,2 C17.5522847,2 18,2.44771525 18,3 L18,5 C18,5.55228475 17.5522847,6 17,6 C16.4477153,6 16,5.55228475 16,5 L16,3 C16,2.44771525 16.4477153,2 17,2 Z"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    calendarClass: {
      default: "",
    },
  },
};
</script>

<style lang="scss">
.yellow-svg {
  fill: #ffcc00;
}

.orange-svg {
  fill: $error-orange;
}

.red-svg {
  fill: #cc3300;
}
</style>
