<template>
  <div class="user-block">
    <template v-if="form">
      <UserForm :selected="selected" v-if="form" ref="form" @hide="hideForm" />
    </template>
    <template v-else>
      <div class="header mb-6">
        <h3 class="main-title my-0">{{ current.name || $t("unnamed") }}</h3>
        <v-btn text @click="deleteUser" class="flat-btn grey-text">{{
          $t("delete")
        }}</v-btn>
      </div>
      <v-tabs v-model="tab" class="mx-0"
        ><v-tab class="mx-0" v-for="item in items" :key="item">{{ item }}</v-tab>

        <v-tab-item><UserGeneral @openForm="openForm" /></v-tab-item>
        <v-tab-item><UserCars /></v-tab-item>
        <v-tab-item><UserParkings /></v-tab-item>
        <v-tab-item><UserSubscriptions /></v-tab-item>
        <v-tab-item><UserLogbook /></v-tab-item>
        <v-tab-item><UserTransactions /></v-tab-item>
        <v-tab-item><UserChat /></v-tab-item> </v-tabs
    ></template>
  </div>
</template>

<script>
export default {
  name: "UserView",
  components: {
    UserGeneral: () => import("@/components/Users/UserGeneral"),
    UserCars: () => import("@/components/Users/UserCars"),
    UserParkings: () => import("@/components/Users/UserParkings"),
    UserSubscriptions: () => import("@/components/Users/UserSubscriptions"),
    UserLogbook: () => import("@/components/Users/UserLogbook"),
    UserTransactions: () => import("@/components/Users/UserTransactions"),
    UserChat: () => import("@/components/Users/UserChat"),
    UserForm: () => import("@/components/Users/UserForm"),
  },
  data: () => {
    return {
      tab: 0,
      form: false,
    };
  },
  computed: {
    current() {
      return this.$store.state.users.current;
    },
    items() {
      return this.$t("user_view_tabs");
    },
  },
  methods: {
    openForm() {
      this.form = true;
    },
    hideForm() {
      this.form = false;
    },
    deleteUser() {
      this.$confirm({
        message: this.$t("user_delete_prompt", {
          user: this.current.name,
        }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("delete"),
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("users/deleteUser", this.current.id);
          }
        },
      });
    },
  },
};
</script>

<style></style>
