<template>
  <div class="parking-summary" v-if="$store.state.dashboard.occupancy">
    <div class="summary-header">
      <h3 class="main-title">{{ $t("parking_history") }}</h3>
      <v-row>
        <v-col>
          <div class="summary-subtitle">{{ $t("spaces_free") }}</div>
          <div>{{ freeLots }}</div>
        </v-col>
        <v-col class="col-4">
          <div class="summary-subtitle">{{ $t("spaces_in_use") }}</div>
          <div>{{ occupiedLots }}</div>
        </v-col>
      </v-row>
    </div>
    <div class="content">
      <TableRecord :isShort="true" :searchValue="search" :filters="filters" :tab="tab" />
    </div>
    <div class="summary-footer">
      <v-btn text class="flat-btn" @click="$router.push('/platform/parking-history')">{{
        $t("view_all")
      }}</v-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "HistorySummary",
  props: {
    place: {
      default: 0,
    },
  },
  data() {
    return {
      tab: 3,
      search: "",
      filters: {
        dates: [],
        car_type: null,
        pricing: null,
      },
    };
  },
  components: {
    TableRecord: () => import("@/components/ParkingHistory/TableRecord"),
  },
  computed: {
    occupancy() {
      let data = [...this.$store.state.dashboard.occupancy];
      if (this.place) {
        data = data.filter((p) => p.place_id === this.place)[0] || {};
      } else {
        if (this.$store.getters["app/isCompany"]) {
          data = data.filter((p) => p.place_id === 0)[0] || {};
        } else {
          data = data[0] || {};
        }
      }
      return data;
    },
    occupiedLots() {
      return this.occupancy.occupied_hourly + this.occupancy.occupied_monthly || 0;
    },
    places() {
      return this.$store.state.app.places;
    },
    allLots() {
      let count = 0;
      this.places.map((place) => {
        count = count + place.place_count;
      });
      return count;
    },
    freeLots() {
      return this.allLots - this.occupiedLots || 0;
    },
  },
  methods: {},
};
</script>
