<template>
  <div class="guests-table">
    <div v-if="loading">
      <div class="d-flex v-expansion-panel-header" v-for="i in 7" :key="i">
        <v-skeleton-loader style="max-width: 400px" type="heading" :loading="true" />
        <v-skeleton-loader type="button" :loading="true" class="ml-auto flex-grow-0" />
      </div>
    </div>
    <v-expansion-panels flat multiple accordion>
      <v-expansion-panel v-for="code in codesToShow" :key="code.id">
        <v-expansion-panel-header>
          <h3 class="main-subtitle mr-auto d-flex align-center">
            <span
              class="status-icon"
              :class="checkActive(code) ? 'active' : 'disabled'"
            />
            <span class="mr-4">
              {{ code.code }}
              <template v-if="code.usages "
                >- {{ code.usages }} {{ $t(`usages`) }}</template
              >
            </span>
          </h3>
          <v-btn text class="flat-btn" @click="openPromoForm(code)">{{
            $t(`edit`)
          }}</v-btn>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="itt-guest-form my-0">
          <div class="d-flex align-center">
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr class="guests-table-headers">
                    <th style="min-width: 150px">{{ $t(`free_hours`) }}</th>
                    <th style="min-width: 150px">{{ $t(`usages_max`) }}</th>
                    <th style="min-width: 150px">{{ $t(`usages_total`) }}</th>
                    <th style="min-width: 150px">{{ $t(`valid_from`) }}</th>
                    <th style="min-width: 150px">{{ $t(`valid_until`) }}</th>
                    <th style="min-width: 150px">{{ $t(`status`) }}</th>
                  </tr>
                </thead>
                <tbody class="guests-table-content">
                  <tr>
                    <td>{{ code.free_minutes / 60 }}</td>
                    <td>{{ code.max_usages || 1 }}</td>
                    <td>{{ code.usages }}</td>
                    <td>
                      <template v-if="code.active_from">{{
                        $moment(code.active_from).format("DD.MM.YYYY")
                      }}</template>
                    </td>
                    <td>
                      <template v-if="code.active_until">{{
                        $moment(code.active_until).format("DD.MM.YYYY")
                      }}</template>
                    </td>
                    <td>
                      {{
                        checkActive(code) ? $t(`code_status[0]`) : $t(`code_status[1]`)
                      }}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <CodeForm :selected="selected" ref="codeForm" @reset="reset" />
  </div>
</template>

<script>
export default {
  name: "PromocodesList",
  data() {
    return {
      selected: {},
    };
  },
  components: {
    CodeForm: () => import("@/components/Promo/CodeForm"),
  },
  props: {
    searchValue: {
      type: String,
      default: "",
    },
  },
  computed: {
    currentPlace() {
      return this.$store.state.promo.place;
    },
    promocodes() {
      let list = [...this.$store.state.promo.promocodes];
      if (this.currentPlace) {
        list = list.filter((c) => c.place_id === this.currentPlace);
      }
      return list;
    },
    codesToShow() {
      return this.search(this.searchValue);
    },
    loading() {
      return this.$store.state.promo.loading_codes;
    },
  },
  methods: {
    search(value) {
      if (value !== "") {
        return [...this.promocodes].filter((item) =>
          item.code.toLowerCase().includes(this.searchValue.toLowerCase())
        );
      }
      return [...this.promocodes];
    },
    openPromoForm(code) {
      this.selected = code;
      this.$refs.codeForm.show();
    },
    reset() {
      this.selected = {};
    },
    checkActive(code) {
      return code.is_active;
      //  && this.$moment(code.active_until).isAfter(this.$moment())
      // && (code.usages < code.max_usages || code.max_usages === 0)
    },
  },
};
</script>
<style lang="scss">
@import "@/scss/_guestsTable.scss";
</style>
