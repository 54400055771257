<template>
  <div>
    <NotificationHOC group="general">
      <template slot="body" slot-scope="props">
        <div class="my-style">
          <div class="notification-header">
            <v-img
              @click="props.close"
              :style="{ width: '24px', height: '24px', cursor: 'pointer' }"
              src="https://storage.googleapis.com/parkingbnb-html-images/%20Close.svg "
            ></v-img>
          </div>
          <div
            class="notification-content d-flex flex-column align-center justify-center"
          >
            <v-img
              class="not-img"
              alt="Good"
              :src="
                props.item.title === 'Success!'
                  ? 'https://storage.googleapis.com/parkingbnb-html-images/_Good.svg'
                  : 'https://storage.googleapis.com/parkingbnb-html-images/_Wrong.svg'
              "
            ></v-img>
            <div class="notification-title">
              {{ props.item.title === "Success!" ? $t("success") : $t("error") }}
            </div>
            <div v-html="props.item.text"></div>

            <template v-if="props.item.customButtons">
              <v-btn
                v-for="(btn, i) in props.item.customButtons"
                :key="i"
                style="min-width: 100%"
                class="main-btn py-2 ml-auto mt-3"
                @click="btn.func;"
                >{{ btn.text }}</v-btn
              ></template
            >
            <v-btn class="main-btn px-6 py-2 mt-3" @click="props.close">{{
              $t("done")
            }}</v-btn>
          </div>
        </div>
      </template>
    </NotificationHOC>
  </div>
</template>

<script>
export default {
  name: "NotificationPopup",
  props: {},
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
