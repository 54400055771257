<template>
  <v-skeleton-loader v-if="loading" :loading="true" type="table"></v-skeleton-loader>
  <v-data-table
    v-else
    :headers="headers"
    :items-per-page="-1"
    :items="records"
    class="table mb-12 promo-history-table overflow-x-scroll"
    hide-default-footer
    mobile-breakpoint="0"
    :no-data-text="$t('no_data')"
  >
    <template v-slot:[`item.username`]="{ item }">
      <h4 class="table-user-name">{{ item.user_name }}</h4>
    </template>
    <template v-slot:[`item.car_plate`]="{ item }">
      <h4>{{ item.parking.vehicle.plate_number }}</h4>
    </template>
    <template v-slot:[`item.promocode`]="{ item }">
      <h4 class="pr-4">{{ item.code }}</h4>
    </template>
    <template v-slot:[`item.used_at`]="{ item }">
      {{ getFormattedDate(item.created_at) }}
    </template>
    <template v-slot:[`item.created_by`]="{ item }">
      {{ $t(`cyber_origins[${item.origin}]`) }}
    </template>
    <template v-slot:[`item.free_hours`]="{ item }">
      <span>{{ item.free_minutes / 60 }}</span>
    </template>
  </v-data-table>
</template>
<script>
export default {
  name: "CodeHistoryTable",
  props: {
    searchValue: {
      type: String,
      default: "",
    },
    filters: {
      dates: [],
    },
  },
  data() {
    return {
      data: [],
      counter: 1,
      itemsPerPage: 25,
      expanded: [],
    };
  },
  computed: {
    currency() {
      return this.$store.getters["app/currency"];
    },
    headers() {
      return [
        {
          text: this.$t("user"),
          value: "username",
          class: "table-guest table-header",
          sortable: false,
        },
        {
          text: this.$t("car_plate"),
          value: "car_plate",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("promocode"),
          value: "code",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("used_at"),
          value: "used_at",
          class: "table-header",
        },
        {
          text: this.$t("created_by"),
          value: "created_by",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("free_hours"),
          value: "free_hours",
          class: "table-header",
        },
      ];
    },
    currentPlace() {
      return this.$store.state.promo.place;
    },
    records() {
      return [...this.$store.state.promo.codes_history];
    },
    loading() {
      return this.$store.state.promo.loading_codes_history;
    },
  },
  mounted() {},
  watch: {},
  methods: {
    search(value) {
      this.$store.dispatch("promo/getCodesHistory", { code: value });
    },
    getFormattedDate(date) {
      return date ? this.$moment(date).format("DD.MM.YYYY HH:mm") : "";
    },
    filter() {
      this.$store.dispatch("promo/getCodesHistory", { code: this.filters.code });
    },
    customSort() {},
  },
};
</script>
