import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import app from './modules/app/index';
import dashboard from './modules/dashboard/index';
import guests from './modules/guests/index';
import history from './modules/history/index';
import promo from './modules/promo/index'
import wallet from './modules/wallet/index'
import settings from './modules/settings/index'
import overparking from './modules/overparking/index'
import streaming from './modules/streaming/index'
import subscriptions from './modules/subscriptions/index'
import chat from './modules/chat/index'
import tenants from './modules/tenants/index'
import companies from './modules/companies/index'
import devices from './modules/devices/index'
import users from './modules/users/index'
import logbook from './modules/logbook/index'

export default new Vuex.Store({
    modules: {
        app,
        dashboard,
        guests,
        history,
        wallet,
        promo,
        settings,
        overparking,
        streaming,
        subscriptions,
        chat,
        tenants,
        companies,
        devices,
        users,
        logbook,
    }
});