<template>
  <Blacklist is-read-only class="black-list" />
</template>

<script>
import Blacklist from '@/components/Settings/Blacklist.vue';
export default {
  name: "ViewBlackListReadOnly",
  components: {
    Blacklist,
  },
}
</script>

<style scoped lang="scss">
.black-list {
  padding: 20px 40px;
}
</style>
