<template>
  <div class="page-member">
    <v-skeleton-loader v-if="!tableData.length" type="table" />
    <v-data-table
      v-else
      :headers="headers"
      :items-per-page="-1"
      class="table not-expandable"
      :items="tableData"
      disable-pagination
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
    >
      <template v-slot:[`item.from`]="{ item }">
        <div>
          {{ $moment(item.from).format("DD.MM.YYYY HH:mm") }}
        </div>
      </template>
      <template v-slot:[`item.to`]="{ item }">
        <div>
          {{ $moment(item.to).format("DD.MM.YYYY HH:mm") }}
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { base } from '@/axios-api';

export default {
  name: "ViewMembers",
  data() {
    return {
      headers: [
        {
          text: this.$t("car_plate"),
          value: "plate",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("from"),
          value: "from",
          class: "table-header",
          sortable: false,
        },
        {
          text: this.$t("to"),
          value: "to",
          class: "table-header",
          sortable: false,
        }
      ],
      tableData: [],
    };
  },
  mounted() {
    base(
      {
        url: "/partners/wasp/members",
        method: 'GET',
      }
    )
      .then((response) => {
        console.log(response)
        this.tableData = response.data.body;
      })
      .catch((error) => {
        console.error(error)
      });
  }
}
</script>

<style scoped lang="scss">
.page-member {
  padding: 40px;
}
</style>
