<template>
  <div>
    <v-btn text class="dwl-btn" @click.stop="show">
      <img
        src="https://storage.googleapis.com/parkingplace-images/download.svg"
        alt="report"
        :style="{ height: '24px', width: '24px' }"
      />
    </v-btn>
    <div class="popup record-popup" v-if="shown">
      <div class="modal-overlay" @click="hide" />
      <v-menu
        attach
        :value="shown"
        :close-on-content-click="false"
        :close-on-click="false"
        content-class="core-filter modal-content"
      >
        <div class="mb-4 d-flex justify-space-between">
          <h3 class="main-title">
            {{ $t("download") }}
            <br />
            {{ view !== "settings" ? $t(`${view}`) : $t("logs") }}
          </h3>
          <v-btn text class="icon-close pa-0" min-width="24px" @click="hide" />
        </div>

        <v-form ref="form">
          <div class="full-border-btm">
            <label class="modal-label mb-3">{{ $t("period") }}</label>
            <div class="d-flex justify-center form-period date" v-if="!hasTimepicker">
              <v-menu
                attach
                content-class="datepicker-menu"
                :close-on-content-click="false"
                @click.native.stop
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    @click.prevent
                    v-on="on"
                    readonly
                    class="modal-input input-half datepicker-inp mt-0"
                    :value="dates[0] ? $moment(dates[0]).format('DD.MM.YYYY') : ''"
                    :label="$t('from')"
                    :rules="[() => view === 'wallet' || !!dates[0] || $t('from_err')]"
                  ></v-text-field>
                  <span class="datepicker-divider"></span>
                  <v-text-field
                    v-on="on"
                    class="modal-input input-half datepicker-inp datepicker-inp-second mt-0"
                    readonly
                    :value="dates[1] ? $moment(dates[1]).format('DD.MM.YYYY') : ''"
                    :label="$t('to')"
                    :rules="[
                      () =>
                        view === 'wallet' ||
                        $moment(dates[1]).isAfter($moment(dates[0])) ||
                        $moment(dates[1])._id === $moment(dates[0])._id ||
                        $t('range_err'),
                    ]"
                  ></v-text-field>
                </template>
                <v-date-picker
                  :locale="$i18n.locale"
                  first-day-of-week="1"
                  :isDark="false"
                  v-model="dates"
                  :max="view === 'your_parking' ? null : $moment().format('YYYY-MM-DD')"
                  :min="dates[0] || ''"
                  range
                ></v-date-picker>
              </v-menu>
            </div>
            <template v-if="view === 'logbook'">
              <div class="form-period date single mt-2">
                <v-menu
                  content-class="datepicker-menu"
                  attach
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      class="modal-input input-half datepicker-inp mt-0"
                      :value="
                        timeStrings.from
                          ? $moment(timeStrings.from).format('DD.MM.YYYY [at] HH:mm')
                          : ''
                      "
                      @change="timeChangeHandler(`enter`, $event)"
                      :label="$t('from')"
                      placeholder="DD.MM.YYYY HH:mm"
                    ></v-text-field>
                  </template>

                  <v-tabs fixed-tabs v-model="timerange.from.tab" class="picker-tabs">
                    <v-tab key="calendar">
                      <img src="@/assets/images/calendar.svg" />
                    </v-tab>
                    <v-tab key="timer">
                      <img width="24px" height="24px" src="@/assets/images/clock.svg" />
                    </v-tab>
                    <v-tab-item key="calendar">
                      <v-date-picker
                        :locale="$i18n.locale"
                        first-day-of-week="1"
                        @change="timerange.from.tab = 1"
                        v-model="timerange.from.date"
                        :max="$moment().format('YYYY-MM-DD')"
                      ></v-date-picker>
                    </v-tab-item>
                    <v-tab-item key="timer">
                      <vue-timepicker
                        format="HH:mm:ss"
                        manual-input
                        v-model="timerange.from.time"
                        blur-delay="5000000"
                      />
                    </v-tab-item>
                  </v-tabs>
                </v-menu>
              </div>
              <div class="form-period date single mt-2">
                <v-menu
                  content-class="datepicker-menu"
                  attach
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      class="modal-input input-half datepicker-inp mt-0"
                      @change="timeChangeHandler(`exit`, $event)"
                      :value="
                        timeStrings.to
                          ? $moment(timeStrings.to).format('DD.MM.YYYY [at] HH:mm')
                          : ''
                      "
                      :label="$t('to')"
                      placeholder="DD.MM.YYYY HH:mm"
                    ></v-text-field>
                  </template>

                  <v-tabs fixed-tabs v-model="timerange.to.tab" class="picker-tabs">
                    <v-tab key="calendar">
                      <img src="@/assets/images/calendar.svg" />
                    </v-tab>
                    <v-tab key="timer">
                      <img width="24px" height="24px" src="@/assets/images/clock.svg" />
                    </v-tab>
                    <v-tab-item key="calendar">
                      <v-date-picker
                        :locale="$i18n.locale"
                        first-day-of-week="1"
                        @change="timerange.to.tab = 1"
                        v-model="timerange.to.date"
                        :max="$moment().format('YYYY-MM-DD')"
                      ></v-date-picker>
                    </v-tab-item>
                    <v-tab-item key="timer">
                      <vue-timepicker
                        format="HH:mm:ss"
                        manual-input
                        v-model="timerange.to.time"
                        blur-delay="5000000"
                      />
                    </v-tab-item>
                  </v-tabs>
                </v-menu>
              </div>
            </template>

            <div v-if="hasFilters">
              <template v-if="view === 'your_parking' && baptistFlow">
                <v-select
                  :no-data-text="$t('no_data')"
                  attach
                  v-model="type"
                  item-value="id"
                  item-text="desc"
                  class="modal-input filter-dropdown"
                  :items="$t(`report_types`)"
                  :label="$t('report_type')"
                />
              </template>
              <template v-if="view !== 'your_parking'"
                ><label class="modal-label mb-3 mt-6 d-block">{{
                  $t("pricing_type")
                }}</label>
                <v-select
                  :no-data-text="$t('no_data')"
                  attach
                  multiple
                  v-model="pricing"
                  item-value="id"
                  :disabled="!!groups.length"
                  item-text="desc"
                  class="modal-input filter-dropdown"
                  :items="allPricingTypes"
                  :label="$t('price_type')"
                >
                  <template v-slot:prepend-item>
                    <v-list-item ripple @click="togglePricing">
                      <v-list-item-content class="px-10">
                        <v-list-item-title>
                          {{ $t("parking_history_tabs")[2] }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-divider class="mt-2"></v-divider>
                  </template>
                  <template slot="selection" slot-scope="data">
                    {{
                      chooseAllPriceTypes && data.item.id === 1
                        ? $t("parking_history_tabs")[2]
                        : ""
                    }}
                    <span
                      class="mr-2"
                      style="white-space: nowrap"
                      v-text="chooseAllPriceTypes ? '' : data.item.desc + ', '"
                    />
                  </template> </v-select
              ></template>

              <label class="modal-label mb-3 mt-6 d-block">{{ $t("group") }}</label>
              <v-autocomplete
                :no-data-text="$t('no_data')"
                attach
                multiple
                v-model="groups"
                item-value="group_id"
                item-text="group_name"
                class="modal-input filter-dropdown"
                :items="allGroups"
                :label="$t('groups')"
                @change="$forceUpdate()"
                :disabled="!!pricing.length && !pricing.includes(1)"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleCompanies">
                    <v-list-item-content class="px-10">
                      <v-list-item-title>
                        {{ $t("parking_history_tabs")[2] }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template slot="selection" slot-scope="data">
                  {{
                    chooseAllGroups && data.item === allGroups[0]
                      ? $t("parking_history_tabs")[2]
                      : ""
                  }}
                  <span
                    class="mr-2"
                    style="white-space: nowrap"
                    v-text="chooseAllGroups ? '' : data.item.group_name + ', '"
                  />
                </template>
              </v-autocomplete>
            </div>
            <div v-if="view === 'parking_history' || view === 'over_parking'">
              <label class="modal-label mb-3 mt-6 d-block">{{ $t("car_plate") }}</label>
              <v-autocomplete
                :no-data-text="$t('no_data')"
                attach
                multiple
                v-model="car_plates"
                class="modal-input filter-dropdown"
                :items="getChosenCompaniesCars()"
                :label="$t('cars')"
                :disabled="!!pricing.length && !pricing.includes(1)"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleCars">
                    <v-list-item-content class="px-10">
                      <v-list-item-title>
                        {{ $t("parking_history_tabs")[2] }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template slot="selection" slot-scope="data">
                  {{
                    chooseAllCars && getChosenCompaniesCars()[0] === data.item
                      ? $t("parking_history_tabs")[2]
                      : ""
                  }}
                  <span
                    class="mr-2"
                    style="white-space: nowrap"
                    v-text="chooseAllCars ? '' : data.item + ', '"
                  />
                </template>
              </v-autocomplete>
            </div>
            <label v-if="view === 'zone_history'" class="modal-label mb-3 mt-6 d-block">{{
              $t("zone")
            }}</label>
            <v-select
              attach
              v-if="hasZones && view === 'zone_history'"
              v-model="status"
              item-value="id"
              item-text="desc"
              class="modal-input filter-dropdown"
              :items="statusSelect"
              :label="$t('zone_status')"
            />
            <v-select
              attach
              multiple
              v-if="view === 'settings'"
              v-model="user_name"
              item-value="user_name"
              item-text="user_name"
              class="modal-input filter-dropdown mt-5"
              :items="users"
              :label="$t('user')"
              style="max-width: 340px"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleUsers">
                  <v-list-item-content class="px-10">
                    <v-list-item-title>
                      {{ $t("parking_history_tabs")[2] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template slot="selection" slot-scope="data">
                {{
                  chooseAllUsers && users[0] === data.item
                    ? $t("parking_history_tabs")[2]
                    : ""
                }}
                <span
                  class="mr-2"
                  style="white-space: nowrap"
                  v-text="chooseAllUsers ? '' : data.item.user_name + ', '"
                />
              </template>
            </v-select>
            <label v-if="isCompany" class="modal-label mb-3 mt-6 d-block">{{
              $t("place")
            }}</label>
            <v-select
              attach
              multiple
              v-if="isCompany"
              v-model="place_id"
              item-value="place_id"
              item-text="place_name"
              class="modal-input filter-dropdown"
              :items="allPlaces"
              :label="$t('places')"
              style="max-width: 340px"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="togglePlaces">
                  <v-list-item-content class="px-10">
                    <v-list-item-title>
                      {{ $t("parking_history_tabs")[2] }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template slot="selection" slot-scope="data">
                {{
                  chooseAllPlaces && allPlaces[0] === data.item
                    ? $t("parking_history_tabs")[2]
                    : ""
                }}
                <span
                  class="mr-2"
                  style="white-space: nowrap"
                  v-text="chooseAllPlaces ? '' : data.item.place_name + ', '"
                />
              </template>
            </v-select>
            <template v-if="view === 'wallet'">
              <v-select
                :no-data-text="$t('no_data')"
                attach
                multiple
                v-model="transaction_type"
                item-value="id"
                item-text="text"
                class="modal-input filter-dropdown mt-6"
                :items="transactionTypes"
                :label="$t('transaction')"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleTransTypes">
                    <v-list-item-content class="px-10">
                      <v-list-item-title>
                        {{ $t("parking_history_tabs")[2] }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template slot="selection" slot-scope="data">
                  {{
                    chooseAllTransTypes && data.item.id === 1
                      ? $t("parking_history_tabs")[2]
                      : ""
                  }}
                  <span
                    class="mr-2"
                    style="white-space: nowrap"
                    v-text="chooseAllTransTypes ? '' : data.item.text + ', '"
                  />
                </template>
              </v-select>
            </template>
            <template v-if="view === 'promocodes'">
              <v-select
                :no-data-text="$t('no_data')"
                attach
                multiple
                v-model="code"
                class="modal-input filter-dropdown mt-6"
                :items="codes"
                :label="$t('promocode')"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggleCodes">
                    <v-list-item-content class="px-10">
                      <v-list-item-title>
                        {{ $t("parking_history_tabs")[2] }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template slot="selection" slot-scope="data">
                  {{
                    chooseAllCodes && codes.indexOf(data.item) === 0
                      ? $t("parking_history_tabs")[2]
                      : ""
                  }}
                  <span
                    class="mr-2"
                    style="white-space: nowrap"
                    v-text="chooseAllCodes ? '' : data.item + ', '"
                  />
                </template>
              </v-select>
            </template>
          </div>

          <div class="modal-footer d-flex align-center px-0 mt-4">
            <button class="main-btn v-btn py-6" @click.prevent="downloadReport">
              {{ $t("download") }}
            </button>
          </div>
        </v-form>
      </v-menu>
    </div>
  </div>
</template>

<script>
import "@/scss/_modal.scss";

const defaultDates = {
  from: {
    tab: 0,
    date: "",
    time: {
      HH: undefined,
      mm: undefined,
      ss: undefined,
    },
  },
  to: {
    tab: 0,
    date: "",
    time: {
      HH: undefined,
      mm: undefined,
      ss: undefined,
    },
  },
};

export default {
  name: "ReportDownloadForm",
  components: {
    VueTimepicker: () => import("vue2-timepicker/src/vue-timepicker.vue"),
  },
  props: {
    view: {
      type: String,
      default: "datePicker",
    },
  },
  data() {
    return {
      shown: null,
      dates: [],
      groups: [],
      car_plates: [],
      pricing: [],
      status: null,
      user_name: [],
      place_id: [],
      transaction_type: [],
      type: undefined,
      code: [],
      timerange: JSON.parse(JSON.stringify(defaultDates)),
    };
  },
  computed: {
    hasFilters() {
      return (
        this.view !== "wallet" &&
        this.view !== "zone_history" &&
        this.view !== "promo" &&
        this.view !== "over_parking" &&
        this.view !== "settings" &&
        this.view !== "promocodes" &&
        this.view !== "logbook" &&
        this.view !== "parking_payment"
      );
    },
    hasZones() {
      return this.$store.getters["app/hasZones"];
    },
    baptistFlow() {
      return this.$store.getters["app/hasOverParking"];
    },
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    hasTimepicker() {
      return this.view === "logbook";
    },
    users() {
      return this.$store.state.settings.users;
    },
    allGroups() {
      return this.$store.state.guests.groups;
    },
    allCars() {
      let cars = [];
      [...this.$store.state.guests.guest_list].map((guest) => {
        if (guest.cars) {
          guest.cars.map((car) => {
            cars.push(car.car_plate || car.plate_number);
          });
        }
      });
      return cars;
    },
    locale() {
      return this.$store.getters["app/locale"];
    },
    hasKiosk() {
      return this.$store.getters["app/hasKiosk"];
    },
    allPricingTypes() {
      let types = [];
      let finalTypes = [];
      [...this.$store.state.app.guest_types].map((i) => {
        types = types.concat(i.guest_types).filter((v, i, a) => a.indexOf(v) === i);
      });
      types.map((type) => {
        finalTypes.push({
          id: type,
          desc: this.$t("price_types").filter((i) => i.id === type)[0].desc,
        });
      });
      return finalTypes;
    },
    allPlaces() {
      return this.$store.state.app.places;
    },
    codes() {
      return [...this.$store.state.promo.promocodes].map((i) => i.code);
    },
    statusSelect() {
      return [
        {
          id: 1,
          desc: this.$t("zone_tabs[0]"),
        },
        {
          id: 2,
          desc: this.$t("zone_tabs[1]"),
        },
      ];
    },
    chooseAllPriceTypes() {
      return this.pricing.length === this.allPricingTypes.length;
    },
    chooseAllGroups() {
      return this.groups.length === this.allGroups.length;
    },
    chooseAllCars() {
      return this.car_plates.length === this.getChosenCompaniesCars().length;
    },
    chooseAllUsers() {
      return this.user_name.length === this.users.length;
    },
    chooseAllPlaces() {
      return this.place_id.length === this.allPlaces.length;
    },
    chooseAllCodes() {
      return this.code.length === this.codes.length;
    },
    isBaptist() {
      return this.$store.getters["app/hasOverParking"];
    },
    historyGroups() {
      let result = this.$store.state.guests.groups.concat(
        this.$store.state.guests.subgroups
      );
      return result.filter(
        (i) => this.groups.includes(i.group_id) || this.groups.includes(i.parent_id)
      );
    },
    transactionTypes() {
      let types = [];
      for (const [key, value] of Object.entries(this.$t(`payment_types`))) {
        types.push({
          id: key,
          text: value,
        });
      }
      return types;
    },
    chooseAllTransTypes() {
      return (
        this.transaction_type.length === this.transactionTypes.length ||
        this.transaction_type.length === 0
      );
    },
    timeStrings() {
      let from = `${this.timerange.from.date} ${this.timerange.from.time.HH}:${this.timerange.from.time.mm}:${this.timerange.from.time.ss}`.replaceAll(
        "undefined",
        ""
      );
      let to = `${this.timerange.to.date} ${this.timerange.to.time.HH}:${this.timerange.to.time.mm}:${this.timerange.to.time.ss}`.replaceAll(
        "undefined",
        ""
      );
      if (from.length < 19) from = "";
      else from = this.$moment(from).toISOString();

      if (to.length < 19) to = "";
      else to = this.$moment(to).toISOString();

      return {
        from,
        to,
      };
    },
  },
  methods: {
    show() {
      this.shown = true;
      // document.body.style = "height: 100vh; overflow: hidden";
      this.$emit("showEmit");
    },
    hide() {
      this.shown = false;
      // document.body.style = "height: auto; overflow: auto";
      this.$emit("hideEmit");

      this.dates = [];
      this.groups = [];
      this.car_plates = [];
      this.pricing = [];
      this.status = null;
      this.place_id = [];
      this.timerange = JSON.parse(JSON.stringify(defaultDates));

      this.$emit("hideEmit");
      document.body.style = "height: auto; overflow: auto";
    },
    timeChangeHandler(type, value) {
      if (!value) {
        this.timerange[type].date = null;
        this.timerange[type].time = {
          HH: undefined,
          mm: undefined,
          ss: undefined,
        };
        this.$forceUpdate();
        return;
      }
      let pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
      let dateString = value.replace(pattern, "$3-$2-$1");
      let date = this.$moment(new Date(dateString)).format("YYYY-MM-DD");
      this.timerange[type].date = date;
      this.timerange[type].time = {
        HH: value.slice(-5).slice(0, 2),
        mm: value.slice(-2),
        ss: "00",
      };
      this.$forceUpdate();
    },
    getChosenCompaniesCars() {
      let cars = [];
      if (this.groups.length) {
        let guests = [...this.$store.state.guests.guest_list].filter((guest) =>
          this.groups.includes(guest.group_id)
        );
        guests.map((guest) => {
          guest.cars.map((car) => {
            cars.push(car.car_plate || car.plate_number);
          });
        });
      } else {
        cars = [...this.allCars];
      }
      return cars;
    },
    toggleCars() {
      this.$nextTick(() => {
        if (this.chooseAllCars) {
          this.car_plates = [];
        } else {
          this.car_plates = this.getChosenCompaniesCars().slice();
        }
      });
    },
    toggleUsers() {
      this.$nextTick(() => {
        if (this.chooseAllUsers) {
          this.user_name = [];
        } else {
          this.user_name = this.users.map((user) => user.user_name).slice();
        }
      });
    },
    toggleCompanies() {
      this.$nextTick(() => {
        if (this.chooseAllGroups) {
          this.groups = [];
        } else {
          this.groups = this.allGroups.map((c) => c.group_id).slice();
        }
      });
    },
    togglePricing() {
      this.$nextTick(() => {
        if (this.chooseAllPriceTypes || (this.groups.length && this.pricing[0] === 1)) {
          this.pricing = [];
        } else {
          this.pricing = this.groups.length
            ? [1]
            : this.allPricingTypes.map((t) => t.id).slice();
        }
      });
    },
    togglePlaces() {
      this.$nextTick(() => {
        if (this.chooseAllPlaces) {
          this.place_id = [];
        } else {
          this.place_id = this.allPlaces.map((p) => p.place_id).slice();
        }
      });
    },
    toggleTransTypes() {
      this.$nextTick(() => {
        if (this.chooseAllPlaces) {
          this.transaction_type = [];
        } else {
          this.transaction_type = this.transactionTypes.map((p) => p.id).slice();
        }
      });
    },
    toggleCodes() {
      this.$nextTick(() => {
        if (this.chooseAllCodes) {
          this.code = [];
        } else {
          this.code = this.codes.slice();
        }
      });
    },
    downloadReport() {
      let params = {
        place_id: this.place_id.length
          ? this.place_id
          : this.allPlaces.map((p) => p.place_id),
      };
      this.$refs.form.validate();
      let sd = this.dates[0] ? new Date(this.dates[0]).toISOString() : "";
      let ed = this.dates[1] ? new Date(this.dates[1]).toISOString() : "";

      if (this.view === "wallet") {
        if (sd) params.created_after = sd.toISOString();
        if (ed) params.created_before = ed.toISOString();
      } else {
        params = {
          ...params,
          from: sd,
          to: ed,
        };
      }
      let route;

      switch (this.view) {
        case "settings":
          route = "/logs";
          params = {
            ...params,
            user_name: this.user_name.length
              ? this.user_name
              : this.users.map((u) => u.user_name),
          };
          break;
        case "your_parking":
          if (this.type === 1) {
            route = "/history/usage_report";
          } else {
            route = "/guests/report";
          }
          params.group_id = this.historyGroups.map((g) => g.group_id);
          break;
        case "parking_payment":
          route = "/account/report";
          break;
        case "parking_history":
          route = "/history/report";
          if (this.hasZones) {
            params.features = "zonal";
          } else if (this.isBaptist) {
            params.features = "overtime";
          }
          params = {
            ...params,
            group_id: this.historyGroups.map((g) => g.group_id),
            car_plate: this.car_plates,
            guest_type: this.pricing.length
              ? this.pricing
              : this.allPricingTypes.map((t) => t.id),
          };
          break;
        case "promo":
          route = "/promo/report";
          break;
        case "zone_history":
          route = "/zonal/zone_history/report";
          params.status = this.status;
          break;
        case "over_parking":
          route = "/history/overtime_report";
          params.plate_number = this.car_plates;
          break;
        case "wallet":
          route = "/platform/transactions/report";
          params.type = this.transaction_type;
          break;
        case "promocodes":
          route = "/platform/promocodes/report";
          params.code = this.code;
          break;
        case "logbook":
          route = "/events/report";
          params = {
            from: this.$moment(this.timeStrings.from).toISOString(),
            to: this.$moment(this.timeStrings.to).toISOString(),
            sort_by: "created_at",
            sort_order: "desc",
          };
          break;
        default:
          break;
      }

      if (this.view === "wallet")
        this.$store.dispatch("wallet/getReport", { params, route });
      else if (this.view === "promocodes")
        this.$store.dispatch("promo/getReportCyber", { params, route });
      else this.$store.dispatch("history/getReport", { params, route });

      this.$refs.form.reset();
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.error-message {
  position: absolute;
  bottom: 105px;
}
.full-border-btm::after {
  margin-top: 30px;
}
</style>
