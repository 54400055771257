<template>
  <div class="tabs-content logbook">
    <ParkingOccupancy :place="place" class="mb-8" />
    <div class="d-flex align-center mb-6">
      <h3 class="main-title my-0 mr-6">{{ $t("logbook") }}</h3>
      <v-text-field
        class="parking-search py-0 my-0"
        :placeholder="$t(`logbook_seacrh`)"
        v-model="search"
        @focus="isTyping = true"
        @blur="isTyping = false"
      />
      <CoreFilter
        ref="coreFilter"
        @filterSet="updFilters"
        @hide="openFilterForm = false"
        @show="openFilterForm = true"
        :hidden="{
          campaign: true,
          created_by: true,
          price_range: true,
          zone: true,
          status: true,
          wallet: true,
          car_type: true,
          pricing: true,
        }"
      />
      <ReportDownloadForm
        @hideEmit="openReportForm = false"
        @showEmit="openReportForm = true"
        ref="modal"
        view="logbook"
      />
      <!-- refresh btn -->
      <v-btn text class="mr-auto dwl-btn" @click.stop="updateTable">
        <img
          :style="{ height: '24px', width: '24px' }"
          src="@/assets/images/refresh.svg"
          alt="refresh"
        />
      </v-btn>
      <v-btn
        @click="openGate(devices.filter((i) => i.camera_position === 1)[0])"
        class="main-btn px-5 py-2 my-2 mr-2"
        >{{ $t("gate_open") }} — {{ $t("entrance") }}</v-btn
      >
      <v-btn
        @click="openGate(devices.filter((i) => i.camera_position === 2)[0])"
        class="main-btn px-5 py-2 my-2 mr-2"
        >{{ $t("gate_open") }} — {{ $t("exit") }}</v-btn
      >
    </div>
    <v-skeleton-loader v-if="loading && !busy" type="table" />
    <v-data-table
      v-else
      :headers="headers"
      :items-per-page="-1"
      class="table"
      :items="logs"
      disable-sort
      hide-default-footer
      mobile-breakpoint="0"
      :no-data-text="$t('no_data')"
    >
      <template v-for="i in headers" v-slot:[`header.${i.value}`]="{ header }">
        <div :key="i.text" class="d-flex align-center">
          <div
            class="triangle-parent"
            style="height: 12px"
            v-if="header.sortable !== false"
          >
            <div
              class="triangle up"
              @click="customSort(headers.indexOf(header), 'asc')"
              :class="header.isDesc === false ? 'active' : ''"
            />
            <div
              class="triangle down"
              @click="customSort(headers.indexOf(header), 'desc')"
              :class="header.isDesc ? 'active' : ''"
            />
          </div>
          <div>{{ header.text }}</div>
        </div>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        <div class="table-duration">
          {{ $moment(item.timestamp).format("DD.MM.YYYY [at] HH:mm") }}
        </div>
      </template>
      <template v-slot:[`item.event`]="{ item }">
        <!-- <span>{{ item.name }}</span> -->
        <div
          class="table-duration table-logevent"
          v-html="
            $t(`log_events.${item.name}`, {
              place: $t(`${item.position}`),
              car:
                item.data.content && item.data.content.car
                  ? item.data.content.car.car_plate
                  : '',
              username:
                item.data.content && item.data.content.user_name
                  ? item.data.content.user_name
                  : '',
              type:
                item.data.content &&
                item.data.content.car &&
                item.data.content.car.price_type
                  ? `(${$t(`price_types[${item.data.content.car.price_type}]`).desc})`
                  : '',
              payment_method:
                item.data.content && item.data.content?.payment_location === 'online'
                  ? $t(`payment_location.${item.data.content.payment_location}`)
                  : item.data.content && item.data.content.payment_method
                    ? $t('payment_methods_cs').filter(
                        (i) => i.key === item.data.content.payment_method
                      )[0].desc
                    : '',
              sum: item.data.content && item.data.content.amount / 100,
              dur: getDuration(item),
              car_from:
                item.name === 'guest_type_mutate'
                  ? item.data.content.car_mutated.car_plate
                  : '',
              from_type:
                item.name === 'guest_type_mutate'
                  ? $t('price_types').filter(
                      (i) => i.id === item.data.content.car_mutated.from_guest_type
                    )[0].desc
                  : '',
              to_type:
                item.name === 'guest_type_mutate'
                  ? $t('price_types').filter(
                      (i) => i.id === item.data.content.car_mutated.to_guest_type
                    )[0].desc
                  : '',
              car_exit:
                item.name === 'guest_type_mutate'
                  ? item.data.content.car_exit.car_plate
                  : '',
              car_exit_type:
                item.name === 'guest_type_mutate'
                  ? $t('price_types').filter(
                      (i) => i.id === item.data.content.car_exit.price_type
                    )[0].desc
                  : '',
              currency:
                item.data.content && item.data.content?.currency
                  ? $t(`currency_symbols.${item.data.content.currency}`)
                  : '',
              payment_location:
                item.data.content && item.data.content?.payment_location
                  ? $t(`payment_location.${item.data.content.payment_location}`)
                  : '',
              requested_from:
                item.data.content && item.data.content?.payment_request_origin
                  ? $t(`log_requested_from.${item.data.content.payment_request_origin}`)
                  : '',
            })
          "
        ></div>
        <div
          v-if="
            item.data.content &&
            item.data.content.car_mutated &&
            item.data.content.car_mutated.parking_fee_applied
          "
        >
          {{
            $t("log_parking_fee", {
              fee: item.data.content.car_mutated.parking_fee / 100,
            })
          }}
        </div>
      </template>
      <template v-slot:[`item.details`]="{ item }">
        <div class="table-duration" v-if="item.data.content && item.data.content.reason">
          {{
            $t(`log_reasons.${item.data.content.reason}`, {
              n:
                item.data.content.reason === "monthly_no_space" &&
                item.data.content.spaces
                  ? item.data.content.spaces.occupied
                  : "",
              vehicles:
                item.data.content.reason === "monthly_no_space" &&
                item.data.content.spaces
                  ? item.data.content.spaces.parked_cars
                    ? item.data.content.spaces.parked_cars.join(", ")
                    : ""
                  : "",
              date:
                item.data.content.reason === "monthly_expired" &&
                item.data.content.subscription
                  ? $moment(item.data.content.subscription.end_at).format(
                      "DD MMM YYYY [at] HH:mm"
                    )
                  : "",
              payment_method:
                item.data.content && item.data.content?.payment_method
                  ? $t('payment_methods_cs').filter(
                    (i) => i.key === item.data.content.payment_method
                  )[0].desc
                  : '',
            })
          }}
        </div>
        <div
          class="table-duration"
          v-if="
            item.data.content &&
            item.data.content.octopus_error_info &&
            item.data.content.octopus_error_info.error_code
          "
        >
          <template
            v-if="octopusErrors.includes(item.data.content.octopus_error_info.error_code)"
          >
            {{
              $t(
                `octopus_error_codes.${item.data.content.octopus_error_info.error_code}`
              )
            }}</template
          >
          <template v-else>{{ $t(`octopus_error_codes.other`) }}</template>
        </div>
        <div
          class="table-duration"
          v-if="
            item.data.content &&
            item.data.content.octopus_error_info &&
            item.data.content.octopus_error_info.octopus_no
          "
        >
          {{
            $t(`octopus_retry`, { n: item.data.content.octopus_error_info.octopus_no })
          }}
        </div>
      </template>
    </v-data-table>

    <div v-if="busy && !loading">
      <v-skeleton-loader v-for="i in 3" :key="i" type="table-row"></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
import { base } from "@/axios-api";
import { Centrifuge } from "centrifuge";
import { formatPlate } from "../common/helpers/helpers";
const CENTR_HOST = "wss://api.parkingbnb.world/tv/centrifugo/connection/websocket";
export default {
  name: "Logbook",
  data: () => {
    return {
      openFilterForm: false,
      openReportForm: false,
      isTyping: false,
      gatePopup: false,
      debouncedInput: "",
      timeout: null,
      gateID: "",
      params: {
        sort_by: "created_at",
        sort_order: "desc",
        limit: 50,
        // from: this.$moment().subtract(1, "minutes"),
      },
      busy: false,

      logs: [],
      octopusErrors: [
        "100016",
        "100017",
        "100020",
        "100032",
        "100034",
        "100019",
        "100021",
        "100024",
        "100035",
        "100048",
        "100049",
      ],
    };
  },
  components: {
    CoreFilter: () => import("@/components/core/Filter"),
    ReportDownloadForm: () => import("@/components/core/ReportDownloadForm"),
    ParkingOccupancy: () => import("@/components/Dashboard/ParkingOccupancy"),
  },
  computed: {
    loading() {
      return this.$store.state.logbook.loading;
    },
    records() {
      return this.$store.state.logbook.history;
    },
    gates() {
      return this.$store.state.streaming.stream_sources;
    },
    headers() {
      return [
        {
          text: this.$t("date"),
          value: "created_at",
          isDesc: true,
          class: "table-date table-header",
        },
        {
          text: this.$t("action"),
          value: "event",
          sortable: false,
          class: "table-header",
        },
        {
          text: this.$t("details"),
          value: "details",
          sortable: false,
          class: "table-header",
        },
      ];
    },
    search: {
      get() {
        return this.debouncedInput;
      },
      set(val) {
        this.debouncedInput = formatPlate(val);
        this.$forceUpdate();
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.searchByCar(this.debouncedInput);
        }, 1000);
      },
    },
    token() {
      return this.$store.state.app.cs_token;
    },
    refreshToken() {
      return this.$store.state.app.cs_refresh_token;
    },
    place() {
      return this.$store.state.app.places[0]
        ? this.$store.state.app.places[0].place_id
        : "";
    },
    devices() {
      return this.$store.state.streaming.stream_sources;
    },
  },
  watch: {
    // loading() {},
    records(oldValue, newValue) {
      if (oldValue.length !== newValue.length) {
        this.busy = false;
        this.logs = this.records;
      }
    },
  },
  mounted() {
    this.scroll();
    this.openCSChannel();
    this.logs = this.records;
  },
  methods: {
    updFilters(filters) {
      let from = filters.dates[0] ? this.$moment(filters.dates[0]).toISOString() : null;
      let to = filters.dates[1] ? this.$moment(filters.dates[1]).toISOString() : null;
      if (from) this.params.from = from;
      if (to) this.params.to = to;
      this.updateTable();
    },
    getDuration(item) {
      if (item.name !== "charge_start") return;
      let unixtime = item.data.content.duration / 1000000000;
      let duration = this.$moment.duration(unixtime, "seconds");
      let months = duration._data.months
        ? duration._data.months + this.$t("date_shorts.months")
        : "";
      let days = duration._data.days
        ? duration._data.days + this.$t("date_shorts.days")
        : "";
      let hours = duration._data.hours
        ? duration._data.hours + this.$t("date_shorts.hours")
        : "";
      let minutes = duration._data.minutes;

      if (duration._data.seconds && duration._data.minutes !== 59) {
        minutes = minutes + 1;
      }

      minutes = minutes + this.$t("date_shorts.minutes");

      return `${months} ${days} ${hours} ${minutes}`;
    },

    scroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          Math.ceil(document.documentElement.scrollTop) + window.innerHeight >=
          document.documentElement.offsetHeight;
        if (
          bottomOfWindow &&
          !this.loading &&
          !this.busy &&
          (this.$route.path === "/platform/logbook" ||
            this.$route.path === "/promo/logbook")
        ) {
          this.loadMore();
        }
      };
    },

    loadMore() {
      this.busy = true;

      this.params = {
        ...this.params,
        limit: this.params.limit + 50,
      };
      this.updateData({ ...this.params, incrementing: true });
    },
    customSort(index, sort_order) {
      this.headers.map((h) => {
        if (this.headers.indexOf(h) !== index) {
          h.isDesc = undefined;
        }
      });
      this.headers[index].isDesc = sort_order === "desc";
      this.params = {
        ...this.params,
        sort_by: this.headers[index].value,
        sort_order,
      };
      this.updateTable();
    },
    searchByCar(value) {
      this.params = {
        ...this.params,
        plate: value,
      };

      this.updateTable();
    },
    updateData(params) {
      // if (!params.from) params.from = this.$moment().subtract(5, "minutes").toISOString();
      this.$store.dispatch("logbook/getLogsHistory", { ...params });
    },
    updateTable() {
      this.updateData({ ...this.params });
    },
    hideGatePopup() {
      this.gatePopup = false;
      this.gateID = "";
    },
    showGatePopup() {
      this.gatePopup = true;
    },
    openGate(device) {
      this.$confirm({
        message: this.$t("gate_open_prompt", { description: device.description }),
        button: {
          no: this.$t("cancel"),
          yes: this.$t("confirm"),
        },
        callback: (confirm) => {
          if (confirm) {
            console.log(device);
            this.$store.dispatch("streaming/openGate", {
              gate_id: device.gate_id,
              reason_id: 5,
              notify: true,
            });
          }
        },
      });
    },
    getToken() {
      return new Promise((resolve, reject) => {
        base({
          url: "/auth/token/refresh-centrifugo",
          params: { token: this.refreshToken },
        })
          .then((res) => {
            if (!res.status === 200) {
              throw new Error(`Unexpected status code ${res.status}`);
            }
            let data = res.data.body;
            this.$store.commit("app/cs_auth_success", data);
            // console.log(data.access_token);
            resolve(data.access_token);
          })
          .catch((err) => {
            reject(err);
            this.$store.commit("app/logout");
            // location.reload();
          });
      });
    },
    openCSChannel() {
      console.log("connect centrifuge");
      if (this.centrifuge && this.centrifuge.state === "connecting") {
        console.log("aborted counter connection");
        return;
      }
      this.centrifuge = new Centrifuge(CENTR_HOST, {
        token: this.token,
        getToken: () => this.getToken(),
      });

      this.centrifuge.connect();
      this.centrifuge.on("connected", (data) => {
        console.log(`connected ${JSON.stringify(data)}`);
        this.connected = false;
      });
      this.centrifuge.on("connecting", (data) => {
        console.log(`connecting ${JSON.stringify(data)}`);
      });
      this.centrifuge.on("error", (err) => {
        console.log(`error ${JSON.stringify(err)}`);
        if (err.error.code === 3500 || err.error.code === 109) {
          // CODE 2 = TRANSPORT CLOSED
          // CODE 3500 = INVALID TOKEN
          // CODE 109 = EXPIRED TOKEN
          if (!this.connecting && this.$store.getters["app/authorized"]) {
            // console.log("auto relogin");
            // this.$store.dispatch("app/counterLogin").then(() => {
            //   this.openCSChannel();
            // });
          }
        }
      });
      this.centrifuge.on("disconnected", (data) => {
        console.log(`disconnected ${JSON.stringify(data)}`);
      });
      this.centrifuge.on("publication", (data) => {
        let push = data;
        // console.log(push);
        if (["events_987", "events_4738", "events_5569", "events_4735", "events_3840", "events_5991"].includes(push.channel)) {
          this.logs.unshift(push.data);
        }
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      let watcher = setInterval(() => {
        if (vm.$store.state.app.places.length) {
          clearInterval(watcher);
          vm.updateTable();
        }
      }, 500);
    });
  },
  beforeRouteLeave(to, from, next) {
    this.centrifuge.disconnect();
    next();
  },
};
</script>
