var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items-per-page":-1,"items":_vm.records,"disable-sort":"","hide-default-footer":"","mobile-breakpoint":"0","no-data-text":_vm.$t('no_data')},on:{"click:row":_vm.openApplication},scopedSlots:_vm._u([_vm._l((_vm.headers),function(i){return {key:`header.${i.value}`,fn:function({ header }){return [_c('div',{key:i.text,staticClass:"d-flex align-center",class:header.value === 'price' ||
          header.value === 'months' ||
          header.value === 'slots'
            ? 'justify-center'
            : ''},[(header.sortable !== false)?_c('div',{staticClass:"triangle-parent",staticStyle:{"height":"12px"}},[_c('div',{staticClass:"triangle up",class:header.isDesc === false ? 'active' : '',on:{"click":function($event){_vm.customSort(_vm.headers.indexOf(header), 0)}}}),_c('div',{staticClass:"triangle down",class:header.isDesc ? 'active' : '',on:{"click":function($event){_vm.customSort(_vm.headers.indexOf(header), 1)}}})]):_vm._e(),_c('div',[_vm._v(_vm._s(header.text))])])]}}}),{key:`item.user`,fn:function({ item }){return [_c('div',{staticClass:"table-user-name"},[_c('h4',[_vm._v(_vm._s(item.user_name || _vm.$t("unnamed")))])])]}},{key:`item.parking`,fn:function({ item }){return [_c('div',{staticClass:"table-parking d-flex justify-start align-center"},[_c('div',{staticClass:"table-parking-img"},[_c('img',{staticClass:"parking-img table-img",attrs:{"src":_vm.places.filter((p) => p.place_id === item.place.id)[0].avatar
                ? _vm.places.filter((p) => p.place_id === item.place.id)[0].avatar
                : 'https://storage.googleapis.com/parkingbnb-html-images/avatar.svg',"alt":item.place.name}})]),_c('span',{staticStyle:{"max-width":"100px"}},[_vm._v(_vm._s(item.place.name))])])]}},{key:`item.start_date`,fn:function({ item }){return [_c('div',{staticClass:"table-date"},[_vm._v(" "+_vm._s(_vm.$moment(item.active_from).format("DD.MM.YYYY"))+" ")])]}},{key:`item.price_type`,fn:function({ item }){return [_c('div',{staticClass:"table-price text-center"},[_vm._v(" "+_vm._s(_vm.$t(`monthly_price_options`).filter((i) => i.id === item.price_option_id)[0] .text)+" ")])]}},{key:`item.price`,fn:function({ item }){return [_c('div',{staticClass:"table-price text-center"},[_vm._v(_vm._s(item.amount / 100)+"HKD")])]}},{key:`item.status`,fn:function({ item }){return [_c('div',{staticClass:"table-status"},[_vm._v(" "+_vm._s(_vm.$t(`pass_status_cp[${item.status - 1}]`))+" ")])]}}],null,true)}),_c('PassRecord',{ref:"popup",attrs:{"record":_vm.selected}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }