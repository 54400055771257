import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdiSvg"
  },
  theme: {
    disable: true
  },
});


// import Vue from 'vue'
// import 'vuetify/dist/vuetify.min.css'
// import Vuetify, {
//   VBtn,
//   VApp,
//   VSelect,
//   VSkeletonLoader,
//   VTab,
//   VRow,
//   VCol,
//   VMenu,
//   VDataTable,
//   VTabs,
//   VDivider,

// } from 'vuetify/lib'

// Vue.use(Vuetify, {
//   components: {
//     VBtn,
//     VApp,
//     VSelect,
//     VSkeletonLoader,
//     VTab,
//     VRow,
//     VCol,
//     VMenu,
//     VDataTable,
//     VTabs,
//     VDivider,
//   },
// })

// const opts = {}

// export default new Vuetify(opts)