<template>
  <div>
    <h3 class="main-title">{{ name }}</h3>
    <h4 class="parking-subtitle mr-2 mt-5">{{ $t("promocodes") }}</h4>
    <div class="parking-header d-flex justify-start align-center flex-wrap mt-4">
      <v-text-field
        v-model="search"
        autocomplete="off"
        class="parking-search ml-0 mr-auto"
        solo
        :placeholder="$t('search_campaign')"
      ></v-text-field>
      <v-btn
        class="main-btn px-4 py-2 mr-4"
        @click="generateCodes"
        v-if="place || !isCompany"
        >{{ $t("promocodes_generate") }}</v-btn
      >
      <v-btn class="main-btn px-4 py-2" @click="addCodes" v-if="place || !isCompany"
        >{{ $t("add") }} {{ $t("promocode") }}</v-btn
      >
    </div>
    <PromocodesList :searchValue="search" />
    <GenerateCodesForm :selected="{}" ref="generationForm" />
    <CodeForm :selected="{}" ref="codeForm" />
  </div>
</template>

<script>
export default {
  name: "Promocodes",
  components: {
    PromocodesList: () => import("@/components/Promo/PromocodesList"),
    GenerateCodesForm: () => import("@/components/Promo/GenerateCodesForm"),
    CodeForm: () => import("@/components/Promo/CodeForm"),
  },
  data() {
    return {
      openPromo: null,
      search: "",
    };
  },
  computed: {
    isCompany() {
      return this.$store.getters["app/isCompany"];
    },
    name() {
      let name = "";
      if (this.isCompany && this.$store.state.app.user.company_name) {
        name = this.$store.state.app.user.company_name;
      } else {
        name = this.$store.state.app.places[0]
          ? this.$store.state.app.places[0].place_name
          : "";
      }
      return name;
    },
    place() {
      return this.$store.state.promo.place;
    },
  },
  mounted() {},
  methods: {
    generateCodes() {
      this.$refs.generationForm.show();
    },
    addCodes() {
      this.$refs.codeForm.show();
    },
  },
};
</script>
