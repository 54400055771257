<template>
  <div class="tabs-content">
    <div class="d-flex align-center mt-5 mb-8">
      <v-tabs v-model="tab" class="mr-6 ml-0 mb-0">
        <v-tab v-for="(item, index) in tabs" :key="index">{{ $t(`${item}`) }}</v-tab>
      </v-tabs>
      <v-btn text class="mr-auto dwl-btn" @click.stop="refresh">
        <img
          :style="{ height: '24px', width: '24px' }"
          src="@/assets/images/refresh.svg"
          alt="refresh"
        />
      </v-btn>
    </div>
    <div>
      <img :src="sources[tab]" style="width: 100%" />
    </div>
  </div>
</template>
<script>
export default {
  name: "Occupancy",
  data() {
    return {
      drawer: false,
      tab: 0,
    };
  },
  computed: {
    tabs() {
      return ["Core C", "Core D", "Core E", "Core F"];
    },
    sources() {
      return {
        0: require("@/assets/images/zonecMap.webp"),
        1: require("@/assets/images/zonedMap.webp"),
        2: require("@/assets/images/zoneeMap.webp"),
        3: require("@/assets/images/zonefMap.webp"),
      };
    },
  },
  methods: {
    refresh() {},
  },
};
</script>
